import React, {useState} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../../hooks/useApi.js";

// Components
import Modal from "../../components/Modal.js";
import SearchSelect from "../../components/SearchSelect.js";

// Style
import {breakpoint} from "../../style/components/breakpoints.js";
import {
  Button,
  Form,
  FormField,
  FormGroup,
  HeadingCenter,
  Label,
  SearchWrapper
} from "../../style/components/general.js";

const defaultValues = {
  user: null
};

const schema = yup.object().shape({
  user: yup.object().required("Please provide user.")
});

const ModalReassignUser = ({visible, setVisible, goBack, update, facilityId, current}) => {
  const [users, setUsers] = useState([]);

  const {api} = useApi("facility-users");

  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  });
  const {handleSubmit, setValue, watch} = form;

  const selectedUser = watch("user");

  const handleSearchUser = query => {
    const filter = {
      showHidden: true,
      Omit: [current]
    };

    if (query) filter.Search = query;

    const params = {
      facilityId: facilityId,
      limit: query ? 5 : 20,
      filter: JSON.stringify(filter),
      noTest: true
    };

    api.callGet("", params).then(({status, data}) => {
      if (status === 200 && data) setUsers(data?.map(user => ({...user, id: user.publicId})) || []);
    });
  };

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      hasBackButton={!!goBack}
      goBack={goBack}
      width={breakpoint.width[1]}>
      <HeadingCenter>Reassign Record</HeadingCenter>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(update)} noValidate>
          <FormGroup>
            <FormField>
              <Label bold>USER</Label>
              {selectedUser ? (
                <Button quiet onClick={() => setValue("user", null)} type="button">
                  {selectedUser.email}&nbsp;
                  <FontAwesomeIcon icon={faClose} />
                </Button>
              ) : (
                <SearchWrapper>
                  <SearchSelect
                    placeholder="Search users..."
                    search={handleSearchUser}
                    results={users}
                    setResults={setUsers}
                    add={selected => {
                      setValue("user", selected);
                    }}
                    showAll
                  />
                </SearchWrapper>
              )}
            </FormField>
          </FormGroup>
          <Button type="submit">Reassign</Button>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalReassignUser.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  facilityId: PropTypes.number.isRequired,
  current: PropTypes.string.isRequired,
  goBack: PropTypes.func
};

export default ModalReassignUser;
