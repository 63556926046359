import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ChartShaded = ({chartId, x, y, height, width, color}) => (
  <Rect
    x={x}
    y={y}
    width={width}
    height={height}
    fill={color}
    color={color}
    clipPath={`url(#clip-sp-${chartId})`}
  />
);

ChartShaded.propTypes = {
  chartId: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
};

// Style Overrides
const Rect = styled.rect`
  opacity: 0.3;
  fill: ${({color}) => color || "transparent"};
  pointer-events: none;
`;

export default ChartShaded;
