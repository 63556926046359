import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {faFont, faLineChart, faPercent, faTable} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Components
import Modal from "../../components/Modal.js";

// Style
import {pad} from "../../style/components/variables.js";
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {breakpoint} from "../../style/components/breakpoints.js";
import {Button, HeadingCenter, Inline, Small, Text} from "../../style/components/general.js";

// Constants
import {AGGREGATE, CHART, STAT, STATIC, TABLE} from "../../utils/report.js";

const ModalSectionType = ({visible, setVisible, handleSelection}) => (
  <Modal visible={visible} setVisible={setVisible} maxWidth={breakpoint.width[1]}>
    <StyledHeading>Select Type</StyledHeading>
    <Options>
      <Option
        onClick={() => {
          handleSelection(TABLE);
        }}>
        <OptionLabel>
          <FontAwesomeIcon icon={faTable} />
          <OptionSmallText>Table</OptionSmallText>
        </OptionLabel>
      </Option>
      <Option
        onClick={() => {
          handleSelection(AGGREGATE);
        }}>
        <OptionLabel>
          <FontAwesomeIcon icon={faTable} />
          <OptionSmallText>Aggregate Table</OptionSmallText>
        </OptionLabel>
      </Option>
    </Options>
    <Options>
      <Option
        onClick={() => {
          handleSelection(STATIC);
        }}>
        <OptionLabel>
          <FontAwesomeIcon icon={faFont} />
          <OptionSmallText>Static Element</OptionSmallText>
        </OptionLabel>
      </Option>
      <Option
        onClick={() => {
          handleSelection(CHART);
        }}>
        <OptionLabel>
          <FontAwesomeIcon icon={faLineChart} />
          <OptionSmallText>Chart</OptionSmallText>
        </OptionLabel>
      </Option>
      <Option
        onClick={() => {
          handleSelection(STAT);
        }}>
        <OptionLabel>
          <FontAwesomeIcon icon={faPercent} />
          <OptionSmallText>Statistic</OptionSmallText>
        </OptionLabel>
      </Option>
    </Options>
  </Modal>
);

ModalSectionType.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired
};

// Style Overrides
const Option = styled(Button)`
  width: 50%;
  border-width: 0;
  height: 100px;
`;

const OptionLabel = styled(Text)`
  ${flex("column", "wrap", "center", "center")};
  ${voice.medium};
  font-weight: bold;
  text-align: center;
  min-width: 100%;
  text-transform: uppercase;
  background-color: ${props => props.theme.secondary};
  color: ${props => props.theme.tertiary};
  padding: ${pad}px;

  svg {
    fill: ${props => props.theme.tertiary};
  }
`;

const OptionSmallText = styled(Small)`
  margin-top: ${pad}px;
`;

const Options = styled(Inline)`
  gap: ${pad}px;
  margin-top: ${pad}px;
  justify-content: space-around;
  width: 100%;
`;

const StyledHeading = styled(HeadingCenter)`
  ${voice.medium}
`;

export default ModalSectionType;
