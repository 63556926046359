import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useApi from "../../hooks/useApi.js";

// Utils
import {GROUP, COMPONENT, FIELD, filterNonexistentField} from "../../utils/builder.js";
import {addCommas, convertToUserTimezone, exists, fromMilitary} from "../../utils/helpers.js";
import {
  evaluateInvalid,
  evaluateRange,
  evaluateTemporalConditional,
  getAncestryName
} from "../checksheet-builder/helpers.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {border, colors, pad, radius} from "../../style/components/variables.js";
import {
  Title,
  Small,
  Text,
  Inline,
  Error,
  FormGroupHeading,
  Pill,
  Abbr
} from "../../style/components/general.js";

const RenderSubmission = React.forwardRef(
  (
    {
      submission,
      submissionName,
      builder,
      notifications,
      dateDue,
      completedAt,
      draft,
      overdue,
      modifications,
      highlight,
      viewModification,
      filterResult
    },
    ref
  ) => {
    const {settings} = useContext(SettingsContext);

    const {api: fileApi} = useApi("files");

    const [fileDict, setFileDict] = useState(null);

    const links = useMemo(() => {
      if (fileDict) return fileDict.links;
      return null;
    }, [fileDict]);

    const files = useMemo(() => {
      if (fileDict) return fileDict.files;
      return null;
    }, [fileDict]);

    const shouldShowConditional = condition => {
      if (!condition || !condition.action || !condition.list || condition.list.length === 0)
        return true;

      const shouldHideOnTrue = condition.action.includes("Hide");
      const shouldShowOnTrue = condition.action.includes("Show");

      if (!shouldHideOnTrue && !shouldShowOnTrue) return true;

      for (let i = 0; i < condition.list.length; i++) {
        if (condition.list[i].depends === "weekday" || condition.list[i].depends === "date") {
          const result = evaluateTemporalConditional(
            convertToUserTimezone(completedAt || dateDue, settings?.timezone),
            condition.list[i].depends,
            condition.list[i].compare
          );

          if (result && shouldHideOnTrue) return false;
          if (result && shouldShowOnTrue) return true;
        }
      }

      return true;
    };

    useEffect(() => {
      let fileIds = Object.keys(submission.responses)
        .filter(
          key =>
            builder?.byId[key] &&
            builder.byId[key].type === "upload" &&
            exists(submission.responses[key]) &&
            submission.responses[key] !== "_CONDITION_UNSATISFIED"
        )
        .flatMap(key => `${submission.responses[key]}`.split(","));

      if (modifications)
        fileIds = [
          ...fileIds,
          ...Object.keys(modifications)
            .filter(
              key =>
                builder?.byId[key] &&
                builder.byId[key].type === "upload" &&
                exists(submission.responses[key]) &&
                submission.responses[key] !== "_CONDITION_UNSATISFIED"
            )
            .map(key => modifications[key])
        ];

      if (fileIds?.length > 0)
        fileApi.callGet("", {ids: fileIds, getSerialized: true}).then(({status, data}) => {
          if (status === 200) setFileDict(data || {});
        });
      else setFileDict({});
    }, [builder, fileApi, modifications, submission]);

    const formatDiff = (
      name,
      value,
      oldValue,
      qualifier = null,
      oldQualifier = null,
      type = null,
      units = null,
      precision = null,
      degree = null
    ) => {
      const newResponse = !oldValue && oldValue !== undefined;

      let coalescedQualifier = oldQualifier;
      let coalescedValue = oldValue;
      if (coalescedQualifier || coalescedValue) {
        // handles case where qualifier changed but value did not, needs to have current version's value
        coalescedValue = coalescedValue || value;
        // handles case where value changed but qualifier did not, needs to have current version's qualifier
        coalescedQualifier = coalescedQualifier || qualifier;
      }

      if (type === "upload" && files && links) {
        const oldFileIds = oldValue?.split(",");
        const newFileIds = value?.split(",");
        const oldFiles = oldFileIds?.map(oldFileId => links[oldFileId]);
        const newFiles = newFileIds?.map(newFileId => files[newFileId]);

        const oldFileLinks = oldFileIds?.map(oldFileId => links[oldFileId]);
        const newFileLinks = newFileIds?.map(newFileId => links[newFileId]);

        coalescedValue = oldFiles?.map((oldFile, i) => (
          <a
            // eslint-disable-next-line react/no-array-index-key
            key={`${name}-${i}-old`}
            href={oldFileLinks[i]}
            target="_blank"
            rel="noreferrer">
            {oldFile?.label || "Uploaded File"}
          </a>
        ));

        value = newFiles?.map((newFile, i) => (
          <a
            // eslint-disable-next-line react/no-array-index-key
            key={`${name}-${i}-new`}
            href={newFileLinks[i]}
            target="_blank"
            rel="noreferrer">
            {newFile?.label || "Uploaded File"}
          </a>
        ));
      } else if (type === "upload")
        return <StyledSpan data-testid="response.newVal">Loading file...</StyledSpan>;

      if (type === "number" || type === "generated" || type === "range") {
        const precisionOverride =
          degree === "Decimal" || type === "generated" ? precision ?? 2 : undefined;

        coalescedValue = addCommas(coalescedValue, precisionOverride);
        value = addCommas(value, precisionOverride);
        if (units) {
          coalescedValue = exists(coalescedValue) ? `${coalescedValue} ${units}` : coalescedValue;
          value = exists(value) ? `${value} ${units}` : value;
        }
      }

      return (
        <FlexSpan>
          {viewModification && (coalescedValue || coalescedQualifier || newResponse) && (
            <>
              <Strikethrough data-testid="response.oldVal" isFile={type === "upload"}>
                {!newResponse &&
                coalescedQualifier &&
                coalescedQualifier !== "DNQ" &&
                coalescedQualifier !== "ND" &&
                coalescedQualifier !== "EST"
                  ? `${coalescedQualifier} `
                  : ""}
                {!newResponse && coalescedQualifier && coalescedQualifier === "ND" ? "< " : ""}
                {!newResponse ? coalescedValue : "NO RESPONSE PROVIDED"}
                {!newResponse &&
                coalescedQualifier &&
                (coalescedQualifier === "DNQ" ||
                  coalescedQualifier === "ND" ||
                  coalescedQualifier === "EST")
                  ? ` (${coalescedQualifier})`
                  : ""}
              </Strikethrough>{" "}
            </>
          )}
          <StyledSpan data-testid="response.newVal" isFile={type === "upload"}>
            {qualifier && qualifier !== "DNQ" && qualifier !== "ND" && qualifier !== "EST"
              ? `${qualifier} `
              : ""}
            {qualifier && qualifier === "ND" ? "< " : ""}
            {/* if original submission is requested, we must get the original values from modifications object of first change made */}
            {value}
            {qualifier && (qualifier === "DNQ" || qualifier === "ND" || qualifier === "EST")
              ? ` (${qualifier})`
              : ""}
          </StyledSpan>
        </FlexSpan>
      );
    };

    const getOutOfRangeComment = name => {
      if (
        submission.responses[`${name}_comment`] &&
        submission.responses[`${name}_comment`] !== "_NEEDS_COMMENT"
      )
        return submission.responses[`${name}_comment`];
      if (submission.responses[name] && !draft && !overdue)
        return "Field was submitted with an unacceptable parameter";
      return "";
    };

    const renderFields = ids => {
      const rendered = ids
        ?.filter(
          id =>
            submission.responses[id] !== "_CONDITION_UNSATISFIED" &&
            builder.byId[id] &&
            shouldShowConditional(builder.byId[id].condition) &&
            !builder.byId[id].hidden &&
            filterNonexistentField(id, builder.byId, submission.responses)
        )
        ?.map(id => {
          const elementObj = builder.byId[id];
          const {
            label,
            name,
            tag,
            element,
            hasAlert,
            hasARange,
            aMin,
            aMax,
            hasSetPoint,
            setLow,
            setHigh,
            type,
            hasQualifier,
            children,
            military,
            units,
            alertCondition,
            degree,
            precision
          } = elementObj;

          let precisionNumber = precision;
          if (exists(precisionNumber) && typeof precisionNumber === "string")
            precisionNumber = parseInt(precisionNumber, 10);

          let value = submission.responses[id];
          let oldValue;

          const hasExplanation =
            exists(value) ||
            (modifications &&
              `${name}_comment` in modifications &&
              modifications[`${name}_comment`] !== "_NEEDS_COMMENT");

          if (modifications && id in modifications) oldValue = modifications[id];
          // if (isOriginal) value = oldValue ?? value;

          if (type === "time") {
            value = military ? value : fromMilitary(value);
            oldValue = military || !oldValue ? oldValue : fromMilitary(oldValue);
          }

          if (type === "generated") {
            value = value === "_NO_PREVIOUS" || value === "_DEP_IS_INF" ? "" : value;
            oldValue = oldValue === "_NO_PREVIOUS" || value === "_DEP_IS_INF" ? "" : oldValue;
          }

          let qualifier = null;
          let oldQualifier = null;

          if (hasQualifier && submission.responses[`${name}_qualifierEnabled`])
            qualifier = submission.responses[`${name}_qualifier`];

          if (
            hasQualifier &&
            modifications &&
            (`${name}_qualifierEnabled` in modifications || modifications[`${name}_qualifier`])
          )
            oldQualifier = modifications[`${name}_qualifier`];

          const renderedChildren = children?.length > 0 ? renderFields(children) : [];
          if (
            element !== FIELD &&
            // only show element when it has visible children
            (!renderedChildren ||
              renderedChildren.length === 0 ||
              renderedChildren.every(child => !child))
          )
            return null;

          if (element === GROUP)
            return (
              <Group key={name}>
                <StyledGroupHeading>{label.toUpperCase()}</StyledGroupHeading>
                {renderedChildren}
              </Group>
            );

          if (element === COMPONENT)
            return (
              <Component key={name}>
                <StyledComponentHeading>{label.toUpperCase()}</StyledComponentHeading>
                {renderedChildren}
              </Component>
            );

          if (element === FIELD && (!filterResult || filterResult[id])) {
            let invalidCategorical = false;
            let invalidNumerical = false;
            if (exists(value) && hasAlert && exists(alertCondition))
              invalidCategorical = evaluateInvalid(value, alertCondition);
            if (exists(value) && hasARange && (exists(aMin) || exists(aMax)))
              invalidNumerical = evaluateRange(value.replace(/,/g, ""), elementObj, qualifier);

            return (
              <Fragment key={name}>
                <Field>
                  <Inline>
                    <LabelWrapper>
                      <Key data-testid={name}>{label.toUpperCase()}</Key>
                      {tag && (
                        <TagPill>
                          <Abbr title={tag}>{tag}</Abbr>
                        </TagPill>
                      )}
                    </LabelWrapper>
                    <Value highlight={highlight && highlight === name}>
                      {exists(value)
                        ? formatDiff(
                            name,
                            value,
                            oldValue,
                            qualifier,
                            oldQualifier,
                            type,
                            units,
                            precisionNumber,
                            degree
                          )
                        : "NO RESPONSE PROVIDED"}
                      <Small>{hasSetPoint && `(Set point: ${setLow} - ${setHigh})`}</Small>
                      {highlight && highlight === name && <Small>&nbsp;(Target Value)</Small>}
                    </Value>
                  </Inline>
                  {/* Messages */}
                  {invalidNumerical && (
                    <Exception>
                      {exists(aMin) && !exists(aMax)
                        ? `Unacceptable value reported. Must be greater than ${aMin} ${units}.`
                        : ""}
                      {exists(aMax) && !exists(aMin)
                        ? `Unacceptable value reported. Must be less than ${aMax} ${units}.`
                        : ""}
                      {exists(aMin) && exists(aMax)
                        ? `Unacceptable value reported. Must be within range ${aMin} - ${aMax} ${units}.`
                        : ""}
                    </Exception>
                  )}
                  {invalidCategorical && Array.isArray(alertCondition) && (
                    <Exception>
                      Unacceptable value reported. Unacceptable Values: {alertCondition.join(", ")}
                    </Exception>
                  )}
                  {invalidCategorical && !Array.isArray(alertCondition) && (
                    <Exception>
                      Unacceptable value reported. Unacceptable Value: {alertCondition}
                    </Exception>
                  )}
                </Field>

                {invalidNumerical && hasExplanation && (
                  <Field>
                    <Inline>
                      <Explanation data-testid={`${name}_comment`}>
                        Provided&nbsp;Explanation:
                      </Explanation>

                      <Value quiet>
                        {modifications &&
                        `${name}_comment` in modifications &&
                        modifications[`${name}_comment`] !== "_NEEDS_COMMENT"
                          ? formatDiff(
                              name,
                              submission.responses[`${name}_comment`] === "_NEEDS_COMMENT"
                                ? ""
                                : submission.responses[`${name}_comment`],
                              modifications[`${name}_comment`] === "_NEEDS_COMMENT"
                                ? ""
                                : modifications[`${name}_comment`]
                            )
                          : getOutOfRangeComment(name)}
                      </Value>
                    </Inline>
                  </Field>
                )}

                {invalidCategorical && hasExplanation && (
                  <Field>
                    <Inline>
                      <Explanation data-testid={`${name}_comment`}>
                        Provided&nbsp;Explanation:
                      </Explanation>

                      <Value quiet>
                        {modifications &&
                        `${name}_comment` in modifications &&
                        modifications[`${name}_comment`] !== "_NEEDS_COMMENT"
                          ? formatDiff(
                              name,
                              submission.responses[`${name}_comment`] === "_NEEDS_COMMENT"
                                ? ""
                                : submission.responses[`${name}_comment`],
                              modifications[`${name}_comment`] === "_NEEDS_COMMENT"
                                ? ""
                                : modifications[`${name}_comment`]
                            )
                          : getOutOfRangeComment(name)}
                      </Value>
                    </Inline>
                  </Field>
                )}

                {value &&
                  type === "weather" &&
                  (submission.responses[`${name}_rainfall`] ||
                    (modifications && modifications[submission.responses[`${name}_rainfall`]])) && (
                    <Field>
                      <Key data-testid={`${name}_rainfall`}>
                        Previous Day&apos;s Cumulative Rainfall:
                      </Key>

                      {modifications && `${name}_rainfall` in modifications
                        ? formatDiff(
                            name,
                            submission.responses[`${name}_rainfall`],
                            modifications[`${name}_rainfall`]
                          )
                        : submission.responses[`${name}_rainfall`]}
                    </Field>
                  )}
              </Fragment>
            );
          }

          return null;
        });

      let hasAddedNotifications = false;
      if (builder?.byId && notifications?.byId)
        Object.keys(notifications.byId).map((key, i) => {
          if (submission.responses[`${key}_comment`]) {
            if (!hasAddedNotifications) rendered.push(<hr key="notificationDivider" />);
            hasAddedNotifications = true;

            const notification = notifications.byId[key];
            const nElKey = `notification${i}`;

            rendered.push(
              <Fragment key={nElKey}>
                <Field>
                  <Key>{notification.name?.toUpperCase() || "MULTI-FIELD PARAMETER"}</Key>
                  {notification?.conditionArray
                    ?.filter(({depends: condDepends}) => builder.byId[condDepends])
                    ?.map(({depends: condDepends, aMin, aMax, alertCondition}, nIdx) => {
                      const {units} = builder.byId[condDepends];
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={`notification-${condDepends}-${nIdx}`}>
                          {exists(aMin) && exists(aMax) && (
                            <Exception>
                              <strong>
                                {getAncestryName(builder.byId[condDepends], builder)}:
                              </strong>{" "}
                              Unacceptable value reported. Must be within range {aMin} - {aMax}{" "}
                              {units}
                            </Exception>
                          )}
                          {exists(aMin) && !exists(aMax) && (
                            <Exception>
                              <strong>
                                {getAncestryName(builder.byId[condDepends], builder)}:
                              </strong>{" "}
                              Unacceptable value reported. Must be greater than {aMin} {units}
                            </Exception>
                          )}
                          {!exists(aMin) && exists(aMax) && (
                            <Exception>
                              <strong>
                                {getAncestryName(builder.byId[condDepends], builder)}:
                              </strong>{" "}
                              Unacceptable value reported. Must be less than {aMin} {units}
                            </Exception>
                          )}
                          {Array.isArray(alertCondition) && (
                            <Exception>
                              <strong>
                                {getAncestryName(builder.byId[condDepends], builder)}:
                              </strong>{" "}
                              Unacceptable value reported. Unacceptable Values:{" "}
                              {alertCondition.join(", ")}
                            </Exception>
                          )}
                          {typeof alertCondition === "string" && (
                            <Exception>
                              <strong>
                                {getAncestryName(builder.byId[condDepends], builder)}:
                              </strong>{" "}
                              Unacceptable value reported. Unacceptable Value: {alertCondition}
                            </Exception>
                          )}
                        </div>
                      );
                    })}
                </Field>
                <Field>
                  <Inline>
                    <Explanation>Provided&nbsp;Explanation:</Explanation>

                    <Value quiet>
                      {modifications && `${key}_comment` in modifications
                        ? formatDiff(
                            key,
                            submission.responses[`${key}_comment`],
                            modifications[`${key}_comment`]
                          )
                        : getOutOfRangeComment(key)}
                    </Value>
                  </Inline>
                </Field>
              </Fragment>
            );
          }
        });

      return rendered;
    };

    const fields = renderFields(builder.allIds);

    return (
      <div ref={ref}>
        <Inline>
          <Title>{submissionName}</Title>
          {submission?.frequency && <Frequency>{submission.frequency.toUpperCase()}</Frequency>}
          {draft && <Draft>INCOMPLETE</Draft>}
        </Inline>
        {fields?.length > 0 && !fields?.every(item => !item) ? (
          fields
        ) : (
          <Message>No fields found for {filterResult ? "these filters" : "this record"}</Message>
        )}
      </div>
    );
  }
);

RenderSubmission.displayName = "Checksheet Submission";

RenderSubmission.propTypes = {
  submission: PropTypes.objectOf(PropTypes.any).isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  notifications: PropTypes.objectOf(PropTypes.any),
  dateDue: PropTypes.string,
  completedAt: PropTypes.string,
  draft: PropTypes.bool,
  overdue: PropTypes.bool,
  modifications: PropTypes.objectOf(PropTypes.any),
  highlight: PropTypes.string,
  viewModification: PropTypes.bool,
  submissionName: PropTypes.string,
  filterResult: PropTypes.objectOf(PropTypes.any)
};

RenderSubmission.defaultProps = {
  dateDue: null,
  completedAt: null,
  draft: false,
  overdue: false,
  modifications: null,
  notifications: null,
  highlight: null,
  viewModification: false,
  submissionName: "",
  filterResult: null
};

// Style Overrides
const Field = styled.div`
  display: block;

  ${({spaced}) =>
    spaced &&
    css`
      margin: ${pad / 2}px 0;
    `}
`;

const Key = styled(Text)`
  color: ${({theme}) => theme.field};
  padding: ${pad}px ${pad}px 0;
  font-weight: bold;
  margin-top: -${pad}px;

  ${bp(3)} {
    padding: ${pad}px;
  }
`;

const Explanation = styled(Text)`
  ${voice.quiet}

  color: ${({theme}) => theme.secondary};
  padding: ${pad}px ${pad}px 0;
  font-weight: bold;
  margin-top: -${pad}px;
  white-space: nowrap;

  ${bp(3)} {
    padding: ${pad}px;
  }
`;

const Value = styled.span`
  display: block;
  color: ${({theme}) => theme.secondary};
  padding: ${pad * 1.5}px ${pad}px 0;
  margin: -${pad}px 0 ${pad / 2}px;

  ${bp(3)} {
    display: inline;
    padding: 0 ${pad}px ${pad}px;
    margin: 0;
  }

  @media print {
    color: ${colors.heroBlack};
  }

  ${({quiet}) =>
    quiet &&
    css`
      ${voice.quiet}
    `}

  ${({highlight, theme}) =>
    highlight &&
    css`
      color: ${theme.highlight};
    `}
`;

const Exception = styled(Error)`
  ${voice.quiet};
  padding: 0 ${pad}px ${pad}px;
`;

const Component = styled.div`
  padding: ${pad}px;
  border-radius: ${radius};

  &:last-child {
    margin-bottom: 0;
  }
`;

const Group = styled.div`
  padding: ${pad}px;
  margin: ${pad}px 0;
  border: ${border} solid ${({theme}) => theme.group};
  border-radius: ${radius};

  @media print {
    display: block;
    page-break-after: auto;
    page-break-inside: avoid;
  }
`;

const StyledComponentHeading = styled(FormGroupHeading)`
  color: ${({theme}) => theme.component};
`;

const StyledGroupHeading = styled(FormGroupHeading)`
  color: ${({theme}) => theme.group};
  ${voice.strong};
`;

const Draft = styled.span`
  ${voice.quiet}
  text-transform: uppercase;
  font-weight: bold;
  border: ${border} solid ${({theme}) => theme.warning};
  padding: ${pad / 10}px ${pad / 4}px;
  border-radius: 10px;
  background: ${({theme}) => theme.warning};
  color: ${({theme}) => theme.tertiary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: ${pad}px;
`;

const Strikethrough = styled.del`
  color: ${({theme}) => theme.error};
  margin-right: ${pad}px;

  ${({isFile}) =>
    isFile &&
    css`
      margin: ${pad / 4}px;
      a {
        :hover {
          text-decoration: line-through underline;
        }
      }
    `}
`;

const StyledSpan = styled.span`
  ${({newVal}) =>
    newVal &&
    css`
      color: ${colors.green};
    `}

  ${({isFile, newVal, theme}) =>
    isFile &&
    css`
      color: ${newVal ? colors.green : theme.secondary};
      margin: ${pad / 4}px;
      a {
        :hover {
          text-decoration: underline;
        }
      }
    `}
`;

const FlexSpan = styled.span`
  ${flex("row", "wrap")}
`;

const LabelWrapper = styled(Inline)`
  gap: ${pad / 2}px;
`;

const Frequency = styled(Pill)`
  min-width: min-content;
`;

const TagPill = styled(Pill)`
  margin-top: -${pad}px;
  margin-left: -${pad / 2}px;
`;

const Message = styled(Text)`
  color: ${({theme}) => theme.secondary};
  margin-top: ${pad * 2}px;
  ${voice.weak};
`;

export default RenderSubmission;
