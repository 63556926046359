import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";

// Utils
import {exists} from "../../utils/helpers";

// Components
import ModalDelete from "../../components/ModalDelete";
import Modal from "../../components/Modal";
import ModalChecksheetRestriction from "./ModalChecksheetRestriction";

// Style
import {voice} from "../../style/components/typography";
import {flex, z} from "../../style/components/mixins";
import {border, pad, radius} from "../../style/components/variables";
import {Button, HeadingCenter, Inline, Text} from "../../style/components/general";

const ModalChecksheetRestrictions = ({
  visible,
  setVisible,
  checksheetRestrictions,
  builder,
  facilityId,
  handleSave
}) => {
  const [target, setTarget] = useState();
  const [targetIdx, setTargetIdx] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);

  const handleEdit = (restriction, idx) => {
    setTargetIdx(idx);
    setTarget(restriction);
    setShowManageModal(true);
  };

  const handleDelete = idx => {
    setTargetIdx(idx);
    setShowDeleteModal(true);
  };

  const update = restriction => {
    if (target && exists(targetIdx)) {
      handleSave(
        checksheetRestrictions.map((r, i) => (i === targetIdx ? restriction : r)),
        targetIdx,
        "edit"
      );
    } else
      handleSave([...checksheetRestrictions, restriction], checksheetRestrictions.length, "add");
  };

  if (showDeleteModal)
    return (
      <ModalDelete
        visible={visible && showDeleteModal}
        setVisible={setVisible}
        hasBackButton
        goBack={() => {
          setTargetIdx(undefined);
          setShowDeleteModal(false);
        }}
        confirmDelete={() => {
          handleSave(
            checksheetRestrictions.filter((_v, i) => i !== targetIdx),
            targetIdx,
            "delete"
          );
          setTargetIdx(undefined);
          setShowDeleteModal(false);
        }}
      />
    );

  if (showManageModal)
    return (
      <ModalChecksheetRestriction
        visible={visible && showManageModal}
        setVisible={setVisible}
        goBack={() => {
          setTarget(undefined);
          setShowManageModal(false);
        }}
        target={target}
        builder={builder}
        facilityId={facilityId}
        checksheetRestrictions={checksheetRestrictions}
        update={update}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <HeadingCenter>Checksheet Restrictions</HeadingCenter>
      <Wrapper>
        {checksheetRestrictions?.map((restriction, idx) => (
          <WrapperInline key={restriction.label}>
            {restriction.label}
            <Inline>
              <Option
                type="button"
                title="Edit"
                onClick={() => handleEdit(restriction, idx)}
                data-testid="condition.edit">
                <FontAwesomeIcon icon={faEdit} />
              </Option>
              <Option
                type="button"
                title="Delete"
                onClick={() => handleDelete(idx)}
                data-testid="condition.delete">
                <FontAwesomeIcon icon={faTrash} />
              </Option>
            </Inline>
          </WrapperInline>
        ))}
      </Wrapper>

      {builder?.allIds?.length > 0 ? (
        <Button type="button" onClick={() => setShowManageModal(true)}>
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;Restriction
        </Button>
      ) : (
        <Centered>
          <Text>Checksheet must have elements to allow creation of a restriction</Text>
        </Centered>
      )}
    </Modal>
  );
};

ModalChecksheetRestrictions.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  checksheetRestrictions: PropTypes.arrayOf(PropTypes.any),
  handleSave: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  facilityId: PropTypes.number.isRequired
};

// Style Overrides

const Wrapper = styled.div`
  margin: ${pad}px 0 ${pad * 2}px;
`;

const WrapperInline = styled(Wrapper)`
  ${flex("row", "nowrap", "space-between", "center")};
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
`;

const Option = styled(Button)`
  font-weight: bold;
  z-index: ${z("above")};
  ${voice.normal}
`;

const Centered = styled(Inline)`
  justify-content: center;
  width: 100%;
`;

export default ModalChecksheetRestrictions;
