import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Components
import Modal from "../../components/Modal.js";
import Review from "./Review.js";
import ModalRecordView from "../checksheets/ModalRecordView.js";

// Styles
import {heroTheme, pad} from "../../style/components/variables.js";
import {Button, Heading, Text} from "../../style/components/general.js";

const ModalReview = ({visible, setVisible, goBack, checksheet, preview}) => {
  const {currentUser} = useContext(AuthContext);

  const [record, setRecord] = useState();

  if (record !== undefined)
    return (
      <ModalRecordView
        visible={visible}
        setVisible={setVisible}
        hasBackButton
        goBack={() => setRecord(undefined)}
        checksheet={checksheet}
        record={record}
      />
    );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      hasBackButton={goBack !== undefined}
      goBack={() => goBack && goBack()}
      allowClose={preview !== undefined}>
      <Heading>
        <Icon icon={faTriangleExclamation} />
        &nbsp; Required Reviews
      </Heading>

      {!preview ? (
        <>
          {checksheet?.reviews?.map(({id, data, endAfter}) => (
            <Review
              key={id}
              checksheet={checksheet}
              review={{...data, end: endAfter}}
              setRecord={setRecord}
            />
          ))}
          <Certification quiet>
            I {currentUser.firstName} {currentUser.lastName}, certify that I have reviewed the
            information provided for this checksheet.
          </Certification>
          <Button type="button" onClick={() => setVisible(false)}>
            Agree & Continue
          </Button>
        </>
      ) : (
        <Review checksheet={checksheet} review={preview} setRecord={setRecord} />
      )}
    </Modal>
  );
};

ModalReview.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired,
  preview: PropTypes.objectOf(PropTypes.any)
};

ModalReview.defaultProps = {
  goBack: undefined,
  preview: undefined
};

// Style Overrides
const Icon = styled(FontAwesomeIcon)`
  fill: ${heroTheme.warning};
`;

const Certification = styled(Text)`
  margin: ${pad}px 0;
`;

export default ModalReview;
