import React, {useState, useEffect, useContext, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {FormProvider, useForm} from "react-hook-form";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import usePrevious from "../../hooks/usePrevious.js";

// Components
import Modal from "../../components/Modal.js";
import {InputRadioGroup} from "../../components/form/FormInputs.js";
import CheckBox from "../../components/CheckBox.js";
import SearchSelect from "../../components/SearchSelect.js";

// Style
import {border, pad, radius} from "../../style/components/variables.js";
import {
  HeadingCenter,
  Abbr,
  Button,
  Label,
  Inline,
  Small,
  Form
} from "../../style/components/general.js";
import {flex} from "../../style/components/mixins.js";
import voice from "../../style/components/typography.js";

const ModalMemberRestrict = ({
  visible,
  setVisible,
  restrictTo = [],
  setRestrictTo,
  external = false,
  setExternal,
  facilityId
}) => {
  const {roles} = useContext(AuthContext) || {};

  const {api: apiUser} = useApi("facility-users");
  const {api: apiRole} = useApi("user-roles");

  const [selectedMembers, setSelectedMembers] = useState(restrictTo);
  const [selectedExternal, setSelectedExternal] = useState(external);

  const available = roles?.filter(
    curr =>
      curr?.permissions?.resource?.byId?.facility_checksheet_record?.view === "enabled" &&
      curr?.permissions?.resource?.byId?.facility_checksheet_record?.create === "enabled"
  );

  const [searchResults, setSearchResults] = useState([]);

  const initialized = useRef(false);

  const form = useForm({
    defaultValues: {
      mode: ""
    }
  });

  const {watch, setValue, handleSubmit} = form;

  const watchMode = watch("mode");

  const prevMode = usePrevious(watchMode);

  useEffect(() => {
    if (restrictTo?.length && !initialized.current) {
      const newMode = restrictTo?.[0]?.publicId ? "User" : "Role";
      setValue("mode", newMode);
      initialized.current = true;
    } else if (restrictTo && !restrictTo.length && !initialized.current) {
      setValue("mode", "Role");
      initialized.current = true;
    }
  }, [setValue, restrictTo]);

  const handleSearchRole = query => {
    if (query) {
      const lower = query.toLowerCase();
      const tempResults = available.filter(
        option =>
          option.label.toLowerCase().includes(lower) &&
          !selectedMembers?.map(role => role.name).includes(option.name)
      );
      tempResults.sort(
        (a, b) => a.label.toLowerCase().indexOf(lower) - b.label.toLowerCase().indexOf(lower)
      );
      setSearchResults(tempResults);
    } else
      setSearchResults(
        available.filter(role => !selectedMembers?.map(r => r.name).includes(role.name))
      );
  };

  const handleSearchUser = query => {
    const filter = {
      Omit: selectedMembers.map(({publicId}) => publicId),
      showHidden: true
    };

    if (query) filter.Search = query;

    const params = {
      facilityId,
      noTest: true,
      limit: query ? 5 : 20,
      filter: JSON.stringify(filter)
    };

    apiUser.callGet("", params).then(({status, data}) => {
      if (status === 200 && data) setSearchResults(data);
    });
  };

  useEffect(() => {
    if (prevMode && prevMode !== watchMode) {
      if (watchMode === "User" && selectedMembers?.length) {
        const params = {
          facilityId,
          noTest: true,
          filter: JSON.stringify({
            Roles: selectedMembers.map(({name}) => name)
          }),
          limit: "all"
        };
        apiUser.callGet("", params).then(({status, data}) => {
          if (status === 200 && data)
            setSelectedMembers(
              data.map(({publicId, email, firstName, lastName}) => ({
                publicId,
                email,
                firstName,
                lastName
              }))
            );
        });
      } else if (watchMode === "Role" && selectedMembers?.length) {
        const params = {
          filter: JSON.stringify({
            Users: selectedMembers.map(({publicId}) => publicId)
          })
        };
        apiRole.callGet("", params).then(({status, data}) => {
          if (status === 200 && data)
            setSelectedMembers(
              data.map(({label, name, id}) => ({
                label,
                name,
                id
              }))
            );
        });
      } else {
        setSelectedMembers([]);
      }
    }
  }, [prevMode, watchMode, facilityId, selectedMembers, apiUser, apiRole]);

  const handleSave = () => {
    setRestrictTo(selectedMembers);
    if (setExternal && !selectedMembers?.length) setExternal(false);
    else if (setExternal) setExternal(selectedExternal);
    setVisible(false);
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalTitle>Restrict Checksheet visibility</ModalTitle>

      <Menu>
        <Wrapper>
          <FormProvider {...form}>
            <Form onSubmit={handleSubmit(handleSave)} noValidate>
              <RadioWrapper>
                {(available?.length > 0 || watchMode === "Role") && (
                  <InputRadioGroup name="mode" options={["User", "Role"]} />
                )}
              </RadioWrapper>
              {watchMode === "Role" ? (
                <SearchSelect
                  placeholder="Find role(s)..."
                  results={searchResults}
                  setResults={setSearchResults}
                  search={handleSearchRole}
                  add={({id, name, label}) => {
                    setSelectedMembers(prev => {
                      if (prev && !prev.map(curr => curr.id).includes(id))
                        return [...prev, {id, name, label}];
                      return prev ?? [{id, name, label}];
                    });
                  }}
                  showAll
                />
              ) : (
                <SearchSelect
                  placeholder="Find user(s)..."
                  results={searchResults}
                  setResults={setSearchResults}
                  search={handleSearchUser}
                  add={({publicId, email, firstName, lastName}) => {
                    setSelectedMembers(prev => {
                      if (prev && !prev.map(curr => curr.publicId).includes(publicId))
                        return [...prev, {publicId, email, firstName, lastName}];
                      return prev ?? [{publicId, email, firstName, lastName}];
                    });
                  }}
                  showAll
                />
              )}
              <Inline>
                {selectedMembers?.map(
                  ({label: roleLabel, firstName, lastName, publicId, id: roleId}) => {
                    const label = firstName ? `${firstName} ${lastName}` : roleLabel;
                    const id = roleId || publicId;
                    return (
                      <SelectedRole key={label}>
                        <Abbr title={label.toUpperCase()}>{label.toUpperCase()}</Abbr>
                        <IconButton
                          onClick={() => {
                            setSelectedMembers(prev => {
                              let toRemove;
                              prev.map((role, index) => {
                                if (
                                  (role.id && role.id === id) ||
                                  (role.publicId && role.publicId === id)
                                )
                                  toRemove = index;
                              });
                              const newRoles = [...prev];
                              newRoles.splice(toRemove, 1);
                              return newRoles;
                            });
                          }}>
                          <FontAwesomeIcon icon={faClose} />
                        </IconButton>
                      </SelectedRole>
                    );
                  }
                )}
              </Inline>
              {setExternal && selectedMembers?.length > 0 && (
                <External>
                  <CheckBox
                    initial={external}
                    handleCheck={state => setSelectedExternal(state)}
                    triggerCheck={external}
                  />
                  <Small>External (access via email only)?</Small>
                </External>
              )}
              <Submit type="submit">Save</Submit>
            </Form>
          </FormProvider>
        </Wrapper>
      </Menu>
    </Modal>
  );
};

ModalMemberRestrict.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  restrictTo: PropTypes.arrayOf(PropTypes.any),
  setRestrictTo: PropTypes.func.isRequired,
  external: PropTypes.bool,
  setExternal: PropTypes.func,
  facilityId: PropTypes.number.isRequired
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const Menu = styled.div`
  position: relative;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  width: 100%;
  padding: ${pad}px;
  margin-bottom: ${pad}px;

  ${Label} {
    ${voice.quiet};
    margin-bottom: ${pad / 2}px;
  }

  ${Inline} {
    flex-wrap: wrap;
    margin-top: ${pad / 2}px;
    gap: ${pad / 2}px;
  }
`;

const Wrapper = styled.div`
  margin-top: ${pad}px;
`;

const Submit = styled(Button)`
  margin-top: ${pad * 3}px;
`;

const SelectedRole = styled.div`
  ${flex("row", "nowrap", "space-between", "center")};
  width: min-content;
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  background-color: ${({theme}) => theme.secondary};
  padding: ${pad / 2}px ${pad}px;

  ${Abbr} {
    ${voice.quiet};
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const IconButton = styled(Button)`
  ${voice.small};
  background-color: transparent;
  width: min-content;
  padding: 0;
  margin-left: ${pad / 2}px;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const External = styled(Inline)`
  span {
    color: ${({theme}) => theme.secondary};
  }
`;

const RadioWrapper = styled.div`
  margin-top: -${pad}px;
`;

export default ModalMemberRestrict;
