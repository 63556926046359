import React, {useMemo, useRef} from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";

// Components
import ChartSlice from "./chart/ChartSlice";
import {exists} from "../utils/helpers";

const ChartPi = ({data, radius = 100, innerRadius = 0, activeIdx}) => {
  const svgRef = useRef();

  const width = 2 * radius;
  const height = 2 * radius;

  const slices = useMemo(() => {
    const generator = d3.pie().value(d => d.value);
    return generator(data);
  }, [data]);

  return (
    <svg ref={svgRef} width={width} height={height}>
      <g transform={`translate(${radius}, ${radius})`}>
        {slices?.map((slice, idx) => (
          <ChartSlice
            key={slice.index}
            slice={{...slice, radius, innerRadius}}
            active={slices.length === 1 || (exists(activeIdx) && activeIdx === idx)}
          />
        ))}
      </g>
    </svg>
  );
};

ChartPi.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  radius: PropTypes.number,
  innerRadius: PropTypes.number,
  activeIdx: PropTypes.number
};

export default ChartPi;
