import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignLeft,
  faClose,
  faFont,
  faPlus,
  faPrint,
  faTable
} from "@fortawesome/free-solid-svg-icons";
import {useReactToPrint} from "react-to-print";

// Contexts
import {useToast} from "../contexts/toast.js";
import {SettingsContext} from "../contexts/settings.js";
import {FacilityNavContext} from "../contexts/facilitynav.js";

// Hooks
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";

// Utils
import {
  CHART,
  CHECKSHEET,
  DEFAULT_FONT_SIZE,
  EVENT,
  FONT_SIZE_NUM_OPTIONS,
  MAX_FONT_SIZE,
  MIN_FONT_SIZE,
  STAT
} from "../utils/report.js";
import {getWithExpiry, setWithExpiry} from "../utils/helpers.js";

// Components
import FacilityPageHeader from "./general/FacilityPageHeader.js";
import Report from "./report-builder/Report.js";
// import ReportPowerBI from "./report-builder/ReportPowerBI.js";
import RenderReportBuilder from "./report-builder/RenderReportBuilder.js";
import RenderReportBuilderPrintable from "./report-builder/RenderReportBuilderPrintable.js";
import ModalSection from "./report-builder/ModalSection.js";
import ModalReorder from "./report-builder/ModalReorder.js";
import ModalDelete from "../components/ModalDelete.js";
import SectionOptions from "./report-builder/SectionOptions.js";
import ScaleBar from "../components/ScaleBar.js";
import SearchSelect from "../components/SearchSelect.js";
import {
  InputText,
  InputDate,
  InputCheck,
  InputNumber,
  InputSelect,
  InputTime
} from "../components/form/FormInputs.js";
import ModalSectionType from "./report-builder/ModalSectionType.js";

// Style
import {flex} from "../style/components/mixins.js";
import {bp} from "../style/components/breakpoints.js";
import {border, pad, radius} from "../style/components/variables.js";
import {voice} from "../style/components/typography.js";
import {
  Abbr,
  AnchorInline,
  Button,
  ButtonFull,
  Error,
  Form,
  FormField,
  FormGroup,
  Heading,
  HeadingMedium,
  Inline,
  Label,
  Loader,
  Page,
  Small,
  Text,
  Warning
} from "../style/components/general.js";

const REPORT_WIDTH = 900;

const defaultValues = {
  name: null,
  hasHeader: false,
  header: null,
  isStatic: false,
  interval: null,
  base: null,
  hasFrequency: false,
  frequency: null,
  startDate: null,
  start: null,
  end: null
};

const schema = yup.object().shape({
  name: yup.string().required("Please provide name"),
  hasHeader: yup.bool(),
  isStatic: yup.bool(),
  interval: yup
    .string()
    .nullable()
    .when("isStatic", {
      is: false,
      then: () =>
        yup
          .number()
          .transform((v, o) => (o === "" ? null : v))
          .nullable()
          .required("Interval is required.")
    }),
  base: yup
    .string()
    .nullable()
    .when("isStatic", {
      is: false,
      then: () => yup.string().required("Base is required.")
    }),
  hasFrequency: yup.bool(),
  frequency: yup
    .string()
    .nullable()
    .when("hasFrequency", {
      is: true,
      then: () => yup.string().required("Frequency is required.")
    }),
  startDate: yup
    .string()
    .nullable()
    .when("hasFrequency", {
      is: true,
      then: () => yup.date().typeError("Start date is required.")
    }),
  startTime: yup
    .string()
    .nullable()
    .when("hasFrequency", {
      is: true,
      then: () => yup.string().typeError("Start time is required.")
    }),
  start: yup.string().nullable(),
  end: yup.string().nullable()
});

const ReportBuilder = () => {
  const isMounted = useMountedState();

  const {addToast} = useToast();
  const {slug} = useParams();
  const {settings} = useContext(SettingsContext);
  const {facility, setFacility} = useContext(FacilityNavContext);

  const {api: apiFacility} = useApi(`facilities/${slug}`);
  const {api: apiReport} = useApi("reports");
  const {api: apiReportPreview} = useApi("reports", {suppress: {success: true, error: true}});
  const {api: apiTemplate, data: templates} = useApi("reports");
  const {api: apiFrequency, data: frequencies} = useApi("frequencies");

  const reportKey = useMemo(() => (slug ? `${slug}.temp_report.builder` : null), [slug]);

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const {handleSubmit, watch, reset, setValue} = form;
  const name = watch("name");
  const hasHeader = watch("hasHeader");
  const headerName = watch("header.name");
  const headerAddress = watch("header.address");
  const topLeft = watch("header.topLeft");
  const topRight = watch("header.topRight");
  const bottomLeft = watch("header.bottomLeft");
  const bottomRight = watch("header.bottomRight");
  const isStatic = watch("isStatic");
  const base = watch("base");
  const interval = watch("interval");
  const start = watch("start");
  const end = watch("end");
  const hasFrequency = watch("hasFrequency");
  const frequency = watch("frequency");
  const startDate = watch("startDate");
  const startTime = watch("startTime");

  const [update, setUpdate] = useState(0);
  const [selectedType, setSelectedType] = useState(false);
  const [showModalSectionType, setShowModalSectionType] = useState(false);
  const [showModalReorder, setShowModalReorder] = useState(false);
  const [showModalSection, setShowModalSection] = useState(false);
  const [showModalClear, setShowModalClear] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalOptions, setShowModalOptions] = useState(false);
  const [target, setTarget] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fontScale, setFontScale] = useState(DEFAULT_FONT_SIZE);
  const [alignment, setAlignment] = useState("left");
  const [canPrint, setCanPrint] = useState(true);
  const [applied, setApplied] = useState(null);
  const [reportResults, setReportResults] = useState([]);
  const [refreshTemplates, setRefreshTemplates] = useState(true);
  const [dateError, setDateError] = useState(null);
  const [report, setReport] = useState(null);

  const reportRef = useRef();
  const containerRef = useRef();
  const printRef = useRef();

  const tables = useMemo(() => {
    if (update > 0 && report)
      return (
        report.extractRegions?.(true)?.filter(({type}) => type === CHECKSHEET || type === EVENT) ||
        []
      );

    return [];
  }, [report, update]);

  // Set default for header
  useEffect(() => {
    if (facility) {
      reset(prev => ({
        ...prev,
        header: {name: facility?.name || "", address: true, bottomRight: "(Generated: Date)"}
      }));
    }
  }, [facility, reset]);

  // Set date based on current interval
  useEffect(() => {
    if (hasHeader && interval && base) {
      if (base === "days") {
        const rangeEnd = dayjs().subtract(1, "day").format("MMM D, YYYY").toUpperCase();
        const rangeStart = dayjs()
          .subtract(Number.parseInt(interval, 10), "day")
          .format("MMM D, YYYY")
          .toUpperCase();
        setValue(
          "header.bottomRight",
          `(Generated: ${rangeEnd}${interval > 1 ? ` - ${rangeStart}` : ""})`
        );
      }

      if (base === "weeks") {
        const rangeEnd = dayjs().subtract(1, "week").format("MMM D, YYYY").toUpperCase();
        const rangeStart = dayjs()
          .subtract(Number.parseInt(interval, 10), "week")
          .format("MMM D, YYYY")
          .toUpperCase();
        setValue("header.bottomRight", `(Generated: ${rangeStart} - ${rangeEnd})`);
      }

      if (base === "months" || base === "years") {
        const rangeEnd = dayjs().subtract(1, "month").format("MMMM, YYYY");
        const rangeStart = dayjs()
          .subtract(Number.parseInt(interval, 10) + 1, "month")
          .format("MMMM, YYYY")
          .toUpperCase();
        setValue(
          "header.bottomRight",
          `(Generated: ${interval > 1 ? `${rangeStart} - ` : ""}${rangeEnd})`
        );
      }
    }
  }, [hasHeader, interval, base, setValue]);

  const handleResize = useCallback(() => {
    if (containerRef.current)
      document.documentElement.style.setProperty(
        "--vw-scale",
        Math.max(Math.min(containerRef.current.clientWidth / REPORT_WIDTH, 1), 0.5)
      );
  }, []);

  const containerCallback = useCallback(
    node => {
      if (node !== null) {
        window.addEventListener("resize", handleResize);

        containerRef.current = node;
        document.documentElement.style.setProperty(
          "--vw-scale",
          Math.max(Math.min(node.clientWidth / REPORT_WIDTH, 1), 0.5)
        );
        setUpdate(1);
      }
    },
    [handleResize]
  );

  const configurePrint = () => {
    const printBody = document.createElement("div");
    printBody.style.pageBreakInside = "avoid";
    const reportClone = reportRef.current.cloneNode(true);
    printBody.appendChild(reportClone);
    printRef.current = printBody;
  };

  const handlePrint = useReactToPrint({
    contentRef: printRef
  });

  const persistToLocalStorage = useCallback(() => {
    const reportData = {
      interval: interval ? `${interval}` : null,
      base: base,
      frequency: frequency,
      groups: report.getGroupTemplates(),
      startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
      startTime,
      fontSize: fontScale,
      alignment,
      isStatic,
      hasFrequency
    };
    if (hasHeader)
      reportData.header = {topLeft, topRight, bottomLeft, bottomRight, headerName, headerAddress};

    setWithExpiry(
      reportKey,
      JSON.stringify(reportData),
      (process.env.REACT_APP_EXPIRATION_IN_MINUTES ?? 10) * 60
    );
  }, [
    alignment,
    base,
    fontScale,
    frequency,
    hasHeader,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    headerName,
    headerAddress,
    interval,
    report,
    reportKey,
    startDate,
    startTime,
    isStatic,
    hasFrequency
  ]);

  const updateFunction = useCallback(() => {
    if (report) report.filterEmptyGroups();
    setUpdate(prev => prev + 1);
    if (update > 0) persistToLocalStorage();
  }, [report, persistToLocalStorage, update]);

  const applyReport = useCallback(
    (template, showToast = true) => {
      const {
        name: savedName,
        groups: savedGroups,
        startDate: savedStartDate,
        startTime: savedStartTime,
        base: savedBase,
        interval: savedInterval,
        frequency: savedFrequency,
        header: savedHeader,
        fontSize: savedFontSize,
        alignment: savedAlignment,
        isStatic: savedIsStatic,
        hasFrequency: savedHasFrequency
      } = template;
      setReport(new Report(savedGroups));
      reset({
        name: savedName,
        isStatic: savedIsStatic,
        header: savedHeader || null,
        hasHeader: !!savedHeader,
        base: savedBase,
        interval: savedInterval ? `${savedInterval}` : null,
        frequency: savedHasFrequency ? savedFrequency : null,
        hasFrequency: savedHasFrequency,
        startDate: savedStartDate ? dayjs(savedStartDate).format("YYYY-MM-DD") : null,
        startTime: savedStartTime
      });
      if (savedFontSize) setFontScale(savedFontSize);
      if (savedAlignment) setAlignment(savedAlignment);
      if (showToast) addToast("Report template successfully applied.", "success");
    },
    [reset, addToast]
  );

  useEffect(() => {
    if (facility && refreshTemplates) {
      apiTemplate.callGet("", {facilityId: facility.id});
      setRefreshTemplates(false);
    }
  }, [facility, apiTemplate, refreshTemplates]);

  useEffect(() => {
    if (isMounted() && reportKey) {
      apiFrequency.callGet();
      apiFacility.callGet().then(({status, data}) => {
        if (status === 200) setFacility(data);
      });
      const json = getWithExpiry(reportKey);
      const data = json ? JSON.parse(json) : null;
      if (data) {
        applyReport(data, false);
      } else {
        setReport(new Report());
      }
    }
  }, [isMounted, apiFrequency, apiFacility, reportKey, reset, applyReport, setFacility]);

  useEffect(() => {
    if (report) report.setUpdater(updateFunction);
  }, [report, updateFunction]);

  useEffect(() => {
    handleResize();
  }, [handleResize, isStatic]);

  useEffect(() => {
    if (report) {
      const allRegions = report.extractRegions();
      if (allRegions && allRegions.length > 0) {
        // Error checks
        const now = dayjs();
        const startDayjs = start ? dayjs(start) : null;
        const endDayjs = end ? dayjs(end) : null;

        if ((startDayjs && startDayjs.isAfter(now)) || (endDayjs && endDayjs.isAfter(now)))
          setDateError("Dates selected cannot be in the future");
        else if (endDayjs && startDayjs && endDayjs.isBefore(startDayjs))
          setDateError("Start date must be before end date");
        else {
          setDateError(null);

          const payload = {
            regions: allRegions
          };

          if (isStatic) {
            payload.start = start;
            payload.end = end;
          } else {
            payload.base = base;
            payload.interval = interval;
          }
          apiReportPreview.callPost(payload).then(({status, data}) => {
            if (status === 200) report.replaceRegions(data);
          });
        }
      }
    }
  }, [report, apiReportPreview, start, end, base, interval, isStatic, setValue]);

  const handleInsertRegion = useCallback(
    (region, id) => {
      if (id && report) report.updateRegion(id, region);
      else if (report) report.insertRegion(region);
      setTarget(null);
    },
    [report]
  );

  const handleShowOptions = id => {
    const region = report.retrieveRegion(id);
    if (region) {
      setTarget({...region, id});
      setShowModalOptions(true);
    }
  };

  const persistDelete = () => {
    if (target?.id) {
      report.deleteRegion(target.id);
      setShowModalDelete(false);
      setTarget(null);
    }
  };

  const saveTemplate = (values, draft) => {
    const params = {
      facilityId: facility.id,
      name: values.name,
      interval: values.interval ? `${values.interval}` : null,
      base: values.base,
      frequency: values.frequency,
      groups: report.getGroupTemplates(),
      autoStartDate: values.startDate ? dayjs(values.startDate).format("MM-DD-YYYY") : null,
      autoStartTime: values.startTime,
      fontSize: fontScale,
      alignment,
      draft
    };

    if (values.hasHeader) params.header = values.header;

    if (facility && !isStatic && report && !report.isEmpty()) {
      apiReport
        .callPost(params)
        .then(({status, data}) => {
          if (status === 201 && data) {
            reset(defaultValues);
            report.reset();
            setRefreshTemplates(true);
            setApplied(null);
            if (reportKey) setWithExpiry(reportKey, null);
          }
        })
        .finally(() => setLoading(false));
    } else
      addToast(
        "At least one section must be added to the report in order to save template!",
        "error"
      );
  };

  useEffect(() => {
    if (report?.updater) report.updater();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontScale, alignment]);

  useEffect(() => {
    if (update > 0 && report) persistToLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistToLocalStorage]);

  const printError = () => {
    addToast(
      <>
        We are sorry, something went wrong with your report. Please submit a{" "}
        <AnchorInline href="/support" target="_blank" rel="noreferrer">
          Support Ticket
        </AnchorInline>
        .
      </>,
      "error"
    );
  };

  const search = query => {
    if (query) {
      const filtered =
        templates?.filter(template =>
          template?.name?.toLowerCase()?.includes(query?.toLowerCase())
        ) || [];
      setReportResults(filtered?.map(template => ({...template, label: template.name})));
    } else {
      setReportResults(templates?.map(template => ({...template, label: template.name})));
    }
  };

  const handleClone = () => {
    const temp = {...target};
    delete temp.id;
    temp.title = `${temp.title} Copy`;
    handleInsertRegion(temp);
    setShowModalOptions(false);
  };

  // if (settings?.powerBiEnabled === "enabled") return <ReportPowerBI />;

  return (
    <Page hasMenu>
      {facility?.name && <FacilityPageHeader facility={facility} path="/report-builder" />}

      <HeadingMedium>Report Builder</HeadingMedium>

      <PageWrapper>
        <PageLeft>
          <FormProvider {...form}>
            <Form onSubmit={handleSubmit(saveTemplate)} noValidate>
              {/* {settings?.sharepointEnabled === "enabled" && (
                <InputRadioGroup
                  name="tableType"
                  defaultValue="default"
                  options={[
                    {label: "Default", value: "default"},
                    {label: "PowerBI", value: "powerBI"}
                  ]}
                />
              )} */}

              <FormField>
                <InputText name="name" placeholder="Report..." required />
              </FormField>

              {templates?.length > 0 && (
                <FormGroup>
                  <SubMenu>
                    <Label bold>CREATE FROM EXISTING?</Label>
                    {!applied ? (
                      <SearchSelect
                        results={reportResults}
                        setResults={setReportResults}
                        search={search}
                        add={selected => {
                          setApplied(selected);
                          if (selected.settings)
                            applyReport({...selected, ...selected.settings, name: ""});
                          else if (selected) applyReport({...selected, name: ""});
                        }}
                        showAll
                        placeholder="Find report..."
                      />
                    ) : (
                      <TemplateTagContainer>
                        <Inline>
                          <TemplateName>
                            <Abbr title={applied.name}>{applied.name}</Abbr>
                          </TemplateName>
                          <IconButton
                            onClick={() => {
                              reset(defaultValues);
                              setReport(new Report());
                              setApplied(null);
                            }}>
                            <FontAwesomeIcon icon={faClose} />
                          </IconButton>
                        </Inline>
                      </TemplateTagContainer>
                    )}
                  </SubMenu>
                </FormGroup>
              )}

              <FormField>
                <InputCheck name="isStatic">Specify date range?</InputCheck>
              </FormField>

              {!isStatic ? (
                <SubMenu>
                  <Label htmlFor="interval" bold>
                    DATA FROM LAST
                  </Label>
                  <FormGroup inline>
                    <InputNumber name="interval" placeholder="Interval..." disableInverse />
                    <InputSelect
                      name="base"
                      placeholder="Base..."
                      options={[
                        {label: `Day${watch("interval") > 1 ? "s" : ""}`, name: "days"},
                        {label: `Week${watch("interval") > 1 ? "s" : ""}`, name: "weeks"},
                        {label: `Month${watch("interval") > 1 ? "s" : ""}`, name: "months"},
                        {label: `Year${watch("interval") > 1 ? "s" : ""}`, name: "years"}
                      ]}
                    />
                  </FormGroup>
                </SubMenu>
              ) : (
                <SubMenu>
                  <FormGroup inline>
                    <FormField inline>
                      <InputDate name="start" label="Start Date" />
                    </FormField>
                    <FormField inline>
                      <InputDate name="end" label="End Date" />
                    </FormField>
                  </FormGroup>
                  {dateError && <Error>{dateError}</Error>}
                </SubMenu>
              )}

              <FormField>
                <InputCheck name="hasHeader">Include a page header?</InputCheck>
              </FormField>

              {hasHeader && (
                <SubMenu>
                  <FormField>
                    <InputText name="header.name" label="Report Header" />
                  </FormField>

                  {facility?.primaryAddress && (
                    <FormField>
                      <InputCheck name="header.address">Include an address?</InputCheck>
                    </FormField>
                  )}

                  <FormField>
                    <InputText name="header.topLeft" label="Top Left" maxLength={100} />
                  </FormField>

                  <FormField>
                    <InputText name="header.bottomLeft" label="Bottom Left" maxLength={100} />
                  </FormField>

                  <FormField>
                    <InputText name="header.topRight" label="Top Right" maxLength={100} />
                  </FormField>

                  <FormField>
                    <Label htmlFor="header.bottomRight" bold>
                      BOTTOM RIGHT
                    </Label>
                    <Warning>
                      Warning! This is a generated date, custom values will replace date.
                    </Warning>
                    <InputText name="header.bottomRight" maxLength={100} />
                  </FormField>
                </SubMenu>
              )}

              {!isStatic && (
                <>
                  <FormField>
                    <InputCheck name="hasFrequency">Generate report on frequency?</InputCheck>
                  </FormField>

                  {hasFrequency && (
                    <SubMenu>
                      <FormField>
                        <InputSelect
                          name="frequency"
                          label="Generate"
                          placeholder="Frequency..."
                          options={
                            frequencies?.map(({label, name: freqName}) => ({
                              label: label,
                              name: freqName
                            })) || []
                          }
                        />
                      </FormField>
                      <FormGroup inline>
                        <FormField inline>
                          <InputDate name="startDate" label="Starting" />
                        </FormField>
                        <FormField inline>
                          <InputTime name="startTime" label="At" />
                        </FormField>
                      </FormGroup>
                    </SubMenu>
                  )}

                  <Buttons>
                    <Draft
                      type="button"
                      onClick={handleSubmit(values => saveTemplate(values, true))}>
                      Create as Draft
                    </Draft>
                    <Submit loading={loading ? 1 : 0}>
                      {loading && <Loader />}
                      Create and Publish
                    </Submit>
                  </Buttons>
                </>
              )}
            </Form>
          </FormProvider>
        </PageLeft>
        {(!settings?.reporting || settings.reporting === "default") && (
          <PageRight>
            <Menu ref={containerCallback}>
              <Inline>
                <Heading>
                  <Abbr title={name}>{name || "Name..."}</Abbr>
                </Heading>

                {!isStatic && base && interval && (
                  <Small>
                    Generated {frequency === "work-week" ? "on " : ""}
                    {startDate && hasFrequency && frequency
                      ? `${frequency} starting ${dayjs(startDate).format("MMM DD, YYYY")} `
                      : ""}
                    with data from last{" "}
                    {interval === "1" ? base.slice(0, base.length - 1) : `${interval} ${base}`}
                  </Small>
                )}
                {isStatic && (
                  <>
                    {start && end && (
                      <Small>
                        From {dayjs(start).format("MMM DD, YYYY")} to{" "}
                        {dayjs(end).format("MMM DD, YYYY")}
                      </Small>
                    )}
                    {start && !end && <Small>From {dayjs(start).format("MMM DD, YYYY")}</Small>}
                    {!start && end && <Small>Until {dayjs(end).format("MMM DD, YYYY")}</Small>}{" "}
                  </>
                )}
              </Inline>
              <Inline>
                {report && !report.isEmpty() && (
                  <>
                    <Button
                      type="button"
                      title="Clear Report"
                      onClick={() => setShowModalClear(true)}>
                      Clear
                    </Button>
                    <Button
                      type="button"
                      title="Text Align"
                      onClick={() => setAlignment(prev => (prev === "left" ? "center" : "left"))}>
                      <FontAwesomeIcon icon={alignment === "left" ? faAlignLeft : faAlignCenter} />
                    </Button>
                    <ScaleBar
                      setState={setFontScale}
                      state={fontScale}
                      min={MIN_FONT_SIZE}
                      max={MAX_FONT_SIZE}
                      length={FONT_SIZE_NUM_OPTIONS}
                      icon={faFont}
                    />
                    <PrintButton
                      type="button"
                      title="Print"
                      onClick={
                        canPrint
                          ? () => {
                              configurePrint();
                              handlePrint();
                            }
                          : printError
                      }
                      clickableDisabled={!canPrint}>
                      <FontAwesomeIcon icon={faPrint} />
                    </PrintButton>
                    <Button
                      type="button"
                      title="Rearrange"
                      onClick={() => setShowModalReorder(true)}>
                      <FontAwesomeIcon icon={faTable} />
                    </Button>
                  </>
                )}
                <Button
                  type="button"
                  title="Add Section"
                  onClick={() => setShowModalSectionType(true)}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Inline>
            </Menu>

            <ReportPreview>
              {hasHeader && (
                <HeaderWrapper>
                  <Col align={alignment}>
                    <Text>{topLeft}</Text>
                    <Small>{bottomLeft}</Small>
                  </Col>
                  <Col align="center">
                    <HeadingMedium>{headerName}</HeadingMedium>
                    {headerAddress && facility?.primaryAddress && (
                      <Text>
                        <span>{facility.primaryAddress?.line1}</span>
                        <br />
                        {facility.primaryAddress?.line2 !== "" && (
                          <>
                            <span>{facility.primaryAddress?.line2}</span>
                            <br />
                          </>
                        )}
                        <span>
                          {facility.primaryAddress.city} {facility.primaryAddress.state}
                          {facility.primaryAddress.zipCode &&
                            `, ${facility.primaryAddress.zipCode}`}
                        </span>
                      </Text>
                    )}
                  </Col>
                  <Col align={alignment === "left" ? "end" : alignment}>
                    <Text>{topRight}</Text>
                    <Small>{bottomRight}</Small>
                  </Col>
                </HeaderWrapper>
              )}

              <RenderReportBuilder
                report={report}
                update={update}
                handleShowOptions={handleShowOptions}
                fontSize={fontScale}
                alignment={alignment}
                setCanPrint={setCanPrint}
              />
            </ReportPreview>
          </PageRight>
        )}
      </PageWrapper>

      {/* Printable Report */}
      <ReportPreview ref={reportRef} hide>
        {hasHeader && (
          <HeaderWrapper>
            <Col align={alignment}>
              <Text>{topLeft}</Text>
              <Text>{bottomLeft}</Text>
            </Col>
            <Col align="center">
              <HeadingMedium>{headerName}</HeadingMedium>
              {headerAddress && facility?.primaryAddress && (
                <Text>
                  <span>{facility.primaryAddress?.line1}</span>
                  <br />
                  {facility.primaryAddress?.line2 !== "" && (
                    <>
                      <span>{facility.primaryAddress?.line2}</span>
                      <br />
                    </>
                  )}
                  <span>
                    {facility.primaryAddress.city} {facility.primaryAddress.state}
                    {facility.primaryAddress.zipCode && `, ${facility.primaryAddress.zipCode}`}
                  </span>
                </Text>
              )}
            </Col>
            <Col align={alignment === "left" ? "end" : alignment}>
              <Text>{topRight}</Text>
              <Text>{bottomRight}</Text>
            </Col>
          </HeaderWrapper>
        )}
        <RenderReportBuilderPrintable
          report={report}
          update={update}
          fontSize={fontScale}
          alignment={alignment}
        />
      </ReportPreview>

      {/* Modals */}
      {showModalSectionType && (
        <ModalSectionType
          visible={showModalSectionType}
          setVisible={state => {
            if (!state) setSelectedType(null);
            setShowModalSectionType(state);
          }}
          handleSelection={type => {
            setSelectedType(type);
            setShowModalSectionType(false);
            setShowModalSection(true);
          }}
        />
      )}

      {showModalSection && (
        <ModalSection
          visible={showModalSection}
          goBack={
            selectedType
              ? () => {
                  setSelectedType(null);
                  setShowModalSection(false);
                  setShowModalSectionType(true);
                }
              : undefined
          }
          setVisible={state => {
            if (!state) {
              setTarget(null);
              setSelectedType(null);
            }
            setShowModalSection(state);
          }}
          start={start}
          end={end}
          base={base}
          interval={interval}
          handleInsertRegion={handleInsertRegion}
          target={target}
          fontSize={fontScale}
          tables={tables}
          selectedType={selectedType}
        />
      )}

      {showModalClear && (
        <ModalDelete
          visible={showModalClear}
          setVisible={setShowModalClear}
          confirmDelete={() => {
            setReport(new Report());
            setWithExpiry(reportKey, null);
            setShowModalClear(false);
          }}
          title="Clear Report"
          prompt="Are you sure you want to clear the report? This cannot be undone."
        />
      )}

      {showModalDelete && (
        <ModalDelete
          visible={showModalDelete}
          setVisible={state => {
            if (!state) setTarget(null);
            setShowModalDelete(state);
          }}
          confirmDelete={persistDelete}
          title="Delete Section"
        />
      )}

      {showModalOptions && (
        <SectionOptions
          visible={showModalOptions}
          setVisible={state => {
            if (!state) setTarget(null);
            setShowModalOptions(state);
          }}
          handleDelete={() => {
            setShowModalDelete(true);
            setShowModalOptions(false);
          }}
          handleEdit={() => {
            setShowModalSection(true);
            setShowModalOptions(false);
          }}
          handleClone={handleClone}
          title={
            target?.type === CHART || target?.type === STAT ? target.data?.label : target?.title
          }
        />
      )}

      {showModalReorder && (
        <ModalReorder report={report} visible={showModalReorder} setVisible={setShowModalReorder} />
      )}
    </Page>
  );
};

// Style Overrides
const PageWrapper = styled.article`
  ${flex("row", "wrap", "start", "stretch")};
  gap: ${pad * 2}px;
`;

const PageLeft = styled.div`
  position: relative;
  width: 100%;
  min-width: min-content;

  ${bp(3)} {
    width: 30%;
    padding: ${pad}px 0 0;
  }
`;

const PageRight = styled.div`
  ${flex("column", "nowrap", "start", "stretch")};
  flex: 1;
  width: 100%;
  min-width: 450px;
  max-width: ${REPORT_WIDTH}px;
  position: relative;

  ${bp(3)} {
    margin: ${pad * 2}px 0 0;
    width: 70%;
  }
`;

const Menu = styled(Inline)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${pad}px;

  ${Heading} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media print {
    margin-bottom: ${pad}px;
    page-break-after: avoid;
    height: 16mm;
  }
`;

const ReportPreview = styled.div`
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  width: ${REPORT_WIDTH}px;
  min-width: 445px;
  height: min-content;

  /* Print Scale A4 */
  transform: scale(var(--vw-scale));
  transform-origin: top left;

  @media print {
    margin-bottom: 9mm;
    display: block;
    border: 0;
    padding: 0;
  }

  ${({hide}) =>
    hide
      ? css`
          display: none;
        `
      : ""}
`;

const HeaderWrapper = styled.div`
  ${flex("row", "nowrap")};
  gap: ${pad * 5}px;
  margin: ${pad}px 0 ${pad * 2}px;
  padding: ${pad}px 0;
  border-top: ${border} solid ${({theme}) => theme.secondary};
  border-bottom: ${border} solid ${({theme}) => theme.secondary};

  @media print {
    page-break-after: avoid;
  }
`;

const Col = styled.div`
  ${flex("column", "wrap", "space-between", "start")};
  width: 33.33%;

  ${HeadingMedium}, ${Text}, ${Small} {
    color: ${({theme}) => theme.secondary};
    width: 100%;
    text-align: ${({align}) => align || "start"};
  }
`;

const SubMenu = styled(FormGroup)`
  position: relative;
  padding: ${pad}px;
  margin-bottom: ${pad * 2}px;
  width: 100%;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
`;

const Submit = styled(ButtonFull)`
  position: relative;
  width: 50%;
  padding: ${pad / 2}px;
  margin-bottom: ${pad * 2}px;
  color: ${({theme}) => theme.success};
`;

const Draft = styled(ButtonFull)`
  position: relative;
  width: 50%;
  padding: ${pad / 2}px;
  margin-bottom: ${pad * 2}px;
  color: ${({theme}) => theme.warning};
`;

const Buttons = styled(Inline)`
  align-items: stretch;
`;

const PrintButton = styled(Button)`
  ${({clickableDisabled}) =>
    clickableDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`;

const TemplateTagContainer = styled.div`
  width: min-content;
  height: min-content;
  border-radius: ${radius};
  background-color: ${({theme}) => theme.primary};
  max-width: 100%;
  padding: ${pad / 2}px ${pad}px;
  margin: 0 ${pad}px ${pad}px 0;
`;

const TemplateName = styled(Text)`
  ${voice.normal};
  width: min-content;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-right: ${pad / 2}px;
`;

const IconButton = styled(Button)`
  ${voice.normal};
  background-color: transparent;
  width: min-content;
  padding: 0;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

export default ReportBuilder;
