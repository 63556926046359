import React, {useState, useEffect, useRef, useMemo, useContext, useLayoutEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckDouble, faClock, faRefresh} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Hooks
import usePrevious from "../../hooks/usePrevious.js";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";

// Utils
import {convertToUserTimezone} from "../../utils/helpers.js";

// Components
import InputError from "./InputError.js";
import Help from "../Help.js";

// Style
import {
  Abbr,
  FormFieldWrapper,
  Inline,
  Input,
  Label,
  Pill,
  Text
} from "../../style/components/general.js";
import {border, colors, pad, radius, shadow} from "../../style/components/variables.js";
import voice from "../../style/components/typography.js";
import {flex} from "../../style/components/mixins.js";

const InputTime = ({...props}) => {
  const {
    testId = "input-time",
    label,
    name,
    prompt,
    placeholder,
    disabled = false,
    hidden = false,
    condition,
    tag,
    required = false,
    military = false
  } = props;

  const {settings} = useContext(SettingsContext);

  const hourOptions = useMemo(() => {
    if (military) return Array.from({length: 24}, (_v, i) => `${i}`);
    return Array.from({length: 12}, (_v, i) => `${i === 0 ? 12 : i}`);
  }, [military]);

  const minuteOptions = useMemo(() => Array.from({length: 60}, (_v, i) => `${i}`), []);

  const {
    setValue,
    watch,
    formState: {errors}
  } = useFormContext();

  const [focused, setFocused] = useState(false);

  const currentTime = convertToUserTimezone(undefined, settings?.timezone);
  const defaultHour = currentTime.format(military ? "H" : "h");
  const defaultMinute = currentTime.format("m");
  const defaultMeridian = currentTime.format("a");

  const [selectedHour, setSelectedHour] = useState();
  const [selectedMinute, setSelectedMinute] = useState();
  const [selectedMeridian, setSelectedMeridian] = useState();

  const [isDirty, setIsDirty] = useState(false);

  const hourRef = useRef();
  const minuteRef = useRef();

  const outside = useRef(null);
  const wrapperRef = useRef(null);

  const value = watch(name);

  const prevFocused = usePrevious(focused);

  const timeFormatted = useMemo(() => {
    const date = currentTime.format("MM-DD-YYYY");
    const obj = value ? dayjs(`${date} ${value}`) : null;
    return obj?.format(military ? "HH:mm" : "hh:mm a") || "";
  }, [currentTime, military, value]);

  const defaultPlaceholder = useMemo(() => (military ? "--:--" : "--:-- --"), [military]);

  const getPlaceholder = () => {
    if (!focused && prompt) return prompt;
    return placeholder || defaultPlaceholder;
  };

  useEffect(() => {
    if (selectedHour && selectedMinute && (military || selectedMeridian) && isDirty) {
      const parsedHour = Number.parseInt(selectedHour, 10);
      const parsedMinute = Number.parseInt(selectedMinute, 10);
      const meridian = military ? "" : ` ${selectedMeridian}`;

      const hourPadding = parsedHour < 10 ? "0" : "";
      const minutePadding = parsedMinute < 10 ? "0" : "";

      setValue(name, `${hourPadding}${selectedHour}:${minutePadding}${selectedMinute}${meridian}`);
    }
  }, [isDirty, military, name, selectedHour, selectedMeridian, selectedMinute, setValue]);

  useEffect(() => {
    const handleClickOutside = ({target}) =>
      focused &&
      outside &&
      outside.current !== null &&
      !outside.current.contains(target) &&
      !outside.current.contains(target.nextSibling) &&
      setFocused(false);

    if (outside) document.addEventListener("mousedown", handleClickOutside);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [focused]);

  useLayoutEffect(() => {
    if (focused && !prevFocused) {
      const centerOffset = 3;
      const hour = selectedHour || defaultHour;
      const minute = selectedMinute || defaultMinute;

      if (hourRef.current && hour) {
        const buttonHeight = hourRef.current.scrollHeight / (military ? 24 : 12);
        const idx = hourOptions.indexOf(hour);
        hourRef.current.scrollTop = buttonHeight * Math.max(idx - centerOffset, 0);
      }
      if (minuteRef.current && minute) {
        const buttonHeight = minuteRef.current.scrollHeight / 60;
        const idx = minuteOptions.indexOf(minute);
        minuteRef.current.scrollTop = buttonHeight * Math.max(idx - centerOffset, 0);
      }
    }
  }, [
    focused,
    prevFocused,
    hourOptions,
    military,
    minuteOptions,
    selectedHour,
    selectedMinute,
    defaultHour,
    defaultMinute
  ]);

  const getInverted = () => {
    const rect = wrapperRef.current?.getBoundingClientRect();
    const screenMidpoint = window.innerHeight / 2;

    if (rect && rect?.top > screenMidpoint + 120) return true;
    return false;
  };

  return (
    <FormFieldWrapper data-testid={testId}>
      {label && (
        <LabelWrapper htmlFor={name} bold inline>
          {label.toUpperCase()}
          {required && <span>*</span>}
          {tag && (
            <Pill quiet>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
        </LabelWrapper>
      )}
      {prompt && <Prompt quiet>{prompt}</Prompt>}
      {!hidden && (
        <InputWrapper disabled={disabled} ref={wrapperRef}>
          <FontAwesomeIcon icon={faClock} />
          <InputInnerWrapper onClick={disabled ? undefined : () => setFocused(true)} />
          <TimeInput type="text" disabled value={timeFormatted || getPlaceholder()} />
          <Picker
            show={focused}
            onClick={e => e.preventDefault()}
            ref={outside}
            invert={getInverted()}>
            <StyledInline>
              <Col military={military} ref={hourRef}>
                {hourOptions.map(option => (
                  <Option
                    key={`hour-${option}`}
                    selected={(selectedHour || defaultHour) === option}
                    onClick={() => {
                      setSelectedHour(option);
                      if (!selectedMinute) setSelectedMinute(defaultMinute);
                      if (!military && !selectedMeridian) setSelectedMeridian(defaultMeridian);
                      setIsDirty(true);
                    }}>
                    {option}
                  </Option>
                ))}
              </Col>
              <Col military={military} ref={minuteRef}>
                {minuteOptions.map(option => (
                  <Option
                    key={`minute-${option}`}
                    selected={(selectedMinute || defaultMinute) === option}
                    onClick={() => {
                      setSelectedMinute(option);
                      if (!selectedHour) setSelectedHour(defaultHour);
                      if (!military && !selectedMeridian) setSelectedMeridian(defaultMeridian);
                      setIsDirty(true);
                    }}>
                    {option}
                  </Option>
                ))}
              </Col>
              {!military && (
                <Col military={false}>
                  <Option
                    selected={(selectedMeridian || defaultMeridian) === "am"}
                    onClick={() => {
                      setSelectedMeridian("am");
                      if (!selectedHour) setSelectedHour(defaultHour);
                      if (!selectedMinute) setSelectedMinute(defaultMinute);
                      setIsDirty(true);
                    }}>
                    AM
                  </Option>
                  <Option
                    selected={(selectedMeridian || defaultMeridian) === "pm"}
                    onClick={() => {
                      setSelectedMeridian("pm");
                      if (!selectedHour) setSelectedHour(defaultHour);
                      if (!selectedMinute) setSelectedMinute(defaultMinute);
                      setIsDirty(true);
                    }}>
                    PM
                  </Option>
                </Col>
              )}
            </StyledInline>
            <ButtonBar>
              <TimeButton
                onClick={() => {
                  setValue(name, "");
                  setSelectedHour(undefined);
                  setSelectedMinute(undefined);
                  setSelectedMeridian(undefined);
                  setIsDirty(false);
                  setFocused(false);
                }}>
                <FontAwesomeIcon icon={faRefresh} />
              </TimeButton>
              <TimeButton
                onClick={() => {
                  if (!selectedHour) setSelectedHour(defaultHour);
                  if (!selectedMinute) setSelectedMinute(defaultMinute);
                  if (!military && !selectedMeridian) setSelectedMeridian(defaultMeridian);
                  setIsDirty(true);
                  setFocused(false);
                }}>
                <FontAwesomeIcon icon={faCheck} />
              </TimeButton>
            </ButtonBar>
          </Picker>
        </InputWrapper>
      )}

      <InputError errors={errors} name={name} />
    </FormFieldWrapper>
  );
};

InputTime.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  prompt: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  capitalize: PropTypes.bool,
  hidden: PropTypes.bool,
  condition: PropTypes.string,
  tag: PropTypes.string,
  military: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)})
  ])
};

// Style Overrides
const LabelWrapper = styled(Label)`
  gap: ${pad / 2}px;
`;

const Prompt = styled(Text)`
  margin-bottom: ${pad}px;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 120px;

  > svg {
    position: absolute;
    right: 10px;
    top: 9px;
    fill: ${({theme}) => theme.secondary};
  }

  ${({disabled}) =>
    !disabled &&
    css`
      cursor: pointer;
    `}
`;

const InputInnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: inherit;
`;

const TimeInput = styled(Input)`
  font-family: monospace;
  padding-left: 12px;
  &:disabled {
    cursor: inherit;

    background: ${({theme}) => theme.tertiary};
  }
`;

const StyledInline = styled.div`
  ${flex("row", "nowrap")};
  height: 220px;
`;

const Picker = styled.span`
  ${voice.quiet};

  z-index: 2;
  position: absolute;
  left: 0;
  color: ${({theme}) => theme.secondary};
  background: ${({theme}) => theme.tertiary};
  border-radius: ${radius};
  width: 140px;
  height: 250px;
  box-shadow: ${shadow};
  overflow: hidden;

  ${({invert}) =>
    invert
      ? css`
          bottom: calc(100% + 5px);
        `
      : css`
          top: calc(100% + 5px);
        `}

  ${({show}) =>
    show
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
          height: 0;
        `}
`;

const Col = styled.div`
  width: ${({military}) => 100 / (military ? 2 : 3)}%;
  overflow-y: scroll;
  border-right: ${border} solid ${colors.grayLight};

  :last-child {
    border: none;
  }
`;

const Option = styled.button`
  position: relative;
  padding: 8px ${pad}px;
  width: 100%;
  height: fit-content;
  line-height: 1.5em;
  text-align: center;

  ${({selected}) =>
    selected &&
    css`
      background-color: ${({theme}) => theme.secondary};
      color: ${({theme}) => theme.tertiary};
    `}

  :hover {
    background-color: ${({theme}) => theme.secondary};
    color: ${({theme}) => theme.tertiary};
  }

  ${voice.small};
`;

const ButtonBar = styled(Inline)`
  justify-content: space-between;
  width: 100%;
  padding: 0 ${pad * 2}px;
  height: 30px;
`;

const TimeButton = styled.div`
  width: max-content;
  height: max-content;
`;

export default InputTime;
