import React, {useContext, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar as faStarFilled} from "@fortawesome/free-solid-svg-icons";
import {faStar} from "@fortawesome/free-regular-svg-icons";

// Contexts
import {NotificationContext} from "../../contexts/notify";
import {AuthContext} from "../../contexts/auth";

// Utils
import {slugify} from "../../utils/helpers";

// Components
import Badge from "../../components/Badge";
import Select from "../../components/Select";
import ModalFacilityFavorite from "../facilities/ModalFacilityFavorite";

// Style
import {z} from "../../style/components/mixins";
import {voice} from "../../style/components/typography";
import {heroTheme, pad} from "../../style/components/variables";
import {Abbr, Pill, Title} from "../../style/components/general";

const FacilityPageHeader = ({facility, path = "", reloadData, disabled = false}) => {
  const {name, type, types, isDeleted} = facility;

  const {currentUser} = useContext(AuthContext);
  const {notifications} = useContext(NotificationContext);

  const [showModalFavorite, setShowModalFavorite] = useState(false);

  const navigate = useNavigate();

  const getCount = id => {
    if (!path?.includes("tasks") || !notifications?.otherTypes) return "";

    const count = notifications.otherTypes[`${id}`]?.totalDueCount;

    if (!count) return "";

    return ` (${count})`;
  };

  const totalCount = useMemo(() => {
    if (!path?.includes("tasks") || !notifications?.otherTypes) return null;

    let count = 0;

    Object.keys(notifications.otherTypes).map(id => {
      count += notifications.otherTypes[`${id}`]?.totalDueCount || 0;
    });

    return count;
  }, [notifications, path]);

  const favorites = useMemo(() => currentUser?.favorites || [], [currentUser]);

  return (
    <>
      <PageTitle>
        {name.toUpperCase()}
        {!facility?.isDeleted && (
          <Wrapper>
            <Favorite type="button" onClick={() => setShowModalFavorite(true)}>
              <FontAwesomeIcon
                icon={favorites?.includes(facility.id) ? faStarFilled : faStar}
                className="primaryIcon">
                Favorite Facility
              </FontAwesomeIcon>
            </Favorite>
          </Wrapper>
        )}
        <Wrapper>
          <BadgeWrapper>
            <Select
              large
              options={types.map(({name: typeName, id}) => ({
                label: `${typeName}${getCount(id)}`,
                name: typeName
              }))}
              selection={type}
              setSelection={target => {
                const targetPath = `/facilities/${slugify(`${name} ${target.name}`)}${path || ""}`;
                if (window.location.pathname !== targetPath) {
                  navigate(targetPath);
                  if (reloadData) reloadData();
                }
              }}
              disabled={disabled}
            />
            {!!totalCount && (
              <Badge offsetX="-10px" offsetY="5px" color={heroTheme.secondary}>
                {totalCount}
              </Badge>
            )}
          </BadgeWrapper>
        </Wrapper>
        <Wrapper>
          {isDeleted && (
            <Pill color={heroTheme.warning}>
              <Abbr title="Archived">ARCHIVED</Abbr>
            </Pill>
          )}
        </Wrapper>
      </PageTitle>

      {showModalFavorite && (
        <ModalFacilityFavorite
          visible={showModalFavorite}
          setVisible={setShowModalFavorite}
          facility={facility}
          reloadFacilities={() => setShowModalFavorite(false)}
        />
      )}
    </>
  );
};

FacilityPageHeader.propTypes = {
  facility: PropTypes.objectOf(PropTypes.any).isRequired,
  path: PropTypes.string,
  reloadData: PropTypes.func,
  disabled: PropTypes.bool
};

// Style Overrides
const PageTitle = styled(Title)`
  display: inline-block;
  width: 100%;
`;

const Favorite = styled.button`
  width: inherit;
  display: inherit;
  padding: 0 ${pad}px;
  background: transparent;
  z-index: ${z("top")};

  .primaryIcon {
    ${voice.quiet};
    /* allow a larger click area */
    position: absolute;
    top: 0;
    right: 0;
    padding: ${pad / 2}px;
    fill: ${({theme}) => theme.secondary};
  }
`;

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: ${pad}px;
`;

const BadgeWrapper = styled.div`
  display: flex;
`;

export default FacilityPageHeader;
