import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Components
import CheckBox from "./CheckBox.js";

// Style
import {flex} from "../style/components/mixins.js";
import {lines, pad} from "../style/components/variables.js";
import {Text, Label, Abbr, Inline} from "../style/components/general.js";

const MultiSelect = ({
  testId = "multiselect",
  label = "",
  options,
  defaultSelection = [],
  setSelection,
  disabled = false,
  allowToggle = false,
  hasLineColors = false
}) => {
  const [selected, setSelected] = useState(defaultSelection || []);
  const optionWrapper = useRef(null);
  const optionAll = useRef(null);

  useEffect(() => {
    setSelection(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleAllSelected = checked => {
    const checkboxes = optionWrapper.current.querySelectorAll("div input");
    Array.from(checkboxes).map(node => {
      node.checked = checked;
    });
    setSelected(checked ? options : []);
  };

  const handleCheck = (checked, option) => {
    if (checked) setSelected(prev => (prev ? [...prev, option] : [option]));
    else setSelected(selected.filter(existing => existing !== option));
  };

  const sanitizeValue = option => {
    if (option?.value) return option.value;
    if (option?.option) return option.option;
    if (option?.name) return option.name;
    return option;
  };

  const sanitizeLabel = option => {
    if (option?.label) return option.label;
    if (option?.option) return option.option;
    if (option?.name) return option.name;
    if (option?.value) return option.value;
    return option;
  };

  const getColor = (index, value) => {
    if (!options) return null;
    if (options === true) return lines[0];
    if (selected?.includes(value)) return lines[index % lines.length];
    return null;
  };

  return (
    <Wrapper data-testid={testId}>
      {label && <Label bold>{label.toUpperCase()}</Label>}
      <Options ref={optionWrapper}>
        {options.map((option, index) => (
          <Inline key={sanitizeLabel(option)}>
            <CheckBox
              testId={`${testId}-option${index + 1}`}
              name={sanitizeValue(option)}
              disabled={disabled}
              handleCheck={checked => handleCheck(checked, sanitizeValue(option))}
              initial={defaultSelection?.includes(sanitizeValue(option))}
            />
            <Description color={hasLineColors ? getColor(index, sanitizeValue(option)) : null}>
              {option.icon && (
                <IconWrapper color={`#${option.color}`}>
                  <FontAwesomeIcon icon={option.icon} />
                  &nbsp;&nbsp;
                </IconWrapper>
              )}
              <Abbr title={sanitizeLabel(option)}>{sanitizeLabel(option)}</Abbr>
            </Description>
          </Inline>
        ))}
      </Options>
      {allowToggle && (
        <div ref={optionAll}>
          <CheckBox disabled={disabled} handleCheck={checked => handleAllSelected(checked)} />
          <Text>All of the above.</Text>
        </div>
      )}
    </Wrapper>
  );
};

MultiSelect.propTypes = {
  testId: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  defaultSelection: PropTypes.array,
  setSelection: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  allowToggle: PropTypes.bool,
  hasLineColors: PropTypes.bool
};

// Style Overrides
const Wrapper = styled.div`
  ${flex("column", "wrap", "start", "start")};
  cursor: default;
`;

const Options = styled.div`
  width: 100%;
  margin: ${pad / 2}px 0;

  ${Inline} {
    max-width: 100%;
  }
`;

const Description = styled(Text)`
  display: block;
  padding-top: 2px;
  max-width: calc(100% - 23px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({color}) =>
    color &&
    css`
      border-left: 4px solid ${color};
      padding-left: ${pad / 2}px;
    `};
`;

const IconWrapper = styled.span`
  ${({color}) =>
    color &&
    css`
      svg {
        fill: ${color};

        path {
          stroke: #000000;
          stroke-width: 10;
        }
      }
    `}
`;

export default MultiSelect;
