import React, {useCallback, useContext} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCodeCompare} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {SettingsContext} from "../../contexts/settings";

// Utils
import {prettyDateWithDayInUserTimezone} from "../../utils/helpers";

// Components
import Accordion from "../../components/Accordion";

// Style
import {voice} from "../../style/components/typography";
import {border, pad, radius} from "../../style/components/variables";
import {Button, Loader, NotLoaded, scrollbar, Small, Text} from "../../style/components/general";

const RecordHistory = ({
  record,
  versions,
  currentVersion,
  setCurrentVersion,
  viewModification,
  setViewModification,
  edit = false
}) => {
  const {settings} = useContext(SettingsContext);

  const artificialVersion = () => {
    const filteredVersions = versions?.filter(version => version.submission);

    const target =
      filteredVersions?.length > 0 ? filteredVersions[filteredVersions.length - 1] : record;

    const targetUnfiltered = versions?.length > 0 ? versions[versions.length - 1] : record;

    const targetUser = targetUnfiltered?.user;

    let description = "";
    if (target.archivedBy)
      description += `Archived by ${target.archivedBy.firstName} ${target.archivedBy.lastName}`;
    else if (target.overdue) description += "Record generated by Hero Builder";
    else if (targetUser) {
      const start = target?.draft ? "Incomplete submission" : "Submission";
      description += `${start} created by ${targetUser.firstName} ${targetUser.lastName}`;
    }

    return (
      <Accordion
        label={prettyDateWithDayInUserTimezone(target.createdAt, settings.timezone)}
        handleOpen={() => {
          setCurrentVersion(prev =>
            prev?.id !== -1
              ? {
                  ...target,
                  user: targetUser,
                  id: -1,
                  submission: {
                    ...target.submission,
                    responses: {...target.submission.responses, ...target.modification}
                  },
                  modification: null
                }
              : undefined
          );
        }}
        toggleOpen={currentVersion?.id && currentVersion.id === -1}
        disabled={edit}>
        <AccordionContent>
          <Small secondary>{description}</Small>
        </AccordionContent>
      </Accordion>
    );
  };

  const versionDescription = useCallback((version, targetVersion) => {
    let message = "";
    if (!version.overdue && version.draft && !targetVersion.overdue && !targetVersion.draft) {
      message += "Submission created";
    } else if (version.draft && !version.overdue && !targetVersion.overdue)
      message += "Incomplete submission modified";
    else if (version.draft && version.overdue && targetVersion.overdue && targetVersion.draft)
      message += "Overdue incomplete submission created";
    else if (version.draft && version.overdue && targetVersion.draft)
      message += "Overdue incomplete submission modified";
    else if (version.overdue && version.draft && !targetVersion.draft)
      message += "Overdue submission created";
    else message += "Modified";

    return `${message} by ${targetVersion.user.firstName} ${targetVersion.user.lastName}`;
  }, []);

  const buildVersions = () => {
    const rendered = versions?.map((version, idx) => {
      // Targets the record if the more recent version doesn't exist
      const targetVersion = idx > 0 ? versions[idx - 1] : record;

      return (
        <Accordion
          testId="record.historyEntry"
          key={`version-${version.id}`}
          label={prettyDateWithDayInUserTimezone(version.createdAt, settings.timezone)}
          handleOpen={() => {
            setCurrentVersion(prev =>
              prev?.id !== version.id ? {...version, user: targetVersion.user} : undefined
            );
            setViewModification(false);
          }}
          toggleOpen={currentVersion?.id && currentVersion.id === version.id}
          disabled={edit}>
          <>
            <AccordionContent>
              {version.submission ? (
                <>
                  <Small secondary>{versionDescription(version, targetVersion)}</Small>
                  {version.modification && (
                    <Mod
                      type="button"
                      disabled={edit}
                      onClick={() => setViewModification(prev => !prev)}
                      data-testid="record.viewModification"
                      active={viewModification}>
                      <FontAwesomeIcon icon={faCodeCompare} />
                    </Mod>
                  )}
                </>
              ) : (
                <Small secondary>
                  Modified completed by from {version.user.firstName}&nbsp;
                  {version.user.lastName} to {targetVersion.user.firstName}&nbsp;
                  {targetVersion.user.lastName}
                </Small>
              )}
            </AccordionContent>
            {version.note && (
              <Explanation quiet>
                <strong>Explanation: </strong>
                {version.note}
              </Explanation>
            )}
          </>
        </Accordion>
      );
    });

    return rendered;
  };

  return (
    <HistoryWrapper>
      <Text>History</Text>
      <hr />
      <VersionsWrapper>
        {buildVersions()}

        {versions && artificialVersion()}

        {!record.archived && !record.draft && !versions && <NoHistory>No history</NoHistory>}

        {!versions && (
          <VersionsNotLoaded>
            <VersionLoader />
          </VersionsNotLoaded>
        )}
      </VersionsWrapper>
    </HistoryWrapper>
  );
};

RecordHistory.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  versions: PropTypes.arrayOf(PropTypes.any),
  currentVersion: PropTypes.objectOf(PropTypes.any),
  setCurrentVersion: PropTypes.func.isRequired,
  viewModification: PropTypes.bool,
  setViewModification: PropTypes.func.isRequired,
  edit: PropTypes.bool
};

// Style Overrides
const HistoryWrapper = styled.div`
  border: 2px solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  margin-bottom: ${pad}px;

  @media print {
    border: none;
  }
`;

const VersionsWrapper = styled.div`
  max-height: 350px;
  min-height: min-content;
  margin-bottom: ${pad}px;
  overflow-y: auto;
  padding: ${pad / 2}px;
  ${scrollbar};
`;

const AccordionContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 ${pad / 2}px ${pad * 2}px;
  border: ${border} solid ${({theme}) => theme.tertiary};

  &:last-child {
    margin-bottom: 0;
  }
`;

const Mod = styled(Button)`
  ${voice.quiet};
  padding: ${pad / 4}px ${pad / 2}px;
  margin-left: ${pad / 2}px;
  text-align: left;
  pointer-events: ${({disabled}) => (!disabled ? "pointer" : "none")};
  opacity: ${({active}) => (!active ? 1 : 0.5)};
`;

const VersionsNotLoaded = styled(NotLoaded)`
  height: min-content;
  width: calc(100% - 1px);
  margin: ${pad / 2}px 0 0 0;
`;

const VersionLoader = styled(Loader)`
  height: min-content;
  width: calc(100% - 1px);
  margin: 0;
`;

const NoHistory = styled(Small)`
  margin-left: ${pad / 2}px;
`;

const Explanation = styled(Text)`
  margin-left: 22px;
`;

export default RecordHistory;
