import React from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import styled from "styled-components";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Components
import {InputTextArea} from "../../components/form/FormInputs.js";
import Modal from "../../components/Modal.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Form, FormField, Inline, Button, HeadingCenter} from "../../style/components/general.js";
import Help from "../../components/Help.js";

const ModalBuilderVersionNote = ({visible, setVisible, submitNote, cancel, handleInitiateBulk}) => {
  const schema = yup.object().shape({
    note: yup.string().nullable().required("Please provide explanation.")
  });

  const form = useForm({
    defaultValues: {
      note: ""
    },
    resolver: yupResolver(schema)
  });

  const {handleSubmit} = form;

  const submit = ({note}) => submitNote(note);

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalTitle>Reason for Update</ModalTitle>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(submit)} noValidate>
          <FormField>
            <InputTextArea
              name="note"
              label="Explanation"
              testId="addNote.message"
              maxLength={1000}
            />
          </FormField>
          <SpacedInline>
            <Inline>
              <Button type="submit">Save</Button>
              <Button type="button" onClick={() => cancel()}>
                Cancel
              </Button>
            </Inline>
            {handleInitiateBulk && (
              <SkipWrapper>
                <Skip
                  type="button"
                  onClick={() => {
                    handleInitiateBulk();
                    cancel();
                  }}>
                  Skip
                </Skip>
                <Help left>
                  Allows submission of multiple edits with a single explanation, requiring a manual
                  save.
                </Help>
              </SkipWrapper>
            )}
          </SpacedInline>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalBuilderVersionNote.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  submitNote: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  handleInitiateBulk: PropTypes.func
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin-bottom: ${pad * 2}px;
`;

const SpacedInline = styled(Inline)`
  justify-content: space-between;
  margin-top: ${pad}px;
  width: 100%;
`;

const Skip = styled.button`
  width: min-content;
  padding-right: ${pad}px;

  text-decoration: underline;
`;

const SkipWrapper = styled(Inline)`
  gap: 0;
`;

export default ModalBuilderVersionNote;
