import React, {useState, useEffect, useCallback, useContext, useMemo} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faSearch,
  faStickyNote,
  faCalendarDay,
  faKeyboard,
  faBoxArchive,
  faArchive,
  faTrash,
  faPaperclip,
  faNoteSticky,
  faEdit,
  faShareFromSquare,
  faMagnifyingGlassArrowRight,
  faRotateLeft,
  faCircleInfo,
  faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Contexts
import {FacilityNavContext} from "../../contexts/facilitynav.js";
import {SettingsContext} from "../../contexts/settings.js";
import {AuthContext} from "../../contexts/auth.js";
import {useToast} from "../../contexts/toast.js";
import {useSocket} from "../../contexts/socket.js";

// Utils
import {prettyDateWithDayInUserTimezone} from "../../utils/helpers.js";
import {isMobile} from "../../utils/responsive.js";

// Components
import BaseTable from "../../components/BaseTable.js";
import Pagination from "../../components/Pagination.js";
import ToggleSwitch from "../../components/ToggleSwitch.js";
import Dropdown from "../../components/Dropdown.js";
import ModalRecordView from "./ModalRecordView.js";
import ModalNotes from "./ModalNotes.js";
import ModalAttachments from "./ModalAttachments.js";
import ArchiveRecord from "../general/ArchiveRecord.js";
import Badge from "../../components/Badge.js";
import Modal from "../../components/Modal.js";
import ModalFieldRestrictions from "./ModalFieldRestrictions.js";
import ModalReviews from "../tasks/ModalReviews.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {colors, heroTheme, pad, radius} from "../../style/components/variables.js";
import {
  Abbr,
  Arrow,
  Button,
  ButtonWrapper,
  Error,
  Heading,
  Inline,
  Pill,
  Search,
  SearchIcon,
  SearchWrapper,
  Small,
  TableFooter,
  TableWrapper,
  Text,
  HeadingMedium,
  Loader
} from "../../style/components/general.js";

// Socket Constants
import {
  LISTEN_NOTIFY_FREQUENCY_CHANGE,
  LIST_LOCKS,
  REQUEST_LOCKS,
  getFacilityRooms
} from "../general/Room.js";

const TableRecord = ({
  index,
  checksheet,
  publishDraft,
  showExport,
  showDelete,
  archiveChecksheet,
  restoreChecksheet,
  canRequestLocks,
  handleViewAll
}) => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const navigate = useNavigate();

  const {slug} = useParams();
  const {settings} = useContext(SettingsContext);
  const {atLeast, roleCanAccessResource, roles} = useContext(AuthContext);
  const {facility} = useContext(FacilityNavContext);
  const {addToast} = useToast();

  const [changeLoading, setChangeLoading] = useState(false);
  const [targetChecksheet, setTargetChecksheet] = useState(checksheet);
  const [locks, setLocks] = useState(null);
  const [open, setOpen] = useState(false);
  const [records, setRecords] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState({});
  const [searchType, setSearchType] = useState("text");
  const [query, setQuery] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [orderBy, setOrderBy] = useState("desc");
  const [groupBy, setGroupBy] = useState(checksheet?.frequency ? "dateDue" : "completedAt");
  const [limit, setLimit] = useState(10);
  const [limits, setLimits] = useState([]);
  // Modals
  const [modalNotesVisible, setModalNotesVisible] = useState(false);
  const [modalAttachmentsVisible, setModalAttachmentsVisible] = useState(false);
  const [modalAcknowledgeVisible, setModalAcknowledgeVisible] = useState(false);
  const [modalExternalVisible, setModalExternalVisible] = useState(false);
  const [modalFieldRestrictionsVisible, setModalFieldRestrictionsVisible] = useState(false);
  const [modalReviewsVisible, setModalReviewsVisible] = useState(false);

  const {api: apiChecksheets} = useApi("checksheets");
  const {api: apiChecksheetRecords, loading: loadingRecords} = useApi("checksheet-records");
  const {api: apiUser} = useApi("users");

  const form = useForm({
    defaultValues: {
      search: ""
    }
  });
  const {reset, register} = form;

  const isActive = useMemo(() => facility && !facility.isDeleted, [facility]);

  const listLocks = useCallback(({lockList}) => setLocks(lockList), []);

  const notifyFrequencyChange = useCallback(
    checksheetId => {
      if (checksheetId === `${targetChecksheet.id}`) {
        setChangeLoading(true);
        apiChecksheets.callGet(targetChecksheet.id).then(({status, data: freqChangeData}) => {
          if (status === 200 && freqChangeData) setTargetChecksheet(freqChangeData);
          setChangeLoading(false);
        });
      }
    },
    [apiChecksheets, targetChecksheet.id]
  );

  // Socket Management - resource locking
  useEffect(() => {
    if (isMounted() && slug && !locks && canRequestLocks)
      socket.emit(REQUEST_LOCKS, {
        rooms: getFacilityRooms(slug, "checksheet"),
        type: "checksheet"
      });

    socket.on(LISTEN_NOTIFY_FREQUENCY_CHANGE, notifyFrequencyChange);
    socket.on(LIST_LOCKS, listLocks);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LISTEN_NOTIFY_FREQUENCY_CHANGE, notifyFrequencyChange);
      socket.off(LIST_LOCKS, listLocks);
    };
  }, [socket, isMounted, slug, locks, canRequestLocks, notifyFrequencyChange, listLocks]);

  const openModal = useCallback(
    localRecords => {
      const {hash} = window.location;
      if (hash && initialLoad) {
        const recordId = parseInt(hash.replace("#", ""), 10);
        if (recordId && !Number.isNaN(recordId)) {
          const shouldOpen = localRecords.filter(record => record.id === recordId);
          if (shouldOpen.length > 0) {
            setSelected(shouldOpen[0]);
            setVisible(true);
            setInitialLoad(false);
          }
        }
      }
    },
    [initialLoad]
  );

  const getChecksheetRecords = useCallback(
    p => {
      const payload = {
        facilityChecksheetId: targetChecksheet.id,
        page: p || 1,
        orderBy: orderBy,
        groupBy: groupBy,
        limit: limit
      };

      if (query) payload.filter = JSON.stringify({Search: query});

      apiChecksheetRecords.callGet(null, payload).then(({status, data}) => {
        if (status === 200 && data) {
          setPageTotal(data.pages);
          setRecords(data.records);

          // Page Limit
          const {total: dataTotal} = data;
          setTotal(dataTotal);
          let count = 10;
          const temp = [];
          while (count < total + 10 && count <= 30) {
            temp.push(count);
            count += 10;
          }
          setLimits(temp);
          if (initialLoad) openModal(data.records);
        }
      });
    },
    [
      apiChecksheetRecords,
      groupBy,
      initialLoad,
      limit,
      openModal,
      orderBy,
      query,
      targetChecksheet.id,
      total
    ]
  );

  // Open first table
  useEffect(() => {
    if (isMounted() && index === 1) setOpen(true);
  }, [isMounted, index]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && targetChecksheet.id && !records && open) getChecksheetRecords();
  }, [isMounted, targetChecksheet, getChecksheetRecords, records, open]);

  // Update checksheet records on sort or limit
  useEffect(() => {
    if (isMounted() && records) getChecksheetRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, orderBy, groupBy, limit, query]);

  const handleSearch = e => {
    setCurrentPage(1);
    setQuery(e.target.value);
  };

  const checksheetLocked = () => {
    if (locks && locks[`checksheet_${targetChecksheet.id}`]) {
      apiUser.callGet(locks[`checksheet_${targetChecksheet.id}`].user).then(({status, data}) => {
        if (status === 200 && data) {
          const {firstName, lastName} = data;
          addToast(`Locked by ${firstName} ${lastName}`);
        }
      });
      return true;
    }

    return false;
  };

  const getNotesColumnContent = record => {
    if (record.overdue && !record.archived && atLeast("admin"))
      return (
        <Inline>
          {!!record.noteCount && (
            <NoteIcon
              onClick={() => {
                setSelected(record);
                setModalNotesVisible(true);
              }}>
              <FontAwesomeIcon icon={faStickyNote} />
              <p>{record.noteCount}</p>
            </NoteIcon>
          )}
        </Inline>
      );

    if (record.noteCount || record.fileCount)
      return (
        !!record.noteCount && (
          <NoteIcon
            onClick={() => {
              setSelected(record);
              setModalNotesVisible(true);
            }}>
            <FontAwesomeIcon icon={faStickyNote} />
            <p>
              {record.noteCount +
                (atLeast("admin") && record.archivedNotes ? record.archivedNotes.length : 0)}
            </p>
          </NoteIcon>
        )
      );

    return null;
  };

  const getAttachmentsColumnContent = record =>
    !!record.fileCount && (
      <Abbr title={`${record.fileCount} record attachment(s)`}>
        <AttachmentIcon
          onClick={() => {
            setSelected(record);
            setModalAttachmentsVisible(true);
          }}>
          <FontAwesomeIcon icon={faPaperclip} />
          <p>{record.fileCount}</p>
        </AttachmentIcon>
      </Abbr>
    );

  const getRestrictToUsers = () => {
    if (targetChecksheet.restrictTo?.[0]?.publicId)
      return targetChecksheet.restrictTo.map(({email}) => email).join(", ");

    if (roles)
      return targetChecksheet.restrictTo
        ?.filter(({id}) => roles.filter(({id: roleId}) => id === roleId)[0]?.label)
        .map(({id}) => roles.filter(({id: roleId}) => id === roleId)[0].label)
        .join(", ");

    return "roles";
  };

  return (
    <StyledTableWrapper data-testid="tableRecord.wrapper">
      <TableMenuRow>
        <Inline>
          <ChecksheetName>
            <Abbr title={targetChecksheet.name}>{targetChecksheet.name.toUpperCase()}</Abbr>
          </ChecksheetName>
          {targetChecksheet?.frequency?.name && (
            <Frequency>
              <Abbr title={targetChecksheet.frequency.name.toUpperCase()}>
                {targetChecksheet.frequency.name.toUpperCase()}
              </Abbr>
            </Frequency>
          )}
          {targetChecksheet.draft && <Draft data-testid="draftIcon">DRAFT</Draft>}
          {targetChecksheet.isArchived && <Draft>ARCHIVED</Draft>}
          {targetChecksheet.dateDue && !targetChecksheet.draft && (
            <NextDue to={`/facilities/${slug}/tasks`}>
              <Abbr
                title={`Next Due: ${prettyDateWithDayInUserTimezone(
                  targetChecksheet.dateDue,
                  settings.timezone
                )}`}>
                Next Due:{" "}
                {prettyDateWithDayInUserTimezone(targetChecksheet.dateDue, settings.timezone)}
              </Abbr>
            </NextDue>
          )}
          {changeLoading && <Loader />}
        </Inline>
        <Inline>
          <ButtonWrapper
            data-testid="tableRecord.expand"
            type="button"
            onClick={() => {
              setOpen(prev => !prev);
              setRecords(null);
            }}>
            <Arrow rotate={!open ? "180deg" : "270deg"} />
          </ButtonWrapper>
        </Inline>
        <BadgeContainer>
          {(!!targetChecksheet.overdueCount || !!targetChecksheet.draftCount) && (
            <Badge
              offsetX="0"
              offsetY="18px"
              color={
                !targetChecksheet.overdueCount && !!targetChecksheet.draftCount
                  ? colors.yellow
                  : undefined
              }>
              {targetChecksheet.overdueCount || targetChecksheet.draftCount}
            </Badge>
          )}
          {!!targetChecksheet.draftCount && !!targetChecksheet.overdueCount && (
            <Badge offsetX="-24px" offsetY="18px" color={colors.yellow}>
              {targetChecksheet.draftCount}
            </Badge>
          )}
        </BadgeContainer>
      </TableMenuRow>

      {open && (
        <>
          <TableMenuRow>
            <StyledSearchWrapper>
              {searchType === "text" && (
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
              )}
              <FormProvider {...form}>
                <Search
                  name="search"
                  type={searchType}
                  placeholder="Search..."
                  {...register("search")}
                  onChange={e => handleSearch(e, targetChecksheet.id)}
                />
              </FormProvider>
              <Spaced>
                <ToggleSwitch
                  iconLeft={faKeyboard}
                  iconRight={faCalendarDay}
                  textLeft="Text"
                  textRight="Date"
                  onChange={() => {
                    setSearchType(prev => (prev === "text" ? "date" : "text"));
                    reset({search: ""});
                  }}
                  options={["text", "date"]}
                  defaultSelected="text"
                />
              </Spaced>
            </StyledSearchWrapper>
            <ButtonBar>
              {targetChecksheet.draft &&
                roleCanAccessResource("facility_checksheet", "update") &&
                isActive && (
                  <TransitionButton
                    type="button"
                    loading={!locks ? 1 : 0}
                    onClick={() => publishDraft(targetChecksheet.id)}
                    data-testid="checksheets.publishDraft">
                    <Abbr title="Publish">
                      <FontAwesomeIcon icon={faShareFromSquare} />
                    </Abbr>
                  </TransitionButton>
                )}

              <TransitionButton
                type="button"
                loading={!locks ? 1 : 0}
                onClick={() => setModalFieldRestrictionsVisible(true)}>
                <Abbr title="Checksheet Info">
                  <FontAwesomeIcon icon={faCircleInfo} />
                </Abbr>
              </TransitionButton>

              {!isMobile() && atLeast("admin") && (
                <Button
                  type="button"
                  data-testid="checksheetsDue.complete"
                  onClick={() => setModalReviewsVisible(true)}>
                  {targetChecksheet.reviews?.length > 0 && (
                    <Badge
                      count={targetChecksheet.reviews?.length}
                      offset="14px"
                      color={heroTheme.warning}
                    />
                  )}
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                </Button>
              )}

              <TransitionButton
                type="button"
                loading={!locks ? 1 : 0}
                onClick={() =>
                  navigate(`/facilities/${slug}/checksheets/${targetChecksheet.id}/preview`)
                }
                data-testid="checksheets.publishDraft">
                <Abbr title="Preview">
                  <FontAwesomeIcon icon={faMagnifyingGlassArrowRight} />
                </Abbr>
              </TransitionButton>

              {roleCanAccessResource("facility_checksheet_record", "export") &&
                targetChecksheet.hasRecords && (
                  <Export
                    type="button"
                    onClick={() => showExport(targetChecksheet)}
                    loading={!locks ? 1 : 0}>
                    <Abbr title="Export">
                      <FontAwesomeIcon icon={faFileExport} />
                    </Abbr>
                  </Export>
                )}

              {roleCanAccessResource("facility_checksheet", "update") &&
                !targetChecksheet.isArchived &&
                isActive && (
                  <Edit
                    type="Button"
                    onClick={() => {
                      if (!checksheetLocked())
                        navigate(`/facilities/${slug}/checksheets/${targetChecksheet.id}/edit`);
                    }}
                    loading={!locks ? 1 : 0}
                    locked={locks && locks[`checksheet_${targetChecksheet.id}`]}>
                    <Abbr title="Edit">
                      <FontAwesomeIcon icon={faEdit} />
                    </Abbr>
                  </Edit>
                )}

              {isActive &&
                (roleCanAccessResource("facility_checksheet", "delete") ||
                  roleCanAccessResource("facility_checksheet", "archive")) && (
                  <>
                    {roleCanAccessResource("facility_checksheet", "archive") && (
                      <Archive
                        type="button"
                        onClick={() => {
                          if (!checksheetLocked()) {
                            if (!targetChecksheet.isArchived) archiveChecksheet(targetChecksheet);
                            else restoreChecksheet(targetChecksheet);
                          }
                        }}
                        loading={!locks ? 1 : 0}
                        locked={locks && locks[`checksheet_${targetChecksheet.id}`]}>
                        {!targetChecksheet.isArchived ? (
                          <Abbr title="Archive">
                            <FontAwesomeIcon icon={faArchive} />
                          </Abbr>
                        ) : (
                          <Abbr title="Restore">
                            <FontAwesomeIcon icon={faRotateLeft} />
                          </Abbr>
                        )}
                      </Archive>
                    )}

                    {roleCanAccessResource("facility_checksheet", "delete") && (
                      <Delete
                        data-testid="tableRecord.deleteChecksheet"
                        type="button"
                        onClick={() => {
                          if (!checksheetLocked()) showDelete(targetChecksheet);
                        }}
                        loading={!locks ? 1 : 0}
                        locked={locks && locks[`checksheet_${targetChecksheet.id}`]}>
                        <Abbr title="Delete">
                          <FontAwesomeIcon icon={faTrash} />
                        </Abbr>
                      </Delete>
                    )}
                  </>
                )}
            </ButtonBar>
          </TableMenuRow>
          <BaseTable
            headings={{
              facilityChecksheetId: {header: "", disabled: true},
              submission: {header: "Record", disabled: true},
              noteCount: {
                header: (
                  <Abbr title="Notes">
                    <NoteColumnHeader icon={faNoteSticky} />
                  </Abbr>
                ),
                disabled: false
              },
              fileCount: {
                header: (
                  <Abbr title="Attachments">
                    <AttachmentColumnHeader icon={faPaperclip} />
                  </Abbr>
                ),
                disabled: false
              },
              status: {header: "Status", disabled: false},
              dateDue: {header: targetChecksheet?.frequency ? "Due" : "", disabled: false},
              completedAt: {header: "Date Completed", disabled: false}
            }}
            data={
              !records
                ? null
                : records.map(record => ({
                    submission: (
                      <Inline>
                        {roleCanAccessResource("facility_checksheet_record", "view") && (
                          <>
                            <Button
                              type="button"
                              onClick={() => {
                                setVisible(true);
                                setSelected(record);
                              }}
                              data-testid="tableRecord.preview">
                              View
                            </Button>
                            {roleCanAccessResource("facility_checksheet_record", "archive") &&
                              record.overdue &&
                              !record.archived &&
                              isActive && (
                                <Edit
                                  onClick={() => {
                                    setSelected(record);
                                    setModalAcknowledgeVisible(true);
                                  }}>
                                  <Abbr title="Archive">
                                    <FontAwesomeIcon icon={faArchive} />
                                  </Abbr>
                                </Edit>
                              )}
                          </>
                        )}
                      </Inline>
                    ),
                    noteCount: getNotesColumnContent(record),
                    fileCount: getAttachmentsColumnContent(record),
                    status: (
                      <Inline>
                        <Status status={record.status} archived={record.archived} quiet>
                          {record.archived && (
                            <Abbr title="Archived">
                              <FontAwesomeIcon icon={faBoxArchive} />
                              &nbsp;
                            </Abbr>
                          )}
                          {record.status}
                        </Status>
                        {record.draft ? <Text quiet>- {record.percentComplete}%</Text> : ""}
                      </Inline>
                    ),
                    dateDue: record.overdue ? (
                      <WarningMsg>
                        {prettyDateWithDayInUserTimezone(
                          record.dateDue,
                          settings.timezone,
                          "ddd, MMM D YYYY"
                        )}
                      </WarningMsg>
                    ) : (
                      prettyDateWithDayInUserTimezone(
                        record.dateDue,
                        settings.timezone,
                        "ddd, MMM D YYYY"
                      )
                    ),
                    completedAt: !record.overdue && (
                      <Completed late={dayjs(record.completedAt).isAfter(dayjs(record.dateDue))}>
                        {record.completedBy &&
                          record.completedBy.firstName &&
                          `${record.completedBy.firstName} ${record.completedBy.lastName} on `}
                        {prettyDateWithDayInUserTimezone(
                          record.completedAt,
                          settings.timezone,
                          "ddd, MMM D YYYY"
                        )}
                      </Completed>
                    )
                  }))
            }
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            groupBy={groupBy}
            setGroupBy={setGroupBy}
            loading={loadingRecords}
          />
          <TableFooter>
            <Inline>
              {total > 10 && limits.length > 0 && (
                <PageLimit>
                  <Dropdown
                    options={limits}
                    selection={limit}
                    setSelection={selection => {
                      setLimit(selection);
                      setCurrentPage(1);
                    }}
                  />
                </PageLimit>
              )}
              {total > 0 && (
                <Text quiet>
                  {limit <= 30 && pageTotal > 1 && limits.length > 0 && "per page, "}
                  <ModalViewLink
                    disabled={!total || total <= 10}
                    onClick={
                      !total || total <= 10
                        ? undefined
                        : () => handleViewAll(targetChecksheet, getChecksheetRecords)
                    }>
                    {total} total
                  </ModalViewLink>
                </Text>
              )}
              {targetChecksheet.external && (
                <External quiet>
                  External checksheet available to&nbsp;
                  <Button type="button" onClick={() => setModalExternalVisible(true)} inText quiet>
                    {targetChecksheet.restrictTo?.[0]?.publicId ? "users" : "roles"}
                  </Button>
                  .
                </External>
              )}
            </Inline>
            {limit <= 30 && pageTotal > 1 && (
              <Pagination
                current={currentPage}
                setCurrent={setCurrentPage}
                pageTotal={pageTotal}
                updateData={() => getChecksheetRecords(currentPage)}
                loading={loadingRecords}
              />
            )}
          </TableFooter>
        </>
      )}

      {visible && (
        <ModalRecordView
          visible={visible}
          setVisible={setVisible}
          record={selected}
          checksheet={checksheet}
          updateData={getChecksheetRecords}
        />
      )}

      {modalNotesVisible && (
        <ModalNotes
          visible={modalNotesVisible}
          setVisible={setModalNotesVisible}
          pendingNotes={selected.postedNotes}
          resolvedNotes={selected.resolvedNotes}
          noteFileCount={selected.noteFileCount}
          noteCt={selected.noteCount}
          fileCt={selected.fileCount}
          files={selected.files}
          archivedNotes={atLeast("admin") ? selected.archivedNotes : null}
          updateData={getChecksheetRecords}
          checksheetName={targetChecksheet.name}
          dateDue={selected.dateDue}
          builder={targetChecksheet.builder}
        />
      )}

      {modalAttachmentsVisible && (
        <ModalAttachments
          visible={modalAttachmentsVisible}
          setVisible={setModalAttachmentsVisible}
          builder={targetChecksheet.builder}
          files={selected.files}
          pendingNotes={selected.postedNotes}
          resolvedNotes={selected.resolvedNotes}
        />
      )}

      {modalAcknowledgeVisible && (
        <ArchiveRecord
          visible={modalAcknowledgeVisible}
          setVisible={setModalAcknowledgeVisible}
          updateData={getChecksheetRecords}
          record={selected}
          date={prettyDateWithDayInUserTimezone(
            selected?.dateDue,
            settings.timezone,
            "MMM D, YYYY"
          )}
          task={checksheet}
          taskType="checksheet"
        />
      )}

      {modalExternalVisible && (
        <Modal visible={modalExternalVisible} setVisible={setModalExternalVisible}>
          <HeadingMedium>External Checksheet</HeadingMedium>
          <Text>
            This is an external checksheet available to&nbsp;
            {getRestrictToUsers()}. It is only available for completion via a link provided by
            email.
          </Text>
        </Modal>
      )}

      {modalFieldRestrictionsVisible && (
        <ModalFieldRestrictions
          visible={modalFieldRestrictionsVisible}
          setVisible={setModalFieldRestrictionsVisible}
          checksheet={targetChecksheet}
        />
      )}

      {modalReviewsVisible && (
        <ModalReviews
          visible={modalReviewsVisible}
          setVisible={setModalReviewsVisible}
          checksheet={targetChecksheet}
        />
      )}
    </StyledTableWrapper>
  );
};

TableRecord.propTypes = {
  index: PropTypes.number.isRequired,
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired,
  publishDraft: PropTypes.func.isRequired,
  showExport: PropTypes.func.isRequired,
  showDelete: PropTypes.func.isRequired,
  archiveChecksheet: PropTypes.func.isRequired,
  restoreChecksheet: PropTypes.func.isRequired,
  canRequestLocks: PropTypes.bool.isRequired,
  handleViewAll: PropTypes.func.isRequired
};

// Style Overrides
const TableMenuRow = styled.div`
  ${flex("row", "nowrap", "space-between", "center")};
  width: 100%;
  padding: ${pad}px;

  ${Inline}:first-child {
    max-width: 90%;
    width: inherit;
  }
`;

const ChecksheetName = styled(Heading)`
  text-overflow: ellipsis;
  overflow-x: hidden;
  display: block;
  white-space: nowrap;
  max-width: 65%;

  ${bp(2)} {
    max-width: inherit;
  }
`;

const Frequency = styled(Small)`
  width: fit-content;
  min-width: min-content;
  padding: ${pad / 4}px ${pad / 2}px;
  border-radius: 10px;
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.primary};
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  ${voice.quiet};
`;

const Draft = styled(Small)`
  width: fit-content;
  padding: ${pad / 4}px ${pad / 2}px;
  border-radius: 10px;
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.warning};
  ${voice.quiet};
`;

const NextDue = styled(Link)`
  color: ${({theme}) => theme.primary};
  display: none;

  ${bp(2)} {
    display: block;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    width: min-content;
  }
`;

const Export = styled(Button)`
  transition: opacity 100ms;
  opacity: 1;
  visibility: visible;

  padding: ${pad / 2}px ${pad}px;
  border-radius: ${radius};
  background: ${({theme}) => theme.secondary};
  color: ${({theme}) => theme.tertiary};

  ${({loading}) =>
    loading
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : ""}
`;

const TransitionButton = styled(Button)`
  transition: opacity 100ms;
  opacity: 1;
  visibility: visible;

  ${({locked}) =>
    locked
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
        `
      : ""}

  ${({loading}) =>
    loading
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : ""}
`;

const Edit = styled(TransitionButton)`
  display: none;

  ${bp(1)} {
    display: block;
  }
`;

const Archive = styled(TransitionButton)`
  padding: ${pad / 2}px ${pad}px;
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.warning};
`;

const Delete = styled(TransitionButton)`
  padding: ${pad / 2}px ${pad}px;
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.error};
`;

const PageLimit = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${pad}px;

  select {
    padding: ${pad / 4}px;
  }
`;

const WarningMsg = styled(Error)`
  ${voice.normal};
  color: ${({theme}) => theme.error};
`;

const Completed = styled(Error)`
  ${voice.normal};
  color: ${({late, theme}) => (late ? theme.error : "inherit")};
`;

const NoteColumnHeader = styled(FontAwesomeIcon)`
  fill: ${({theme}) => theme.tertiary};
`;

const NoteIcon = styled(Inline)`
  flex-wrap: nowrap;
  color: ${({theme}) => theme.primary};
  width: min-content;
  cursor: pointer;
  margin-bottom: 2px;
  font-size: 14px;
  gap: 2px;

  svg {
    pointer-events: none;
    fill: ${({theme}) => theme.primary};
    font-size: 14px;
  }

  p {
    margin-top: 1px;
    margin-left: ${pad / 2}px;
  }
`;

const AttachmentColumnHeader = styled(FontAwesomeIcon)`
  fill: ${({theme}) => theme.tertiary};
`;

const AttachmentIcon = styled(Inline)`
  flex-wrap: nowrap;
  color: ${({theme}) => theme.primary};
  width: min-content;
  cursor: pointer;
  margin-bottom: 2px;
  font-size: 14px;

  svg {
    pointer-events: none;
    fill: ${({theme}) => theme.primary};
    font-size: 14px;
  }

  p {
    margin-top: 1px;
    margin-left: ${pad / 2}px;
  }
`;

const Spaced = styled.div`
  height: min-content;
  margin-left: ${pad / 2}px;

  ${bp(2)} {
    margin-left: ${pad}px;
  }
`;

const Status = styled(Pill)`
  width: 118px;
  justify-content: center;

  background: ${({status, theme}) => {
    if (status === "Incomplete") return theme.warning;
    if (status === "Overdue") return theme.error;
    if (status === "Late") return theme.alert;
    return theme.success;
  }};

  ${({archived}) =>
    archived &&
    css`
      opacity: 0.7;
    `}

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const BadgeContainer = styled.div`
  top: 8px;
  right: 7px;
  display: block;
  position: absolute;

  ${bp(3)} {
    top: 6px;
    right: 9px;
  }
`;

const StyledSearchWrapper = styled(SearchWrapper)`
  max-width: calc(100% - 176px);
`;

const External = styled(Text)`
  display: inherit;
`;

const ModalViewLink = styled.span`
  ${({disabled}) =>
    !disabled &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`;

const StyledTableWrapper = styled(TableWrapper)`
  min-width: 330px;
`;

const ButtonBar = styled(Inline)`
  margin-left: ${pad / 2}px;
  gap: 7px;

  ${bp(1)} {
    gap: ${pad}px;
  }
`;

export default TableRecord;
