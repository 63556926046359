import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment,
  useMemo,
  useCallback
} from "react";
import {useReactToPrint} from "react-to-print";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPrint,
  faFileExport,
  faBoxArchive,
  faFilter
} from "@fortawesome/free-solid-svg-icons";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";
import {useToast} from "../../contexts/toast.js";
import {useSocket} from "../../contexts/socket.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {startExport} from "../../utils/export.js";
import {formatSubmissions, getAncestors} from "../../utils/builder.js";
import {isMobileSize} from "../../utils/responsive.js";
import {prettyDateInUserTimezone, prettyDateWithDayInUserTimezone} from "../../utils/helpers.js";

// Components
import RenderSubmission from "./RenderSubmission.js";
import RenderChecksheet from "../checksheet-builder/RenderChecksheet.js";
import InputText from "../../components/form/InputText.js";
import InputCheck from "../../components/form/InputCheck.js";
import InputCheckGroup from "../../components/form/InputCheckGroup.js";
import Weather from "../../components/Weather.js";
import RecordResponsibility from "./RecordResponsibility.js";
import RecordHistory from "./RecordHistory.js";
import RecordNotes from "./RecordNotes.js";
import Badge from "../../components/Badge.js";
// Modals
import Modal from "../../components/Modal.js";
import ModalFilter from "../checksheet-builder/ModalFilter.js";
import ModalReassignUser from "./ModalReassignUser.js";
import ModalRecordVersionNote from "./ModalRecordVersionNote.js";

// Style
import {border, colors, pad, radius} from "../../style/components/variables.js";
import {bp, breakpoint} from "../../style/components/breakpoints.js";
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {
  Abbr,
  Button,
  ButtonLoader,
  Form,
  FormField,
  FormGroup,
  Heading,
  Inline,
  Title
} from "../../style/components/general.js";

// Socket Constants
import {getFacilityRooms, LIST_LOCKS, LOCK, RELEASE, REQUEST_LOCKS} from "../general/Room.js";

const ModalRecordView = ({
  visible,
  setVisible,
  record,
  checksheet,
  updateData,
  editing = false,
  hasBackButton = false,
  goBack
}) => {
  const socket = useSocket();

  const isMounted = useMountedState();

  const {addToast} = useToast();

  const {
    slug,
    builder: {allIds, byId}
  } = useMemo(() => checksheet || {}, [checksheet]);
  const {submission} = useMemo(() => record || {}, [record]);

  const hasNotes = useRef(
    Object.keys(record.postedNotes || {}).length > 0 ||
      Object.keys(record.archivedNotes || {}).length > 0 ||
      Object.keys(record.resolvedNotes || {}).length > 0
  );
  const headerRef = useRef();
  const weatherRef = useRef();
  const recordRef = useRef();
  const notesRef = useRef();
  const printRef = useRef();

  const configurePrint = () => {
    const printBody = document.createElement("div");
    printBody.style.pageBreakInside = "avoid";

    if (weatherRef?.current) {
      const weatherClone = weatherRef.current.cloneNode(true);
      printBody.appendChild(weatherClone);
    }

    const headerClone = headerRef.current.cloneNode(true);
    printBody.appendChild(headerClone);

    if (hasNotes.current) {
      const recordNotesWrapper = document.createElement("div");
      recordNotesWrapper.style.display = "flex";
      recordNotesWrapper.style.justifyContent = "start";
      recordNotesWrapper.style.flexWrap = "nowrap";
      recordNotesWrapper.style.gap = "10px";

      const notesClone = notesRef.current.cloneNode(true);
      const recordClone = recordRef.current.cloneNode(true);

      const recordWrapper = document.createElement("div");
      recordWrapper.style.width = "65%";

      recordWrapper.appendChild(recordClone);

      const notesWrapper = document.createElement("div");
      notesWrapper.style.width = "34%";
      notesWrapper.style.marginTop = "55px";

      notesWrapper.appendChild(notesClone);

      recordNotesWrapper.appendChild(recordWrapper);
      recordNotesWrapper.appendChild(notesWrapper);

      printBody.appendChild(recordNotesWrapper);
    } else {
      const recordClone = recordRef.current.cloneNode(true);
      printBody.appendChild(recordClone);
    }

    printRef.current = printBody;
  };

  const handlePrint = useReactToPrint({
    contentRef: printRef
  });

  const {currentUser, atLeast, roleCanAccessResource} = useContext(AuthContext);
  const {facility} = useContext(FacilityNavContext);
  const {settings} = useContext(SettingsContext);

  const [locks, setLocks] = useState();
  const [selectedRecord, setSelectedRecord] = useState(record);
  const [versions, setVersions] = useState();
  const [currentVersion, setCurrentVersion] = useState(null);
  const [edit, setEdit] = useState(editing);
  const [editUser, setEditUser] = useState(editing);
  const [exporting, setExporting] = useState(false);
  const [options] = useState(
    selectedRecord && selectedRecord.submission && selectedRecord.submission.responses && byId
      ? Object.keys(selectedRecord.submission.responses)
          .filter(key => key in byId)
          .map(key => ({
            name: `${getAncestors(byId[key].parentName, byId)} ${byId[key].label}`,
            value: key
          }))
      : []
  );
  const [refreshNotes, setRefreshNotes] = useState(false);
  const [viewMode, setViewMode] = useState("record");
  const [viewModification, setViewModification] = useState(false);
  const [fieldTags, setFieldTags] = useState([]);
  const [updatingVersions, setUpdatingVersions] = useState(false);
  const [showExplanationModal, setShowExplanationModal] = useState(false);
  const [queuedEdit, setQueuedEdit] = useState(false);
  // Filters
  const [filters, setFilters] = useState(null);
  const [filterResult, setFilterResult] = useState(null);
  const [showModalFilter, setShowModalFilter] = useState(false);
  // Export
  const [loadingExport, setLoadingExport] = useState(false);
  const [exportError, setExportError] = useState();

  const exportAllowed = useRef();

  const {api: apiUser} = useApi("users");
  const {api: apiChecksheetRecords} = useApi("checksheet-records");
  const {api: apiRecords} = useApi("checksheet-records", {suppress: {success: false, error: true}});
  const {api: apiVersions} = useApi("checksheet-record-versions");
  const {api: apiFieldTags} = useApi("field-tags");

  const schema = yup.object().shape({
    exportName: yup.string().required(),
    help: yup.boolean(),
    fields: yup.lazy(() =>
      options && options.length === 1
        ? yup.string().nullable()
        : yup.array().nullable().of(yup.string())
    )
  });

  const initialValues = {
    exportName: "",
    help: false,
    fields: null
  };

  const form = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });
  const {handleSubmit, reset} = form;

  const filterCount = useMemo(() => {
    let count = 0;
    if (filters?.types?.length) count += filters.types.length;
    if (filters?.tags?.length) count += filters.tags.length;
    return count;
  }, [filters]);

  // Socket Management - resource locking
  useEffect(() => {
    if (isMounted() && !locks) {
      socket.emit(REQUEST_LOCKS, {
        rooms: getFacilityRooms(slug, "checksheet"),
        type: "record"
      });
    }
  }, [isMounted, locks, socket, slug]);

  const listLocks = useCallback(({lockList}) => setLocks(lockList), []);

  useEffect(() => {
    socket.on(LIST_LOCKS, listLocks);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LIST_LOCKS, listLocks);
    };
  }, [isMounted, socket, listLocks]);

  // Initial Load
  useEffect(() => {
    apiVersions.callGet(null, {recordId: selectedRecord.id}).then(({status, data}) => {
      if (status === 200 && data?.versions) setVersions(data.versions);
      setUpdatingVersions(false);
    });

    apiFieldTags.callGet().then(({status, data}) => {
      if (status === 200 && data)
        setFieldTags(data.map(({id, name}) => ({id, name, label: name.toUpperCase()})));
    });
  }, [socket, currentUser, apiVersions, apiFieldTags, selectedRecord]);

  const handleEdit = (values, outOfRange = null, outOfRangeMultiple = null, draft = false) => {
    const {completedAt} = values;
    const responses = {...values};
    delete responses.completedAt;

    const formatted = formatSubmissions(responses, checksheet.builder);

    const edited = {
      submission: {
        name: submission.name,
        frequency: submission.frequency,
        responses: formatted
      },
      userId: currentUser.publicId,
      draft,
      completedAt: completedAt || null,
      outOfRange: outOfRange ? Object.keys(outOfRange) : null,
      outOfRangeMultiple: outOfRangeMultiple ? Object.keys(outOfRangeMultiple) : null
    };

    if (selectedRecord.draft || selectedRecord.overdue)
      apiChecksheetRecords.callPut(selectedRecord.id, edited).then(({status}) => {
        if (status === 200) {
          updateData();
          setVisible(false);
        }
      });
    else {
      setQueuedEdit(edited);
      setShowExplanationModal(true);
    }
  };

  const handleNote = note => {
    apiChecksheetRecords.callPut(selectedRecord.id, {...queuedEdit, note}).then(({status}) => {
      if (status === 200) {
        updateData();
        setVisible(false);
      }
    });
  };

  const handleUpdateUser = ({user}) => {
    if (user) {
      const edited = {
        completedBy: user.publicId
      };
      if (selectedRecord.draft)
        apiChecksheetRecords.callPut(selectedRecord.id, edited).then(({status, data}) => {
          if (status === 200) {
            setUpdatingVersions(true);
            updateData();
            setSelectedRecord(data.data);
            setEditUser(false);
          }
        });
      else {
        setQueuedEdit(edited);
        setShowExplanationModal(true);
      }
    }
  };

  useEffect(() => {
    if (!visible) setEdit(false);
  }, [setVisible, visible]);

  useEffect(() => {
    if (refreshNotes) {
      setRefreshNotes(false);
      updateData();
    }
  }, [refreshNotes, updateData]);

  // Handle filtering
  useEffect(() => {
    if (filters?.types || filters?.tags) {
      setFilterResult(
        Object.fromEntries(
          Object.entries(byId).filter(
            ([, {tag, type}]) =>
              filters.types?.includes(type) || (tag && filters.tags?.includes(tag))
          )
        )
      );
    } else {
      setFilters(null);
      setFilterResult(null);
    }
  }, [filters, allIds, byId]);

  // Check if export is available
  useEffect(() => {
    if (!exportAllowed?.current)
      apiRecords
        .callPatch(null, {
          facilityChecksheetRecordId: selectedRecord.id,
          exportName: "test",
          help: true
        })
        .then(res => {
          exportAllowed.current = res.status === 200;
          if (res.status !== 200) setExportError(res);
        });
  }, [apiRecords, selectedRecord.id]);

  const createExport = async values => {
    setLoadingExport(true);
    const {exportName, fields, help} = values;
    const params = {
      facilityChecksheetRecordId: selectedRecord.id,
      exportName,
      help: help || false
    };

    if (fields?.length > 0) params["fields"] = fields.toString();

    apiRecords
      .callPatch(null, params)
      .then(res => {
        const {status, data} = res;
        if (status === 200 && data) {
          const rows = [];
          const {body, header} = data;
          body.map(row => {
            const temp = {};
            row.map(pair => {
              temp[pair.key] = pair.value;
            });
            rows.push(temp);
          });

          startExport("csv", exportName, rows, header);
          setVisible(false);
          reset({...initialValues});
        } else addToast(res, "error");
      })
      .finally(() => setLoadingExport(false));
  };

  const checksheetLocked = () => {
    if (locks && locks[`checksheet_${checksheet.id}_record_${record.id}`]) {
      apiUser
        .callGet(locks[`checksheet_${checksheet.id}_record_${record.id}`].user)
        .then(({status, data}) => {
          if (status === 200 && data) {
            const {firstName, lastName} = data;
            addToast(`Locked by ${firstName} ${lastName}`);
          }
        });
      return true;
    }

    return false;
  };

  if (showExplanationModal)
    return (
      <ModalRecordVersionNote
        visible={showExplanationModal}
        setVisible={setShowExplanationModal}
        submitNote={handleNote}
        cancel={() => {
          setQueuedEdit(undefined);
          setShowExplanationModal(false);
        }}
      />
    );

  if (editUser)
    return (
      <ModalReassignUser
        visible={visible && editUser}
        setVisible={setVisible}
        goBack={() => setEditUser(false)}
        facilityId={facility?.id}
        update={handleUpdateUser}
        current={
          selectedRecord.completedBy
            ? selectedRecord.completedBy.publicId
            : selectedRecord.user?.publicId
        }
      />
    );

  if (showModalFilter)
    return (
      <ModalFilter
        visible={showModalFilter}
        setVisible={setShowModalFilter}
        filters={filters}
        setFilters={setFilters}
        tags={fieldTags}
        hasBackButton
        goBack={() => setShowModalFilter(false)}
      />
    );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      isChecksheetPreview
      clickOutsideToClose={false}
      maxWidth={breakpoint.width[5]}
      hasBackButton={hasBackButton}
      goBack={goBack}>
      <Row>
        <HeadingWrapper>
          <Heading>Checksheet Submission</Heading>

          <Info ref={headerRef}>
            <Status status={selectedRecord.status} archived={selectedRecord.archived}>
              {selectedRecord.archived && (
                <Abbr title="Archived">
                  <FontAwesomeIcon icon={faBoxArchive} />
                  &nbsp;
                </Abbr>
              )}
              {selectedRecord.status}
            </Status>
            {selectedRecord.conflict && (
              <Conflict>
                <Abbr title="This record has conflicting versions. Please contact system administrator to resolve.">
                  CONFLICT
                </Abbr>
              </Conflict>
            )}
            <span>
              {selectedRecord.dateDue && (
                <MobileBlock overdue={selectedRecord.overdue}>
                  <strong>DATE&nbsp;DUE</strong>:&nbsp;{isMobileSize() && <br />}
                  {prettyDateWithDayInUserTimezone(selectedRecord.dateDue, settings.timezone)}
                </MobileBlock>
              )}
              <br />
              <br />
              {selectedRecord.completedAt && (
                <MobileBlock>
                  <strong>DATE&nbsp;COMPLETED</strong>:&nbsp;{isMobileSize() && <br />}
                  {prettyDateInUserTimezone(
                    selectedRecord.completedAt,
                    settings.timezone,
                    "MMM D, YYYY"
                  )}{" "}
                  <CompletedByWrapper>
                    {selectedRecord.completedBy && (
                      <>
                        by {selectedRecord.completedBy.firstName}&nbsp;
                        {selectedRecord.completedBy.lastName}
                      </>
                    )}
                    {atLeast("super") && (
                      <CompletedByEdit onClick={() => setEditUser(true)} type="button">
                        <FontAwesomeIcon icon={faEdit} />
                      </CompletedByEdit>
                    )}
                  </CompletedByWrapper>
                </MobileBlock>
              )}
            </span>
          </Info>
        </HeadingWrapper>
        <StyledInline>
          {atLeast("admin") && !editing && submission && !selectedRecord.overdue && (
            <SpacedInline>
              {roleCanAccessResource("facility_checksheet_record_version", "export") &&
                !edit &&
                options.length > 0 && (
                  <Export
                    onClick={() => {
                      if (!exportAllowed?.current && exportError) addToast(exportError, "error");
                      else setExporting(prev => !prev);
                    }}
                    error={!exportAllowed?.current}>
                    {!exporting ? (
                      <abbr title="Export">
                        <FontAwesomeIcon icon={faFileExport} />
                      </abbr>
                    ) : (
                      "Cancel"
                    )}
                  </Export>
                )}

              {roleCanAccessResource("facility_checksheet_record_version", "export") &&
                !edit &&
                !exporting && (
                  <Export
                    type="button"
                    onClick={() => {
                      configurePrint();
                      handlePrint();
                    }}>
                    <abbr title="Print">
                      <FontAwesomeIcon icon={faPrint} />
                    </abbr>
                  </Export>
                )}

              {!edit && (
                <Filters>
                  <FilterButton type="button" onClick={() => setShowModalFilter(true)}>
                    <Badge count={filterCount} offset="14px" color={colors.heroGreen} />
                    <FontAwesomeIcon icon={faFilter} />
                  </FilterButton>
                </Filters>
              )}

              {updateData &&
                roleCanAccessResource("facility_checksheet_record_version", "create") &&
                !facility?.isDeleted &&
                !checksheet.isArchived &&
                !exporting &&
                !viewModification &&
                (!currentVersion ||
                  versions?.length === 0 ||
                  currentVersion.id === versions[0].id) && (
                  <Edit
                    type="button"
                    locked={
                      locks &&
                      locks[`checksheet_${checksheet.id}_record_${record.id}`] &&
                      locks[`checksheet_${checksheet.id}_record_${record.id}`].user !==
                        currentUser.publicId
                    }
                    onClick={() => {
                      if (!edit && !checksheetLocked()) {
                        setEdit(true);
                        socket.emit(
                          LOCK,
                          getFacilityRooms(slug, "checksheet"),
                          `checksheet_${checksheet.id}_record_${record.id}`
                        );
                      } else {
                        setEdit(false);
                        socket.emit(
                          RELEASE,
                          getFacilityRooms(slug, "checksheet"),
                          `checksheet_${checksheet.id}_record_${record.id}`
                        );
                      }
                    }}>
                    {!edit ? (
                      <abbr title="Edit">
                        <FontAwesomeIcon icon={faEdit} />
                      </abbr>
                    ) : (
                      "Cancel"
                    )}
                  </Edit>
                )}
            </SpacedInline>
          )}
          <SpacedInline>
            <ModeToggle
              onClick={() => {
                setViewMode(prev => (prev === "record" ? "notes" : "record"));
              }}>
              View {viewMode === "record" ? "Notes" : "Record"}
            </ModeToggle>
            {roleCanAccessResource("facility_checksheet_record_version", "create") &&
              selectedRecord.overdue && (
                <Button type="button" onClick={() => setEdit(prev => !prev)}>
                  {edit ? "Cancel" : "Complete"}
                </Button>
              )}
          </SpacedInline>
        </StyledInline>
      </Row>

      <Wrapper>
        <PageLeft data-testid="record.left" mode={viewMode}>
          {!edit && submission && !exporting && (
            <RenderSubmission
              ref={recordRef}
              submission={
                currentVersion?.submission ? currentVersion.submission : selectedRecord.submission
              }
              builder={
                selectedRecord.overdue && !selectedRecord.draft && !edit
                  ? {allIds: [], byId: {}}
                  : checksheet.builder
              }
              filterResult={filterResult}
              dateDue={selectedRecord.dateDue}
              completedAt={selectedRecord.completedAt}
              startDate={selectedRecord.startDate ?? selectedRecord.dateDue}
              draft={selectedRecord.draft}
              modifications={currentVersion?.modification || null}
              viewModification={viewModification}
              submissionName={checksheet.name}
              notifications={checksheet.notifications}
            />
          )}

          <Hide hide={!edit ? 1 : 0}>
            <Inline>
              <Title>{checksheet.name}</Title>
              {submission.frequency && (
                <Frequency>{submission.frequency && submission.frequency.toUpperCase()}</Frequency>
              )}
              {checksheet.draft && <Draft>INCOMPLETE</Draft>}
            </Inline>
            <RenderChecksheet
              task={checksheet}
              taskRecord={selectedRecord}
              readOnly={false}
              preview={false}
              persistToLocalStorage={editing}
              responses={submission.responses}
              setSubmission={(responses, outOfRange, outOfRangeMultiple) =>
                handleEdit(responses, outOfRange, outOfRangeMultiple)
              }
              setDraft={
                selectedRecord.overdue
                  ? (responses, outOfRange, outOfRangeMultiple) =>
                      handleEdit(responses, outOfRange, outOfRangeMultiple, true)
                  : null
              }
              cancelFunction={() => setEdit(false)}
              previousSubmission={selectedRecord.previousSubmission}
              dateDue={selectedRecord.dateDue}
              draft={selectedRecord.draft}
              overdue={selectedRecord.overdue}
              startDate={selectedRecord.startDate ?? selectedRecord.dateDue}
              completedAt={selectedRecord.completedAt}
            />
          </Hide>

          {exporting && (
            <FormProvider {...form}>
              <Form onSubmit={handleSubmit(createExport)}>
                <FormGroup>
                  <FormField>
                    <InputText
                      name="exportName"
                      label="Please provide a name for the export."
                      required
                    />
                  </FormField>
                  <FormField>
                    <InputCheck name="help">
                      Include selected help information in export?
                    </InputCheck>
                  </FormField>
                  <FormField>
                    <InputCheckGroup name="fields" label="Target Fields" options={options} all />
                  </FormField>
                </FormGroup>
                <Button type="submit" loading={loadingExport ? 1 : 0}>
                  Export{loadingExport && <ButtonLoader />}
                </Button>
              </Form>
            </FormProvider>
          )}
        </PageLeft>

        {selectedRecord && (
          <PageRight mode={viewMode}>
            {selectedRecord.weather && (
              <Weather ref={weatherRef} weather={selectedRecord.weather} />
            )}

            {record?.user !== "GENERATED" && record?.completedAt && (
              <RecordResponsibility
                record={selectedRecord}
                versions={updatingVersions ? null : versions}
                currentVersion={currentVersion}
                setCurrentVersion={setCurrentVersion}
              />
            )}

            <RecordHistory
              record={selectedRecord}
              versions={versions}
              currentVersion={currentVersion}
              setCurrentVersion={setCurrentVersion}
              setViewModification={setViewModification}
              edit={edit}
            />

            <RecordNotes
              ref={notesRef}
              checksheet={checksheet}
              record={selectedRecord}
              updateData={updateData}
            />
          </PageRight>
        )}
      </Wrapper>
    </Modal>
  );
};

ModalRecordView.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateData: PropTypes.func,
  editing: PropTypes.bool,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

// Style Overrides
const HeadingWrapper = styled.div`
  margin-right: ${pad}px;
`;

const Row = styled.section`
  width: 100%;
  padding: 0 0 ${pad}px;
  margin-bottom: ${pad}px;
  ${flex("row", "wrap", "space-between", "end")};
`;

const Wrapper = styled.article`
  ${flex("row", "wrap-reverse", "start", "start")};
`;

const PageLeft = styled.div`
  position: relative;
  width: 100%;
  border: 2px solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad * 1.5}px;

  ${({mode}) =>
    mode === "notes" &&
    css`
      display: none;
    `}

  ${bp(3)} {
    display: block;
    width: 65%;
  }
`;

const PageRight = styled.div`
  width: 100%;

  ${({mode}) =>
    mode === "record" &&
    css`
      display: none;
    `}

  ${bp(3)} {
    position: relative;
    display: block;
    width: 35%;
    padding-left: ${pad}px;

    ${({addingNote}) =>
      addingNote &&
      css`
        position: sticky;
        left: 70%;
        top: 60px;
        z-index: 3;
      `}
  }
`;

const CompletedByWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${pad / 2}px;
`;

const CompletedByEdit = styled.button`
  width: fit-content;

  svg {
    fill: ${({theme}) => theme.secondary};
    padding: ${pad / 2}px 0 0;
  }
`;

const Frequency = styled.span`
  text-transform: uppercase;
  padding: 0 ${pad / 2}px;
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  background: ${({theme}) => theme.primary};
  color: ${({theme}) => theme.tertiary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: ${pad}px;
  min-width: min-content;
`;

const Draft = styled.span`
  ${voice.quiet}
  text-transform: uppercase;
  border: ${border} solid ${({theme}) => theme.warning};
  padding: ${pad / 10}px ${pad / 4}px;
  border-radius: 10px;
  background: ${({theme}) => theme.warning};
  color: ${({theme}) => theme.tertiary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: ${pad}px;
`;

const Conflict = styled.span`
  width: 100px;
  text-align: center;
  text-transform: uppercase;
  padding: ${pad / 5}px ${pad / 2}px;
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  margin-right: ${pad}px;
  background-color: ${({theme}) => theme.error};
  ${voice.quiet};
`;

const Info = styled.p`
  padding-bottom: ${pad}px;
  color: ${({theme}) => theme.secondary};

  &:last-child {
    padding: 0;
  }

  @media print {
    display: flex;
    flex-direction: row-reverse;
    align-items: start;
    justify-content: space-between;
    width: 100%;
  }
`;

const Export = styled(Button)`
  ${({error}) =>
    error &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const StyledInline = styled(Inline)`
  justify-content: space-between;
  margin-top: ${pad}px;
  width: 100%;

  ${bp(3)} {
    margin-top: 0;
    width: unset;
  }
`;

const SpacedInline = styled(Inline)`
  gap: ${pad}px;
`;

const ModeToggle = styled(Button)`
  ${bp(3)} {
    display: none;
  }
`;

const Status = styled.span`
  ${voice.quiet};
  width: 100px;
  text-align: center;
  text-transform: uppercase;
  padding: ${pad / 5}px ${pad / 2}px;
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  display: inline-block;
  white-space: nowrap;
  display: block;
  margin-bottom: ${pad}px;

  background: ${({status, theme}) => {
    if (status === "Incomplete") return theme.warning;
    if (status === "Overdue") return theme.error;
    if (status === "Late") return theme.alert;
    return theme.success;
  }};

  ${({archived}) =>
    archived &&
    css`
      opacity: 0.7;
    `}

  svg {
    fill: ${({theme}) => theme.tertiary};
  }

  @media print {
    margin: 0;
  }
`;

const Hide = styled.div`
  ${({hide}) =>
    hide &&
    css`
      display: none;
    `}
`;

const MobileBlock = styled.span`
  ${({overdue, theme}) =>
    overdue &&
    css`
      color: ${theme.error};
    `}
`;

const Filters = styled.div`
  position: relative;
`;

const FilterButton = styled(Button)`
  overflow: initial;
`;

const Edit = styled(Button)`
  transition: opacity 100ms;
  opacity: 1;
  visibility: visible;

  ${({locked}) =>
    locked
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
        `
      : ""}

  ${({loading}) =>
    loading
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : ""}
`;

export default ModalRecordView;
