import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import {exists} from "../../utils/helpers";
import {isMobileDevice} from "../../utils/responsive";

// Styles
import {heroTheme} from "../../style/components/variables";

const ChartPoint = ({
  chartId,
  id,
  x,
  xDisplay,
  y,
  yDisplay,
  r,
  qualifier = "",
  missing = false,
  source,
  color = heroTheme.black,
  units = "",
  label = "",
  width,
  tooltips = false,
  setTooltipData = () => {},
  tooltipPosition,
  setTooltipPosition = () => {},
  active = {},
  setActive = () => {}
}) => {
  const mouseover = () => {
    setTooltipData({
      x: xDisplay,
      y: yDisplay,
      units,
      qualifier,
      label: label,
      color,
      missing
    });
  };

  const mouseleave = () => {
    setTooltipPosition({top: null, left: null, right: null});
    setTooltipData({});
  };

  const mousemove = ({nativeEvent}) => {
    if (
      nativeEvent &&
      exists(nativeEvent.offsetX) &&
      exists(nativeEvent.offsetY) &&
      nativeEvent.offsetX > width / 2
    )
      setTooltipPosition({
        top: `${nativeEvent.offsetY}px`,
        left: null,
        right: `${width - nativeEvent.offsetX + 20}px`
      });
    else if (nativeEvent && exists(nativeEvent.offsetX) && exists(nativeEvent.offsetY))
      setTooltipPosition({
        top: `${nativeEvent.offsetY}px`,
        left: `${nativeEvent.offsetX + 20}px`,
        right: null
      });
    else setTooltipPosition({});
  };

  const onclick = ({nativeEvent}) => {
    if (setActive)
      setActive({
        source,
        date: xDisplay
      });

    if (tooltips && nativeEvent && isMobileDevice()) {
      if (tooltipPosition && tooltipPosition.top) mouseleave();
      else {
        mousemove({nativeEvent});
        mouseover();
      }
    } else setTooltipPosition({});
  };

  if (missing)
    return (
      <Rect
        id={id}
        x={x - 4.2}
        y={y}
        width={r * 1.5}
        height={r * 1.5}
        transform={`rotate(-45, ${x - 4.2}, ${y})`}
        fill="red"
        clipPath={`url(#clip-${chartId})`}
        onMouseEnter={tooltips ? mouseover : null}
        onMouseMove={tooltips ? mousemove : null}
        onMouseLeave={tooltips ? mouseleave : null}
        onClick={tooltips ? onclick : null}
        active={active}
        source={source}
        xDisplay={xDisplay}
      />
    );

  return (
    <Circle
      id={id}
      cx={x}
      cy={y}
      r={r}
      fill={color}
      color={color}
      clipPath={`url(#clip-${chartId})`}
      onMouseEnter={tooltips ? mouseover : null}
      onMouseMove={tooltips ? mousemove : null}
      onMouseLeave={tooltips ? mouseleave : null}
      onClick={tooltips ? onclick : null}
      active={active}
      source={source}
      xDisplay={xDisplay}
    />
  );
};

ChartPoint.propTypes = {
  chartId: PropTypes.string.isRequired,
  id: PropTypes.string,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  xDisplay: PropTypes.string,
  yDisplay: PropTypes.string,
  r: PropTypes.number.isRequired,
  missing: PropTypes.bool,
  color: PropTypes.string,
  qualifier: PropTypes.string,
  source: PropTypes.string,
  units: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.number.isRequired,
  setActive: PropTypes.func,
  active: PropTypes.objectOf(PropTypes.any),
  setTooltipData: PropTypes.func,
  setTooltipPosition: PropTypes.func,
  tooltipPosition: PropTypes.objectOf(PropTypes.any),
  tooltips: PropTypes.bool
};

// Style Overrides
const Circle = styled.circle`
  fill: ${({color}) => color || "black"};
  stroke: ${({active, source, xDisplay}) =>
    active && active.source && active.source === source && active.date && active.date === xDisplay
      ? heroTheme.primary
      : "transparent"};
  stroke-width: 2px;
`;

const Rect = styled.rect`
  fill: red;
  stroke: ${({active, source, xDisplay}) =>
    active && active.source && active.source === source && active.date && active.date === xDisplay
      ? heroTheme.primary
      : "transparent"};
  stroke-width: 2px;
`;

export default ChartPoint;
