import React, {useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Components
import {AccountRow, Save} from "./components.js";
import {InputCheck, InputSelect} from "../../components/form/FormInputs.js";

// Style
import {colors, pad} from "../../style/components/variables.js";
import {
  Form,
  FormField,
  SettingsRowWrapper,
  Abbr,
  Pill,
  Inline
} from "../../style/components/general.js";

const Integrations = ({edit, setEdit}) => {
  const {settings, saveSettings} = useContext(SettingsContext);

  const isMounted = useMountedState();

  const {api: apiMS} = useApi("microsoft");

  const microsoftLoaded = useRef(false);

  // const [reportingOps, setReportingOps] = useState([
  //   {label: "Hero Builder (default)", value: "default"}
  // ]);
  const [groups, setGroups] = useState([]);
  const [allowGroupChange, setAllowGroupChange] = useState(false);

  const form = useForm({
    defaultValues: {reporting: ["default"]}
  });
  const {watch, setValue, reset, handleSubmit} = form;
  const watchSharepoint = watch("sharepoint");

  useEffect(() => {
    if (isMounted() && settings?.microsoft === "enabled" && !microsoftLoaded.current) {
      // setReportingOps(prev => [...prev, {label: "Microsoft Power BI", value: "powerbi"}]);
      microsoftLoaded.current = true;
    }
  }, [isMounted, settings]);

  useEffect(() => {
    if (isMounted()) {
      if (settings?.powerBiEnabled === "enabled") setValue("reporting", ["default", "powerbi"]);
      if (settings) {
        setValue("sharepoint", settings?.sharepointEnabled === "enabled");
        setValue("sharepointGroupId", settings?.sharepointGroupId);
      }
    }
  }, [isMounted, settings, setValue]);

  useEffect(() => {
    if (isMounted() && settings?.microsoft === "enabled" && watchSharepoint)
      apiMS
        .callGet(null, {
          resourceType: "sharepoint",
          resourcePath: "groups",
          retrieveAllowGroupChange: true
        })
        .then(({status, data}) => {
          if (status === 200 && data?.groups) {
            setGroups(data.groups.map(({id, displayName}) => ({label: displayName, value: id})));
            setAllowGroupChange(data.allowGroupChange);
          }
        });
  }, [isMounted, settings, apiMS, watchSharepoint]);

  const handleSave = ({reporting, sharepoint, sharepointGroupId}) => {
    saveSettings({
      powerBiEnabled: reporting?.includes("powerbi") ? "enabled" : "disabled",
      sharepointEnabled: sharepoint ? "enabled" : "disabled",
      sharepointGroupId: sharepoint ? sharepointGroupId : "disabled"
    });
    reset();
    setEdit(false);
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(handleSave)} noValidate>
        <SettingsRowWrapper>
          <AccountRow label="Connections">
            <Abbr
              title={
                settings?.microsoft === "enabled"
                  ? "Enabled"
                  : "Disabled: Contact your system admin if interested in integrations."
              }>
              <Connection>
                <Icon enabled={settings?.microsoft} icon={faCircle} />
                <Img src="/assets/microsoft.svg" alt="Microsoft" />
                &nbsp; Microsoft
              </Connection>
            </Abbr>
          </AccountRow>
          {/* <AccountRow label="Reporting">
            {edit ? (
              <FormField>
                <InputCheckGroup name="reporting" options={reportingOps} />
              </FormField>
            ) : (
              <Inline>
                <Pill>Hero Builder (default)</Pill>
                {settings.powerBiEnabled === "enabled" && (
                  <Pill>
                    <Img src="/assets/powerbi.png" alt="PowerBI" />
                    &nbsp;Power Bi
                  </Pill>
                )}
              </Inline>
            )}
          </AccountRow> */}
          {settings?.microsoft === "enabled" && (
            <AccountRow label="Tables">
              {edit ? (
                <FormField>
                  <InputCheck name="sharepoint">Allow Microsoft Sharepoint Tables?</InputCheck>
                  {watchSharepoint && (
                    <AccountRow>
                      <InputSelect
                        name="sharepointGroupId"
                        placeholder="Target Group..."
                        options={groups}
                        disabled={!allowGroupChange}
                      />
                    </AccountRow>
                  )}
                </FormField>
              ) : (
                <Inline>
                  <Pill>Hero Builder (default)</Pill>
                  {settings.sharepointEnabled === "enabled" && (
                    <Pill>
                      <Img src="/assets/sharepoint.ico" alt="Sharepoint" />
                      &nbsp;Sharepoint
                    </Pill>
                  )}
                </Inline>
              )}
            </AccountRow>
          )}
        </SettingsRowWrapper>
        {edit && <Save type="submit">Save</Save>}
      </Form>
    </FormProvider>
  );
};

Integrations.propTypes = {
  edit: PropTypes.bool.isRequired,
  setEdit: PropTypes.func.isRequired
};

// Style Overrides
const Connection = styled(Pill)`
  display: flex;
  justify-content: center;
  background: ${({theme}) => theme.secondary};
`;

const Icon = styled(FontAwesomeIcon)`
  width: ${pad}px;
  margin-right: ${pad}px;
  fill: ${({enabled}) => (enabled ? colors.green : colors.red)};
`;

const Img = styled.img`
  height: ${pad * 1.5}px;
`;

export default Integrations;
