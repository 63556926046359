import React, {useCallback, useEffect, useRef, useState} from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faQuestionCircle, faStickyNote} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Hoooks
import useApi from "../hooks/useApi.js";
import useScrollListener from "../hooks/useScrollListener.js";
import useMountedState from "../hooks/useMountedState.js";

// Utils
import {COMPONENT, GROUP, FIELD} from "../utils/builder.js";
import {exists, inViewport} from "../utils/helpers.js";

// Components
import CalloutMenu from "./CalloutMenu.js";
import AddNoteCalloutContent from "../pages/tasks/AddNoteCalloutContent.js";
import ViewNotesCalloutContent from "../pages/tasks/ViewNotesCalloutContent.js";
import AccordionWrapped from "./AccordionWrapped.js";
import Modal from "./Modal.js";
import {
  InputText,
  InputNumber,
  InputCheck,
  InputCheckGroup,
  InputRadioGroup,
  InputSelect,
  InputTextArea,
  InputSwitch,
  InputConfirm,
  InputRange,
  InputFile,
  InputGenerated,
  InputDate,
  InputTime
} from "./form/FormInputs.js";

// Style
import {pad, radius, shadow, navHeight} from "../style/components/variables.js";
import {flex, z} from "../style/components/mixins.js";
import {voice} from "../style/components/typography.js";
import {bp} from "../style/components/breakpoints.js";
import {cross} from "../style/components/shapes.js";
import {
  FormGroup,
  Label,
  FormGroupHeading,
  StickyWrapper,
  Button,
  Inline,
  HeadingCenter,
  TextCenter,
  Abbr,
  Text
} from "../style/components/general.js";

const Element = ({
  room,
  byId = {},
  stage,
  task,
  taskRecord,
  taskType = "checksheet",
  attributes,
  readOnly = false,
  children,
  previousRead,
  weather,
  restriction,
  setFormulasEvaluating,
  globalUnacceptable,
  globalUnacceptableTitles
}) => {
  const {type, name, label, prompt, required, element, parentName, tag, condition} = attributes;

  let formattedCondition;
  if (condition) {
    formattedCondition = `${condition.action}: `;
    condition.list.map(({check, compare, depends, operator}, idx) => {
      if (condition.list.length > 1 && idx === condition.list.length - 1)
        formattedCondition += `, ${condition.operator} `;
      else if (condition.list.length > 1 && idx > 0) formattedCondition += ", ";

      const checkArray = Array.isArray(check) ? check : [check];
      const compareArray = Array.isArray(compare) ? compare : [compare];

      const dependsType = byId[depends]?.type || depends;
      let dependsUnits = byId[depends]?.units ? ` ${byId[depends]?.units}` : "";
      let dependsLabel = byId[depends]?.label || depends?.toUpperCase();

      if (depends === "cumulative") dependsLabel = "CUMULATIVE RAINFALL";
      if (depends === "cumulative" || depends === "rainfall") dependsUnits = " inches";

      if (depends === "weekday" || depends === "date") {
        compareArray.map((compareVal, compareIdx) => {
          if (depends === "date") {
            let transformed = "";
            const parts = compareVal.split("_");
            if (parts?.length > 1) {
              transformed = parts[1].toUpperCase();
              transformed +=
                parts[1] === "monthly" && parts?.length === 3
                  ? " on the last day of the month"
                  : ` starting ${dayjs(parts[0].slice(5)).format("MMM D")}`;
            } else transformed = dayjs(compare.slice(5)).format("MMM D");
            formattedCondition += transformed;
          } else formattedCondition += compareVal;
          if (compareIdx < compareArray.length - 2) formattedCondition += ", ";
          if (compareIdx === compareArray.length - 2) formattedCondition += ` ${operator} `;
        });
      } else {
        formattedCondition += `${dependsLabel} `;

        checkArray.map((checkVal, checkIdx) => {
          let comp = compareArray[checkIdx];

          let ch = checkVal || "is";

          if (ch === "===") ch = "is";
          if (ch === "!==") ch = "is not";
          if (ch === ">=") ch = "≥";
          if (ch === "<=") ch = "≤";
          if (ch === ">" && dependsType === "time") ch = "is after";
          if (ch === "<" && dependsType === "time") ch = "is before";
          if (dependsType === "multiselect") {
            ch = "has";
            if (comp === "checked") comp = "all checked";
            if (comp === "unchecked") comp = "all unchecked";
            if (comp === "one") comp = "one checked";
            if (comp === "more") comp = "at least one checked";
          }

          if (comp.match(/^Other: /)) comp = comp.replace(/^Other: /, "");

          formattedCondition += `${ch} ${comp}${dependsUnits}`;
          if (checkIdx < checkArray.length - 1) formattedCondition += ` ${operator} `;
        });
      }
    });
  }

  if ((!parentName && element === GROUP) || element === COMPONENT) return children;

  if (type === "checkbox") {
    const {hasAlert, alertMessage, alertCondition} = attributes;
    return (
      <>
        <InputCheck
          name={name}
          label={label}
          disabled={readOnly || !!restriction}
          globalUnacceptable={globalUnacceptable}
          globalUnacceptableTitles={globalUnacceptableTitles}
          hasAlert={hasAlert}
          alertMessage={alertMessage}
          alertCondition={alertCondition === "checked"}
          condition={formattedCondition}
          tag={tag}>
          {prompt}
        </InputCheck>
        {children}
      </>
    );
  }

  if (type === "multiselect") {
    const {options, all} = attributes;
    return (
      <>
        <InputCheckGroup
          name={name}
          label={label}
          prompt={prompt}
          options={options}
          all={all}
          disabled={readOnly || !!restriction}
          tag={tag}
          condition={formattedCondition}
          required={required}
        />
        {children}
      </>
    );
  }

  if (type === "radio") {
    const {options, other, orient, hasAlert, alertMessage, alertCondition} = attributes;
    return (
      <>
        <InputRadioGroup
          name={name}
          showError
          label={label}
          prompt={prompt}
          other={other}
          options={options}
          disabled={readOnly || !!restriction}
          orient={orient}
          globalUnacceptable={globalUnacceptable}
          globalUnacceptableTitles={globalUnacceptableTitles}
          hasAlert={hasAlert}
          alertMessage={alertMessage}
          alertCondition={alertCondition}
          condition={formattedCondition}
          tag={tag}
          required={required}
        />
        {children}
      </>
    );
  }

  if (type === "dropdown") {
    const {options, placeholder, other, hasAlert, alertMessage, alertCondition} = attributes;
    return (
      <>
        <InputSelect
          name={name}
          label={label}
          placeholder={placeholder}
          prompt={prompt}
          options={options}
          disabled={readOnly || !!restriction}
          other={other}
          globalUnacceptable={globalUnacceptable}
          globalUnacceptableTitles={globalUnacceptableTitles}
          hasAlert={hasAlert}
          alertMessage={alertMessage}
          alertCondition={alertCondition}
          condition={formattedCondition}
          tag={tag}
          required={required}
        />
        {children}
      </>
    );
  }

  if (type === "textarea") {
    const {maxLength} = attributes;
    return (
      <>
        <InputTextArea
          name={name}
          label={label}
          prompt={prompt}
          disabled={readOnly || !!restriction}
          maxLength={maxLength}
          condition={formattedCondition}
          tag={tag}
          required={required}
        />
        {children}
      </>
    );
  }

  if (type === "switch") {
    const {on, off, hasAlert, alertMessage, alertCondition} = attributes;
    return (
      <>
        <InputSwitch
          name={name}
          label={label}
          prompt={prompt}
          disabled={readOnly || !!restriction}
          on={on}
          off={off}
          globalUnacceptable={globalUnacceptable}
          globalUnacceptableTitles={globalUnacceptableTitles}
          hasAlert={hasAlert}
          alertMessage={alertMessage}
          alertCondition={alertCondition === on}
          condition={formattedCondition}
          tag={tag}
        />
        {children}
      </>
    );
  }

  if (type === "confirm") {
    const {phone, email} = attributes;
    return (
      <>
        <InputConfirm
          name={name}
          label={label}
          prompt={prompt}
          disabled={readOnly || !!restriction}
          phone={phone}
          email={email}
          condition={formattedCondition}
          tag={tag}
          required
        />
        {children}
      </>
    );
  }

  if (type === "weather") return null;

  if (type === "range") {
    const {
      rangeMin,
      rangeMax,
      precision,
      aMin,
      aMax,
      units,
      degree,
      hasARange,
      rangeException,
      hasSetPoint,
      setLow,
      setHigh,
      hasQualifier,
      notification,
      hasInfinity
    } = attributes;

    let precisionNumber = precision;
    if (exists(precisionNumber) && typeof precisionNumber === "string")
      precisionNumber = parseInt(precisionNumber, 10);

    return (
      <>
        <InputRange
          name={name}
          label={label}
          prompt={prompt}
          degree={degree}
          precision={precisionNumber}
          disabled={readOnly || !!restriction}
          rangeMin={rangeMin}
          rangeMax={rangeMax}
          aMin={aMin}
          aMax={aMax}
          units={units}
          hasARange={hasARange}
          rangeException={rangeException}
          hasSetPoint={hasSetPoint}
          setLow={setLow}
          setHigh={setHigh}
          previousRead={previousRead}
          qualifier={hasQualifier}
          notification={notification}
          allowInfinite={hasInfinity}
          condition={formattedCondition}
          tag={tag}
          required={required}
        />
        {children}
      </>
    );
  }

  if (type === "time") {
    // const {military} = attributes;
    return (
      <>
        <InputTime
          name={name}
          label={label}
          prompt={prompt}
          tag={tag}
          condition={formattedCondition}
          required={required}
          // military={military}
        />
        {children}
      </>
    );
  }

  if (type === "number") {
    const {
      min,
      max,
      aMin,
      aMax,
      strictMin,
      strictMax,
      units,
      degree,
      precision,
      hasARange,
      rangeException,
      restrictedRangeException,
      hasSetPoint,
      setLow,
      setHigh,
      hasQualifier,
      notification,
      hasInfinity
    } = attributes;

    let precisionNumber = precision;
    if (exists(precisionNumber) && typeof precisionNumber === "string")
      precisionNumber = parseInt(precisionNumber, 10);

    return (
      <>
        <InputNumber
          name={name}
          label={label}
          prompt={prompt}
          degree={degree}
          precision={precisionNumber}
          disabled={readOnly || !!restriction}
          min={min}
          max={max}
          globalUnacceptable={globalUnacceptable}
          globalUnacceptableTitles={globalUnacceptableTitles}
          aMin={aMin}
          aMax={aMax}
          strictMin={strictMin}
          strictMax={strictMax}
          units={units}
          hasARange={hasARange}
          rangeException={rangeException}
          restrictedRangeException={restrictedRangeException}
          hasSetPoint={hasSetPoint}
          setLow={setLow}
          setHigh={setHigh}
          previousRead={previousRead}
          qualifier={hasQualifier}
          notification={notification}
          allowInfinite={hasInfinity}
          condition={formattedCondition}
          tag={tag}
          required={required}
        />
        {children}
      </>
    );
  }

  if (attributes.type === "generated") {
    const {
      units,
      depends,
      trueDepends,
      formula,
      trueFormula,
      disabled,
      precision,
      aMin,
      aMax,
      min,
      max,
      strictMin,
      strictMax,
      hasARange,
      hasSetPoint,
      setLow,
      setHigh,
      rangeException,
      restrictedRangeException,
      formulaString,
      hasQualifier,
      hasDivideByZeroDefault,
      divideByZeroDefault,
      absoluteValue,
      hidden
    } = attributes;
    // handle both string and array depends field
    const temp = trueDepends ?? depends;
    const trueDependsList = Array.isArray(temp) ? temp : [temp];
    const dependsList = Array.isArray(depends) ? depends : [depends];

    let precisionNumber = precision;
    if (exists(precisionNumber) && typeof precisionNumber === "string")
      precisionNumber = parseInt(precisionNumber, 10);

    return (
      <>
        <InputGenerated
          room={room}
          name={name}
          task={task}
          taskRecord={taskRecord}
          taskType={taskType}
          byId={byId}
          stage={stage}
          label={label}
          units={units}
          precision={precisionNumber}
          trueDepends={trueDependsList}
          trueFormula={trueFormula ?? formula}
          formula={formula}
          condition={formattedCondition}
          depends={dependsList}
          readOnly={readOnly || !!restriction}
          disabled={disabled}
          globalUnacceptable={globalUnacceptable}
          globalUnacceptableTitles={globalUnacceptableTitles}
          aMin={aMin}
          aMax={aMax}
          min={min}
          max={max}
          strictMin={strictMin}
          strictMax={strictMax}
          hasARange={hasARange}
          hasSetPoint={hasSetPoint}
          setLow={setLow}
          setHigh={setHigh}
          rangeException={rangeException}
          restrictedRangeException={restrictedRangeException}
          formulaString={formulaString}
          previousRead={previousRead}
          qualifier={hasQualifier}
          absoluteValue={absoluteValue}
          errorDefault={
            hasDivideByZeroDefault && exists(divideByZeroDefault) ? `${divideByZeroDefault}` : null
          }
          weather={weather}
          setFormulasEvaluating={setFormulasEvaluating}
          hidden={hidden}
          tag={tag}
        />
        {children}
      </>
    );
  }

  if (attributes.type === "upload") {
    return (
      <>
        <InputFile
          name={name}
          label={label}
          prompt={prompt}
          condition={formattedCondition}
          tag={tag}
          required={required}
        />
        {children}
      </>
    );
  }

  if (attributes.type === "date") {
    return (
      <>
        <InputDate
          name={name}
          label={label}
          prompt={prompt}
          condition={formattedCondition}
          tag={tag}
          required={required}
        />
        {children}
      </>
    );
  }

  return (
    <>
      <InputText
        name={name}
        label={label}
        prompt={prompt}
        disabled={readOnly || !!restriction}
        condition={formattedCondition}
        tag={tag}
        required={required}
      />
      {children}
    </>
  );
};

const PROP_TYPES = {
  room: PropTypes.string,
  attributes: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node,
  task: PropTypes.objectOf(PropTypes.any),
  taskRecord: PropTypes.objectOf(PropTypes.any),
  taskType: PropTypes.string,
  byId: PropTypes.objectOf(PropTypes.any),
  stage: PropTypes.string,
  readOnly: PropTypes.bool,
  restriction: PropTypes.string,
  previousRead: PropTypes.objectOf(PropTypes.any),
  weather: PropTypes.objectOf(PropTypes.any),
  setFormulasEvaluating: PropTypes.func,
  globalUnacceptable: PropTypes.node,
  globalUnacceptableTitles: PropTypes.arrayOf(PropTypes.string)
};

const withWrapper = CurrentElement => {
  const WrappedElement = props => {
    const {
      byId = {},
      attributes,
      togglePreview,
      setShowModalHelp,
      setTarget,
      task,
      taskRecord,
      taskType = "checksheet",
      isOpen = false,
      setIsOpen,
      room,
      showPreview = false,
      allowNotes = true,
      restriction
    } = props;
    const isMounted = useMountedState();

    const {setValue} = useFormContext();

    const {api: apiNotes} = useApi(
      taskType === "checksheet" ? "record-notes" : "event-record-notes"
    );

    const scrolling = useScrollListener();

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    // Note Management
    const [currentNote, setCurrentNote] = useState(null);
    const [notes, setNotes] = useState(null);
    const [calloutInWindow, setCalloutInWindow] = useState(false);
    const [addNoteCalloutVisible, setAddNoteCalloutVisible] = useState(false);
    const [viewNotesCalloutVisible, setViewNotesCalloutVisible] = useState(false);

    const callout = useRef();

    const getNotes = useCallback(() => {
      if (attributes.element === GROUP) {
        const params = {
          taskId: task.id,
          status: "pending",
          [!task?.stages ? "group" : "stage"]: attributes.name
        };

        apiNotes.callGet(null, params).then(({status, data}) => {
          if (status === 200) {
            const draftState = !taskRecord || taskRecord.draft;
            const filtered = data.filter(({taskRecordId, draft}) => {
              if (taskRecord) return taskRecordId === taskRecord.id && draft === draftState;
              return draft === draftState;
            });
            setNotes(filtered);
          }
        });
      }
    }, [attributes, task, taskRecord, apiNotes]);

    // Initial Load if callout is in viewport
    useEffect(() => {
      if (isMounted() && callout?.current && inViewport(callout.current) && notes === null)
        getNotes();
    }, [isMounted, notes, getNotes]);

    // Reload if callout comes into viewport on scroll
    useEffect(() => {
      if (isMounted() && calloutInWindow && notes === null) getNotes();
    }, [isMounted, calloutInWindow, notes, getNotes]);

    // Check if callout is in viewport
    useEffect(() => {
      if (isMounted() && scrolling && callout?.current && !calloutInWindow)
        setCalloutInWindow(inViewport(callout.current));
    }, [isMounted, scrolling, calloutInWindow]);

    const openEditWindow = note => {
      setCurrentNote(note);
      setViewNotesCalloutVisible(false);
      setAddNoteCalloutVisible(true);
    };

    const openDeleteModal = note => {
      setCurrentNote(note);
      setDeleteModalVisible(true);
    };

    const closeDeleteModal = () => {
      setCurrentNote(null);
      setDeleteModalVisible(false);
      getNotes();
    };

    const deleteNote = () => apiNotes.callDelete(currentNote.id).then(() => closeDeleteModal());

    const element = (
      <OuterWrapper>
        <ElementWrapper
          type={attributes.element}
          restriction={restriction ? 1 : 0}
          parent={attributes.parentName}
          fieldPadding={
            attributes.element === FIELD &&
            attributes.parentName &&
            byId[attributes.parentName]?.element === GROUP
          }
          data-testid={`${attributes.element}-${attributes.name}`}
          hide={attributes.hidden ? 1 : 0}>
          {togglePreview &&
            (!attributes.parentName || attributes.parentName === "") &&
            attributes.toggle && (
              <StickyButton>
                <Button type="button" onClick={() => togglePreview(attributes.name)}>
                  Overview
                </Button>
              </StickyButton>
            )}

          <Inline>
            {attributes.element === COMPONENT && (
              <Help
                type="button"
                element={attributes.element}
                onClick={() => {
                  setShowModalHelp(true);
                  setTarget(attributes);
                }}
                data-testid={`${attributes.name}-help`}>
                <Inline>
                  <ElementLabel type={attributes.element} restriction={restriction ? 1 : 0}>
                    {attributes.label}
                  </ElementLabel>
                  {attributes?.help?.length > 0 && (
                    <HelpIcon type={attributes.element} icon={faQuestionCircle} />
                  )}
                </Inline>
              </Help>
            )}
            {attributes.element === GROUP && attributes.toggle && (
              <ElementLabel type={attributes.element}>{attributes.label}</ElementLabel>
            )}
          </Inline>

          {attributes.globalPrompt && <Label bold>{attributes.globalPrompt}</Label>}
          <Content
            type={attributes.element}
            scaleDown={room === "tasks"}
            grid={attributes.grid}
            showPreview={showPreview}>
            <CurrentElement {...props} />

            {attributes.element === "group" && !attributes.toggle && (
              <>
                {deleteModalVisible && (
                  <Modal visible={deleteModalVisible} setVisible={setDeleteModalVisible}>
                    <HeadingCenter>Confirm Delete</HeadingCenter>
                    <FormGroup>
                      <TextCenter>
                        Are you sure you want to delete? This action cannot be undone.
                      </TextCenter>
                    </FormGroup>
                    <Delete type="button" onClick={() => deleteNote()}>
                      Delete
                    </Delete>
                  </Modal>
                )}

                {allowNotes && (
                  <CalloutMenu
                    calloutList={[
                      {
                        visible: addNoteCalloutVisible,
                        setVisible: state => {
                          if (!state) setCurrentNote(null);
                          setAddNoteCalloutVisible(state);
                          setValue("note", {});
                        },
                        content: (
                          <AddNoteCalloutContent
                            visible={addNoteCalloutVisible}
                            setVisible={state => {
                              if (!state) setCurrentNote(null);
                              setAddNoteCalloutVisible(state);
                              setValue("note", {});
                            }}
                            setRefreshNotes={getNotes}
                            current={currentNote}
                            setCurrent={setCurrentNote}
                            group={attributes.name}
                            label={attributes.label}
                            task={task}
                            taskRecord={taskRecord}
                            taskType={taskType}
                          />
                        ),
                        button: (
                          <AddNoteButton ref={callout} type="button" data-testid="addNote">
                            <IconWrapper rotated={!addNoteCalloutVisible}>
                              <NoteHookIcon />
                            </IconWrapper>
                          </AddNoteButton>
                        ),
                        buttonType: "circle"
                      },
                      {
                        visible: viewNotesCalloutVisible,
                        setVisible: setViewNotesCalloutVisible,
                        content: (
                          <ViewNotesCalloutContent
                            setVisible={setViewNotesCalloutVisible}
                            visible={viewNotesCalloutVisible}
                            task={task}
                            taskType={taskType}
                            groupLabel={attributes.label}
                            groupName={attributes.name}
                            notes={notes}
                            handleEdit={openEditWindow}
                            handleDelete={openDeleteModal}
                          />
                        ),
                        button: (notes?.length > 0 || task?.hasNoteHistory) && (
                          <ViewNotesButton type="button">
                            <Abbr title={notes?.length > 0 ? "Pending" : "History"}>
                              <FontAwesomeIcon icon={notes?.length > 0 ? faStickyNote : faBook} />
                            </Abbr>
                          </ViewNotesButton>
                        )
                      }
                    ]}
                  />
                )}
              </>
            )}
          </Content>
        </ElementWrapper>
      </OuterWrapper>
    );

    if (attributes.element === GROUP && !attributes.toggle)
      return isOpen !== undefined && setIsOpen ? (
        <OuterWrapper>
          <AccordionWrapped
            label={attributes.label}
            isGroup
            isEvent={taskType === "event"}
            handleOpen={() => setIsOpen(attributes.name)}
            toggleOpen={isOpen}
            disallowOpen={taskType === "event"}
            restricted={!!restriction}
            name={attributes.name}
            showHelp={
              attributes?.help?.length > 0
                ? () => {
                    setShowModalHelp(true);
                    setTarget(attributes);
                  }
                : null
            }>
            {element}
          </AccordionWrapped>
          {restriction && (
            <Absolute>
              <Text>{restriction.toUpperCase()} RESTRICTION APPLIED</Text>
            </Absolute>
          )}
        </OuterWrapper>
      ) : null;

    return element;
  };

  WrappedElement.propTypes = {
    ...PROP_TYPES,
    setTarget: PropTypes.func,
    togglePreview: PropTypes.func,
    setShowModalHelp: PropTypes.func,
    conditionsSatisfied: PropTypes.objectOf(PropTypes.any),
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    preview: PropTypes.bool,
    showPreview: PropTypes.bool,
    allowNotes: PropTypes.bool
  };

  return WrappedElement;
};

Element.propTypes = PROP_TYPES;

// Style Overrides
const ElementWrapper = styled(FormGroup)`
  width: 100%;
  border-radius: ${radius};
  padding: ${pad}px ${pad / 2}px;
  padding-top: 0;
  height: min-content;

  ${({restriction, parent, type}) =>
    restriction && !parent && type !== GROUP
      ? css`
          opacity: 0.4;
        `
      : ""}

  ${({type, theme}) =>
    type === GROUP &&
    css`
      border: 0;
      color: ${theme.group};
    `}

  ${({type, theme}) =>
    type === COMPONENT &&
    css`
      border: 0;
      color: ${theme.component};
    `}

  ${({type}) =>
    type === FIELD &&
    css`
      ${flex("column", "nowrap", "start", "start")};
      position: relative;
      background: transparent;
      min-width: 200px;
      padding: 0;
    `}

  ${({fieldPadding}) =>
    fieldPadding &&
    css`
      padding: 0 ${pad / 2}px;
    `}

  ${({hide}) =>
    hide &&
    css`
      display: none;
    `}
`;

const ElementLabel = styled(FormGroupHeading)`
  color: ${({theme}) => theme.group};
  text-transform: uppercase;
  margin-bottom: ${pad / 2}px;

  ${({type, theme}) =>
    type === GROUP &&
    css`
      ${voice.medium};
      font-weight: bold;
      color: ${theme.group};
    `}

  ${({type, theme}) =>
    type === COMPONENT &&
    css`
      ${voice.medium};
      font-weight: bold;
      color: ${theme.component};
    `}
`;

const Content = styled.div`
  width: 100%;

  ${({type}) =>
    type === "field" &&
    css`
      max-width: calc(100% - 50px);
    `}

  ${({showPreview}) =>
    showPreview &&
    css`
      opacity: 0.35;
    `}

  ${({grid, scaleDown}) =>
    grid &&
    css`
      ${flex("row", "wrap", "flex-start", "flex-start")};

      h2 {
        width: 100%;
      }

      > div {
        padding-right: ${pad}px;
        max-width: 100%;

        ${bp(scaleDown ? 3 : 4)} {
          max-width: 50%;
        }

        ${bp(scaleDown ? 4 : 5)} {
          max-width: 33.33%;
        }
      }
    `}
`;

const StickyButton = styled(StickyWrapper)`
  top: ${navHeight + pad * 2}px;
  margin-top: ${pad}px;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  z-index: ${z("above")};
  width: 100%;

  button {
    box-shadow: ${shadow};
  }
`;

const Help = styled.button`
  width: auto;
  padding: ${pad / 2}px ${pad / 2}px ${pad / 2}px 0;

  ${({element, theme}) =>
    element === GROUP &&
    css`
      fill: ${theme.group};
    `}

  ${({element, theme}) =>
    element === COMPONENT &&
    css`
      fill: ${theme.component};
    `}
`;

const HelpIcon = styled(FontAwesomeIcon)`
  margin: -${pad / 2}px ${pad}px 0;
`;

const AddNoteButton = styled(Button)`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  max-width: none;
`;

const ViewNotesButton = styled(Button)`
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  max-width: none;
  background-color: ${({theme}) => theme.primary};
`;

const NoteHookIcon = styled.div`
  ${({theme}) => cross("100%", theme.tertiary, "2.5px")}
`;

const IconWrapper = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 10px;
  transform: ${({rotated}) => (rotated ? css`rotate(45deg)` : css`rotate(0deg)`)};
  transform-origin: center center;
  transition: transform 0.2s;
`;

const Delete = styled(Button)`
  background: ${({theme}) => theme.error};
  color: ${({theme}) => theme.tertiary};
`;

const OuterWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  margin-bottom: ${pad}px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Absolute = styled.div`
  position: absolute;
  margin: auto;
  align-self: center;
  padding: ${pad}px;
  border-radius: ${radius};
  text-shadow: ${shadow};

  p {
    ${voice.medium};
    font-weight: bold;
  }
`;

const WrappedElement = withWrapper(Element);

export default WrappedElement;
