import React, {Fragment, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Modal from "../../components/Modal.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {pad} from "../../style/components/variables.js";
import {
  HeadingCenter,
  Form,
  Text,
  Button,
  FormGroup,
  Span
} from "../../style/components/general.js";

const ModalDeleteElement = ({
  visible,
  setVisible,
  targetElement,
  builder,
  notifications,
  persistDelete,
  hasBackButton = false,
  goBack = () => {}
}) => {
  const {element, name, label, parentName} = targetElement;

  const isMounted = useMountedState();

  const [deleteNotifications, setDeleteNotifications] = useState([]);

  const hasLinkedParent = useMemo(
    () => !!builder.byId[parentName]?.templateId,
    [builder, parentName]
  );

  const dependants = useMemo(() => {
    const matches = Object.values(builder.byId).filter(({formula, condition}) => {
      let itemMatches = [];
      if (formula)
        itemMatches = [
          ...itemMatches,
          ...formula.filter(item => item.type === "variable" && item.value === targetElement.name)
        ];

      if (condition?.list)
        itemMatches = [
          ...itemMatches,
          ...condition.list.filter(item => item.depends === targetElement.name)
        ];

      return itemMatches.length > 0;
    });

    return matches;
  }, [builder, targetElement]);

  useEffect(() => {
    if (isMounted() && notifications?.allIds?.length > 0) {
      const {allIds, byId} = notifications;
      const matches = allIds.filter(id => byId[id].depends === name);
      setDeleteNotifications(matches);
    }
  }, [isMounted, name, notifications]);

  const handleSubmit = e => {
    e.preventDefault();
    persistDelete(targetElement, deleteNotifications);
  };

  let canDelete = true;

  const renderMessage = () => {
    if (hasLinkedParent) {
      canDelete = false;
      return (
        <Text>
          Unable to delete. This field&apos;s component is linked to a template. Please unlink
          before deleting.
        </Text>
      );
    }

    if (dependants?.length > 0) {
      canDelete = false;
      return (
        <Text>
          Unable to delete. This field is being used to generate the following fields:&nbsp;
          <Span>
            {dependants.map(({name: depName, label: depLabel, parentName: depParent}) => (
              <Fragment key={depName}>
                {builder.byId[depParent]
                  ? `${builder.byId[depParent].label} ${depLabel}`
                  : depLabel}
                <br />
              </Fragment>
            ))}
          </Span>
        </Text>
      );
    }

    if (deleteNotifications?.length > 0)
      return (
        <Text>
          {label} is used in one or more field notifications. Are you sure you want to delete this
          {element}, its notifications <Span error>WILL BE DELETED</Span>? This action cannot be
          undone.
        </Text>
      );

    return (
      <Text>Are you sure you want to delete this {element}? This action cannot be undone.</Text>
    );
  };

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <ModalTitle>Delete Element</ModalTitle>

      <Form onSubmit={handleSubmit} noValidate>
        <FormGroup>{renderMessage()}</FormGroup>
        <Options>
          <Option
            type="button"
            onClick={() => (hasBackButton && goBack ? goBack() : setVisible(false))}>
            Cancel
          </Option>
          {canDelete && <Delete type="submit">Delete</Delete>}
        </Options>
      </Form>
    </Modal>
  );
};

ModalDeleteElement.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  persistDelete: PropTypes.func.isRequired,
  targetElement: PropTypes.objectOf(PropTypes.any).isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  notifications: PropTypes.objectOf(PropTypes.any),
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin-bottom: ${pad * 2}px;
`;

const Options = styled.div`
  ${flex("row", "nowrap", "start", "center")};
  width: 100%;
`;

const Option = styled(Button)`
  margin: 0 ${pad / 2}px;
`;

const Delete = styled(Option)`
  background: ${({theme}) => theme.error};
  color: ${({theme}) => theme.tertiary};

  &:disabled {
    opacity: 0.6;
  }
`;

export default ModalDeleteElement;
