import React, {useCallback, useMemo, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import {exists} from "../../utils/helpers.js";
import {STATIC, EVENT, CHART, STAT, HEADER, TEXT, INPUT, CHECKSHEET} from "../../utils/report.js";

// Components
import Region from "./Region.js";

// Style
import {pad} from "../../style/components/variables.js";
import {flex} from "../../style/components/mixins.js";
import {Section} from "../../style/components/general.js";

const LEFT_COLUMN = 0;
const RIGHT_COLUMN = 1;

const MIN_FITTABLE_ROWS = 1;

// in mm for printing
const PAGE_HEIGHT = 1250;
const TABLE_ROW_HEIGHT = 31;
const CHART_HEIGHT = 300;
const STAT_HEIGHT = 100;
const HEADER_HEIGHT = TABLE_ROW_HEIGHT;
const INPUT_HEIGHT = 30;
const CHART_WIDTH_FULL = 850;
const CHART_WIDTH_HALF = 320;
const FLEX_GAP = 10;
const FLEX_GAP_MM = 2;
const MM_PER_PX = 1;

const INSERT_TO_PAGE_FAILURE = 0;
const INSERT_TO_PAGE_SUCCESS = 1;

const ITER_LIMIT = 1000;

const calculateTableHeight = (length, id, idx) => {
  const labelElement = document.getElementById(`${id}-label`);
  const headerElement = document.getElementById(`${id}-headers`);
  let headerHeight = headerElement?.clientHeight;
  let labelHeight = labelElement?.clientHeight;

  if (headerHeight) {
    headerHeight *= MM_PER_PX;
  } else headerHeight = TABLE_ROW_HEIGHT;

  if (labelHeight) {
    labelHeight *= MM_PER_PX;
  } else labelHeight = TABLE_ROW_HEIGHT;

  let tableHeight = headerHeight + labelHeight;

  for (let i = idx; i < idx + length; i++) {
    const dataElement = document.getElementById(`${id}-data-${i}`);
    let dataHeight = dataElement?.clientHeight;

    if (dataHeight) dataHeight *= MM_PER_PX;
    else dataHeight = TABLE_ROW_HEIGHT;

    tableHeight += dataHeight;
  }

  return tableHeight;
};

const calculateTextHeight = id => {
  const el = document.getElementById(`${id}-region`);
  let height = el?.clientHeight;

  if (height) height *= MM_PER_PX;
  else height = TABLE_ROW_HEIGHT;

  return height;
};

const calculateFittableRows = (height, id, idx, length) => {
  const headerElement = document.getElementById(`${id}-headers`);
  let headerHeight = headerElement?.clientHeight;

  if (headerHeight) headerHeight *= MM_PER_PX;
  else headerHeight = TABLE_ROW_HEIGHT;

  // subtract calculated header height and constant table title row height
  let available = height - headerHeight - TABLE_ROW_HEIGHT;
  let numRows = 0;

  while (available > 0 && idx < length) {
    const dataElement = document.getElementById(`${id}-data-${idx}`);
    let dataHeight = dataElement?.clientHeight;

    if (dataHeight) dataHeight *= MM_PER_PX;
    else dataHeight = TABLE_ROW_HEIGHT;

    // break before incrementing row count, because breaking after would include an extra row
    if (dataHeight <= available) available -= dataHeight;
    else break;

    idx++;
    numRows++;
  }

  return numRows;
};

const calculateFittableText = (id, availableHeight, textLength) => {
  const el = document.getElementById(`${id}-region`);
  let height = el?.clientHeight;

  if (height) height *= MM_PER_PX;
  else height = TABLE_ROW_HEIGHT;

  const ratio = availableHeight / height;

  return Math.floor(ratio * textLength);
};

const RenderReportBuilderPrintable = ({
  report,
  update,
  hasHeader = false,
  fontSize = 14,
  alignment = "left",
  setCanPrint
}) => {
  const abort = useRef(false);
  const createPage = useCallback(
    (groupIdx, overflowLeft, overflowRight, pageIdx) => {
      let availableHeight = PAGE_HEIGHT;

      const page = [];

      if (pageIdx === 0) {
        let headerHeight = 0;
        if (hasHeader) {
          const headerElement = document.getElementById("reportHeader");
          if (headerElement?.clientHeight) {
            headerHeight = headerElement.clientHeight * MM_PER_PX;
          }
        }
        availableHeight -= headerHeight + FLEX_GAP * 2;
      }

      const nextGroup = () => {
        groupIdx++;
        overflowLeft.rowIdx = 0;
        overflowLeft.dataIdx = 0;
        overflowRight.rowIdx = 0;
        overflowRight.dataIdx = 0;
      };

      const isLeftColumnComplete = () => {
        if (overflowLeft.rowIdx === null) return true;
        const group = report.groups[groupIdx];
        if (!group) return true;
        const column = group.columns[LEFT_COLUMN];
        if (!column) return true;
        // if past last row of column, column is complete
        if (overflowLeft.rowIdx >= column.rows.length) return true;
        // if on last row of column, column is complete if
        // dataIdx is beyond end of data list for that row
        if (overflowLeft.rowIdx === column.rows.length - 1) {
          const row = column.rows[overflowLeft.rowIdx];
          if (!row) return true;
          const {region} = row;
          if (!region || !region.data) return true;
          if (
            [CHECKSHEET, EVENT, STATIC, TEXT].includes(region?.type) &&
            overflowLeft.dataIdx >= region.data.length
          )
            return true;
        }
        return false;
      };

      const isRightColumnComplete = () => {
        if (overflowRight.rowIdx === null) return true;
        const group = report.groups[groupIdx];
        if (!group) return true;
        const column = group.columns[RIGHT_COLUMN];
        if (!column) return true;
        if (overflowRight.rowIdx >= column.rows.length) return true;
        if (overflowRight.rowIdx === column.rows.length - 1) {
          const row = column.rows[overflowRight.rowIdx];
          if (!row) return true;
          const {region} = row;
          if (!region || !region.data) return true;
          if (
            [CHECKSHEET, EVENT, STATIC, TEXT].includes(region?.type) &&
            overflowRight.dataIdx >= region.data.length
          )
            return true;
        }
        return false;
      };

      const isGroupComplete = () => {
        const group = report.groups[groupIdx];
        // if two columns, check both right and left, otherwise just left
        return isLeftColumnComplete() && (group.columns.length === 1 || isRightColumnComplete());
      };

      let availableHeightLeft = availableHeight;
      let availableHeightRight = availableHeight;

      const addToListOverflow = (region, list, side) => {
        // if side is null, full width table
        if (!region || !region.data) return INSERT_TO_PAGE_SUCCESS;

        const {
          type,
          headers,
          data,
          title,
          id,
          stats,
          highlight,
          hasBandedRows,
          hasHiddenGridlines
        } = region;

        let numStats = 0;
        if (stats) numStats = stats.filter(({enabled}) => enabled).length;

        let overflow = overflowLeft;
        let localAvailableHeight = availableHeightLeft;
        let itemsLeft = data.length - overflowLeft.dataIdx;
        // full width table must fit based on minimum of two column's remaining heights
        if (!exists(side))
          localAvailableHeight = Math.min(availableHeightLeft, availableHeightRight);
        else if (side === RIGHT_COLUMN) {
          overflow = overflowRight;
          localAvailableHeight = availableHeightRight;
          itemsLeft = data.length - overflowRight.dataIdx;
        }

        const fittableRows =
          type === TEXT || type === HEADER || type === INPUT
            ? localAvailableHeight / TABLE_ROW_HEIGHT
            : calculateFittableRows(localAvailableHeight, id, overflow.dataIdx, data.length);

        const fittable =
          type === TEXT || type === HEADER || type === INPUT
            ? calculateFittableText(id, localAvailableHeight, data.length)
            : fittableRows;

        // should fit at least 3 rows
        if (fittableRows < MIN_FITTABLE_ROWS && itemsLeft >= MIN_FITTABLE_ROWS) {
          if (!exists(side) || side === LEFT_COLUMN) availableHeightLeft = 0;
          if (!exists(side) || side === RIGHT_COLUMN) availableHeightRight = 0;
          return INSERT_TO_PAGE_FAILURE;
        }

        if (fittable >= itemsLeft) {
          // Entire region fits
          const totalHeight =
            type === TEXT || type === HEADER || type === INPUT
              ? calculateTextHeight(id)
              : calculateTableHeight(itemsLeft, id, overflow.dataIdx);

          list.push(
            <Row key={`${id}-printable`}>
              <Region
                type={type}
                title={title}
                headers={headers}
                data={
                  [CHECKSHEET, EVENT, STATIC, TEXT].includes(type) && data
                    ? data.slice(overflow.dataIdx, data.length)
                    : data
                }
                requiredBreak={[CHECKSHEET, EVENT, STATIC].includes(type) && overflow.dataIdx !== 0}
                // we need to account for the fact that some stat rows may be on the previous page
                footerRowCount={Math.min(itemsLeft, numStats)}
                printable
                id={id}
                fontSize={fontSize}
                alignment={alignment}
                highlight={highlight}
                hasBandedRows={hasBandedRows}
                hasHiddenGridlines={hasHiddenGridlines}
              />
            </Row>
          );

          if (!exists(side) || side === LEFT_COLUMN) availableHeightLeft -= totalHeight + FLEX_GAP;
          if (!exists(side) || side === RIGHT_COLUMN)
            availableHeightRight -= totalHeight + FLEX_GAP;
          overflow.dataIdx += itemsLeft;
        } else {
          // Continues on next page
          const rowsLeftAfterInsert = data.length - overflow.dataIdx - fittable;
          const footerRowCount = numStats - rowsLeftAfterInsert;
          list.push(
            <Row key={`${id}-s${pageIdx}-printable`}>
              <Region
                type={type}
                title={title}
                headers={headers}
                data={
                  [CHECKSHEET, EVENT, STATIC, TEXT].includes(type) && data
                    ? data.slice(overflow.dataIdx, overflow.dataIdx + fittable)
                    : data
                }
                requiredBreak={[CHECKSHEET, EVENT, STATIC].includes(type) && overflow.dataIdx !== 0}
                footerRowCount={footerRowCount > 0 ? footerRowCount : 0}
                id={id}
                printable
                fontSize={fontSize}
                alignment={alignment}
                highlight={highlight}
                hasBandedRows={hasBandedRows}
                hasHiddenGridlines={hasHiddenGridlines}
              />
            </Row>
          );
          // page is now full
          if (!exists(side) || side === LEFT_COLUMN) availableHeightLeft = 0;
          if (!exists(side) || side === RIGHT_COLUMN) availableHeightRight = 0;
          overflow.dataIdx += fittable;
        }
        return INSERT_TO_PAGE_SUCCESS;
      };

      const addToListNoOverflow = (region, list, side) => {
        if (!region) return INSERT_TO_PAGE_SUCCESS;

        const {
          type,
          headers,
          data,
          title,
          id,
          height,
          hasSecondInput,
          secondInput,
          chartType,
          analyticType
        } = region;

        let localAvailableHeight = availableHeightLeft;
        // full width table must fit based on minimum of two column's remaining heights
        if (!exists(side))
          localAvailableHeight = Math.min(availableHeightLeft, availableHeightRight);
        else if (side === RIGHT_COLUMN) localAvailableHeight = availableHeightRight;

        let elementHeight = 0;
        let printScale = null;

        if (type === CHART) elementHeight = CHART_HEIGHT;
        if (type === STAT) elementHeight = STAT_HEIGHT;
        if (type === HEADER) elementHeight = HEADER_HEIGHT;
        if (type === INPUT) elementHeight = INPUT_HEIGHT;

        if (type === CHART || type === STAT)
          printScale = {height, width: !exists(side) ? CHART_WIDTH_FULL : CHART_WIDTH_HALF};

        if (elementHeight < localAvailableHeight) {
          list.push(
            <Row key={`${id}-printable`}>
              <Region
                id={id}
                type={type}
                title={title}
                headers={headers}
                data={data}
                printScale={printScale}
                printable
                fontSize={fontSize}
                alignment={alignment}
                secondInput={hasSecondInput ? secondInput : null}
                chartType={chartType}
                analyticType={analyticType}
              />
            </Row>
          );

          if (!exists(side) || side === LEFT_COLUMN)
            availableHeightLeft -= elementHeight + FLEX_GAP;
          if (!exists(side) || side === RIGHT_COLUMN)
            availableHeightRight -= elementHeight + FLEX_GAP;
        } else {
          if (!exists(side) || side === LEFT_COLUMN) availableHeightLeft = 0;
          if (!exists(side) || side === RIGHT_COLUMN) availableHeightRight = 0;
          return INSERT_TO_PAGE_FAILURE;
        }
        return INSERT_TO_PAGE_SUCCESS;
      };
      let groupIter = 0;
      while (groupIdx < report.groups.length) {
        if (groupIter > ITER_LIMIT) {
          setCanPrint(false);
          abort.current = true;
          break;
        }
        groupIter++;
        const group = report.groups[groupIdx];
        if (group?.columns?.length === 1 && !abort.current) {
          // full width region
          const fullColumn = [];
          const rows = report.groups[groupIdx].columns[0]?.rows;

          const minAvailableHeight = Math.min(availableHeightLeft, availableHeightRight);
          if (minAvailableHeight / TABLE_ROW_HEIGHT < MIN_FITTABLE_ROWS) break;

          if (rows && rows.length === 1) {
            const {region, id} = rows[0];
            let success;
            if ([CHECKSHEET, EVENT, STATIC, TEXT].includes(region?.type))
              success = addToListOverflow({...region, id}, fullColumn);
            else success = addToListNoOverflow({...region, id}, fullColumn);

            if (!success) break;

            page.push(
              <GroupWrapper key={`${group.id}-s${pageIdx}-printable`}>{fullColumn}</GroupWrapper>
            );

            if (isLeftColumnComplete() && region.data) overflowLeft.rowIdx = null;
            else if (
              ![CHECKSHEET, EVENT, STATIC, TEXT].includes(region?.type) ||
              !region.data ||
              overflowLeft.dataIdx >= region.data.length
            ) {
              overflowLeft.rowIdx += 1;
              overflowLeft.dataIdx = 0;
            }
          }
        } else if (!abort.current) {
          // columns region
          const leftColumn = [];
          const rightColumn = [];

          const leftRows = report.groups[groupIdx].columns[LEFT_COLUMN]?.rows;
          const rightRows = report.groups[groupIdx].columns[RIGHT_COLUMN]?.rows;

          if (leftRows && !abort.current) {
            let iter = 0;
            while (exists(overflowLeft.rowIdx) && overflowLeft.rowIdx < leftRows.length) {
              if (iter > ITER_LIMIT) {
                setCanPrint(false);
                abort.current = true;
                break;
              }
              iter++;
              if (availableHeightLeft / TABLE_ROW_HEIGHT < MIN_FITTABLE_ROWS) break;

              let success;
              const {region, id} = leftRows[overflowLeft.rowIdx];
              if ([CHECKSHEET, EVENT, STATIC, TEXT].includes(region?.type))
                success = addToListOverflow({...region, id}, leftColumn, LEFT_COLUMN);
              else success = addToListNoOverflow({...region, id}, leftColumn, LEFT_COLUMN);

              if (!success) break;
              if (isLeftColumnComplete() && region.data) overflowLeft.rowIdx = null;
              else if (
                ![CHECKSHEET, EVENT, STATIC, TEXT].includes(region?.type) ||
                !region.data ||
                overflowLeft.dataIdx >= region.data.length
              ) {
                overflowLeft.rowIdx += 1;
                overflowLeft.dataIdx = 0;
              }
            }
          }

          if (rightRows && !abort.current) {
            let iter = 0;
            while (exists(overflowRight.rowIdx) && overflowRight.rowIdx < rightRows.length) {
              if (iter > ITER_LIMIT) {
                setCanPrint(false);
                abort.current = true;
                break;
              }
              iter++;
              if (availableHeightRight / TABLE_ROW_HEIGHT < MIN_FITTABLE_ROWS) break;

              let success;
              const {region, id} = rightRows[overflowRight.rowIdx];
              if ([CHECKSHEET, EVENT, STATIC, TEXT].includes(region?.type))
                success = addToListOverflow({...region, id}, rightColumn, RIGHT_COLUMN);
              else success = addToListNoOverflow({...region, id}, rightColumn, RIGHT_COLUMN);

              if (!success) break;
              if (isRightColumnComplete() && region.data) overflowRight.rowIdx = null;
              else if (
                ![CHECKSHEET, EVENT, STATIC, TEXT].includes(region?.type) ||
                !region.data ||
                overflowRight.dataIdx >= region.data.length
              ) {
                overflowRight.rowIdx += 1;
                overflowRight.dataIdx = 0;
              }
            }
          }

          page.push(
            <GroupWrapper key={`${group.id}-s${pageIdx}-printable`}>
              <Column>{leftColumn}</Column>
              <Column>{rightColumn}</Column>
            </GroupWrapper>
          );
        }

        if (isGroupComplete()) nextGroup();

        if (
          availableHeightLeft / TABLE_ROW_HEIGHT < MIN_FITTABLE_ROWS ||
          availableHeightRight / TABLE_ROW_HEIGHT < MIN_FITTABLE_ROWS ||
          abort.current
        )
          break;
      }

      return {
        page: <Page key={`p${pageIdx}`}>{page}</Page>,
        groupIdx
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report, update]
  );

  const arrangeTables = useMemo(() => {
    if (report?.groups?.length && !abort.current) {
      const allPages = [];
      const overflowLeft = {rowIdx: 0, dataIdx: 0};
      const overflowRight = {rowIdx: 0, dataIdx: 0};

      let pageIdx = 0;
      let groupIdx = 0;

      // Groups
      while (groupIdx < report.groups.length) {
        const {page, groupIdx: newGroupIdx} = createPage(
          groupIdx,
          overflowLeft,
          overflowRight,
          pageIdx
        );

        allPages.push(page);
        groupIdx = newGroupIdx;
        pageIdx++;
        if (pageIdx > ITER_LIMIT || abort.current) {
          setCanPrint(false);
          break;
        }
      }

      return <Document>{allPages}</Document>;
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPage, report, update]);

  return <ReportBuilderSection>{report && arrangeTables}</ReportBuilderSection>;
};

RenderReportBuilderPrintable.propTypes = {
  report: PropTypes.objectOf(PropTypes.any),
  update: PropTypes.number.isRequired,
  hasHeader: PropTypes.bool,
  fontSize: PropTypes.number,
  alignment: PropTypes.string,
  setCanPrint: PropTypes.func
};

// Style Overrides
const ReportBuilderSection = styled(Section)`
  ${flex("column", "nowrap", "flex-start", "center")};
  display: block;
  width: 100%;
  padding: 0;
  gap: ${FLEX_GAP_MM}mm;
  page-break-inside: auto;
`;

const GroupWrapper = styled.div`
  ${flex("row", "nowrap", "flex-start", "start")};
  position: relative;
  width: 100%;
  break-inside: auto;
  gap: ${FLEX_GAP_MM}mm;
  margin-bottom: ${pad / 2}px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

const Page = styled.div`
  width: 100%;
  page-break-after: always;
  page-break-inside: avoid;

  :last-of-type {
    page-break-after: avoid;
  }
`;

const Document = styled.div`
  page-break-inside: auto;
  width: 100%;
`;

const Row = styled.div`
  width: 100%;
  height: min-content;
`;

const Column = styled.div`
  width: 50%;
  align-self: stretch;
  ${flex("column", "nowrap", "flex-start", "start")}
  gap: ${FLEX_GAP_MM}mm;
`;

export default RenderReportBuilderPrintable;
