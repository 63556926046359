import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import * as d3 from "d3";

const ChartSlice = ({slice, active = false}) => {
  const {data, radius, innerRadius, startAngle, endAngle} = slice;

  const generator = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(radius)
    .startAngle(startAngle) // Starting angle (in radians)
    .endAngle(endAngle);

  const [x, y] = generator.centroid(data);
  const translate = `translate(${x}, ${y})`;

  return (
    <>
      <Path d={generator()} active={active} />
      <Text textAnchor="middle" alignmentBaseline="middle" transform={translate}>
        {data.label}
      </Text>
    </>
  );
};

ChartSlice.propTypes = {
  slice: PropTypes.objectOf(PropTypes.any).isRequired,
  active: PropTypes.bool
};

// Style Overrides
const Path = styled.path`
  fill: ${({active, theme}) => (active ? theme.primary : theme.secondary)};
  stroke: ${({theme}) => theme.tertiary};
  stroke-width: 2;
`;

const Text = styled.text`
  fill: ${({theme}) => theme.tertiary};
`;

export default ChartSlice;
