import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import styled, {css} from "styled-components";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignLeft,
  faEdit,
  faFont,
  faPlus,
  faPrint,
  faTable
} from "@fortawesome/free-solid-svg-icons";
import {useReactToPrint} from "react-to-print";

// Contexts
import {SettingsContext} from "../contexts/settings.js";
import {FacilityNavContext} from "../contexts/facilitynav.js";

// Utils
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";
import {useToast} from "../contexts/toast.js";
import {
  CHART,
  CHECKSHEET,
  DEFAULT_FONT_SIZE,
  EVENT,
  FONT_SIZE_NUM_OPTIONS,
  MAX_FONT_SIZE,
  MIN_FONT_SIZE,
  STAT
} from "../utils/report.js";

// Components
import Report from "./report-builder/Report.js";
import RenderReportBuilder from "./report-builder/RenderReportBuilder.js";
import RenderReportBuilderPrintable from "./report-builder/RenderReportBuilderPrintable.js";
import ModalSection from "./report-builder/ModalSection.js";
// import ModalEditSection from "./report-builder/ModalEditSection.js";
import ModalReorder from "./report-builder/ModalReorder.js";
import ModalDelete from "../components/ModalDelete.js";
import ModalEditMeta from "./report-builder/ModalEditMeta.js";
import ModalSectionType from "./report-builder/ModalSectionType.js";
import SectionOptions from "./report-builder/SectionOptions.js";
import ScaleBar from "../components/ScaleBar.js";

// Style
import {
  Abbr,
  AnchorInline,
  Button,
  Heading,
  HeadingMedium,
  Inline,
  Loader,
  NotLoaded,
  Page,
  Pill,
  Small,
  Text,
  Title
} from "../style/components/general.js";
import {flex} from "../style/components/mixins.js";
import {border, pad, radius} from "../style/components/variables.js";
import voice from "../style/components/typography.js";

const REPORT_WIDTH = 900;

const ReportEditor = () => {
  const isMounted = useMountedState();

  const {addToast} = useToast();
  const {slug, edit} = useParams();

  const {settings} = useContext(SettingsContext);
  const {facility, setFacility} = useContext(FacilityNavContext);

  const {api: apiFacility} = useApi(`facilities/${slug}`);

  const {api: apiReport} = useApi("reports", {suppress: {success: false, error: false}});
  const {api: apiFrequency} = useApi("frequencies");

  const [update, setUpdate] = useState(0);
  const [selectedType, setSelectedType] = useState(false);
  const [showModalSectionType, setShowModalSectionType] = useState(false);
  const [showModalReorder, setShowModalReorder] = useState(false);
  const [showModalSection, setShowModalSection] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalOptions, setShowModalOptions] = useState(false);
  const [target, setTarget] = useState(null);
  const [showEditMeta, setShowEditMeta] = useState(false);
  const [fontScale, setFontScale] = useState(DEFAULT_FONT_SIZE);
  const [canPrint, setCanPrint] = useState(true);
  const [alignment, setAlignment] = useState("left");

  const [report, setReport] = useState(null);
  const [reportWithMeta, setReportWithMeta] = useState(null);

  const reportRef = useRef();
  const containerRef = useRef();
  const printRef = useRef();
  const saving = useRef(false);

  const tables = useMemo(() => {
    if (update > 0 && report)
      return (
        report.extractRegions?.(true)?.filter(({type}) => type === CHECKSHEET || type === EVENT) ||
        []
      );

    return [];
  }, [report, update]);

  const configurePrint = () => {
    const printBody = document.createElement("div");
    printBody.style.pageBreakInside = "avoid";
    const reportClone = reportRef.current.cloneNode(true);
    printBody.appendChild(reportClone);
    printRef.current = printBody;
  };

  const handlePrint = useReactToPrint({
    contentRef: printRef
  });

  const saveTemplate = useCallback(() => {
    if (facility && report && !report.isEmpty()) {
      apiReport.callPut(edit, {
        groups: report.getGroupTemplates(),
        fontSize: fontScale,
        alignment
      });
    } else
      addToast(
        "At least one section must be added to the report in order to save template!",
        "error"
      );
  }, [addToast, apiReport, edit, facility, report, fontScale, alignment]);

  const handleSaveRateLimit = useCallback(() => {
    if (saving.current) clearTimeout(saving.current);
    const timer = setTimeout(() => {
      saveTemplate();
      saving.current = null;
    }, 500);
    saving.current = timer;
  }, [saveTemplate]);

  const updateFunction = useCallback(() => {
    report.filterEmptyGroups();
    if (update > 0) {
      if (!report.isEmpty()) handleSaveRateLimit();
      else
        addToast(
          "At least one section must be added to the report in order to save template!",
          "error"
        );
      setUpdate(prev => prev + 1);
    }
  }, [report, update, handleSaveRateLimit, addToast]);

  const handleResize = useCallback(() => {
    if (containerRef.current)
      document.documentElement.style.setProperty(
        "--vw-scale",
        Math.max(Math.min(containerRef.current.clientWidth / 900, 1), 0.5)
      );
  }, []);

  const containerCallback = useCallback(
    node => {
      if (node !== null && !containerRef.current) {
        window.addEventListener("resize", handleResize);

        containerRef.current = node;
        document.documentElement.style.setProperty(
          "--vw-scale",
          Math.max(Math.min(node.clientWidth / 900, 1), 0.5)
        );

        setUpdate(1);
      }
    },
    [handleResize]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted()) {
      apiFrequency.callGet();
      apiFacility.callGet().then(({status, data}) => {
        if (status === 200) setFacility(data);
      });
      apiReport.callGet(edit).then(({status, data}) => {
        if (status === 200 && data) {
          const {id, name, groups, startDate, base, interval, frequency, draft} = data;
          const {header} = data.settings;
          setReport(new Report(groups));
          const localizedDate = startDate ? dayjs.utc(startDate).tz(settings.timezone) : null;
          setReportWithMeta({
            id,
            name,
            header: header || null,
            base,
            draft,
            interval: `${interval}`,
            frequency: frequency?.name,
            startDate: localizedDate ? dayjs(localizedDate).format("YYYY-MM-DD") : null,
            startTime: localizedDate ? dayjs(localizedDate).format("HH:mm") : null
          });
          if (data.settings?.fontSize) setFontScale(data.settings.fontSize);
          if (data.settings?.alignment) setAlignment(data.settings.alignment);
        }
      });
    }
  }, [isMounted, apiFrequency, apiFacility, apiReport, edit, settings, setFacility]);

  useEffect(() => {
    if (report) {
      report.setUpdater(updateFunction);
      if (!update) setUpdate(1);
    }
  }, [report, updateFunction, update]);

  useEffect(() => {
    if (report?.updater) report.updater();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontScale, alignment]);

  const saveMeta = values => {
    if (values) {
      setReportWithMeta({...reportWithMeta, ...values});
      const payload = {
        name: values.name,
        base: values.base,
        interval: `${values.interval}`,
        frequency: values.frequency,
        autoStartDate: values.startDate ? dayjs(values.startDate).format("MM-DD-YYYY") : null,
        autoStartTime: values.startTime
      };
      if (values.hasHeader) payload.header = values.header;
      apiReport.callPut(reportWithMeta.id, payload).then(({status, data: reportData}) => {
        if (status === 200 && reportData?.data?.groups) {
          setReport(new Report(reportData.data.groups));
          setShowEditMeta(false);
        }
      });
    }
  };

  const handleInsertRegion = useCallback(
    (region, id) => {
      if (report) {
        if (id) report.updateRegion(id, region);
        else report.insertRegion(region);
        setTarget(null);
      }
    },
    [report]
  );

  const handleShowOptions = id => {
    if (report) {
      const region = report.retrieveRegion(id);
      if (region) {
        setTarget({...region, id});
        setShowModalOptions(true);
      }
    }
  };

  const persistDelete = () => {
    if (target?.id && report) {
      report.deleteRegion(target.id);
      setShowModalDelete(false);
      setTarget(null);
    }
  };

  const printError = () => {
    addToast(
      <>
        We are sorry, something went wrong with your report. Please submit a{" "}
        <AnchorInline href="/support" target="_blank" rel="noreferrer">
          Support Ticket
        </AnchorInline>
        .
      </>,
      "error"
    );
  };

  const handleClone = () => {
    const temp = {...target};
    delete temp.id;
    temp.title = `${temp.title} Copy`;
    handleInsertRegion(temp);
    setShowModalOptions(false);
  };

  return (
    <Page hasMenu>
      <Title>Report Editor</Title>

      <PageWrapper>
        {report && reportWithMeta ? (
          <>
            <Menu ref={containerCallback}>
              <Inline>
                <Heading>
                  <Abbr title={reportWithMeta.name}>{reportWithMeta.name || "Name..."}</Abbr>
                </Heading>

                {reportWithMeta.draft && <Draft>DRAFT</Draft>}

                <EditMeta type="button" onClick={() => setShowEditMeta(true)}>
                  <FontAwesomeIcon icon={faEdit} />
                </EditMeta>
                <Small>
                  Generated{" "}
                  {reportWithMeta.startDate && reportWithMeta.frequency
                    ? `${
                        reportWithMeta.frequency.name || reportWithMeta.frequency
                      } starting ${dayjs(reportWithMeta.startDate).format("MMM DD, YYYY")} `
                    : ""}
                  with data from last{" "}
                  {reportWithMeta.interval === "1"
                    ? reportWithMeta.base.slice(0, reportWithMeta.base.length - 1)
                    : `${reportWithMeta.interval} ${reportWithMeta.base}`}
                </Small>
              </Inline>

              <Inline>
                {report && !report.isEmpty() && (
                  <>
                    <Button
                      type="button"
                      title="Text Align"
                      onClick={() => setAlignment(prev => (prev === "left" ? "center" : "left"))}>
                      <FontAwesomeIcon icon={alignment === "left" ? faAlignLeft : faAlignCenter} />
                    </Button>
                    <ScaleBar
                      setState={setFontScale}
                      state={fontScale}
                      min={MIN_FONT_SIZE}
                      max={MAX_FONT_SIZE}
                      length={FONT_SIZE_NUM_OPTIONS}
                      icon={faFont}
                    />
                    <PrintButton
                      type="button"
                      title="Print"
                      onClick={
                        canPrint
                          ? () => {
                              configurePrint();
                              handlePrint();
                            }
                          : printError
                      }
                      clickableDisabled={!canPrint}>
                      <FontAwesomeIcon icon={faPrint} />
                    </PrintButton>
                    <Button
                      type="button"
                      title="Rearrange"
                      onClick={() => setShowModalReorder(true)}>
                      <FontAwesomeIcon icon={faTable} />
                    </Button>
                  </>
                )}
                <Button
                  type="button"
                  title="Add Section"
                  onClick={() => setShowModalSectionType(true)}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Inline>
            </Menu>

            <ReportPreview>
              {reportWithMeta.header && (
                <HeaderWrapper id="reportHeader">
                  <Col align={alignment}>
                    <Text>{reportWithMeta.header.topLeft}</Text>
                    <Small>{reportWithMeta.header.bottomLeft}</Small>
                  </Col>
                  <Col align="center">
                    <HeadingMedium>{reportWithMeta.header.name}</HeadingMedium>
                    {reportWithMeta.header.address && facility?.primaryAddress && (
                      <Text>
                        <span>{facility.primaryAddress?.line1}</span>
                        <br />
                        {facility.primaryAddress?.line2 !== "" && (
                          <>
                            <span>{facility.primaryAddress?.line2}</span>
                            <br />
                          </>
                        )}
                        <span>
                          {facility.primaryAddress.city} {facility.primaryAddress.state}
                          {facility.primaryAddress.zipCode &&
                            `, ${facility.primaryAddress.zipCode}`}
                        </span>
                      </Text>
                    )}
                  </Col>
                  <Col align={alignment === "left" ? "end" : alignment}>
                    <Text>{reportWithMeta.header.topRight}</Text>
                    <Small>{reportWithMeta.header.bottomRight}</Small>
                  </Col>
                </HeaderWrapper>
              )}

              <RenderReportBuilder
                report={report}
                update={update}
                handleShowOptions={handleShowOptions}
                fontSize={fontScale}
                alignment={alignment}
              />
            </ReportPreview>
          </>
        ) : (
          <NotLoaded>
            <Loader />
          </NotLoaded>
        )}
      </PageWrapper>

      {/* Printable Report */}
      {report && reportWithMeta && (
        <ReportPreview ref={reportRef} hide>
          {reportWithMeta.header && (
            <HeaderWrapper>
              <Col align={alignment}>
                <Text>{reportWithMeta.header.topLeft}</Text>
                <Small>{reportWithMeta.header.bottomLeft}</Small>
              </Col>
              <Col align="center">
                <HeadingMedium>{reportWithMeta.header.name}</HeadingMedium>
                {reportWithMeta.header.address && facility?.primaryAddress && (
                  <Text>
                    <span>{facility.primaryAddress?.line1}</span>
                    <br />
                    {facility.primaryAddress?.line2 !== "" && (
                      <>
                        <span>{facility.primaryAddress?.line2}</span>
                        <br />
                      </>
                    )}
                    <span>
                      {facility.primaryAddress.city} {facility.primaryAddress.state}
                      {facility.primaryAddress.zipCode && `, ${facility.primaryAddress.zipCode}`}
                    </span>
                  </Text>
                )}
              </Col>
              <Col align={alignment === "left" ? "end" : alignment}>
                <Text>{reportWithMeta.header.topRight}</Text>
                <Small>{reportWithMeta.header.bottomRight}</Small>
              </Col>
            </HeaderWrapper>
          )}
          <RenderReportBuilderPrintable
            report={report}
            update={update}
            hasHeader={!!reportWithMeta.header}
            fontSize={fontScale}
            alignment={alignment}
            setCanPrint={setCanPrint}
          />
        </ReportPreview>
      )}

      {/* Modals */}
      {showModalSectionType && (
        <ModalSectionType
          visible={showModalSectionType}
          setVisible={state => {
            if (!state) setSelectedType(null);
            setShowModalSectionType(state);
          }}
          handleSelection={type => {
            setSelectedType(type);
            setShowModalSectionType(false);
            setShowModalSection(true);
          }}
        />
      )}

      {showModalSection && (
        <ModalSection
          visible={showModalSection}
          goBack={
            selectedType
              ? () => {
                  setSelectedType(null);
                  setShowModalSection(false);
                  setShowModalSectionType(true);
                }
              : undefined
          }
          setVisible={state => {
            if (!state) {
              setTarget(null);
              setSelectedType(null);
            }
            setShowModalSection(state);
          }}
          base={reportWithMeta?.base}
          interval={reportWithMeta?.interval}
          handleInsertRegion={handleInsertRegion}
          tables={tables}
          target={target}
          selectedType={selectedType}
        />
      )}

      {showEditMeta && (
        <ModalEditMeta
          visible={showEditMeta}
          setVisible={setShowEditMeta}
          reportMeta={reportWithMeta}
          save={saveMeta}
        />
      )}

      {showModalDelete && (
        <ModalDelete
          visible={showModalDelete}
          setVisible={state => {
            if (!state) setTarget(null);
            setShowModalDelete(state);
          }}
          confirmDelete={persistDelete}
          title="Delete Section"
        />
      )}

      {showModalOptions && (
        <SectionOptions
          visible={showModalOptions}
          setVisible={state => {
            if (!state) setTarget(null);
            setShowModalOptions(state);
          }}
          handleDelete={() => {
            setShowModalDelete(true);
            setShowModalOptions(false);
          }}
          handleEdit={() => {
            setShowModalSection(true);
            setShowModalOptions(false);
          }}
          handleClone={handleClone}
          title={
            target?.type === CHART || target?.type === STAT ? target.data?.label : target?.title
          }
        />
      )}

      {showModalReorder && (
        <ModalReorder report={report} visible={showModalReorder} setVisible={setShowModalReorder} />
      )}
    </Page>
  );
};

// Style Overrides
const PageWrapper = styled.article`
  max-width: 770px;
  min-width: 450px;
  position: relative;
  height: min-content;
  margin-top: ${pad}px;
`;

const EditMeta = styled.div`
  display: flex;
  align-items: center;
  width: min-content;

  svg {
    margin-left: ${pad / 2}px;
    fill: ${props => props.theme.secondary};
  }
`;

const Draft = styled(Pill)`
  ${voice.quiet};
  white-space: nowrap;
  background: ${({theme}) => theme.warning};
`;

const Menu = styled.div`
  /* display: flex; */
  /* justify-content: space-between; */
  width: 100%;
  margin-bottom: ${pad}px;

  ${Heading} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media print {
    margin-bottom: ${pad}px;
    page-break-after: avoid;
    height: 16mm;
  }
`;

const ReportPreview = styled.div`
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  width: ${REPORT_WIDTH}px;
  min-width: 445px;
  height: min-content;

  /* Print Scale A4 */
  transform: scale(var(--vw-scale));
  transform-origin: top left;

  @media print {
    margin-bottom: 9mm;
    display: block;
    border: 0;
    padding: 0;
  }

  ${props =>
    props.hide
      ? css`
          display: none;
        `
      : ""}
`;

const HeaderWrapper = styled.div`
  ${flex("row", "nowrap")};
  gap: ${pad * 5}px;
  margin: ${pad}px 0 ${pad * 2}px;
  padding: ${pad}px 0;
  border-top: ${border} solid ${props => props.theme.secondary};
  border-bottom: ${border} solid ${props => props.theme.secondary};

  @media print {
    page-break-after: avoid;
  }
`;

const Col = styled.div`
  ${flex("column", "wrap", "space-between", "start")};
  width: 33.33%;

  ${HeadingMedium}, ${Text}, ${Small} {
    color: ${props => props.theme.secondary};
    width: 100%;
    text-align: ${props => props.align || "start"};
  }
`;

const PrintButton = styled(Button)`
  ${props =>
    props.clickableDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`;

export default ReportEditor;
