import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import TableReportRecords from "../reports/TableReportRecords.js";
import ModalArchive from "../reports/ModalArchive.js";
import ModalDelete from "../../components/ModalDelete.js";

// Style
import {pad, border, radius} from "../../style/components/variables.js";
import {Text, Loader, NotLoaded} from "../../style/components/general.js";

const Reports = ({facilityId}) => {
  const isMounted = useMountedState();

  const {data, loading, api} = useApi("reports");

  const [currentReport, setCurrentReport] = useState(null);
  const [showArchive, setShowArchive] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [initialLoad, setInitialLoad] = useState(true);

  const scrollToTable = useCallback(() => {
    const {hash} = window.location;
    if (hash) {
      const tableId = hash.replace("#", "");
      const table = document.getElementById(tableId);
      if (table) {
        const y = table.getBoundingClientRect().top - 100;
        setTimeout(() => window.scrollBy({behavior: "smooth", top: y}), 500);
      }
      setInitialLoad(false);
    }
  }, []);

  const getReports = useCallback(() => {
    api
      .callGet(null, {
        facilityId,
        orderBy: "asc",
        groupBy: "frequency"
      })
      .then(response => {
        if (response.status === 200) scrollToTable();
      });
  }, [api, facilityId, scrollToTable]);

  useEffect(() => {
    if (isMounted() && facilityId && initialLoad) getReports();
  }, [api, facilityId, initialLoad, isMounted, getReports]);

  const openArchive = report => {
    setCurrentReport(report);
    setShowArchive(true);
  };

  const openDelete = report => {
    setCurrentReport(report);
    setShowDelete(true);
  };

  const archiveReport = () =>
    api.callPut(currentReport.id, {archive: true}).then(() => {
      getReports();
      setShowArchive(false);
    });

  const publishReport = report => api.callPut(report.id, {draft: false}).then(() => getReports());

  const deleteReport = () =>
    api.callDelete(currentReport.id).then(() => {
      getReports();
      setShowDelete(false);
    });

  const restoreReport = report => api.callPut(report.id, {archive: false}).then(() => getReports());

  return (
    <>
      {loading ? (
        <NotLoaded>
          <Loader />
        </NotLoaded>
      ) : (
        <OutermostWrapper>
          {data && data.length > 0 ? (
            data.map((report, idx) => (
              <div id={`r${report.id}`} key={report.id}>
                <TableReportRecords
                  index={idx}
                  report={report}
                  showDelete={openDelete}
                  archiveReport={openArchive}
                  restoreReport={restoreReport}
                  publishReport={publishReport}
                />
              </div>
            ))
          ) : (
            <NotFound>
              <Text>No Reports have been created for this facility.</Text>
            </NotFound>
          )}
        </OutermostWrapper>
      )}

      {showArchive && (
        <ModalArchive
          visible={showArchive}
          setVisible={setShowArchive}
          archive={archiveReport}
          loading={loading}
        />
      )}

      {showDelete && (
        <ModalDelete
          visible={showDelete}
          setVisible={setShowDelete}
          confirmDelete={deleteReport}
          title="Delete Report"
          loading={loading}
        />
      )}
    </>
  );
};

Reports.propTypes = {
  facilityId: PropTypes.number.isRequired
};

// Style Overrides
const OutermostWrapper = styled.div`
  margin-bottom: ${pad * 2}px;
`;

const NotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  min-height: 75px;
  padding: ${pad}px;
  margin-right: ${pad}px;
  border-radius: ${radius};
  border: ${border} solid ${props => props.theme.secondary};
  width: 100%;
`;

export default Reports;
