import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";
import {useSocket} from "../../contexts/socket.js";

// Utils
import {GROUP, formatSubmissions} from "../../utils/builder.js";
import {DEFAULT_MARKER} from "../../utils/google/maps.js";
import {
  getCompletionRate,
  getMapFromCoords,
  getReadableKey,
  getSnakeCase,
  openLink,
  prettyDateInUserTimezone
} from "../../utils/helpers.js";

// Components
import RenderChecksheet from "../checksheet-builder/RenderChecksheet.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {flex} from "../../style/components/mixins.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {
  Label,
  Button,
  ButtonLoader,
  Pill,
  Abbr,
  AnchorInline,
  Text,
  List,
  ListItem
} from "../../style/components/general.js";

// Socket Constants
import {
  LISTEN_NOTIFY_COMPLETED,
  LISTEN_NOTIFY_DRAFT,
  LOCK,
  NOTIFY_COMPLETED,
  NOTIFY_DRAFT,
  RELEASE,
  getFacilityRooms
} from "../general/Room.js";

const embedInGroup = (builder, stageName, stageId) => {
  if (!builder) return builder;
  const {byId, allIds} = builder;
  const newById = {
    [stageId]: {
      element: GROUP,
      name: stageId,
      label: stageName,
      children: allIds,
      parentName: ""
    }
  };
  Object.keys(byId).map(fieldName => {
    const field = byId[fieldName];
    newById[fieldName] = {...field, parentName: stageId};
  });
  const newAllIds = [stageId];
  return {byId: newById, allIds: newAllIds};
};

const Event = ({room, event, locks, markers}) => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {slug} = useParams();

  const {currentUser, roleCanAccessResource} = useContext(AuthContext);

  const {facility} = useContext(FacilityNavContext);

  const {settings} = useContext(SettingsContext);

  const [keyHolder, setKeyHolder] = useState(null);
  const [completing, setCompleting] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentStageKey, setCurrentStageKey] = useState(null);
  const [targetStage, setTargetStage] = useState(null);

  const {api: apiEventRecords} = useApi("event-records");
  const {api: fileApi} = useApi("files");
  const {api: apiUsers} = useApi("users");

  const date = prettyDateInUserTimezone(event.dateDue, settings.timezone, "YYYY-MM-DD");

  // Initial Load
  useEffect(() => {
    if (isMounted() && event?.stages?.allIds) {
      const {lastStage} = event;
      const {allIds, byId} = event.stages;
      if (lastStage?.stage && (lastStage.stage !== allIds[allIds.length - 1] || lastStage.draft)) {
        setCurrentRecord(lastStage);

        const {stage, draft} = lastStage;
        const current = byId[stage];

        if (draft) {
          setTargetStage(current);
          setCurrentStageKey(stage);
        } else {
          const {next} = current || {};
          if (next) {
            setTargetStage(byId[next]);
            setCurrentStageKey(stage);
          }
        }
      } else {
        setTargetStage(byId[allIds[0]]);
        setCurrentStageKey(allIds[0]);
      }
    }
  }, [isMounted, date, event]);

  const progress = useMemo(() => {
    const {lastStage} = event;
    const {allIds} = event.stages || {};
    if (!allIds) return "";
    const offset = lastStage?.draft ? 0 : 1;
    const currentIndex = allIds.indexOf(lastStage?.stage) + offset;
    const numStages = allIds?.length;
    const show = (!lastStage || lastStage?.stage) && numStages > 1 && currentIndex < numStages;
    return show ? ` ${currentIndex + 1}/${numStages}` : "";
  }, [event]);

  const markerIconMap = useMemo(() => {
    const map = {};
    markers?.map(({id, ...rest}) => {
      map[id] = {...rest};
    });
    return map;
  }, [markers]);

  const openFile = fileId => {
    fileApi.callGet(fileId).then(res => {
      if (res.status === 200) openLink(res.data.link);
    });
  };

  const notifyDraft = useCallback(
    ({resourceKey}) => {
      if (resourceKey) {
        const [type, resourceId] = resourceKey.split("_");
        if (type === "event" && resourceId === `${event.id}`) {
          setDraftLoading(false);
          setCompleting(false);
        }
      }
    },
    [event]
  );

  const notifyCompleted = useCallback(
    ({resourceKey, stagesComplete}) => {
      if (resourceKey && stagesComplete !== null) {
        const [type, resourceId] = resourceKey.split("_");
        if (type === "event" && resourceId === `${event.id}` && !stagesComplete) {
          setCreateLoading(false);
          setCompleting(false);
        }
      }
    },
    [event]
  );

  // Socket Management
  useEffect(() => {
    if (isMounted()) {
      socket.on(LISTEN_NOTIFY_DRAFT, notifyDraft);
      socket.on(LISTEN_NOTIFY_COMPLETED, notifyCompleted);
    }

    return () => {
      // unbind all event handlers used in this component
      socket.off(LISTEN_NOTIFY_DRAFT, notifyDraft);
      socket.off(LISTEN_NOTIFY_COMPLETED, notifyCompleted);
    };
  }, [isMounted, socket, notifyCompleted, notifyDraft]);

  // Set "Key Holder" from current locks
  useEffect(() => {
    const key = `event_${event.id}_${date}`;
    if (isMounted() && locks && locks[key] && locks[key].user !== currentUser.publicId) {
      apiUsers.callGet(locks[key].user).then(({status, data}) => {
        if (status === 200) {
          const {firstName, lastName} = data;
          setKeyHolder(
            <Abbr title={`${firstName} ${lastName}`}>
              by {firstName}&nbsp;{lastName}
            </Abbr>
          );
        }
      });
    } else if (!locks || !locks[key]) {
      setCompleting(false);
      setKeyHolder(null);
    } else setKeyHolder(null);
  }, [isMounted, apiUsers, locks, event, date, currentUser, setKeyHolder]);

  const handleSubmission = (submission, draft, outOfRange) => {
    socket.emit(RELEASE, getFacilityRooms(slug, "event"), `event_${event.id}_${date}`);

    if (draft) setDraftLoading(true);
    else setCreateLoading(true);

    const {completedAt, ...rest} = submission;

    const params = {
      stage: targetStage?.name ? getSnakeCase(targetStage.name) : null,
      submission: {
        responses: targetStage?.builder ? formatSubmissions(rest, targetStage.builder) : submission
      },
      completedAt: completedAt || null,
      draft,
      eventId: event.id
    };

    if (outOfRange) params.outOfRange = Object.keys(outOfRange);

    const request = currentRecord
      ? apiEventRecords.callPut(currentRecord.id, params)
      : apiEventRecords.callPost({
          ...params,
          eventId: event.id
        });

    const targetStatus = currentRecord ? 200 : 201;
    request.then(({status}) => {
      if (status === targetStatus)
        socket.emit(draft ? NOTIFY_DRAFT : NOTIFY_COMPLETED, room, `event_${event.id}_${date}`);
      else {
        setDraftLoading(false);
        setCreateLoading(false);
      }
    });
  };

  const getCompletionRateHelper = () => {
    if (!currentRecord.submission.responses) return 0;
    const {completedAt: _ca, ...rest} = {...currentRecord.submission.responses};
    return getCompletionRate(rest);
  };

  const targetGroup = facility?.builder && facility.builder.byId[event.group];

  let groupMarker = null;

  if (event?.group)
    groupMarker =
      Object.keys(markerIconMap)?.length > 0 && targetGroup?.hasAddress && targetGroup?.markerId
        ? markerIconMap[targetGroup?.markerId]
        : DEFAULT_MARKER;

  return (
    <EventWrapper open={completing} key={event.name}>
      <HeaderWrapper>
        <Label bold>
          <Abbr title={event.name}>{event.name}</Abbr>
        </Label>
        {targetGroup && groupMarker && (
          <Text>
            Location:&nbsp;
            <Icon icon={groupMarker.icon} color={`#${groupMarker.color}`} />
            {targetGroup.hasAddress ? (
              <AnchorInline
                href={getMapFromCoords(targetGroup.address.lat, targetGroup.address.lon)}>
                {targetGroup.label}
              </AnchorInline>
            ) : (
              targetGroup.label
            )}
          </Text>
        )}
        <List column>
          {event.responses &&
            Object.keys(event.responses)
              .filter(key => event.responses[key] !== "")
              .map(key => (
                <EventItem key={key}>
                  {event.type.fields.byId && key in event.type.fields.byId
                    ? event.type.fields.byId[key].label
                    : getReadableKey(key)}
                  {": "}
                  {event.type.fields.byId &&
                  key in event.type.fields.byId &&
                  event.type.fields.byId[key].type === "upload" ? (
                    <FileLink
                      onClick={() => openFile(event.responses[key])}
                      key={`file-${event.responses[key]}`}>
                      Open File
                    </FileLink>
                  ) : (
                    event.responses[key]
                  )}
                </EventItem>
              ))}
        </List>
        <br />
        <Pills>
          {event?.frequency && <Pill>{event.frequency.name.toUpperCase()}</Pill>}
          {currentRecord?.draft && <Draft>INCOMPLETE&nbsp; {getCompletionRateHelper()}%</Draft>}
        </Pills>
      </HeaderWrapper>

      {roleCanAccessResource("event_record", "create") && !completing && (
        <CompleteWrapper>
          <DueDate>
            {event.dateDue
              ? `Due before ${prettyDateInUserTimezone(event.dateDue, settings.timezone)}`
              : "Due Today"}
          </DueDate>
          {keyHolder ? (
            <Locked data-testid="eventsDue.locked">
              <FontAwesomeIcon icon={faLock} />
              &nbsp;Locked&nbsp;{keyHolder}&nbsp;
            </Locked>
          ) : (
            <Complete
              type="button"
              title="Complete"
              loading={createLoading ? 1 : 0}
              onClick={() => {
                if (!targetStage?.builder || Object.keys(targetStage.builder.byId).length === 0)
                  handleSubmission({});
                else {
                  socket.emit(LOCK, getFacilityRooms(slug, "event"), `event_${event.id}_${date}`);
                  setCompleting(true);
                }
              }}>
              {createLoading && <ButtonLoader />}
              {event.stages ? `Complete${progress}` : "Dismiss"}
              {event.stages?.allIds?.length > 1 && targetStage?.name ? `: ${targetStage.name}` : ""}
            </Complete>
          )}
        </CompleteWrapper>
      )}

      {completing && targetStage?.builder?.allIds?.length > 0 && (
        <RenderChecksheet
          task={{
            ...event,
            builder: embedInGroup(targetStage.builder, targetStage?.name, currentStageKey)
          }}
          taskRecord={currentRecord}
          startDate={event.nextStart}
          dateDue={event.dateDue}
          responses={currentRecord?.draft ? currentRecord.submission.responses : null}
          setDraft={(submission, outOfRange) => handleSubmission(submission, true, outOfRange)}
          setSubmission={(submission, outOfRange) =>
            handleSubmission(submission, false, outOfRange)
          }
          completeLoading={createLoading}
          draftLoading={draftLoading}
          stage={currentStageKey}
          cancelFunction={() => {
            socket.emit(RELEASE, getFacilityRooms(slug, "event"), `event_${event.id}_${date}`);
            setCompleting(false);
          }}
          room={room}
          hideMeta
          smallScale
          persistToLocalStorage
          allowNotes
        />
      )}
    </EventWrapper>
  );
};

Event.propTypes = {
  room: PropTypes.string,
  locks: PropTypes.objectOf(PropTypes.any),
  event: PropTypes.objectOf(PropTypes.any).isRequired,
  markers: PropTypes.arrayOf(PropTypes.any).isRequired
};

Event.defaultProps = {
  room: null,
  locks: {}
};

// Style Overrides
const EventWrapper = styled.div`
  ${flex("row", "nowrap", "space-between", "stretch")};
  position: relative;
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  width: 100%;

  ${({open}) =>
    open &&
    css`
      flex-wrap: wrap;
    `}
`;

const HeaderWrapper = styled.div`
  ${flex("column", "wrap", "start")};
  width: 60%;
`;

const Pills = styled.div`
  display: flex;
  align-self: start;
`;

const Draft = styled(Pill)`
  background: ${({theme}) => theme.warning};
  margin-left: ${pad}px;
`;

const FileLink = styled(Text)`
  font-weight: bold;
  color: ${({theme}) => theme.primary};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const EventItem = styled(ListItem)`
  display: flex;
  flex-direction: row;
  gap: ${pad / 2}px;
`;

const CompleteWrapper = styled.div`
  ${flex("column", "nowrap", "space-between")};
  width: 40%;
`;

const DueDate = styled(Text)`
  display: none;

  ${bp(1)} {
    display: block;
    position: relative;
    text-align: right;
    padding: 0;
    margin-bottom: ${pad}px;
    width: 100%;
  }
`;

const Locked = styled.p`
  margin-top: ${pad * 3}px;
  width: max-content;
  align-self: flex-end;
`;

const Icon = styled(FontAwesomeIcon)`
  ${voice.quiet};
  font-weight: bold;
  margin-right: ${pad / 2}px;
  fill: ${({color, theme}) => color || theme.tertiary};
`;

const Complete = styled(Button)`
  align-self: end;
`;

export default Event;
