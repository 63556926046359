import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

// Utils
import {hexToRgba} from "../utils/helpers.js";
import {isSmallMobile} from "../utils/responsive.js";

// Style
import {flex, z} from "../style/components/mixins.js";
import {pad, radius} from "../style/components/variables.js";
import {bp, breakpoint} from "../style/components/breakpoints.js";
import {CloseButton, ModalButton, scrollbar} from "../style/components/general.js";

const Modal = ({
  children,
  visible,
  setVisible,
  goBack,
  maxWidth,
  minWidth,
  width,
  fixedHeight = false,
  isChecksheetPreview = false,
  renderModalControls,
  clickOutsideToClose = true,
  allowClose = true,
  hasBackButton = false,
  scrollRef,
  hasToolbar = false,
  allowScroll = true
}) => {
  const outside = useRef(null);
  const modalRef = scrollRef ?? outside;

  useEffect(() => {
    const handleOutsideClick = e =>
      visible &&
      clickOutsideToClose &&
      modalRef &&
      modalRef.current !== null &&
      !modalRef.current.contains(e.target) &&
      !modalRef.current.contains(e.target.nextSibling) &&
      setVisible(false);

    if (modalRef && allowClose) document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [allowClose, clickOutsideToClose, modalRef, setVisible, visible]);

  useEffect(() => {
    if (visible) document.getElementsByTagName("body")[0].style.overflow = "hidden";

    return () => {
      document.getElementsByTagName("body")[0].style.overflow = "inherit";
    };
  }, [visible]);

  return (
    visible && (
      <ModalContainer>
        <ModalContent
          id="modal"
          data-testid="modal"
          ref={modalRef}
          isMobile={isSmallMobile() ? 1 : 0}
          maxWidth={maxWidth}
          width={width}
          minWidth={minWidth}
          fixedHeight={fixedHeight}
          isChecksheetPreview={isChecksheetPreview}
          allowScroll={allowScroll}>
          <ModalControls hasToolbar={hasToolbar ? 1 : 0}>
            {renderModalControls}
            {allowClose && !hasBackButton && (
              <CloseButton data-testid="closeButton" onClick={() => setVisible(false)} />
            )}
            {hasBackButton && goBack && (
              <ModalButton onClick={() => goBack()}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </ModalButton>
            )}
          </ModalControls>
          {children}
        </ModalContent>
      </ModalContainer>
    )
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  renderModalControls: PropTypes.node,
  allowClose: PropTypes.bool,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  width: PropTypes.string,
  fixedHeight: PropTypes.bool,
  isChecksheetPreview: PropTypes.bool,
  clickOutsideToClose: PropTypes.bool,
  // TODO: remove and just check whether goBack exists?
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func,
  scrollRef: PropTypes.objectOf(PropTypes.any),
  hasToolbar: PropTypes.bool,
  allowScroll: PropTypes.bool
};

// Style Overrides
export const ModalContainer = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${z("peak")};
  ${flex("row", "nowrap", "center", "center")};
`;

const ModalContent = styled.div`
  position: relative;
  background: ${({theme}) => theme.tertiary};
  border-radius: ${radius};
  padding: 0 ${pad}px ${pad}px;
  max-height: ${({isMobile}) => (isMobile ? 85 : 75)}vh;
  width: calc(100% - ${pad * 2}px);
  max-width: calc(100% - ${pad * 2}px);

  ${({allowScroll}) =>
    allowScroll
      ? css`
          overflow-y: auto;
          overflow-x: hidden;
          ${scrollbar};
        `
      : css`
          overflow-y: hidden;
          overflow-x: hidden;
        `}

  ${({maxWidth}) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({width}) =>
    width &&
    css`
      width: ${width};
    `}

  ${({fixedHeight}) =>
    fixedHeight
      ? css`
          height: 75vh;
        `
      : ""}

  ${({minWidth}) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}

  ${bp(1)} {
    padding: 0 ${pad * 1.5}px ${pad * 1.5}px;
    max-height: 90vh;

    ${({fixedHeight}) =>
      fixedHeight
        ? css`
            height: 90vh;
          `
        : ""}
  }

  ${bp(2)} {
    padding: 0 ${pad * 3}px ${pad * 3}px;
  }

  ${bp(3)} {
    max-width: ${breakpoint.width[3]};

    ${({maxWidth}) =>
      maxWidth &&
      css`
        max-width: ${maxWidth};
      `}
  }
`;

export const ModalControls = styled.div`
  z-index: ${z("top")};
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  padding-top: ${pad}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background: ${({hasToolbar, theme}) =>
    hasToolbar
      ? theme.tertiary
      : `linear-gradient(to bottom, ${`${hexToRgba(theme.tertiary)} 0%`}, ${
          theme.name === "light" ? "rgba(255, 255, 255, 0) 100%" : "rgba(0, 0, 0, 0) 100%"
        })`};

  ${bp(1)} {
    padding-top: ${pad * 2}px;
  }

  ${bp(2)} {
    padding: ${pad * 3}px 0 ${pad}px;
  }
`;

export default Modal;
