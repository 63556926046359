import React from "react";
import PropTypes from "prop-types";
import {select} from "d3";
import styled from "styled-components";

// Utils
import {exists} from "../../utils/helpers";

const ChartAxis = ({
  constructor,
  transform,
  label,
  units,
  labelX,
  labelY,
  labelTransform,
  clipPathId
}) => {
  const ref = axis => axis && constructor && select(axis).call(constructor);
  return (
    <>
      <g ref={ref} transform={transform} clipPath={clipPathId} />
      {label && exists(labelX) && exists(labelY) && (
        <Text transform={labelTransform} x={labelX} y={labelY} textAnchor="middle">
          {label}
          {units ? ` ${units}` : ""}
        </Text>
      )}
    </>
  );
};

ChartAxis.propTypes = {
  constructor: PropTypes.func,
  transform: PropTypes.string,
  label: PropTypes.string,
  units: PropTypes.string,
  labelX: PropTypes.number,
  labelY: PropTypes.number,
  labelTransform: PropTypes.string,
  clipPathId: PropTypes.string
};

// Style Overrides
const Text = styled.text`
  font-size: 12px;
`;

export default ChartAxis;
