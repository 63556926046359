import React, {useState, useEffect, useContext, useCallback, useMemo} from "react";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Contexts
import {useSocket} from "../../contexts/socket.js";
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";

// Utils
import {formatSubmissions} from "../../utils/builder.js";
import {getCompletionRate, prettyDateInUserTimezone} from "../../utils/helpers.js";
import {isMobile} from "../../utils/responsive.js";

// Components
import RenderChecksheet from "../checksheet-builder/RenderChecksheet.js";
import ModalReviews from "./ModalReviews.js";
import ModalReview from "./ModalReview.js";
import Badge from "../../components/Badge.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {border, pad, radius, heroTheme} from "../../style/components/variables.js";
import {flex} from "../../style/components/mixins.js";
import {
  Abbr,
  Button,
  Inline,
  Loader,
  Pill,
  Small,
  Text,
  Title
} from "../../style/components/general.js";

// Socket Constants
import {LISTEN_NOTIFY_FREQUENCY_CHANGE, LOCK, RELEASE, getFacilityRooms} from "../general/Room.js";

const LISTEN_NOTIFY_COMPLETED = "notify:completed";
const LISTEN_NOTIFY_DRAFT = "notify:draft";

const NOTIFY_COMPLETED = "notify_completed";
const NOTIFY_DRAFT = "notify_draft";

const ChecksheetDue = ({
  room,
  locks = {},
  checksheet,
  setHide = () => {},
  setHasUpdates = () => {}
}) => {
  const {slug} = useParams();

  const isMounted = useMountedState();

  const socket = useSocket();

  const {settings} = useContext(SettingsContext);

  const {currentUser, roleCanAccessResource, atLeast} = useContext(AuthContext);

  const {api: apiChecksheets} = useApi("checksheets");
  const {api: apiChecksheetRecords} = useApi("checksheet-records");
  const {api: apiUsers} = useApi("users");

  const [changeLoading, setChangeLoading] = useState(false);
  const [targetChecksheet, setTargetChecksheet] = useState(checksheet);
  const [keyHolder, setKeyHolder] = useState(null);
  const [completing, setCompleting] = useState(null);
  const [recordDraft, setRecordDraft] = useState(null);
  const [completeLoading, setCompleteLoading] = useState(null);
  const [draftLoading, setDraftLoading] = useState(null);
  // Modals
  const [showModalReviews, setShowModalReviews] = useState(false);
  const [showModalReviewsRequired, setShowModalReviewsRequired] = useState(false);

  const recordDraftUser = useMemo(() => {
    const user = recordDraft?.updatedBy || recordDraft?.user;
    if (user === "GENERATED") return undefined;
    return user;
  }, [recordDraft]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && targetChecksheet.recordDraft) setRecordDraft(targetChecksheet.recordDraft);
  }, [isMounted, targetChecksheet]);

  const notifyDraft = useCallback(
    ({resourceKey}) => {
      if (resourceKey) {
        const [type, resourceId] = resourceKey.split("_");
        if (type === "checksheet" && resourceId === `${targetChecksheet.id}`)
          apiChecksheets.callGet(resourceId).then(({status, data}) => {
            if (status === 200 && data?.recordDraft) {
              setRecordDraft(data.recordDraft);
              setCompleting(false);
            }
          });
      }
    },
    [apiChecksheets, targetChecksheet.id]
  );

  const notifyCompleted = useCallback(
    ({resourceKey}) => {
      if (resourceKey) {
        const [type, resourceId] = resourceKey.split("_");
        if (type === "checksheet" && resourceId === `${targetChecksheet.id}`) {
          if (targetChecksheet.frequency)
            setHide(prev => (!prev.includes(resourceId) ? [...prev, resourceId] : prev));
          setRecordDraft(null);
          setCompleting(false);
        }
      }
    },
    [targetChecksheet, setHide]
  );

  const notifyFrequencyChange = useCallback(
    checksheetId => {
      if (checksheetId === `${targetChecksheet.id}`) {
        setChangeLoading(true);
        apiChecksheets.callGet(targetChecksheet.id).then(({status, data}) => {
          if (status === 200 && data) {
            if (!data.frequency !== !targetChecksheet.frequency || !data.available)
              setHide(prev => [...prev, `${data.id}`]);
            if (!data.frequency !== !targetChecksheet.frequency) setHasUpdates(true);

            setTargetChecksheet(data);
          }
          setChangeLoading(false);
        });
      }
    },
    [apiChecksheets, targetChecksheet, setHide, setHasUpdates]
  );

  // Socket Management
  useEffect(() => {
    socket.on(LISTEN_NOTIFY_DRAFT, notifyDraft);
    socket.on(LISTEN_NOTIFY_COMPLETED, notifyCompleted);
    socket.on(LISTEN_NOTIFY_FREQUENCY_CHANGE, notifyFrequencyChange);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LISTEN_NOTIFY_DRAFT, notifyDraft);
      socket.off(LISTEN_NOTIFY_COMPLETED, notifyCompleted);
      socket.off(LISTEN_NOTIFY_FREQUENCY_CHANGE, notifyFrequencyChange);
    };
  }, [socket, notifyCompleted, notifyDraft, notifyFrequencyChange]);

  const key = `checksheet_${targetChecksheet.id}`;

  // Set "Key Holder" from current locks
  useEffect(() => {
    if (isMounted() && currentUser?.publicId && locks) {
      if (locks && locks[key] && locks[key].user !== currentUser.publicId)
        apiUsers.callGet(locks[key].user).then(({status, data}) => {
          if (status === 200) {
            const {firstName, lastName} = data;
            setKeyHolder(
              <Abbr title={`${firstName} ${lastName}`}>
                by {firstName}&nbsp;{lastName}
              </Abbr>
            );
          }
        });

      if (!locks || !locks[key]) {
        setCompleting(false);
        setKeyHolder(null);
      }
    }
  }, [isMounted, currentUser.publicId, locks, key, apiUsers]);

  const saveChecksheet = (
    record,
    draftId = null,
    isDraft = false,
    outOfRange = null,
    outOfRangeMultiple = null,
    notifyFieldCondition = null
  ) => {
    const params = {
      ...record,
      draft: isDraft,
      outOfRange: outOfRange ? Object.keys(outOfRange) : null,
      outOfRangeMultiple: outOfRangeMultiple ? Object.keys(outOfRangeMultiple) : null,
      notifyFieldCondition: notifyFieldCondition ? Object.keys(notifyFieldCondition) : null
    };

    const success = draftId ? 200 : 201;
    const request = draftId
      ? apiChecksheetRecords.callPut(draftId, params)
      : apiChecksheetRecords.callPost(params);

    request.then(({status, data}) => {
      if (status === success && data) {
        const {data: updatedRecord} = data;

        socket.emit(
          RELEASE,
          getFacilityRooms(slug, "checksheet"),
          `checksheet_${updatedRecord.facilityChecksheetId}`
        );

        socket.emit(
          isDraft ? NOTIFY_DRAFT : NOTIFY_COMPLETED,
          room,
          `checksheet_${updatedRecord.facilityChecksheetId}`
        );
      }

      setCompleteLoading(null);
      setDraftLoading(null);
    });
  };

  const buildChecksheetRecord = (
    responses,
    draftId = null,
    isDraft = false,
    outOfRange = null,
    outOfRangeMultiple = null,
    notifyFieldCondition = null
  ) => {
    const {id, name, frequency, builder} = targetChecksheet;

    const info = {facilityChecksheetId: id};

    if (isDraft) setDraftLoading(id);
    else setCompleteLoading(id);

    const {completedAt, ...rest} = responses;
    const formattedResponses = formatSubmissions(rest, builder);
    const submission = {
      name,
      responses: formattedResponses
    };

    if (frequency?.name) submission.frequency = frequency.name;

    const checksheetRecord = {...info, submission: {...submission}, completedAt};

    saveChecksheet(
      checksheetRecord,
      draftId,
      isDraft,
      outOfRange,
      outOfRangeMultiple,
      notifyFieldCondition
    );
  };

  const getCompletionRateHelper = () => {
    if (!recordDraft.submission.responses) return 0;
    const {completedAt: _ca, ...rest} = {...recordDraft.submission.responses};
    return getCompletionRate(rest);
  };

  return (
    <ChecksheetWrapper open={completing} data-testid="checksheet.wrapper">
      <HeaderWrapper>
        {targetChecksheet.name && (
          <ChecksheetName>
            <Abbr title={targetChecksheet.name}>{targetChecksheet.name.toUpperCase()}</Abbr>
          </ChecksheetName>
        )}
        <Pills>
          {targetChecksheet?.frequency?.name && (
            <Frequency>
              <Abbr title={targetChecksheet.frequency.name}>{targetChecksheet.frequency.name}</Abbr>
            </Frequency>
          )}
          {recordDraft && recordDraft.submission && recordDraftUser && (
            <Draft>
              <Abbr title="INCOMPLETE">
                INCOMPLETE&nbsp;
                {getCompletionRateHelper(recordDraft.submission.responses)}%
              </Abbr>
            </Draft>
          )}
        </Pills>

        {changeLoading && <Loader />}
      </HeaderWrapper>

      {!completing && targetChecksheet && (
        <CompleteWrapper>
          {targetChecksheet.dateDue && (
            <DueDate>
              Due&nbsp;
              {prettyDateInUserTimezone(
                targetChecksheet.dateDue,
                settings.timezone,
                "MMM DD, YYYY"
              )}
            </DueDate>
          )}
          {roleCanAccessResource("facility_checksheet_record", "create") && (
            <Complete>
              {locks && locks[key] && currentUser.publicId !== locks[key].user ? (
                <Text data-testid="checksheetsDue.locked">
                  {locks[key].percentage > 0 && (
                    <>
                      <Small>(Progress:&nbsp;{locks[key].percentage}%)</Small> <br />
                    </>
                  )}
                  <FontAwesomeIcon icon={faLock} />
                  &nbsp;Locked&nbsp;
                  {keyHolder}
                </Text>
              ) : (
                <>
                  {!isMobile() && atLeast("admin") && (
                    <Button
                      type="button"
                      data-testid="checksheetsDue.complete"
                      onClick={() => setShowModalReviews(true)}>
                      {targetChecksheet.reviews?.length > 0 && (
                        <Badge
                          count={targetChecksheet.reviews?.length}
                          offset="14px"
                          color={heroTheme.warning}
                        />
                      )}
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                    </Button>
                  )}
                  <Button
                    type="button"
                    data-testid="checksheetsDue.complete"
                    onClick={() => {
                      if (
                        targetChecksheet?.reviews.length > 0 &&
                        (!recordDraft || recordDraftUser?.publicId !== currentUser.publicId)
                      )
                        setShowModalReviewsRequired(true);
                      socket.emit(LOCK, getFacilityRooms(slug, "checksheet"), key);
                      setCompleting(true);
                    }}>
                    Complete
                  </Button>
                </>
              )}
            </Complete>
          )}
        </CompleteWrapper>
      )}

      {completing && targetChecksheet?.builder?.allIds?.length > 0 && (
        <RenderChecksheet
          room={room}
          task={checksheet}
          responses={recordDraft ? recordDraft.submission.responses : null}
          previousSubmission={targetChecksheet.previousSubmission}
          setSubmission={(responses, outOfRange, outOfRangeMultiple, notifyFieldCondition) =>
            buildChecksheetRecord(
              responses,
              recordDraft ? recordDraft.id : null,
              false,
              outOfRange,
              outOfRangeMultiple,
              notifyFieldCondition
            )
          }
          setDraft={(responses, outOfRange, outOfRangeMultiple, notifyFieldCondition) =>
            buildChecksheetRecord(
              responses,
              recordDraft ? recordDraft.id : null,
              true,
              outOfRange,
              outOfRangeMultiple,
              notifyFieldCondition
            )
          }
          completeLoading={completeLoading === targetChecksheet.id}
          draftLoading={draftLoading === targetChecksheet.id}
          dateDue={targetChecksheet.dateDue}
          startDate={targetChecksheet.startDate ?? targetChecksheet.dateDue}
          showDates
          cancelFunction={() => {
            socket.emit(RELEASE, getFacilityRooms(slug, "checksheet"), key);
            setCompleting(false);
          }}
          user={
            recordDraftUser ? `${recordDraft.user.firstName} ${recordDraft.user.lastName}` : null
          }
          completedAt={recordDraft ? recordDraft.completedAt : null}
          draftDate={recordDraft ? recordDraft.createdAt : null}
          readOnly={!locks || (locks[key] && locks[key].user !== currentUser.publicId)}
          preview={false}
          persistToLocalStorage
          allowDeleteFromLocalStorage
        />
      )}

      {showModalReviewsRequired && (
        <ModalReview
          visible={showModalReviewsRequired}
          setVisible={setShowModalReviewsRequired}
          checksheet={targetChecksheet}
        />
      )}

      {showModalReviews && (
        <ModalReviews
          visible={showModalReviews}
          setVisible={setShowModalReviews}
          checksheet={targetChecksheet}
        />
      )}
    </ChecksheetWrapper>
  );
};

ChecksheetDue.propTypes = {
  room: PropTypes.string,
  locks: PropTypes.objectOf(PropTypes.any),
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired,
  setHide: PropTypes.func,
  setHasUpdates: PropTypes.func
};

// Style Overrides
const ChecksheetWrapper = styled.section`
  ${flex("row", "nowrap", "space-between", "stretch")};
  position: relative;
  width: 100%;
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  margin-top: ${pad / 2}px;

  ${bp(3)} {
    margin-bottom: ${pad}px;
  }

  ${({open}) =>
    open &&
    css`
      flex-wrap: wrap;
    `}
`;

const HeaderWrapper = styled(Inline)`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 70%;
  gap: unset;

  ${bp(2)} {
    width: calc(100% - 170px);
  }
`;

const CompleteWrapper = styled.div`
  ${flex("column", "nowrap", "space-between")};
  width: 30%;
`;

const ChecksheetName = styled(Title)`
  align-self: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90%;
`;

const Pills = styled.div`
  display: flex;
  align-self: start;
`;

const Draft = styled(Pill)`
  background: ${({theme}) => theme.warning};
  margin-left: ${pad}px;
`;

const Frequency = styled(Pill)`
  background: ${({theme}) => theme.primary};
`;

const DueDate = styled(Text)`
  display: none;

  ${bp(1)} {
    display: block;
    position: relative;
    text-align: right;
    padding: 0;
    margin-bottom: ${pad}px;
    width: 100%;
  }
`;

const Complete = styled(Inline)`
  justify-content: end;
  align-self: end;

  ${Text} {
    text-align: right;
  }
`;

export default ChecksheetDue;
