import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faMapMarkerAlt, faSearch} from "@fortawesome/free-solid-svg-icons";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Utils
import {isSmallMobile} from "../../utils/responsive.js";
import {GROUP} from "../../utils/builder.js";
import {DEFAULT_MARKER, FACILITY_MARKER} from "../../utils/google/maps.js";
import {getKebabCase} from "../../utils/helpers.js";

// Components
import FacilityPageHeader from "./FacilityPageHeader.js";
import Modal from "../../components/Modal.js";
import Map from "../../components/Map.js";
import MultiSelect from "../../components/MultiSelect.js";
import Badge from "../../components/Badge.js";
import RenderFacilityBuilder from "../facility-builder/RenderFacilityBuilder.js";
import PrimaryAddress from "../facility/PrimaryAddress.js";
import ModalMapAll from "./ModalMapAll.js";

// Style
import {z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {bp, breakpoint} from "../../style/components/breakpoints.js";
import {border, colors, pad, radius, shadow} from "../../style/components/variables.js";
import {
  Loader,
  Button,
  Inline,
  Small,
  Search,
  SearchWrapper,
  SearchIcon,
  StickyWrapper
} from "../../style/components/general.js";

const ModalMap = ({visible, setVisible, facility, setFacility, currentPath}) => {
  const navigate = useNavigate();

  const isMounted = useMountedState();

  const {atLeast} = useContext(AuthContext);

  const {api: apiAddress, data: markers} = useApi("addresses");

  const [view, setView] = useState("map");
  const [query, setQuery] = useState(null);
  const [refreshMarkers, setRefreshMarkers] = useState(false);
  const [builderQueryResult, setBuilderQueryResult] = useState(null);
  const [locations, setLocations] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [filters, setFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showMapAllModal, setShowMapAllModal] = useState(false);

  const markerIconMap = useMemo(() => {
    const map = {};
    markers?.map(({id, ...rest}) => {
      map[id] = {...rest};
    });
    return map;
  }, [markers]);

  /**
   * Refresh Google Map Markers
   * @param {array} a Addresses
   * @param {object} b Facility Builder
   */
  const loadMap = useCallback(
    (a, b) => {
      let extracted = [
        ...a.map(address => ({label: address.name, ...address, marker: FACILITY_MARKER})),
        ...Object.values(b.byId || {})
          .filter(({hasAddress}) => hasAddress)
          .map(({id, name, label, address, help, markerId}) => ({
            id: id || name,
            label,
            ...address,
            details: help ? [...help] : [{}],
            draggable: true,
            marker:
              markerId && markerId in markerIconMap
                ? {id: markerId, ...markerIconMap[markerId]}
                : DEFAULT_MARKER
          }))
      ];

      if (activeFilters?.length > 0)
        extracted = extracted.filter(({marker}) => activeFilters.includes(marker.id));

      setLocations(extracted);
      setRefreshMarkers(true);
    },
    [markerIconMap, activeFilters]
  );

  const showFacilityAddresses = useCallback(
    () => !query || activeFilters.includes(DEFAULT_MARKER.id),
    [query, activeFilters]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted()) apiAddress.callGet(null, {markers: true});
  }, [isMounted, apiAddress]);

  useEffect(() => {
    if (isMounted() && facility?.addresses && facility?.builder) {
      loadMap(
        builderQueryResult && !showFacilityAddresses() ? [] : facility?.addresses,
        builderQueryResult ?? facility?.builder
      );
    }
  }, [isMounted, facility, loadMap, builderQueryResult, showFacilityAddresses]);

  // Outside click handler
  const filterMenu = useRef(null);

  useEffect(() => {
    const handleClickOutsideFilterMenu = e =>
      filterMenu?.current !== null &&
      !filterMenu.current.contains(e.target) &&
      !filterMenu.current.contains(e.target.nextSibling) &&
      setShowFilters(false);

    if (filterMenu) document.addEventListener("mousedown", handleClickOutsideFilterMenu);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutsideFilterMenu);
  }, [filterMenu]);

  const handleApply = () => {
    setShowFilters(false);
    setActiveFilters(filters);
  };

  const handleClear = () => {
    setFilters([]);
    setActiveFilters([]);
    setShowFilters(false);
  };

  const toBuilder = () => {
    navigate(`/facilities/${facility.slug}/builder`);
    setVisible(false);
  };

  const searchAndFilter = useCallback(() => {
    if (facility) {
      const {allIds, byId} = facility;
      if ((activeFilters.length === 0 && !query) || !(allIds && byId)) {
        setBuilderQueryResult(null);
      } else {
        const updatedAllIds = allIds.filter(
          name =>
            activeFilters?.length === 0 ||
            (!byId[name].marker && activeFilters.includes(DEFAULT_MARKER.id)) ||
            activeFilters.includes(byId[name].markerId)
        );

        const groups = Object.entries(byId).filter(
          ([name, obj]) =>
            (!query || obj.label.toLowerCase().includes(query.toLowerCase())) &&
            allIds.includes(name)
        );

        const updatedById = Object.fromEntries(groups);

        setBuilderQueryResult({allIds: updatedAllIds, byId: updatedById});
      }
    }
  }, [activeFilters, query, facility]);

  // Handle search or filter
  useEffect(() => {
    if (isMounted() && (query !== null || activeFilters)) searchAndFilter();
  }, [isMounted, query, activeFilters, searchAndFilter]);

  const handleResize = useCallback(localView => {
    if (window.innerWidth >= 1024) setView(null);
    else if (localView === null) setView("map");
  }, []);

  const handleResizeTimeout = useCallback(
    (resized, localView) => {
      clearTimeout(resized);
      resized = setTimeout(() => handleResize(localView), 10);
    },
    [handleResize]
  );

  const handleSearch = e => {
    e.preventDefault();
    const search = e.target.value;
    setQuery(search);
  };

  useEffect(() => {
    let resizedFn;
    const fnWrapper = () => handleResizeTimeout(resizedFn, view);
    if (isMounted() && locations?.length > 0) {
      window.removeEventListener("resize", fnWrapper);
      if (!view || window.innerWidth >= 1024) handleResize();

      window.addEventListener("resize", fnWrapper);
    }

    return () => {
      window.removeEventListener("resize", fnWrapper);
    };
  }, [isMounted, view, locations, handleResize, handleResizeTimeout]);

  const currentHash = useMemo(
    () =>
      (facility?.name &&
        currentPath.split(
          `/facilities/${getKebabCase(facility.name)}-${getKebabCase(facility.type)}`
        )[1]) ||
      null,
    [currentPath, facility]
  );

  if (showMapAllModal && facility?.types?.length > 0)
    return (
      <ModalMapAll
        visible={visible}
        setVisible={setVisible}
        goBack={() => {
          setShowMapAllModal(false);
          setRefreshMarkers(true);
        }}
        facility={facility}
      />
    );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      maxWidth={breakpoint.width[4]}
      allowClose
      allowClickOutsideToClose={!showFilters}
      hasToolbar>
      <PageHeaderWrapper>
        {facility?.name && (
          <FacilityPageHeader
            facility={facility}
            reloadData={() => setFacility(null)}
            path={currentHash}
          />
        )}
      </PageHeaderWrapper>
      <StyledStickyWrapper>
        <SpaceBetween>
          {!facility?.isDeleted && (
            <ToBuilder type="button" onClick={toBuilder}>
              Go To Facility {atLeast("creator") ? "Builder" : "Map"}
            </ToBuilder>
          )}
          <StyledInline>
            <SearchWrapperSpaced>
              <SearchIcon>
                <FontAwesomeIcon icon={faSearch} />
              </SearchIcon>
              <Search
                name="search"
                type="text"
                placeholder="Search..."
                onChange={e => handleSearch(e)}
              />
            </SearchWrapperSpaced>
            <MenuButton type="button" onClick={() => setShowFilters(true)}>
              <Badge count={activeFilters?.length} offset="14px" color={colors.heroGreen} />
              <FontAwesomeIcon icon={faFilter} />
            </MenuButton>
            <ViewSwitch
              onClick={() => setView(view === "builder" ? "map" : "builder")}
              general={!atLeast("creator")}>
              {view === "builder" ? "View Map" : "View Builder"}
            </ViewSwitch>
            {facility?.types?.length > 0 && (
              <MenuButton type="button" onClick={() => setShowMapAllModal(true)}>
                View All Types
              </MenuButton>
            )}
          </StyledInline>
          {showFilters && (
            <OptionSub ref={filterMenu}>
              <Small>Filter(s)</Small>
              <MultiSelect
                options={[
                  {...DEFAULT_MARKER, value: DEFAULT_MARKER.id},
                  ...markers.map(({id, label, icon, color}) => ({
                    value: `${id}`,
                    label,
                    icon,
                    color
                  }))
                ]}
                setSelection={setFilters}
                defaultSelection={filters}
              />
              <SpacedInline>
                <ApplyFilter type="button" onClick={handleApply}>
                  Apply
                </ApplyFilter>
                <ApplyFilter type="button" onClick={handleClear}>
                  Clear
                </ApplyFilter>
              </SpacedInline>
            </OptionSub>
          )}
        </SpaceBetween>
      </StyledStickyWrapper>

      <BuilderPage>
        {facility?.builder?.byId &&
          Object.keys(facility.builder.byId).filter(
            key =>
              (facility.builder.byId[key].hasHelp || facility.builder.byId[key].hasAddress) &&
              facility.builder.byId[key].element === GROUP
          ).length > 0 && (
            <Wrapper>
              {activeFilters?.length === 0 && facility?.primaryAddress && (
                <PrimaryAddress details={{...facility?.details, address: facility?.primaryAddress}}>
                  <View
                    onClick={() => {
                      setActiveMarker(facility?.primaryAddress.id);
                      if (view && setView) setView("map");
                    }}>
                    View on Map
                    <Icon icon={faMapMarkerAlt} color={`#${FACILITY_MARKER.color}`} />
                  </View>
                </PrimaryAddress>
              )}
              <Builder view={view}>
                <DragDropContext onDragStart={() => {}} onDragEnd={() => {}}>
                  <Droppable droppableId="facility.builder" isDropDisabled>
                    {provided => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <RenderFacilityBuilder
                          facility={facility}
                          builder={builderQueryResult || facility?.builder}
                          querying={!!query && query !== ""}
                          activeMarker={activeMarker}
                          setActiveMarker={setActiveMarker}
                          markerIconMap={markerIconMap}
                          readOnly
                          groupsOnly
                          view={view}
                          setView={setView}>
                          {provided.placeholder}
                        </RenderFacilityBuilder>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Builder>
            </Wrapper>
          )}

        <MapWrapper
          view={view}
          fullWidth={
            !facility?.builder?.byId ||
            Object.keys(facility.builder.byId).filter(
              key =>
                (facility.builder.byId[key].hasHelp || facility.builder.byId[key].hasAddress) &&
                facility.builder.byId[key].element === GROUP
            ).length === 0
          }
          isMobile={isSmallMobile() ? 1 : 0}>
          {locations ? (
            <Map
              locations={locations}
              activeMarker={activeMarker}
              setActiveMarker={setActiveMarker}
              filters={activeFilters}
              querying={!!query && query !== ""}
              refreshMarkers={refreshMarkers}
              setRefreshMarkers={setRefreshMarkers}
            />
          ) : (
            <Loader />
          )}
        </MapWrapper>
      </BuilderPage>
    </Modal>
  );
};

ModalMap.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  facility: PropTypes.objectOf(PropTypes.any),
  setFacility: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired
};

// Style Overrides
const MapWrapper = styled(StickyWrapper)`
  width: 100%;
  height: ${({isMobile}) => (isMobile ? 70 : 54)}vh;
  display: block;
  top: 80px;
  margin-top: 0;

  ${bp(2)} {
    height: 70vh;
  }

  ${bp(3)} {
    width: calc(60% - 5px);

    ${({fullWidth}) =>
      fullWidth &&
      css`
        width: 100%;
      `};
  }

  ${({fullWidth}) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  ${({view}) =>
    view === "builder" &&
    css`
      display: none;
    `};
`;

const StyledStickyWrapper = styled(StickyWrapper)`
  width: 100%;
  height: 80px;
  display: block;
  top: 40px;
  z-index: ${z("top")};
  background-color: ${({theme}) => theme.tertiary};

  ${bp(2)} {
    height: 60px;
  }
`;

const ToBuilder = styled(Button)`
  margin-right: ${pad}px;
  margin-top: 7px;
  margin-bottom: ${pad * 1.2}px;
`;

const SpaceBetween = styled(Inline)`
  display: block;

  ${bp(2)} {
    margin-top: 0;
    justify-content: space-between;
    display: flex;
  }
`;

const StyledInline = styled(Inline)`
  gap: ${pad}px;
`;

const OptionSub = styled.div`
  position: absolute;
  width: 220px;
  right: unset;
  top: 65px;
  left: 149px;
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  background: ${({theme}) => theme.tertiary};
  margin-top: ${pad}px;
  z-index: ${z("top")};
  box-shadow: ${shadow};
  color: ${({theme}) => theme.secondary};

  ${bp(2)} {
    top: 37px;
    right: 125px;
    left: unset;
  }

  ${bp(3)} {
    right: 0;
  }
`;

const ApplyFilter = styled(Button)`
  ${voice.quiet};
  width: fit-content;
  margin-right: ${pad / 2}px;
  padding: ${pad / 2}px;
  display: inline-flex;

  svg {
    fill: ${({theme}) => theme.tertiary};
    align-self: center;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ViewSwitch = styled(Button)`
  margin-top: -${pad / 2}px;
  ${bp(3)} {
    display: none;
    pointer-events: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: inherit;

  ${bp(3)} {
    display: block;
    width: calc(40% - 5px);
  }

  ${({view}) =>
    !view || view === "builder"
      ? css`
          display: block;
        `
      : css`
          display: none;
        `};
`;

const Builder = styled.div`
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
`;

const View = styled.button`
  width: fit-content;
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.secondary};

  ${({active, theme}) =>
    active &&
    css`
      color: ${theme.primary};
    `}

  &:hover {
    text-decoration: underline;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  padding: ${pad}px 0 ${pad}px ${pad / 2}px;
  fill: ${({color, theme}) => color || theme.primary};
`;

const MenuButton = styled(Button)`
  margin-top: -${pad / 2}px;
`;

const SearchWrapperSpaced = styled(SearchWrapper)`
  margin-top: -${pad / 2}px;
`;

const BuilderPage = styled(Inline)`
  align-items: flex-start;
  display: block;
  width: 100%;
  height: 100%;
  gap: ${pad}px;
  margin-top: 0;

  ${bp(2)} {
    margin-top: ${pad}px;
  }

  ${bp(3)} {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`;

const SpacedInline = styled(Inline)`
  margin-top: ${pad}px;
`;

const PageHeaderWrapper = styled.div`
  position: relative;
  z-index: ${z("peak")};
`;

export default ModalMap;
