import React, {useEffect, useState, useCallback, useContext} from "react";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSearch, faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

// Contexts
import {AuthContext} from "../contexts/auth.js";

// Hooks
import useMountedState from "../hooks/useMountedState.js";
import useApi from "../hooks/useApi.js";

// Utils
import {resolveDependencyMismatches} from "./checksheet-builder/helpers.js";
import {objectGuard} from "../utils/helpers.js";
import {
  addField,
  initialFieldValues,
  removeElement,
  sortHelp,
  updateElement
} from "../utils/builder.js";

// Components
import RenderChecksheet from "./checksheet-builder/RenderChecksheet.js";
import RenderChecksheetBuilder from "./checksheet-builder/RenderChecksheetBuilder.js";
import ModalTemplateInstances from "./checksheet-builder/ModalTemplateInstances.js";
import ModalAddField from "./checksheet-builder/ModalAddField.js";
import ModalDelete from "../components/ModalDelete.js";
import ModalPreviewTemplate from "./checksheet-builder/ModalPreviewTemplate.js";
import ModalHelp from "./checksheet-builder/ModalHelp.js";
import ModalEditElement from "./checksheet-builder/ModalEditElement.js";
import ModalFormula from "./checksheet-builder/ModalFormula.js";
import ModalConditionals from "./checksheet-builder/ModalConditionals.js";
import ModalDeleteElement from "./checksheet-builder/ModalDeleteElement.js";
import ModalRedirect from "./general/ModalRedirect.js";
import ModalElapsed from "./checksheet-builder/ModalElapsed.js";

// Style
import {voice} from "../style/components/typography.js";
import {breakpoint} from "../style/components/breakpoints.js";
import {pad, transition, border, radius} from "../style/components/variables.js";
import {flex} from "../style/components/mixins.js";
import {
  Page,
  Section,
  Title,
  ButtonFull,
  FormGroup,
  Button,
  Inline,
  Text,
  StickyWrapper,
  Loader,
  NotLoaded,
  Error,
  ButtonLoader,
  RelativeWrapper,
  SearchWrapper,
  Search,
  SearchIcon,
  HeadingSmall
} from "../style/components/general.js";

const ComponentTemplates = () => {
  const isMounted = useMountedState();

  const {roleCanAccessResource} = useContext(AuthContext);

  const [query, setQuery] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [currentResults, setCurrentResults] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [units, setUnits] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [edit, setEdit] = useState(false);
  const [viewInstances, setViewInstances] = useState(false);
  const [builder, setBuilder] = useState({byId: {}, allIds: []});
  const [error, setError] = useState("");
  const [unitsToAdd, setUnitsToAdd] = useState([]);
  const [loadingTemplatesCreate, setLoadingTemplatesCreate] = useState(false);
  const [loadingTemplatesUpdate, setLoadingTemplatesUpdate] = useState(false);
  const [activeDroppableId, setActiveDroppableId] = useState("");
  const [selected, setSelected] = useState({});
  // Modals
  const [target, setTarget] = useState(null);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalHelp, setShowModalHelp] = useState(false);
  const [showModalRedirectFormula, setShowModalRedirectFormula] = useState(false);
  const [formulaMode, setFormulaMode] = useState("create");
  const [showModalFormula, setShowModalFormula] = useState(false);
  const [elapsedMode, setElapsedMode] = useState("create");
  const [showModalElapsed, setShowModalElapsed] = useState(false);
  const [showModalConditional, setShowModalConditional] = useState(false);

  const {api, loading: loadingTemplates} = useApi("base-components");
  const {api: apiUnits} = useApi("units");

  const loadTemplates = useCallback(
    () =>
      api.callGet("", {query: ""}).then(({status, data}) => {
        if (status === 200) {
          setCurrentResults(data);
          setTemplateList(data);
        }
      }),
    [api]
  );

  useEffect(() => {
    if (isMounted()) {
      loadTemplates();

      apiUnits.callGet().then(({status, data}) => {
        if (status === 200) setUnits(data);
      });
    }
  }, [isMounted, loadTemplates, apiUnits]);

  useEffect(() => {
    if (refresh) {
      setQuery("");
      setEdit(false);
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setCurrentResults(
      templateList.filter(({label}) => label.toLowerCase().includes(query.toLowerCase()))
    );
  }, [query, templateList]);

  const formatOptions = useCallback(
    targetFields =>
      targetFields?.map((field, i) => {
        if (field?.options?.length > 0)
          return {
            ...field,
            name: `field${i}`,
            options: field.options.map(option => ({
              option: option.option || option.value || objectGuard(option)
            })),
            toggle: true
          };

        return {...field, name: `field${i}`, toggle: true};
      }),
    []
  );

  const formatSelected = useCallback(
    template => {
      const templateFields =
        template?.fields?.length > 0 ? formatOptions(template.fields) : [initialFieldValues];

      return {
        id: template.id,
        base: false,
        label: template.label || "",
        fields: templateFields,
        instances: template.instances,
        help: template.help,
        hasHelp: template.hasHelp
      };
    },
    [formatOptions]
  );

  const saveBuilder = (updated, newUnits) => {
    if (newUnits?.length > 0) {
      const temp = newUnits.filter(unit => !unitsToAdd.includes(unit));
      setUnitsToAdd(prev => [...prev, ...temp]);
      setUnits(prev => [...prev, ...temp]);
    }
    setBuilder(updated);
  };

  const initializeBuilder = useCallback(() => {
    const formatted = formatSelected(selected);
    const fields = [...formatted.fields];
    delete formatted.fields;
    setBuilder({
      byId: {
        ...Object.fromEntries(
          fields.map((field, index) => [
            `field${index}`,
            {
              ...field,
              parentName: "component",
              name: `field${index}`,
              element: "field"
            }
          ])
        ),
        component: {
          ...formatted,
          element: "component",
          children: fields.map((_field, index) => `field${index}`),
          label: formatted.label,
          name: "component",
          toggle: true
        }
      },
      allIds: ["component"]
    });
  }, [formatSelected, selected]);

  useEffect(() => {
    if (isMounted() && Object.keys(selected).length > 0) initializeBuilder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, formatSelected, selected]);

  const buildTemplate = () => {
    const newUnits = [];
    const component = builder?.byId?.component;
    const children = builder?.byId?.component?.children;
    const template = {
      label: component.label,
      options: {help: component.help},
      units: newUnits
    };

    if (!builder || !builder.byId) return {};

    const byId = Object.fromEntries(
      Object.entries(builder.byId).map(([name, value]) => {
        const temp = {...value};
        delete temp.toggle;
        return [name, temp];
      })
    );

    return {
      ...template,
      fields: children.map(field => ({
        ...byId[field],
        units: byId[field].units ? byId[field].units.replace("Other: ", "") : null,
        element: "field"
      }))
    };
  };

  const handleEdit = base => {
    const children = builder?.byId?.component?.children;
    if (!children || children.length === 0) {
      setError("Template must contain at least one field");
      return;
    }
    setError("");

    if (base) setLoadingTemplatesCreate(true);
    else setLoadingTemplatesUpdate(false);

    const template = buildTemplate();

    if (base)
      api.callPost(template).then(() => {
        setEdit(false);
        setRefresh(true);
        setLoadingTemplatesCreate(false);
        loadTemplates();
      });
    else
      api.callPut(selected.id, template).then(() => {
        setEdit(false);
        setRefresh(true);
        setLoadingTemplatesUpdate(false);
        loadTemplates();
      });
  };

  const handleDelete = () => {
    api.callDelete(selected.id).then(() => {
      setSelected({});
      setRefresh(true);
      setQuery("");
      setEdit(false);
      setShowDelete(false);
      loadTemplates();
    });
  };

  const handleEditField = update => {
    const newUnits = [];
    if (update.units && !units.includes(update.units)) {
      setUnits(prev => [...prev, update.units].sort());
      newUnits.push(update.units);
    }
    const {builder: updated} = updateElement(builder, update, false);
    saveBuilder(updated, newUnits);
    setShowModalEdit(false);
  };

  const handleDeleteField = update => {
    const updated = removeElement(builder, update);
    saveBuilder(updated);
    setShowModalDelete(false);
  };

  const handleAddField = field => {
    setError("");
    const newUnits = [];
    if (field.units && !units.includes(field.units)) {
      setUnits(prev => [...prev, field.units].sort());
      newUnits.push(field.units);
    }
    const temp = {
      ...field,
      toggle: true,
      name: `field${builder?.byId?.component?.children?.length || 0}`
    };
    const updated = addField(builder, temp);
    saveBuilder(updated, newUnits);
  };

  const handleAddMultipleFields = fields => {
    setError("");
    const newUnits = [];
    let updated = {...builder};
    const nameIdx = builder?.byId?.component?.children?.length || 0;
    fields.map((field, i) => {
      if (field.units && !units.includes(field.units)) {
        setUnits(prev => [...prev, field.units].sort());
        newUnits.push(field.units);
      }
      const temp = {
        ...field,
        toggle: true,
        name: `field${nameIdx + i}`
      };
      updated = addField(updated, temp);
    });
    saveBuilder(updated, newUnits);
  };

  const handleFormula = field => {
    if (field.type === "generated") handleEditField(field);
    else {
      const newField = {
        ...field,
        type: "generated",
        disabled: true
      };
      handleAddField(newField);
    }
  };

  const handleConditional = values => {
    const element = {
      ...target,
      condition: values.condition
    };
    handleEditField(element);
  };

  const cloneElement = targetElement => {
    const {element, label, name} = targetElement;
    const clone = {
      ...builder.byId[name],
      label: `${label} copy`,
      generates: [],
      toggle: true,
      condition: null,
      name: `field${builder?.byId?.component?.children?.length || 0}`
    };
    const nameMap = {};

    let updated;
    if (element === "field") updated = addField(builder, clone);

    resolveDependencyMismatches(updated, nameMap);
    saveBuilder(updated);
  };

  const onDragStart = provided => {
    const {droppableId} = provided.source;
    setActiveDroppableId(droppableId);
  };

  // re-ordering list
  const onDragEnd = result => {
    const {destination, source, type} = result;
    if (!destination) {
      return;
    }

    let updatedBuilder = {...builder};

    if (type === source.droppableId) {
      const byId = {...builder.byId};

      const parent = byId[type];
      const children = [...parent.children];

      const [removed] = children.splice(source.index, 1);
      children.splice(destination.index, 0, removed);

      const nameMap = {};
      updatedBuilder.byId = Object.fromEntries(
        children.map((name, i) => {
          nameMap[name] = `field${i}`;
          return [`field${i}`, {...byId[name], name: `field${i}`}];
        })
      );

      resolveDependencyMismatches(updatedBuilder, nameMap);

      const help = sortHelp(parent.help, children, byId);
      updatedBuilder = {
        ...updatedBuilder,
        byId: {
          ...updatedBuilder.byId,
          [type]: {...parent, children: children.map((_name, i) => `field${i}`), help}
        }
      };

      saveBuilder(updatedBuilder);
    }
  };

  const handleShowModalFormula = element => {
    if (element.type === "generated") setShowModalRedirectFormula(true);
    else setShowModalFormula(true);
  };

  const handleShowModalElapsed = element => {
    if (element.type === "generated") {
      setElapsedMode("edit");
      setShowModalElapsed(true);
    } else {
      setElapsedMode("create");
      setShowModalElapsed(true);
    }
  };

  return (
    <TemplatePage>
      <Menu fullWidth>
        <Title>Manage Templates</Title>
        <Button type="button" onClick={() => window.history.back()}>
          <FontAwesomeIcon icon={faArrowLeft} />
          &nbsp;&nbsp;Go Back
        </Button>
      </Menu>
      <TemplateInline>
        <TemplateListContent>
          <TemplateList>
            <HeadingSmall>Component Templates</HeadingSmall>

            <br />

            <SearchWrapper>
              <SearchIcon>
                <FontAwesomeIcon icon={faSearch} />
              </SearchIcon>
              <Search
                type="text"
                placeholder="Search"
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
            </SearchWrapper>

            <br />

            {!loadingTemplates ? (
              <TemplateTable>
                {currentResults?.length > 0 ? (
                  currentResults.map(template => (
                    <ComponentTemplate
                      data-testid="templates.option"
                      key={template.id}
                      onClick={() => {
                        setSelected({
                          id: template.id,
                          label: template.label || "",
                          help: template.help || [{}],
                          hasHelp: template.hasHelp || false,
                          displayGrid: template.displayGrid || false,
                          fields: template.fields || [initialFieldValues],
                          instances: template.instances
                        });
                        setQuery(template.label);
                        window.scrollTo(0, 0);
                      }}
                      active={selected && selected.label === template.label}>
                      {template.label}
                    </ComponentTemplate>
                  ))
                ) : (
                  <Text>No templates found.</Text>
                )}
              </TemplateTable>
            ) : (
              <NotLoaded>
                <Loader />
              </NotLoaded>
            )}
          </TemplateList>
        </TemplateListContent>
        <Preview>
          <Options>
            <HeadingSmall>Selected Component</HeadingSmall>
            {selected.label && (
              <Inline>
                {roleCanAccessResource("component", "update") && (
                  <Button
                    type="button"
                    onClick={() => {
                      if (!edit) setEdit(true);
                      else {
                        setEdit(false);
                        initializeBuilder();
                        setError("");
                      }
                    }}>
                    {!edit ? <FontAwesomeIcon icon={faEdit} /> : "Cancel"}
                  </Button>
                )}
                {roleCanAccessResource("component", "delete") && (
                  <Delete type="button" onClick={() => setShowDelete(!showDelete)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </Delete>
                )}
              </Inline>
            )}
          </Options>
          {selected.label ? (
            <RelativeWrapper>
              {edit ? (
                <>
                  <Builder>
                    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                      <Droppable isDropDisabled droppableId="disabled">
                        {provided => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            <RenderChecksheetBuilder
                              builder={builder}
                              setBuilder={saveBuilder}
                              cloneElement={cloneElement}
                              activeDroppableId={activeDroppableId}
                              setTarget={setTarget}
                              setShowModalAdd={setShowModalAdd}
                              setShowModalEdit={setShowModalEdit}
                              setShowModalDelete={setShowModalDelete}
                              setShowModalHelp={setShowModalHelp}
                              setShowModalFormula={handleShowModalFormula}
                              setShowModalElapsed={handleShowModalElapsed}
                              setShowModalConditional={setShowModalConditional}
                              inTemplateBuilder>
                              {provided.placeholder}
                            </RenderChecksheetBuilder>
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {error && <Error>{error}</Error>}
                  </Builder>

                  <ButtonWrapper>
                    <Button
                      type="button"
                      onClick={() => handleEdit(false)}
                      data-testid="templates.submit"
                      loading={loadingTemplatesUpdate ? 1 : 0}>
                      Save Template
                      {loadingTemplatesUpdate && <ButtonLoader />}
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => handleEdit(true)}
                      data-testid="templates.submit"
                      loading={loadingTemplatesCreate ? 1 : 0}>
                      <FontAwesomeIcon icon={faPlus} />
                      &nbsp;New Template
                      {loadingTemplatesCreate && <ButtonLoader />}
                    </Button>
                  </ButtonWrapper>
                </>
              ) : (
                <>
                  <FormGroup>
                    <RenderChecksheet task={{builder}} readOnly hideMeta />
                  </FormGroup>

                  {selected.instances &&
                    Object.keys(selected.instances).length > 0 &&
                    selected.instances[Object.keys(selected.instances)[0]] &&
                    selected.instances[Object.keys(selected.instances)[0]].length > 0 && (
                      <FormGroup>
                        <Button type="button" onClick={() => setViewInstances(true)}>
                          View Associated Components
                        </Button>
                      </FormGroup>
                    )}
                </>
              )}
            </RelativeWrapper>
          ) : (
            <Text>Please select a template to view.</Text>
          )}
        </Preview>
      </TemplateInline>

      {showDelete && (
        <ModalDelete
          visible={showDelete}
          setVisible={setShowDelete}
          confirmDelete={() => handleDelete()}
          title="Delete Component Template"
          loading={loadingTemplates}
        />
      )}

      {showPreview && (
        <ModalPreviewTemplate
          visible={showPreview}
          setVisible={setShowPreview}
          template={buildTemplate()}
        />
      )}

      {viewInstances && (
        <ModalTemplateInstances
          visible={viewInstances}
          setVisible={setViewInstances}
          template={selected}
        />
      )}

      {showModalHelp && target && (
        <ModalHelp visible={showModalHelp} setVisible={setShowModalHelp} target={target} />
      )}

      {showModalEdit && target && (
        <ModalEditElement
          visible={showModalEdit}
          setVisible={setShowModalEdit}
          targetElement={target}
          persistEdit={handleEditField}
          builder={builder}
          editing={false}
          units={units}
        />
      )}

      {showModalAdd && target && (
        <ModalAddField
          visible={showModalAdd}
          setVisible={setShowModalAdd}
          addFields={handleAddMultipleFields}
          parentName={target.name}
          units={units}
        />
      )}

      {showModalRedirectFormula && (
        <ModalRedirect
          visible={showModalRedirectFormula}
          setVisible={setShowModalRedirectFormula}
          buttonOptions={[
            {
              action: () => {
                setFormulaMode("create");
                setShowModalFormula(true);
                setShowModalRedirectFormula(false);
              },
              description: "Create formula based on this field",
              icon: faPlus
            },
            {
              action: () => {
                setFormulaMode("edit");
                setShowModalFormula(true);
                setShowModalRedirectFormula(false);
              },
              description: "Edit existing formula field",
              icon: faEdit
            }
          ]}
        />
      )}

      {showModalFormula && target && (
        <ModalFormula
          visible={showModalFormula}
          setVisible={state => {
            if (!state) setFormulaMode("create");
            setShowModalFormula(state);
          }}
          targetElement={target}
          save={handleFormula}
          builder={builder}
          units={units}
          mode={formulaMode}
        />
      )}

      {showModalElapsed && target && (
        <ModalElapsed
          visible={showModalElapsed}
          setVisible={state => {
            if (!state) setElapsedMode("create");
            setShowModalElapsed(state);
          }}
          mode={elapsedMode}
          targetElement={target}
          builder={builder}
          save={handleFormula}
        />
      )}

      {showModalConditional && target && (
        <ModalConditionals
          visible={showModalConditional && !!target}
          setVisible={state => {
            if (!state) setTarget(null);
            setShowModalConditional(state);
          }}
          targetElement={target}
          save={handleConditional}
          builder={builder}
        />
      )}

      {showModalDelete && target && (
        <ModalDeleteElement
          builder={builder}
          visible={showModalDelete}
          setVisible={setShowModalDelete}
          persistDelete={handleDeleteField}
          targetElement={target}
        />
      )}
    </TemplatePage>
  );
};

// Style Overrides
const Menu = styled(Inline)`
  justify-content: space-between;
  margin-bottom: ${pad}px;
`;

const TemplateTable = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: ${pad}px;
`;

const TemplatePage = styled(Page)`
  margin-bottom: ${pad * 2}px;
`;

const TemplateInline = styled.div`
  ${flex("row", "nowrap", "start", "start")};
  width: 100%;
  gap: ${pad}px;
  max-width: ${breakpoint.width[5]};
`;

const TemplateList = styled(Section)`
  width: 100%;
  padding: ${pad}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
`;

const TemplateListContent = styled.div`
  width: 35%;
  min-width: 160px;
`;

const Preview = styled(StickyWrapper)`
  width: 65%;
  padding: ${pad}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  margin: 0;
`;

const ComponentTemplate = styled(ButtonFull)`
  width: 100%;
  text-transform: uppercase;
  background: transparent;
  margin-bottom: ${pad}px;
  color: ${({theme}) => theme.component};
  border: ${border} solid ${({theme}) => theme.component};
  transition: ${transition};
  font-weight: bold;
  ${voice.normal};

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    fill: ${({theme}) => theme.component};
  }

  &:hover {
    color: ${({theme}) => theme.tertiary};
    background: ${({theme}) => theme.component};

    svg {
      fill: ${({theme}) => theme.tertiary};
    }
  }

  ${({active, theme}) =>
    active &&
    css`
      color: ${theme.tertiary};
      background: ${theme.component};
    `}
`;

const Options = styled(Inline)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: ${pad / 2}px;
`;

const Delete = styled(Button)`
  background: ${({theme}) => theme.error};
`;

const Builder = styled.div`
  position: relative;
  width: 100%;
  padding: ${pad}px 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${pad / 2}px;

  ${Button} {
    width: 50%;
  }
`;

export default ComponentTemplates;
