import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Utils
import useMountedState from "../hooks/useMountedState.js";

// Style
import {border, colors, radius} from "../style/components/variables.js";

const CheckBox = ({
  name = "checkbox",
  testId = "checkbox",
  initial = false,
  disabled = false,
  handleCheck,
  triggerCheck
}) => {
  const isMounted = useMountedState();

  const [checked, setChecked] = useState(initial);

  useEffect(() => {
    if (isMounted() && !disabled && triggerCheck !== null && triggerCheck !== undefined) {
      setChecked(triggerCheck);
    }
  }, [isMounted, disabled, initial, triggerCheck]);

  return (
    <CheckWrapper data-testid={testId} disabled={disabled}>
      <CheckInput
        type="checkbox"
        name={name}
        data-testid={`${testId}-input`}
        checked={checked}
        onChange={() => {
          handleCheck(!checked);
          setChecked(prev => !prev);
        }}
        disabled={disabled}
      />
    </CheckWrapper>
  );
};

CheckBox.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string,
  initial: PropTypes.bool,
  disabled: PropTypes.bool,
  handleCheck: PropTypes.func.isRequired,
  triggerCheck: PropTypes.bool
};

// Style Overrides
const CheckWrapper = styled.div`
  position: relative;
`;

const CheckInput = styled.input`
  appearance: none;
  position: relative;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  background: ${props => props.theme.tertiary};

  &:hover {
    cursor: pointer;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 4px;
    width: 37%;
    height: 85%;
    border: ${border} solid ${props => props.theme.secondary};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: all ease 0.1s;
    opacity: 0;
  }

  &:checked:before {
    opacity: 1;
  }

  ${props =>
    props.disabled &&
    css`
      background-color: ${colors.grayLight};
      &:hover {
        cursor: not-allowed;
      }
    `}
`;

export default CheckBox;
