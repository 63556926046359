import React from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import styled from "styled-components";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Components
import {InputTextArea} from "../../components/form/FormInputs.js";
import Modal from "../../components/Modal.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Form, FormField, Inline, Button, HeadingCenter} from "../../style/components/general.js";

const ModalRecordVersionNote = ({visible, setVisible, submitNote, cancel}) => {
  const schema = yup.object().shape({
    note: yup.string().nullable().required()
  });

  const form = useForm({
    defaultValues: {
      note: ""
    },
    resolver: yupResolver(schema)
  });

  const {handleSubmit} = form;

  const submit = ({note}) => submitNote(note);

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalTitle>Reason for Update</ModalTitle>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(submit)} noValidate>
          <FormField>
            <InputTextArea
              name="note"
              label="Explanation"
              testId="addNote.message"
              maxLength={1000}
            />
          </FormField>

          <ButtonWrapper>
            <Button type="submit">Save</Button>
            <Button type="button" onClick={() => cancel()}>
              Cancel
            </Button>
          </ButtonWrapper>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalRecordVersionNote.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  submitNote: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin-bottom: ${pad * 2}px;
`;

const ButtonWrapper = styled(Inline)`
  gap: ${pad}px;
  margin-top: ${pad}px;
`;

export default ModalRecordVersionNote;
