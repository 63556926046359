import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Style
import {voice} from "../../style/components/typography";
import {colors, heroTheme, pad, radius, shadow} from "../../style/components/variables";

const ChartTooltip = ({
  chartId,
  top,
  left,
  right,
  color = heroTheme.black,
  label,
  missing = false,
  x = "",
  y = "",
  qualifier = "",
  units = ""
}) => {
  const getDisplay = () => {
    if (missing) return "No Data";
    if (qualifier) {
      if (qualifier === "ND") return `< ${y} ${units} ${qualifier}`;
      if (qualifier === "DNQ" || qualifier === "EST") return `${y} ${units} ${qualifier}`;
      return `${qualifier} ${y}${units ? ` ${units}` : ""}`;
    }
    return `${y}${units ? ` ${units}` : ""}`;
  };

  // only render if has content
  return (
    (missing || label || x || y) && (
      <Tooltip id={`tooltip-${chartId}`} top={top} left={left} right={right}>
        <>
          <TooltipLabel color={color}>{label}</TooltipLabel>
          <XValue>{x}</XValue>
          <YValue>{getDisplay()}</YValue>
        </>
      </Tooltip>
    )
  );
};

ChartTooltip.propTypes = {
  chartId: PropTypes.string, // TODO: does this need to be requried
  top: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  missing: PropTypes.bool,
  units: PropTypes.string,
  x: PropTypes.string,
  y: PropTypes.string,
  qualifier: PropTypes.string
};

// Style Overrides
const Tooltip = styled.div`
  display: ${({top, left, right}) => (!top && !left && !right ? "none" : "block")};
  top: ${({top}) => top ?? "auto"};
  right: ${({right}) => right ?? "auto"};
  left: ${({left}) => left ?? "auto"};
  position: absolute;
  background-color: ${colors.heroWhite};
  padding: ${pad / 2}px;
  border-radius: ${radius};
  box-shadow: ${shadow};
`;

const TooltipLabel = styled.div`
  ${voice.quiet};
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  color: ${({color, theme}) => color || theme.secondary};
`;

const XValue = styled.div`
  ${voice.quiet};
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  margin-top: ${pad / 2}px;
`;

const YValue = styled.div`
  ${voice.quiet};
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  margin-top: ${pad / 2}px;
`;

export default ChartTooltip;
