import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Components
import ChartPi from "../../components/ChartPi";

// Style
import {pad, radius} from "../../style/components/variables";
import {List, ListItem, Loader, Text} from "../../style/components/general";

const RecordResponsibility = ({record, versions, currentVersion}) => {
  const [slices, setSlices] = useState();

  const calculatePercentage = (total, completed) => Math.round((completed / total) * 100);

  useEffect(() => {
    if (record && versions) {
      const draftVersions = versions.filter(({draft}) => draft);

      const modVersions = versions.filter(({draft, overdue}) => !draft && !overdue);

      const draftsWithModification = draftVersions.filter(({modification}) => !!modification);

      const oldestVersion = draftsWithModification[draftsWithModification.length - 1];

      const artificialVersion = oldestVersion ? {...oldestVersion} : null;

      if (artificialVersion)
        artificialVersion.modification = Object.fromEntries(
          Object.entries(artificialVersion.submission.responses)
            .filter(([k, v]) => v && !(k in artificialVersion.modification))
            .map(([k]) => [k, ""])
        );

      const {user: submittingUser} =
        modVersions.length > 0 ? modVersions[modVersions.length - 1] : record;

      const users = {};

      const responses = Object.keys(record.submission.responses).filter(
        fieldKey => !!record.submission.responses[fieldKey]
      );
      const recordLength = responses.length;

      responses.map(fieldKey => {
        let user = submittingUser;
        let found = false;
        for (let i = 0; i < draftVersions.length; i++) {
          const version = draftVersions[i];

          if (version.modification && fieldKey in version.modification) {
            const key = `${user.firstName} ${user.lastName}`;
            users[key] = users[key] ? users[key] + 1 : 1;
            found = true;
            break;
          }

          user = version.user;
        }

        if (!found) {
          if (artificialVersion && fieldKey in artificialVersion.modification) {
            const key = `${artificialVersion.user.firstName} ${artificialVersion.user.lastName}`;
            users[key] = users[key] ? users[key] + 1 : 1;
          } else {
            const key = `${user.firstName} ${user.lastName}`;
            users[key] = users[key] ? users[key] + 1 : 1;
          }
        }
      });

      setSlices([
        ...Object.keys(users).map(k => {
          const percentage = calculatePercentage(recordLength, users[k]);
          return {
            user: k,
            label: `${percentage}%`,
            value: percentage
          };
        })
      ]);
    }
  }, [record, versions]);

  const activeIdx = useMemo(() => {
    let targetIdx;
    if (!currentVersion?.submission) return undefined;
    slices?.map(({user}, idx) => {
      if (
        currentVersion?.user &&
        user === `${currentVersion.user.firstName} ${currentVersion.user.lastName}`
      )
        targetIdx = idx;
    });
    return targetIdx;
  }, [currentVersion, slices]);

  return (
    <ResponsibilityWrapper>
      <Text>Data Input</Text>
      <hr />
      <Content>
        <List column>
          {slices?.map(({user, label}) => (
            <ListItem key={user}>
              {user} ({label})
            </ListItem>
          ))}
        </List>
        <ChartWrapper>
          {slices ? <ChartPi radius={75} data={slices} activeIdx={activeIdx} /> : <Loader />}
        </ChartWrapper>
      </Content>
    </ResponsibilityWrapper>
  );
};

RecordResponsibility.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  versions: PropTypes.arrayOf(PropTypes.any),
  currentVersion: PropTypes.objectOf(PropTypes.any)
};

// Style Overrides
const ResponsibilityWrapper = styled.div`
  border: 2px solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  margin-bottom: ${pad}px;

  @media print {
    border: none;
  }
`;

const Content = styled.div`
  display: flex;

  ${List} {
    width: 50%;
  }
`;

const ChartWrapper = styled.div`
  position: relative;
  display: flex;
  align-self: end;
`;

export default RecordResponsibility;
