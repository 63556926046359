import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClone, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";

// Components
import Modal from "../../components/Modal.js";

// Style
import {pad} from "../../style/components/variables.js";
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {breakpoint} from "../../style/components/breakpoints.js";
import {
  Abbr,
  Button,
  HeadingCenter,
  Inline,
  Label,
  Small,
  Text
} from "../../style/components/general.js";

const SectionOptions = ({visible, setVisible, handleEdit, handleDelete, handleClone, title}) => (
  <Modal visible={visible} setVisible={setVisible} maxWidth={breakpoint.width[1]}>
    <StyledHeading>Section Options</StyledHeading>
    <StyledLabel>
      <strong>Selected: </strong>
      <Abbr title={title}>{title && title.length > 60 ? `${title.slice(0, 60)}...` : title}</Abbr>
    </StyledLabel>
    <Options>
      <Option
        onClick={() => {
          handleEdit();
        }}>
        <OptionLabel>
          <FontAwesomeIcon icon={faEdit} />
          <OptionSmallText>Edit section</OptionSmallText>
        </OptionLabel>
      </Option>
      <Option
        onClick={() => {
          handleClone();
        }}>
        <OptionLabel>
          <FontAwesomeIcon icon={faClone} />
          <OptionSmallText>Clone section</OptionSmallText>
        </OptionLabel>
      </Option>
      <Option
        onClick={() => {
          handleDelete();
        }}>
        <OptionLabel>
          <FontAwesomeIcon icon={faTrash} />
          <OptionSmallText>Delete section</OptionSmallText>
        </OptionLabel>
      </Option>
    </Options>
  </Modal>
);

SectionOptions.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleClone: PropTypes.func.isRequired
};

// Style Overrides
const Option = styled(Button)`
  width: 50%;
  border-width: 0;
  height: 100px;
`;

const OptionLabel = styled(Text)`
  ${flex("column", "wrap", "center", "center")};
  ${voice.medium};
  font-weight: bold;
  text-align: center;
  min-width: 100%;
  text-transform: uppercase;
  background-color: ${props => props.theme.secondary};
  color: ${props => props.theme.tertiary};
  padding: ${pad}px;

  svg {
    fill: ${props => props.theme.tertiary};
  }
`;

const OptionSmallText = styled(Small)`
  margin-top: ${pad}px;
`;

const Options = styled(Inline)`
  gap: ${pad}px;
  margin-top: ${pad}px;
  justify-content: stretch;
`;

const StyledHeading = styled(HeadingCenter)`
  ${voice.medium}
`;

const StyledLabel = styled(Label)`
  word-wrap: break-word;
`;

export default SectionOptions;
