import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import {exists} from "../../utils/helpers.js";
import {defaultUnacceptableParameterPrompt} from "../../utils/builder.js";
import {formatFormulaToSave, scrollTo, validateFormula} from "./helpers.js";

// Components
import Modal from "../../components/Modal.js";
import FormulaEditor from "./FormulaEditor.js";

// Style
import {Button, Form, HeadingCenter} from "../../style/components/general.js";

const ModalFormula = ({
  visible,
  setVisible,
  targetElement,
  save,
  builder,
  units,
  mode,
  hasBackButton,
  goBack,
  hasPrimaryAddress
}) => {
  const defaultValues =
    mode === "edit"
      ? {
          label: targetElement.label,
          tag: targetElement.tag,
          units: targetElement.units,
          hasPrecision: exists(targetElement.precision),
          precision: targetElement.precision,
          hasARange: targetElement.hasARange,
          aMin: targetElement.aMin,
          aMax: targetElement.aMax,
          strictMin: targetElement.strictMin,
          strictMax: targetElement.strictMax,
          rangeException: targetElement.rangeException,
          hasRange: targetElement.hasRange,
          min: targetElement.min,
          max: targetElement.max,
          restrictedRangeException: targetElement.restrictedRangeException,
          hasSetPoint: targetElement.hasSetPoint,
          setLow: targetElement.setLow,
          setHigh: targetElement.setHigh,
          absoluteValue: targetElement.absoluteValue,
          formula: targetElement.formula,
          hasQualifier: targetElement.hasQualifier,
          hidden: targetElement.hidden,
          hasDivideByZeroDefault: targetElement.hasDivideByZeroDefault,
          divideByZeroDefault: targetElement.divideByZeroDefault,
          addMode: "Select Field"
        }
      : {
          label: "",
          tag: "",
          units: "",
          hasPrecision: false,
          precision: null,
          hasARange: false,
          aMin: null,
          aMax: null,
          strictMin: false,
          strictMax: false,
          rangeException: defaultUnacceptableParameterPrompt,
          hasRange: false,
          min: null,
          max: null,
          restrictedRangeException: "",
          hasSetPoint: false,
          setLow: null,
          setHigh: null,
          absoluteValue: false,
          hasQualifier: false,
          hidden: false,
          hasDivideByZeroDefault: false,
          divideByZeroDefault: null,
          formula: [
            {
              value: targetElement.name,
              type: "variable",
              previous: false
            }
          ],
          addMode: "Select Field"
        };

  const schema = yup.object().shape({
    label: yup.string().required("Required"),
    tag: yup.string().nullable(),
    units: yup.string().required("Required"),
    formula: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().nullable(),
          value: yup.string().required(),
          type: yup.string().required(),
          previous: yup.bool().nullable()
        })
      )
      .test({
        message: "Cannot submit: invalid formula",
        test: value => validateFormula(value, builder, targetElement)
      }),
    hasPrecision: yup.boolean(),
    precision: yup
      .mixed()
      .nullable()
      .when("hasPrecision", {
        is: val => !!val,
        then: () =>
          yup
            .number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable()
            .typeError("Precision must be a number.")
            .min(0, "Precision must be a value between 0 and 8.")
            .max(8, "Precision must be a value between 0 and 8.")
            .required("Please provide precision")
      }),
    hasARange: yup.boolean(),
    aMin: yup
      .mixed()
      .nullable()
      .when("hasARange", {
        is: val => !!val,
        then: () =>
          yup
            .number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable()
            .typeError("Min must be a number.")
            .test({
              message: "Either min or max must be provided.",
              test: (aMin, ctx) => exists(aMin) || exists(ctx.parent.aMax)
            })
      }),
    aMax: yup
      .mixed()
      .nullable()
      .when("hasARange", {
        is: val => !!val,
        then: () =>
          yup
            .number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable()
            .typeError("Max must be a number.")
            .test({
              message: "Invalid range, please make sure that max value is greater than the min.",
              test: (aMax, ctx2) =>
                exists(aMax) && exists(ctx2.parent.aMin) ? aMax > ctx2.parent.aMin : true
            })
      }),
    rangeException: yup.string(),
    hasRange: yup.boolean(),
    min: yup
      .mixed()
      .nullable()
      .when("hasRange", {
        is: val => !!val,
        then: () =>
          yup
            .number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable()
            .typeError("Min must be a number.")
            .test({
              message: "Either min or max must be provided.",
              test: (min, ctx) => exists(min) || exists(ctx.parent.max)
            })
      }),
    max: yup
      .mixed()
      .nullable()
      .when("hasRange", {
        is: val => !!val,
        then: () =>
          yup
            .number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable()
            .typeError("Max must be a number.")
            .test({
              message: "Invalid range, please make sure that max value is greater than the min.",
              test: (max, ctx2) =>
                exists(max) && exists(ctx2.parent.min) ? max > ctx2.parent.min : true
            })
      }),
    restrictedRangeException: yup.string(),
    hasSetPoint: yup.boolean(),
    setLow: yup
      .string()
      .nullable()
      .when("hasSetPoint", {
        is: val => !!val,
        then: () => yup.number().typeError("Set point min must be a number.").required()
      }),
    setHigh: yup
      .string()
      .nullable()
      .when(["hasSetPoint", "setLow"], {
        is: (hasRange, setLow) => hasRange && !Number.isNaN(setLow),
        then: () =>
          yup
            .number()
            .typeError("Set point max must be a number.")
            .test({
              message:
                "Invalid range, please make sure that max value is greater than the set point min.",
              test: (setHigh, ctx2) => setHigh > ctx2.parent.setLow
            })
      }),
    absoluteValue: yup.boolean(),
    hasQualifier: yup.boolean(),
    hidden: yup.boolean(),
    hasDivideByZeroDefault: yup.boolean(),
    divideByZeroDefault: yup
      .string()
      .nullable()
      .when("hasDivideByZeroDefault", {
        is: val => !!val,
        then: () =>
          yup.number().typeError("Default must be a number.").required("Default is required.")
      })
  });

  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit,
    reset,
    formState: {errors}
  } = form;

  const submission = values => {
    const formatted = formatFormulaToSave(builder, mode, targetElement, values);
    const updatedBuilder = builder;
    save(formatted, updatedBuilder);
    reset(defaultValues);
    if (hasBackButton && goBack) goBack();
    else setVisible(false);
  };

  // scrolls to first erroring formula field, if no other fields are before it in the form
  useEffect(() => {
    const elements = Object.keys(errors)
      .filter(n => !!n)
      .map(n => [n, document.getElementById(n)]);
    elements.sort((a, b) =>
      a[1] && b[1] ? a[1].getBoundingClientRect().y - b[1].getBoundingClientRect().y : 0
    );
    if (elements.length > 0 && elements[0][0].includes("formula") && elements[0][1])
      scrollTo(elements[0][1], -150, document.getElementById("modal") ?? window, "auto");
  }, [errors]);

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <HeadingCenter>{mode === "edit" ? "Edit" : "Create"} Formula</HeadingCenter>
      <FormProvider {...form}>
        <Form noValidate onSubmit={handleSubmit(submission)}>
          <FormulaEditor
            targetElement={targetElement}
            builder={builder}
            units={units}
            mode={mode}
            hasPrimaryAddress={hasPrimaryAddress}
          />
          <Button type="submit" data-testid="formula.save">
            Save
          </Button>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalFormula.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  targetElement: PropTypes.objectOf(PropTypes.any).isRequired,
  save: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  units: PropTypes.arrayOf(PropTypes.string),
  mode: PropTypes.string,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func,
  hasPrimaryAddress: PropTypes.bool
};

ModalFormula.defaultProps = {
  units: [],
  mode: "create",
  hasBackButton: false,
  goBack: () => {},
  hasPrimaryAddress: false
};

export default ModalFormula;
