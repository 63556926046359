import React, {Fragment, useContext, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Style
import {border, pad, radius} from "../../style/components/variables";
import {Button, Label, Small, Text} from "../../style/components/general";
import {AuthContext} from "../../contexts/auth";
import voice from "../../style/components/typography";
import {getMapFromCoords, openLink} from "../../utils/helpers";
import useApi from "../../hooks/useApi";

const RecordNotes = React.forwardRef(({checksheet, record, updateData}, ref) => {
  const {byId} = useMemo(() => checksheet?.builder || {}, [checksheet]);

  const {currentUser, atLeast} = useContext(AuthContext);

  const {api: apiFiles} = useApi("files");
  const {api: apiNotes} = useApi("record-notes");

  const openFile = fileId =>
    apiFiles.callGet(fileId).then(({status, data}) => status === 200 && openLink(data.link));

  const handleResolve = (noteId, group) => {
    apiNotes
      .callPut(noteId, null, {
        params: {userId: currentUser.publicId, status: "resolved"},
        headers: {"Content-Type": "multipart/form-data"}
      })
      .then(({status}) => {
        if (status === 200) {
          if (record.postedNotes[group]) {
            const temp = record.postedNotes[group].filter(note => note.id === noteId);
            record.postedNotes[group] = record.postedNotes[group].filter(
              note => note.id !== noteId
            );
            if (record.postedNotes[group].length === 0) delete record.postedNotes[group];
            if (temp && temp.length > 0) {
              if (group in record.resolvedNotes) record.resolvedNotes[group].push(temp[0]);
              else record.resolvedNotes[group] = [temp[0]];
            }
          }

          updateData();
        }
      });
  };

  const buildNotesDisplay = (notes, canResolve) => {
    const groups = Object.keys(notes).filter(group => checksheet.builder.allIds.includes(group));
    groups.sort(
      (a, b) => checksheet.builder.allIds.indexOf(a) - checksheet.builder.allIds.indexOf(b)
    );

    return groups.map(group => (
      <Fragment key={group}>
        <Label bold>{byId[group].label}</Label>
        {notes[group].map(note => (
          <NoteWrapper
            key={`${byId[group].name}_note_${note.id}`}
            data-testid="preview.noteWrapper">
            <Message>{note.message}</Message>
            {note.coords && (
              <AddressLink
                href={getMapFromCoords(note.coords.lat, note.coords.lon)}
                target="_blank"
                rel="noreferrer">
                View in Maps
              </AddressLink>
            )}
            {note.files && note.files.length > 0 && (
              <Files>
                <hr />
                <p>Attached Files</p>
                {note.files.map(file => (
                  <FileLink onClick={() => openFile(file.id)} key={`file-${file.id}`}>
                    {file.label}
                  </FileLink>
                ))}
              </Files>
            )}
            {note.user && <Small secondary>By: {note.user}</Small>}

            {canResolve && (
              <ResolveButton
                data-testid="resolveButton"
                onClick={() => handleResolve(note.id, group)}>
                Resolve
              </ResolveButton>
            )}
          </NoteWrapper>
        ))}
      </Fragment>
    ));
  };

  return (
    <NotesWrapper ref={ref}>
      <NoteSection>
        <Text>Posted Notes</Text>
        <hr />
        {record.postedNotes && Object.keys(record.postedNotes).length > 0 ? (
          buildNotesDisplay(record.postedNotes, true)
        ) : (
          <Small>No Posted Notes</Small>
        )}
      </NoteSection>
      <NoteSection>
        <Small>Resolved Notes</Small>
        <hr />
        {record.resolvedNotes && Object.keys(record.resolvedNotes).length > 0 ? (
          buildNotesDisplay(record.resolvedNotes, false)
        ) : (
          <Small>No Resolved Notes</Small>
        )}
      </NoteSection>
      {atLeast("admin") && record.archivedNotes && record.archivedNotes.length > 0 && (
        <NoteSection>
          <Text>Checksheet Archive Note</Text>
          <hr />
          {record.archivedNotes.map(note => (
            <NoteWrapper
              key={`archive_note_${note.id}`}
              data-testid="preview.resolvedWrapper"
              resolved>
              {note.name.includes("error") && <Label bold>{note.name}</Label>}
              <Message>
                {note.name === "Hero was not implemented at this time" ? note.name : note.message}
              </Message>
              {note.files && note.files.length > 0 && (
                <Files>
                  <hr />
                  <p>Attached Files</p>
                  {note.files.map(file => (
                    <FileLink onClick={() => openFile(file.id)} key={`file-${file.id}`}>
                      {file.label}
                    </FileLink>
                  ))}
                </Files>
              )}
              {note.user && (
                <>
                  <hr />
                  <Small secondary>By: {note.user}</Small>
                </>
              )}
            </NoteWrapper>
          ))}
        </NoteSection>
      )}
    </NotesWrapper>
  );
});

RecordNotes.displayName = "RecordNotes";

RecordNotes.propTypes = {
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateData: PropTypes.func
};

// Style Overrides
const NotesWrapper = styled.div`
  border: 2px solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  margin-bottom: ${pad}px;

  @media print {
    border: none;
  }
`;

const NoteWrapper = styled.div`
  position: relative;
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  margin-bottom: ${pad}px;
  width: 100%;
  flex: 1 auto;

  @media print {
    display: block;
    page-break-after: auto;
    page-break-inside: avoid;
  }

  &:last-child {
    margin: 0;
  }
`;

const NoteSection = styled.div`
  margin-bottom: ${pad * 2}px;

  &:last-child {
    margin: 0;
  }
`;

const Message = styled(Text)`
  margin-bottom: ${pad}px;
`;

const Files = styled.div`
  ${voice.quiet};
  text-align: left;
  color: ${({theme}) => theme.secondary};
  margin-bottom: ${pad / 2}px;

  p {
    font-weight: bold;
  }

  a {
    color: ${({theme}) => theme.primary};

    :hover {
      text-decoration: underline;
    }
  }
`;

const FileLink = styled(Text)`
  ${voice.quiet};
  font-weight: bold;
  color: ${({theme}) => theme.primary};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const AddressLink = styled.a`
  color: ${({theme}) => theme.primary};

  p {
    color: ${({theme}) => theme.primary};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ResolveButton = styled(Button)`
  position: absolute;
  right: ${pad}px;
  margin: 0;
  bottom: 8px;
  ${voice.quiet};

  @media print {
    display: none;
  }
`;

export default RecordNotes;
