import React, {useState} from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import styled from "styled-components";

// Contexts
import {useToast} from "../contexts/toast.js";

// Utils
import {getLocation} from "../utils/helpers.js";

// Components
import Address from "./Address.js";
import InputNumber from "./form/InputNumber.js";

// Style
import {pad} from "../style/components/variables.js";
import {FormField, Button, Inline} from "../style/components/general.js";

const COORDS = "Coordinates";
const ADDRSS = "Address";

const DEFAULT_ADDRESS = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  zipCode: ""
};

const DEFAULT_COORDS = {
  lat: "",
  lon: ""
};

const Location = ({targetElement}) => {
  const {addToast} = useToast();

  const {setValue} = useFormContext({
    defaultValues: targetElement
      ? {...targetElement.address}
      : {...DEFAULT_ADDRESS, ...DEFAULT_COORDS}
  });

  const [toggle, setToggle] = useState(COORDS);

  const useMyLocation = async () => {
    const {error, lat, lon} = await getLocation();
    if (error) addToast(error, "error");
    setValue("address.lat", lat);
    setValue("address.lon", lon);
  };

  return (
    <>
      <Inline>
        <MenuOption
          data-testid="toggleAddress"
          type="button"
          onClick={() => {
            setToggle(prev => (prev === COORDS ? ADDRSS : COORDS));
            setValue("address", {...DEFAULT_ADDRESS, ...DEFAULT_COORDS});
          }}>
          Use {toggle === COORDS ? "Address" : "Coordinates"}
        </MenuOption>
        {toggle === "Coordinates" && (
          <MenuOption data-testid="useLocation" type="button" onClick={useMyLocation}>
            Fill Using My Location
          </MenuOption>
        )}
      </Inline>

      {toggle === "Coordinates" ? (
        <>
          <FormField>
            <InputNumber
              testId="lat"
              name="address.lat"
              label="Latitude"
              overrideWidth="225px"
              autocomplete={false}
            />
          </FormField>
          <FormField>
            <InputNumber
              testId="lon"
              name="address.lon"
              label="Longitude"
              overrideWidth="225px"
              autocomplete={false}
            />
          </FormField>
        </>
      ) : (
        <Address />
      )}
    </>
  );
};

Location.propTypes = {
  targetElement: PropTypes.objectOf(PropTypes.any)
};

// Style Overrides
const MenuOption = styled(Button)`
  margin-right: ${pad}px;
`;

export default Location;
