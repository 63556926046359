import React, {Fragment, useContext} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {Draggable} from "react-beautiful-dnd";
import {faEdit, faTrash, faLock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Utils
import {COMPONENT, GROUP} from "../../utils/builder.js";
import {generateUniqueKey, getMapFromCoords} from "../../utils/helpers.js";
import {DEFAULT_MARKER} from "../../utils/google/maps.js";

// Components
import ElementOptions from "../../components/ElementOptions.js";
import AddElement from "./AddElement.js";

// Style
import {flex, z} from "../../style/components/mixins.js";
import {pad, radius, status} from "../../style/components/variables.js";
import {Abbr, Button, Inline, Label, Small, Text, Warning} from "../../style/components/general.js";

const RenderFacilityBuilder = ({
  builder,
  editElement,
  removeElement,
  setElementLocation,
  handleAddGroup,
  handleAddComponent,
  handleBulk,
  activeDroppableId = "",
  inFacilityHistory = false,
  modifications,
  children,
  querying = false,
  markerIconMap,
  activeMarker,
  setActiveMarker,
  active,
  locks,
  readOnly = false,
  groupsOnly = false,
  view,
  setView
}) => {
  const {atLeast, currentUser, roleCanAccessResource} = useContext(AuthContext);

  const renderOptions = element => {
    if (active && locks && locks[element.name]) {
      const {user} = locks[element.name];
      const {users} = active;
      if (user !== currentUser.publicId) {
        const match = users.filter(curr => curr.publicId === user)[0];
        if (match)
          return (
            <Lock>
              <FontAwesomeIcon icon={faLock} />
              &nbsp;Locked by {match.name}
            </Lock>
          );
      }
    }

    return (
      <Options
        className="renderOptions"
        data-testid={element.element === GROUP ? "group.options" : "component.options"}
        element={element.element}>
        {roleCanAccessResource("facility", "update") && (
          <>
            <Option
              data-testid="element.editButton"
              type="button"
              onClick={() => editElement(element)}>
              <abbr title="Edit">
                <FontAwesomeIcon icon={faEdit} />
              </abbr>
            </Option>
            <Option
              type="button"
              onClick={() => removeElement(element)}
              data-testid="element.deleteButton">
              <abbr title="Delete">
                <FontAwesomeIcon icon={faTrash} />
              </abbr>
            </Option>
          </>
        )}
      </Options>
    );
  };

  const numOptions = item => {
    if (roleCanAccessResource("facility", "create")) return 2;
    // "Set Location" Button is about the size of 2.5 standard option buttons
    return !item.hasAddress && item.parentName === "" ? 2.5 : 0;
  };

  const modificationHasAttr = (id, ...attrs) =>
    attrs.some(
      attr =>
        modifications &&
        modifications[id] &&
        typeof modifications[id] !== "string" &&
        modifications[id].operation !== "removed" &&
        attr in modifications[id] &&
        modifications[id][attr] !== "added"
    );

  const combineAllIds = () => {
    if (!modifications) return builder.allIds;
    return [
      ...Object.keys(modifications).filter(
        key => modifications[key].operation === "removed" && !modifications[key].parentName
      ),
      ...builder.allIds
    ];
  };

  const combineChildren = id => {
    const itemChildren = builder.byId[id]?.children || [];
    if (!modifications) return itemChildren;
    return [
      ...Object.keys(modifications).filter(
        key => modifications[key].operation === "removed" && modifications[key].parentName === id
      ),
      ...itemChildren
    ];
  };

  const renderElements = (ids, level) =>
    ids
      .filter(id => {
        if (groupsOnly) {
          const item = builder.byId[id];
          if (item?.hasAddress || item?.hasHelp) return true;
          return false;
        }
        return true;
      })
      .map((id, index) => {
        let item = builder.byId[id];
        let removedItem = false;
        let modification = modifications ? modifications[id] : undefined;

        if (!item && modifications && modifications[id]?.operation === "removed") {
          item = modifications[id];
          removedItem = true;
          modification = "removed";
        }

        let newLevel;
        if (level === "top") newLevel = "group";
        if (level === "group") newLevel = "component";
        return (
          item && (
            <Draggable
              draggableId={id}
              index={index}
              key={id}
              isDragDisabled={
                !atLeast("creator") ||
                !roleCanAccessResource("facility", "update") ||
                querying ||
                readOnly
              }>
              {provided => (
                <Wrapper
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  key={id}
                  readOnly={readOnly ? 1 : 0}
                  inFacilityHistory={inFacilityHistory ? 1 : 0}
                  data-testid="builder.field">
                  <ElementOptions
                    builder={builder}
                    element={item}
                    activeDroppableId={activeDroppableId}
                    handleAddComponent={handleAddComponent}
                    renderOptions={readOnly ? () => {} : renderOptions}
                    setElementLocation={setElementLocation}
                    numOptions={numOptions(item)}
                    readOnly={readOnly}
                    inHistory={inFacilityHistory}
                    inFacilityBuilder
                    modification={modification}>
                    {(modificationHasAttr(id, "globalPrompt") ||
                      (inFacilityHistory && item.hasGlobalPrompt && item.globalPrompt)) && (
                      <Label bold>Global Prompt</Label>
                    )}

                    {modificationHasAttr(id, "globalPrompt") &&
                      modifications[id].hasGlobalPrompt !== false &&
                      modifications[id].globalPrompt && (
                        <Detail isOld>
                          <DetailLabel bold={!inFacilityHistory}>
                            {modifications[id].globalPrompt}
                          </DetailLabel>
                        </Detail>
                      )}

                    {readOnly && item.hasGlobalPrompt && item.globalPrompt && (
                      <Detail
                        isNew={
                          !removedItem &&
                          !!modifications &&
                          !!modifications[id] &&
                          (!!modifications[id].globalPrompt ||
                            modifications[id].hasGlobalPrompt === false)
                        }>
                        <DetailLabel bold={!inFacilityHistory}>{item.globalPrompt}</DetailLabel>
                      </Detail>
                    )}

                    {((item.hasAddress && item.address && Object.keys(item.address).length > 0) ||
                      (item.hasHelp &&
                        item?.help?.filter(
                          helpItem => !helpItem.id && helpItem.key && helpItem.value
                        )?.length > 0) ||
                      item.internalIds?.length > 0 ||
                      modificationHasAttr(id, "help") ||
                      modificationHasAttr(id, "internalIds") ||
                      modificationHasAttr(id, "address")) && (
                      <>
                        {(modificationHasAttr(id, "help") ||
                          (inFacilityHistory &&
                            item?.help?.filter(
                              helpItem => !helpItem.id && helpItem.key && helpItem.value
                            )?.length > 0)) && <Label bold>Help</Label>}

                        {modificationHasAttr(id, "help") &&
                          modifications[id].hasHelp !== false &&
                          modifications[id].help
                            ?.filter(helpItem => !helpItem.id && helpItem.key && helpItem.value)
                            ?.map(({key, value}) => (
                              <Detail key={`${id}-help-old-${generateUniqueKey(key)}`} isOld>
                                <DetailLabel>
                                  <Abbr title={key}>{key}</Abbr>
                                </DetailLabel>
                                <StyledNotes>
                                  <StyledNoteText bold={!inFacilityHistory}>{value}</StyledNoteText>
                                </StyledNotes>
                              </Detail>
                            ))}

                        {readOnly &&
                          item?.hasHelp &&
                          item?.help
                            ?.filter(helpItem => !helpItem.id)
                            ?.map(({key, value}) => (
                              <Detail
                                key={`${id}-help-${generateUniqueKey(key)}`}
                                isNew={
                                  !removedItem &&
                                  !!modifications &&
                                  !!modifications[id] &&
                                  (!!modifications[id].help || modifications[id].hasHelp === false)
                                }>
                                <DetailLabel>
                                  <Abbr title={key}>{key}</Abbr>
                                </DetailLabel>
                                <StyledNotes>
                                  <StyledNoteText bold={!inFacilityHistory}>{value}</StyledNoteText>
                                </StyledNotes>
                              </Detail>
                            ))}

                        {(modificationHasAttr(id, "internalIds") ||
                          (inFacilityHistory &&
                            item?.internalIds?.filter(({key, value}) => key && value)?.length >
                              0)) && <Label bold>Internal IDs</Label>}

                        {modificationHasAttr(id, "internalIds") &&
                          modifications[id].internalIds?.map(
                            ({key, value}) =>
                              key &&
                              value && (
                                <Detail key={`${id}-id-old-${generateUniqueKey(key)}`} isOld>
                                  <DetailLabel>
                                    <Abbr title={key}>{key}</Abbr>
                                  </DetailLabel>
                                  <StyledNotes>
                                    <StyledNoteText bold={!inFacilityHistory}>
                                      {value}
                                    </StyledNoteText>
                                  </StyledNotes>
                                </Detail>
                              )
                          )}

                        {item?.internalIds?.map(
                          ({key, value}) =>
                            key &&
                            value && (
                              <Detail
                                key={`${id}-id-${generateUniqueKey(key)}`}
                                isNew={
                                  !removedItem &&
                                  !!modifications &&
                                  !!modifications[id] &&
                                  !!modifications[id].internalIds
                                }>
                                <DetailLabel>
                                  <Abbr title={key?.toUpperCase()}>{key?.toUpperCase()}</Abbr>
                                </DetailLabel>
                                <StyledNotes>
                                  <StyledNoteText bold={!inFacilityHistory}>{value}</StyledNoteText>
                                </StyledNotes>
                              </Detail>
                            )
                        )}

                        {!inFacilityHistory && item?.address && (
                          <View
                            type="button"
                            onClick={() => {
                              setActiveMarker(id);
                              if (view && setView) setView("map");
                            }}
                            active={activeMarker === id}
                            plain>
                            View on Map
                            <Icon
                              icon={
                                markerIconMap && markerIconMap[item.markerId]?.icon
                                  ? markerIconMap && markerIconMap[item.markerId]?.icon
                                  : DEFAULT_MARKER.icon
                              }
                              color={`#${
                                markerIconMap && markerIconMap[item.markerId]?.color
                                  ? markerIconMap && markerIconMap[item.markerId]?.color
                                  : DEFAULT_MARKER.color
                              }`}
                            />
                          </View>
                        )}

                        {!inFacilityHistory && readOnly && item?.address && (
                          <AddressLink
                            href={getMapFromCoords(item.address.lat, item.address.lon)}
                            target="_blank"
                            rel="noreferrer">
                            Get Directions
                          </AddressLink>
                        )}

                        {(modificationHasAttr(id, "address") ||
                          (inFacilityHistory && item?.address)) && <Label bold>Address</Label>}

                        {modificationHasAttr(id, "address") &&
                          modifications[id].hasAddress !== false &&
                          modifications[id].address && (
                            <Detail isOld noFlex>
                              {modifications[id].address.primary && (
                                <Label data-testid="edit.location" bold>
                                  <span>Primary</span>
                                </Label>
                              )}
                              {modifications[id].address.line1 && (
                                <Text>
                                  <span>{modifications[id].address.line1}</span>
                                  <br />
                                  {modifications[id].address.line2 &&
                                    modifications[id].address.line2 !== "" && (
                                      <>
                                        <span>{modifications[id].address.line2}</span>
                                        <br />
                                      </>
                                    )}
                                  <span>
                                    {modifications[id].address.city}{" "}
                                    {modifications[id].address.state}
                                    {modifications[id].address.zipCode &&
                                      `, ${modifications[id].address.zipCode}`}
                                  </span>
                                  <br />
                                  <Small>
                                    {modifications[id].address.details &&
                                      modifications[id].address.details.map(
                                        detail =>
                                          Object.keys(detail).length > 0 && (
                                            <Fragment key={detail.key}>
                                              {`${detail.key}: ${detail.value}`}
                                              <br />
                                            </Fragment>
                                          )
                                      )}
                                  </Small>
                                </Text>
                              )}
                              {!modifications[id].address.line1 &&
                                modifications[id].address.lat && (
                                  <Text>
                                    <span>Lat: {modifications[id].address.lat}</span>
                                    <br />
                                    <span>Lon: {modifications[id].address.lon}</span>
                                  </Text>
                                )}
                            </Detail>
                          )}

                        {inFacilityHistory && item?.hasAddress && item?.address && (
                          <Detail
                            isNew={
                              !removedItem &&
                              !!modifications &&
                              !!modifications[id] &&
                              (!!modifications[id].address ||
                                modifications[id].hasAddress === false)
                            }
                            noFlex>
                            {item.address.primary && (
                              <Label data-testid="edit.location" bold>
                                <span>Primary</span>
                              </Label>
                            )}
                            {item.address.line1 && (
                              <Text>
                                <span>{item.address.line1}</span>
                                <br />
                                {item.address.line2 && item.address.line2 !== "" && (
                                  <>
                                    <span>{item.address.line2}</span>
                                    <br />
                                  </>
                                )}
                                <span>
                                  {item.address.city} {item.address.state}
                                  {item.address.zipCode && `, ${item.address.zipCode}`}
                                </span>
                                <br />
                                <Small>
                                  {item.address.details &&
                                    item.address.details.map(
                                      detail =>
                                        Object.keys(detail).length > 0 && (
                                          <Fragment key={detail.key}>
                                            {`${detail.key}: ${detail.value}`}
                                            <br />
                                          </Fragment>
                                        )
                                    )}
                                </Small>
                              </Text>
                            )}
                            {!item.address.line1 && item.address.lat && (
                              <Text>
                                <span>Lat: {item.address.lat}</span>
                                <br />
                                <span>Lon: {item.address.lon}</span>
                              </Text>
                            )}
                          </Detail>
                        )}
                      </>
                    )}
                    {newLevel &&
                      item.children &&
                      !groupsOnly &&
                      renderElements(combineChildren(id), newLevel)}
                  </ElementOptions>
                  {provided.placeholder}
                </Wrapper>
              )}
            </Draggable>
          )
        );
      });

  return (
    builder && (
      <>
        {renderElements(combineAllIds(), "top")}
        {children}
        {atLeast("creator") &&
          roleCanAccessResource("facility", "create") &&
          handleAddGroup &&
          !querying && (
            <Wrapper data-testid="builder.field">
              <AddElement builder={builder} addElement={handleAddGroup} />
            </Wrapper>
          )}

        {querying && builder.allIds.length === 0 && <Text>Search returned 0 results</Text>}
        {readOnly && !querying && builder.allIds.length === 0 && (
          <NoElements>
            <Text>No elements</Text>
          </NoElements>
        )}
        {handleBulk && (
          <>
            <BulkEditSticky>
              <BulkButton type="button" onClick={handleBulk}>
                Save
              </BulkButton>
            </BulkEditSticky>
            <WarningWrapper>
              <Warning>Warning!</Warning>
              <Text quiet>You have one or more unsaved changes!</Text>
            </WarningWrapper>
          </>
        )}
      </>
    )
  );
};

RenderFacilityBuilder.propTypes = {
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node,
  removeElement: PropTypes.func,
  editElement: PropTypes.func,
  setElementLocation: PropTypes.func,
  inFacilityHistory: PropTypes.bool,
  modifications: PropTypes.objectOf(PropTypes.any),
  handleAddGroup: PropTypes.func,
  handleAddComponent: PropTypes.func,
  handleBulk: PropTypes.func,
  activeDroppableId: PropTypes.string,
  querying: PropTypes.bool,
  markerIconMap: PropTypes.objectOf(PropTypes.any),
  activeMarker: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setActiveMarker: PropTypes.func.isRequired,
  active: PropTypes.objectOf(PropTypes.any),
  locks: PropTypes.objectOf(PropTypes.any),
  readOnly: PropTypes.bool,
  groupsOnly: PropTypes.bool,
  view: PropTypes.string,
  setView: PropTypes.func
};

// Style Overrides
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: max-content;
  padding-top: ${({readOnly, inFacilityHistory}) => (readOnly && !inFacilityHistory ? 0 : pad)}px;

  ${({inFacilityHistory}) =>
    inFacilityHistory &&
    css`
      :first-child {
        padding-top: 0;
      }
    `}
`;

const Lock = styled.p`
  padding: ${pad}px;
`;

const Options = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  z-index: ${z("base")};
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.2s;
  min-height: 38px;

  ${({element}) =>
    element === COMPONENT &&
    css`
      top: ${pad / 2}px;
    `}
`;

const Option = styled(Button)`
  margin: 0 0 0 ${pad}px;
  font-weight: bold;
  height: 100%;
`;

const Detail = styled.div`
  ${({noFlex}) =>
    !noFlex &&
    css`
      display: flex;
      justify-content: space-between;
    `}

  margin: ${pad}px 0;

  p,
  span {
    color: ${({theme}) => theme.secondary};

    ${({isNew, isOld}) => {
      if (isNew)
        return css`
          color: ${status.success};
        `;

      if (isOld)
        return css`
          color: ${status.error};
          text-decoration: line-through;
        `;

      return "";
    }}
  }
`;

const DetailLabel = styled(Text)`
  width: 25%;
  padding-right: ${pad}px;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 80px;

  ${({fullWidth}) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const StyledNotes = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
`;

const StyledNoteText = styled.span`
  font-weight: 700;
  color: ${({theme}) => theme.secondary};
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;

  &:before {
    content: attr(title);
  }
`;

const View = styled.button`
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: ${radius};

  ${({active, theme}) =>
    active &&
    css`
      border-color: ${theme.primary};
    `};

  &:hover {
    text-decoration: underline;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  padding: ${pad}px 0 ${pad}px ${pad / 2}px;
  fill: ${({color, theme}) => color || theme.primary};
`;

const AddressLink = styled.a`
  width: fit-content;
  color: ${({theme}) => theme.secondary};

  &:hover {
    text-decoration: underline;
  }
`;

const NoElements = styled.div`
  ${flex("row", "nowrap", "center", "center")}
  padding: ${pad}px;
`;

const BulkEditSticky = styled.div`
  bottom: ${pad * 2}px;
  margin-top: 0;
  display: flex;
  position: sticky;
  justify-content: start;
  z-index: ${z("above")};
  height: 20px;
`;

const WarningWrapper = styled(Inline)`
  width: 75%;
  align-items: start;
  justify-self: end;
  justify-content: end;
`;

const BulkButton = styled(Button)`
  width: 150px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 50%);
`;

export default RenderFacilityBuilder;
