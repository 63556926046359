import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import {exists} from "../utils/helpers.js";

// Style
import {border, pad, radius} from "../style/components/variables.js";
import {Error, Heading, Label, Loader, TextCenter} from "../style/components/general.js";
import {bp} from "../style/components/breakpoints.js";
import voice from "../style/components/typography.js";

const Statistic = ({
  testId = "stat",
  precision = 4,
  label = "Statistic",
  stat,
  error,
  loading = false
}) => {
  const setPrecision = value =>
    exists(value) && typeof value === "number" && value.toFixed(precision);

  return (
    <Wrapper data-testid={testId}>
      {!loading && stat ? (
        <>
          <Label center bold>
            {stat?.label?.toUpperCase() || label.toUpperCase()}
          </Label>

          {setPrecision(stat.values) ? (
            <StatValue data-testid={`${testId}-value`} center>
              {setPrecision(stat.values)}&nbsp;
              {stat.descriptor}
            </StatValue>
          ) : (
            <TextCenter>No Data</TextCenter>
          )}

          {error && <Error data-testid={`${testId}-error`}>{error}</Error>}
        </>
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
};

Statistic.propTypes = {
  stat: PropTypes.objectOf(PropTypes.any),
  testId: PropTypes.string,
  label: PropTypes.string,
  precision: PropTypes.number,
  error: PropTypes.string,
  loading: PropTypes.bool
};

// Style Overrides
const Wrapper = styled.div`
  position: relative;
  padding: ${pad}px;
  width: fit-content;
  width: 100%;
  min-height: 100px;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  margin: 0;
  overflow: hidden;
`;

const StatValue = styled(Heading)`
  ${bp(3)} {
    ${voice.medium}
  }

  ${bp(5)} {
    ${voice.strong}
  }
`;

export default Statistic;
