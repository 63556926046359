import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";

// Style
import {voice} from "../../style/components/typography";
import {border, pad, radius} from "../../style/components/variables";
import {Abbr, Label, List, ListItem, Text} from "../../style/components/general";

const ChecksheetRestrictions = ({showModalEdit, restrictions}) => {
  const renderRestrictions = () =>
    restrictions.map(restriction => {
      const {label} = restriction;

      return (
        <Restriction key={label}>
          <StyledText bold>{label}</StyledText>
        </Restriction>
      );
    });

  return (
    <Menu>
      <Edit type="button" onClick={() => showModalEdit(true)}>
        <Abbr title="Edit">
          <FontAwesomeIcon icon={faEdit} />
        </Abbr>
      </Edit>
      {restrictions?.length > 0 ? (
        <RestrictionList>
          <Label bold>Checksheet Restrictions</Label>
          <br />
          <List column>{renderRestrictions()}</List>
        </RestrictionList>
      ) : (
        <RestrictionList>
          <Label bold>Checksheet Restrictions</Label>
          <Text quiet>Checksheet restrictions have not been configured.</Text>
        </RestrictionList>
      )}
    </Menu>
  );
};

ChecksheetRestrictions.propTypes = {
  showModalEdit: PropTypes.func.isRequired,
  restrictions: PropTypes.arrayOf(PropTypes.any).isRequired
};

// Style Overrides
const Menu = styled.div`
  position: relative;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  width: 100%;
  padding: ${pad}px;
  margin-bottom: ${pad}px;

  ${Text} {
    max-width: 95%;
  }
`;

const Edit = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${pad / 2}px ${pad}px;
  width: min-content;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const RestrictionList = styled.div`
  margin-bottom: ${pad}px;

  ${Label} {
    ${voice.quiet};
  }
`;

const Restriction = styled(ListItem)`
  margin-top: ${pad / 2}px;
  width: 100%;
`;

const StyledText = styled(Text)`
  display: flex;
  ${voice.small};
`;

export default ChecksheetRestrictions;
