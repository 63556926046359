import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faCheck,
  faClose,
  faEdit,
  faEye,
  faEyeSlash,
  faPencil,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Modal from "../../components/Modal.js";
import ModalDelete from "../../components/ModalDelete.js";
import ModalCustomTableTarget from "./ModalCustomTableTarget.js";
import SearchSelect from "../../components/SearchSelect.js";
import SearchSelectUser from "../../components/SearchSelectUser.js";
import {
  InputText,
  InputCheck,
  InputError,
  InputSelect,
  InputRadioGroup
} from "../../components/form/FormInputs.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {
  Form,
  FormGroup,
  FormField,
  HeadingCenter,
  Label,
  Text,
  Button,
  Inline,
  FormFieldWrapper,
  ButtonLoader,
  ModalButton,
  Abbr
} from "../../style/components/general.js";

const DEFAULT_COLUMNS = {
  preview: "Preview",
  name: "Name",
  date: "Original Date",
  createdBy: "Created By",
  createdAt: "Created At",
  updatedBy: "Updated By",
  updatedAt: "Updated At"
};

const defaultValues = {
  tableType: "default",
  name: "",
  label: "",
  global: false,
  columns: Object.keys(DEFAULT_COLUMNS).map(key => ({
    enabled: true,
    override: false,
    name: key,
    label: DEFAULT_COLUMNS[key]
  }))
};

const defaultNotifications = {
  customConfirmation: false,
  customConfirmationList: [],
  confirmation: false
};

const ModalCustomTable = ({
  visible,
  setVisible,
  facilityId,
  target = null,
  setTarget = null,
  tables,
  reload
}) => {
  const isMounted = useMountedState();

  const {roles, roleCanAccessResource} = useContext(AuthContext);
  const {settings} = useContext(SettingsContext);

  const {api, loading} = useApi("custom-tables");
  const {api: apiTags} = useApi("tags");
  const {api: apiUser} = useApi("users");
  const {api: apiMS} = useApi("microsoft");

  const [editTargetFolder, setEditTargetFolder] = useState(false);
  const [targetFolder, setTargetFolder] = useState();
  const [targetFolderHasChildren, setTargetFolderHasChildren] = useState();
  const [allTags, setAllTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [restrictTo, setRestrictTo] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [users, setUsers] = useState([]);

  const root = useRef();

  let defaultAfter = 0;

  if (tables)
    tables.map(({order}) => {
      if (target && target.order - 1 === order) defaultAfter = order;
    });

  const schema = yup.object().shape({
    label: yup.string().required("Please provide a name for your table."),
    after:
      tables && tables.length > 0
        ? yup
            .number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable()
            .required("Please choose where to display the new table.")
        : yup.number().nullable(),
    sharepointFolder: yup
      .string()
      .nullable()
      .when("tableType", {
        is: "sharepoint",
        then: () => yup.string().required("Provide target folder.")
      }),
    columns: yup
      .array()
      .of(
        yup.object().shape({
          enabled: yup.bool(),
          override: yup.bool(),
          name: yup.string().nullable(),
          label: yup.string().required()
        })
      )
      .test({
        message:
          "One or more column names have not been confirmed. Please click 'check' to resolve.",
        test: s => s.filter(col => col.override).length === 0
      })
      .test({
        message: "At least one column must be enabled.",
        test: s => s.filter(col => col.enabled).length > 0
      }),
    tags: yup
      .array()
      .of(yup.object())
      .test({
        test: (val, ctx) =>
          ctx.parent.tableType === "sharepoint" || (Array.isArray(val) && val.length > 0),
        message: "Please provide at least one tag."
      }),
    roles: yup.array().of(yup.object()).nullable(),
    global: yup.bool(),
    fileOnly: yup.bool(),
    notifications: yup.object().shape({
      confirmation: yup.bool().nullable(),
      customConfirmation: yup.bool().nullable(),
      customConfirmationList: yup
        .mixed()
        .nullable()
        .when("customConfirmation", {
          is: val => !!val,
          then: () =>
            yup
              .array()
              .nullable()
              .of(yup.string())
              .test({
                test: val => Array.isArray(val) && val.length > 0 && !val.some(user => !user),
                message: "Please provide user list"
              })
        })
    })
  });

  const form = useForm({
    defaultValues: target
      ? {...defaultValues, ...target, after: defaultAfter}
      : {...defaultValues, after: defaultAfter},
    resolver: yupResolver(schema)
  });
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    formState: {errors}
  } = form;
  const {remove, insert} = useFieldArray({control, name: "columns"});
  const {append: appendTag} = useFieldArray({control, name: "tags"});
  const {append: appendRole} = useFieldArray({control, name: "roles"});
  const watchType = watch("tableType");
  const watchColumns = watch("columns");
  const watchSelectedTags = watch("tags");
  const watchSelectedRoles = watch("roles");
  const watchCustomConfirmation = watch("notifications.customConfirmation");
  const watchCustomConfirmationList = watch("notifications.customConfirmationList");

  // Load tags
  useEffect(() => {
    if (isMounted())
      apiTags.callGet().then(({status, data}) => {
        if (status === 200)
          setAllTags(data.map(({label}) => ({name: label, label: label?.toUpperCase()})));
      });
  }, [apiTags, isMounted]);

  // Reset tag error
  useEffect(() => {
    if (watchSelectedTags?.length > 0) clearErrors("tags");
  }, [clearErrors, watchSelectedTags]);

  // Update columns
  useEffect(() => {
    if (isMounted() && watchType === "sharepoint")
      setValue(
        "columns",
        defaultValues.columns.filter(({name}) => name !== "date")
      );
  }, [isMounted, watchType, setValue]);

  useEffect(() => {
    if (isMounted() && watchType === "default") setValue("columns", defaultValues.columns);
  }, [isMounted, watchType, setValue]);

  // Edit existing
  useEffect(() => {
    if (isMounted() && target?.columns) {
      const selected = [];
      const targetColumns = target.columns.map(({name, label}) => {
        selected.push(name);
        return {
          enabled: true,
          override: false,
          name: name,
          label: label
        };
      });
      defaultValues.columns.map(({name, label}) => {
        if (!selected.includes(name))
          targetColumns.push({
            enabled: false,
            override: false,
            name: name,
            label: label
          });
      });

      if (!target.notifications) setValue("notifications", defaultNotifications);

      setValue("tableType", target?.sharepointFolderId ? "sharepoint" : "default");
      setValue("sharepointFolder", target?.sharepointFolderId);
      setValue("columns", targetColumns);
    } else setValue("notifications", defaultNotifications);
  }, [isMounted, target, setValue]);

  // re-ordering list
  const onDragEnd = result => {
    const {destination, source} = result;
    if (!destination || source.index === destination.index) return;

    const movingField = watch(`columns.${source.index}`);
    let sourceOffset = 0;
    let destinationOffset = 1;

    if (destination.index < source.index) {
      sourceOffset = 1;
      destinationOffset = 0;
    }

    insert(destination.index + destinationOffset, {...movingField});
    remove(source.index + sourceOffset);
  };

  const searchUser = query => {
    if (query)
      apiUser
        .callGet("", {
          limit: 5,
          noTest: true,
          filter: JSON.stringify({
            Search: query,
            showHidden: true
          })
        })
        .then(({status, data}) => {
          if (status === 200) setUsers(data);
        });
    else setUsers([]);
  };

  const searchTags = query => {
    const tagLabels = watchSelectedTags?.map(({label}) => label?.toLowerCase());

    if (query) {
      const lower = query.toLowerCase();
      const tempResults = allTags.filter(
        option =>
          option.label.toLowerCase().includes(lower) &&
          !tagLabels.includes(option.label.toLowerCase())
      );
      tempResults.sort(
        (a, b) => a.label.toLowerCase().indexOf(lower) - b.label.toLowerCase().indexOf(lower)
      );
      setTags(tempResults);
    } else
      setTags(
        allTags?.filter(option => option?.label && !tagLabels.includes(option.label.toLowerCase()))
      );
  };

  const searchRoles = query => {
    if (query) {
      const lower = query.toLowerCase();
      const tempResults = roles.filter(
        option =>
          option.label.toLowerCase().includes(lower) &&
          !watchSelectedRoles.map(({label}) => label?.toLowerCase()).includes(option.label)
      );
      tempResults.sort(
        (a, b) => a.label.toLowerCase().indexOf(lower) - b.label.toLowerCase().indexOf(lower)
      );
      setRestrictTo(tempResults);
    } else
      setRestrictTo(
        roles?.filter(
          option =>
            option?.label &&
            !watchSelectedRoles
              .map(({label}) => label?.toLowerCase())
              .includes(option.label.toLowerCase())
        )
      );
  };

  // Microsoft Integration
  const getFolder = useCallback(
    async folderId =>
      apiMS
        .callGet(null, {
          resourceType: "sharepoint",
          resourcePath: "drive",
          resourceId: folderId
        })
        .then(({status, data}) => {
          if (status === 200 && data?.drive) {
            setTargetFolder(data.drive[0].parentReference);
            setValue("sharepointFolder", folderId);
          }
        }),
    [apiMS, setValue]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && watchType === "sharepoint") {
      if (!root?.current) {
        apiMS
          .callGet(null, {
            resourceType: "sharepoint",
            resourcePath: "drive"
          })
          .then(({status, data}) => {
            if (status === 200) {
              let targetParentFolder = null;

              const targetFolders = data.drive
                ?.filter(type => type?.folder)
                ?.map(folder => {
                  targetParentFolder = folder.parentReference;
                  return folder;
                });

              if (!targetParentFolder) targetParentFolder = {...data.root, id: "root"};

              setTargetFolderHasChildren(targetFolders?.length > 0);

              root.current = targetParentFolder;

              if (target?.sharepointFolderId) getFolder(target.sharepointFolderId);
              else if (targetParentFolder) {
                setTargetFolder(targetParentFolder);
                setValue("sharepointFolder", targetParentFolder.id);
              }
            }
          });
      }
    }
  }, [isMounted, watchType, target, getFolder, apiMS, setValue]);

  const handleSave = values => {
    const {
      label: tableName,
      after,
      columns: currColumns,
      tags: currTags,
      roles: currRoles,
      global,
      fileOnly,
      notifications,
      sharepointFolder
    } = values;

    let order = after ?? 1;
    if (order && (!target || target.order > order)) order += 1;
    if (!order) order = 1;

    const formatted = currColumns
      .filter(({enabled}) => enabled)
      .map(({name, label}) => ({name, label}));

    const data = {
      facilityId,
      label: tableName.toUpperCase(),
      order,
      columns: formatted,
      tags: currTags.map(({label}) => label),
      roles: currRoles.map(({id}) => id),
      global: global,
      fileOnly: fileOnly,
      notifications,
      sharepointFolderId: sharepointFolder
    };

    const success = target ? 200 : 201;
    const request = target ? api.callPut(target.id, data) : api.callPost(data);

    request.then(({status}) => {
      if (status === success) {
        reload();
        setVisible(false);
      }
    });
  };

  // Reset on Component unmount
  useEffect(
    () => () => {
      if (!isMounted()) setTarget(null);
    },
    [isMounted, setTarget]
  );

  const deleteTable = () =>
    api.callDelete(target.id).then(() => {
      reload();
      setVisible(false);
    });

  const renderModalControls = () =>
    roleCanAccessResource("custom_table", "delete") &&
    target && (
      <ModalButton
        data-testid="event.deleteButton"
        onClick={() => setConfirmDelete(true)}
        title="Delete Event">
        <FontAwesomeIcon icon={faTrash} inverse />
      </ModalButton>
    );

  const getColumn = (name, label, order) => {
    const enabled = watch(`columns.${order}.enabled`);
    const override = watch(`columns.${order}.override`);
    const newLabel = watch(`columns.${order}.label`);
    return (
      <Draggable
        draggableId={`columns.${order}`}
        index={order}
        key={`columns.${order}`}
        isDragDisabled={!enabled}>
        {innerProvided => (
          <>
            <ColumnInfo
              ref={innerProvided.innerRef}
              enabled={enabled}
              {...innerProvided.draggableProps}
              {...innerProvided.dragHandleProps}>
              <Inline id={name}>
                <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />

                <InputCheck name={`columns.${order}.enabled`} hidden />

                {override ? (
                  <InputText name={`columns.${order}.label`} disabled={!enabled} />
                ) : (
                  <span>
                    {newLabel || label}&nbsp;
                    {newLabel !== DEFAULT_COLUMNS[name] && `(${DEFAULT_COLUMNS[name]})`}
                  </span>
                )}
              </Inline>
              <Inline>
                <Button
                  type="button"
                  onClick={() => setValue(`columns.${order}.override`, !override)}
                  disabled={!enabled}>
                  <FontAwesomeIcon icon={override ? faCheck : faPencil} />
                </Button>
                <Button
                  type="button"
                  onClick={() => setValue(`columns.${order}.enabled`, !enabled)}>
                  <FontAwesomeIcon icon={enabled ? faEye : faEyeSlash} />
                </Button>
              </Inline>
            </ColumnInfo>
            {innerProvided.placeholder}
          </>
        )}
      </Draggable>
    );
  };

  const renderColumns = cols => cols.map(({name, label}, index) => getColumn(name, label, index));

  if (confirmDelete) {
    return (
      <ModalDelete
        visible={confirmDelete}
        setVisible={setConfirmDelete}
        confirmDelete={deleteTable}
        title="Delete Table"
        prompt="Are you sure you want to delete? Deleting this table may make some notes unavailable if there is no table corresponding to its tag. This action cannot be undone."
        loading={loading}
        goBack={() => setConfirmDelete(false)}
        hasBackButton
      />
    );
  }

  if (editTargetFolder && root?.current)
    return (
      <ModalCustomTableTarget
        visible={editTargetFolder}
        setVisible={setEditTargetFolder}
        target={root.current}
        setTarget={t => {
          setTargetFolder(t || root.current);
          setValue("sharepointFolder", t?.id || root.current.id);
        }}
        root={root.current}
        goBack={() => setEditTargetFolder(false)}
        hasBackButton
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible} renderModalControls={renderModalControls()}>
      <ModalTitle>{target ? `Edit ${target.label}` : "Add Custom Table"}</ModalTitle>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(handleSave)} noValidate>
          {settings?.sharepointEnabled === "enabled" && (
            <InputRadioGroup
              name="tableType"
              options={[
                {label: "Default", value: "default"},
                {label: "Sharepoint", value: "sharepoint"}
              ]}
            />
          )}

          <FormGroup>
            <FormField>
              <InputText name="label" placeholder="Table Name" />
            </FormField>

            {watchType === "sharepoint" && (
              <FormField>
                <Label htmlFor="sharepointFolder" bold>
                  TARGET FOLDER
                </Label>
                <TargetFolder>
                  <input name="sharepointFolder" hidden />
                  <Inline>
                    {targetFolder?.name ? (
                      <Text bold>
                        <Abbr title={targetFolder.id}>{targetFolder.name}</Abbr>
                      </Text>
                    ) : (
                      <>
                        <Text>Target...</Text>
                        <ButtonLoader />
                      </>
                    )}
                    {targetFolderHasChildren && (
                      <LabelButton type="button" onClick={() => setEditTargetFolder(true)}>
                        <Abbr title="Change target folder">
                          <FontAwesomeIcon icon={faEdit} />
                        </Abbr>
                      </LabelButton>
                    )}
                  </Inline>
                  <InputError name="sharepointFolder" errors={errors} />
                </TargetFolder>
              </FormField>
            )}

            {tables.length > 0 && (
              <FormField>
                <InputSelect
                  name="after"
                  label="Display after"
                  placeholder="Select..."
                  options={[
                    {label: "Top of Page", value: "0"},
                    ...tables
                      .filter(table => !target || table.order !== target.order)
                      .map(table => ({label: table.name, value: table.order}))
                  ]}
                />
              </FormField>
            )}

            <FormField>
              <Label htmlFor="columns" bold>
                Columns
              </Label>
              <Text>Select from options below which columns you want to include.</Text>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="columns">
                  {outerProvided => (
                    <div {...outerProvided.droppableProps} ref={outerProvided.innerRef}>
                      {renderColumns(watchColumns)}
                      {outerProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <InputError errors={errors} name="columns" />
            </FormField>

            {watchType === "default" && (
              <FormField>
                <SearchSelect
                  label="Tags"
                  prompt="Select from available tags to define table data."
                  placeholder="Tag..."
                  results={tags}
                  setResults={setTags}
                  search={searchTags}
                  add={result => {
                    const selectedLabel = typeof result === "string" ? result : result.label;
                    if (
                      !watchSelectedTags ||
                      !watchSelectedTags.map(({label}) => label).includes(selectedLabel)
                    ) {
                      if (typeof result === "string")
                        appendTag({
                          label: result,
                          name: result
                        });
                      else appendTag(result);
                    }
                  }}
                  allowNew
                  showAll
                />
                {watchSelectedTags?.length > 0 && (
                  <Selections>
                    {watchSelectedTags.map(({name, label}) => (
                      <Selection
                        type="button"
                        key={name}
                        onClick={() => {
                          setValue(
                            "tags",
                            watchSelectedTags.filter(r => r.label !== label)
                          );
                        }}>
                        {label.toUpperCase()}&nbsp;
                        <FontAwesomeIcon icon={faClose} />
                      </Selection>
                    ))}
                  </Selections>
                )}
                <InputError errors={errors} name="tags" />
              </FormField>
            )}

            <FormField>
              <SearchSelect
                label="Restrict Visibility"
                prompt="Restrict table visibility to specific roles?"
                placeholder="Role..."
                results={restrictTo}
                setResults={setRestrictTo}
                search={searchRoles}
                add={result => {
                  if (
                    !watchSelectedRoles ||
                    !watchSelectedRoles.map(({id}) => id).includes(result.id)
                  )
                    appendRole(result);
                }}
                showAll
              />
              {watchSelectedRoles?.length > 0 && (
                <Selections>
                  {watchSelectedRoles.map(({id, name, label}) => (
                    <Selection
                      type="button"
                      key={name}
                      onClick={() => {
                        setValue(
                          "roles",
                          watchSelectedRoles.filter(r => r.id !== id)
                        );
                      }}>
                      {label.toUpperCase()}&nbsp;
                      <FontAwesomeIcon icon={faClose} />
                    </Selection>
                  ))}
                </Selections>
              )}
              <InputError errors={errors} name="roles" />
            </FormField>
          </FormGroup>
          <FormGroup>
            <InputCheck name="global">Display on Global Tables page?</InputCheck>

            {watchType === "default" && (
              <>
                <InputCheck name="fileOnly">
                  Only allow file upload for rows in this table?
                </InputCheck>
                <InputCheck name="notifications.confirmation">
                  Enable confirmation email sent to note author?
                </InputCheck>
                <InputCheck name="notifications.customConfirmation">
                  Enable confirmation email sent to custom list of users?
                </InputCheck>
                {watchCustomConfirmation && (
                  <>
                    <SearchSelectUser
                      label="Send to..."
                      search={searchUser}
                      results={users}
                      setResults={setUsers}
                      add={email =>
                        !watchCustomConfirmationList.includes(email) &&
                        setValue("notifications.customConfirmationList", [
                          ...watchCustomConfirmationList,
                          email
                        ])
                      }
                      remove={email =>
                        setValue(
                          "notifications.customConfirmationList",
                          watchCustomConfirmationList.filter(curr => curr !== email)
                        )
                      }
                      selections={watchCustomConfirmationList}
                    />
                    <InputError
                      atBottom={false}
                      errors={
                        watchCustomConfirmationList && watchCustomConfirmationList.length
                          ? {}
                          : errors
                      }
                      name="notifications.customConfirmationList"
                    />
                  </>
                )}
              </>
            )}
          </FormGroup>
          <Button type="submit" loading={loading ? 1 : 0}>
            Save{loading && <ButtonLoader />}
          </Button>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalCustomTable.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  facilityId: PropTypes.number.isRequired,
  target: PropTypes.objectOf(PropTypes.any),
  setTarget: PropTypes.func,
  tables: PropTypes.arrayOf(PropTypes.any).isRequired,
  reload: PropTypes.func.isRequired
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const TargetFolder = styled.div`
  position: relative;
  width: fit-content;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: 0 ${pad}px;
`;

const LabelButton = styled.button`
  position: relative;
  display: block;
  width: fit-content;
  padding-left: ${pad}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: ${radius};

  ${({active}) =>
    active &&
    css`
      text-decoration: underline;
    `}
`;

const ColumnInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${pad / 2}px ${pad}px;
  background: ${({theme}) => theme.secondary};
  border-radius: ${radius};
  margin-top: ${pad}px;

  ${({enabled}) =>
    !enabled &&
    css`
      opacity: 0.5;
    `}

  ${FormFieldWrapper} {
    margin: 0;
  }

  svg {
    fill: ${({theme}) => theme.tertiary};
  }

  span {
    padding: ${pad}px;
    color: ${({theme}) => theme.tertiary};
  }

  input {
    background: ${({theme}) => theme.tertiary};
    max-width: 150px;
    margin: 0 ${pad}px;
  }

  ${Button} {
    padding: 0 0 0 ${pad}px;
    background: transparent;
  }
`;

const Selections = styled.div`
  position: relative;
  ${flex("row", "wrap", "start", "start")};
`;

const Selection = styled.button`
  display: flex;
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad / 2}px;
  width: fit-content;
  margin: ${pad}px ${pad / 2}px 0 0;
  ${voice.quiet};
`;

export default ModalCustomTable;
