import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Modal from "../../components/Modal.js";
import {InputText, InputCheckGroup} from "../../components/form/FormInputs.js";

// Style
import {z} from "../../style/components/mixins.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {breakpoint} from "../../style/components/breakpoints.js";
import {
  FormGroup,
  HeadingCenter,
  ButtonFull,
  Form,
  FormField,
  ButtonLoader,
  Loader,
  List,
  scrollbar,
  ListItem,
  Button,
  NotLoaded
} from "../../style/components/general.js";

const defaultValues = {name: "", types: []};

const schema = yup.object().shape({
  name: yup.string().required("Please provide name"),
  types: yup.array().test({
    message: "At least one type is required.",
    test: arr => Array.isArray(arr) && arr?.length > 0
  })
});

const ModalFacilityAdd = ({visible, setVisible, reloadFacilities}) => {
  const isMounted = useMountedState();

  const [loading, setLoading] = useState(false);
  const [facilityTypes, setFacilityTypes] = useState();
  const [facilities, setFacilities] = useState([]);
  const [facilityResults, setFacilityResults] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);

  const {api: apiFacilityType} = useApi("facility-types");
  const {api: apiFacility} = useApi("facilities");

  const form = useForm({defaultValues, resolver: yupResolver(schema)});
  const {watch, setValue, handleSubmit, reset} = form;

  const watchName = watch("name");

  // Initial Load
  useEffect(() => {
    if (isMounted()) {
      apiFacilityType.callGet().then(({status, data}) => {
        if (status === 200 && data) setFacilityTypes(data);
      });
      apiFacility.callGet().then(({status, data}) => {
        if (status === 200 && data) {
          const formatted = data?.map(facility => {
            if (facility.name?.props?.children)
              return {...facility, name: facility.name?.props?.children};
            return facility;
          });
          setFacilities(formatted);
        }
      });
    }
  }, [isMounted, apiFacilityType, apiFacility]);

  // Handle Search
  useEffect(() => {
    const results = [];
    const added = [];
    if (watchName && watchName !== "") {
      facilities?.map(facility => {
        if (
          facility.name.toLowerCase().includes(watchName?.toLowerCase()) &&
          !added.includes(facility.name)
        ) {
          results.push(facility);
          added.push(facility.name);
        }
      });
      setFacilityResults(results);
    } else setFacilityResults(facilities);
  }, [watchName, facilities]);

  const handleAddFacility = ({name, types, ...values}) => {
    const currentTypes = selectedFacility?.types?.map(({id}) => id);
    apiFacility
      .callPost({
        name,
        types: types.filter(id => !currentTypes?.includes(Number.parseInt(id, 10))),
        ...values
      })
      .then(({status}) => {
        if (isMounted() && status === 201) {
          reloadFacilities();
          reset({});
          setLoading(false);
          setVisible(false);
        }
      });
  };

  return (
    <Modal visible={visible} setVisible={setVisible} maxWidth={breakpoint.width[2]}>
      <ModalTitle data-testid="create-facility">Create Facility</ModalTitle>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(handleAddFacility)} noValidate>
          {facilityTypes?.length > 0 ? (
            <FormGroup>
              <FormField>
                <InputText
                  name="name"
                  label="Facility Name"
                  required
                  hidden={selectedFacility !== null}
                  autocomplete={false}
                />
                {selectedFacility && watchName === selectedFacility.name && (
                  <Button
                    type="button"
                    title="Unselect Facility"
                    onClick={() => {
                      setValue("name", null);
                      setSelectedFacility(null);
                    }}>
                    <span>FACILITY: {selectedFacility.name}</span>
                    &nbsp;
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                )}
                {!selectedFacility && facilityResults?.length > 0 && watchName?.length > 0 && (
                  <Results hideScroll={facilityResults?.length === 1}>
                    {facilityResults?.map(result => (
                      <Result
                        key={`result-${result.id}`}
                        onClick={() => {
                          setValue("name", result.name);
                          setValue(
                            "types",
                            result.types?.map(({id}) => `${id}`)
                          );
                          setSelectedFacility(result);
                          setFacilityResults(facilities);
                        }}>
                        {result.name}
                      </Result>
                    ))}
                  </Results>
                )}
              </FormField>
              <FormField>
                <InputCheckGroup
                  name="types"
                  label="Please provide Facility type(s)."
                  options={facilityTypes.map(({id, name}) => ({
                    label: name,
                    value: id,
                    disabled: selectedFacility?.types?.some?.(type => type.id === id)
                  }))}
                  required
                />
              </FormField>
            </FormGroup>
          ) : (
            <NotLoaded>
              <Loader />
            </NotLoaded>
          )}

          <ButtonFull type="submit" loading={loading ? 1 : 0}>
            {loading && <ButtonLoader />}Create Facility
          </ButtonFull>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalFacilityAdd.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  reloadFacilities: PropTypes.func.isRequired
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const Results = styled(List)`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: min-content;
  min-width: ${({large}) => (large ? "100%" : "155px")};
  max-height: ${({showMore}) => (showMore ? 125 : 90)}px;
  margin-top: ${pad / 4}px;
  background: ${({theme}) => theme.tertiary};
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  box-shadow: 0 3px 5px rgb(0, 0, 0, 0.5);
  z-index: ${z("top")};

  ${({hideScroll}) =>
    hideScroll
      ? css`
          overflow-y: hidden;
        `
      : css`
          overflow-y: auto;
          ${scrollbar};
        `}
`;

const Result = styled(ListItem)`
  display: inline-block;
  width: 100%;
  padding: 0 ${pad}px;
  white-space: nowrap;
  border: ${border} solid ${({theme}) => theme.tertiary};

  &:hover {
    color: ${({theme}) => theme.tertiary};
    background: ${({theme}) => theme.secondary};
    border: ${border} solid ${({theme}) => theme.secondary};

    ${Button} {
      color: ${({theme}) => theme.tertiary};
    }
  }

  ${Button} {
    width: 100%;
    text-align: left;
    padding: 0;
    background: 0;
    color: ${({theme}) => theme.secondary};
  }
`;

export default ModalFacilityAdd;
