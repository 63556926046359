import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";

// Style
import {voice} from "../../style/components/typography";
import {border, pad, radius} from "../../style/components/variables";
import {Abbr, Inline, Label, Text} from "../../style/components/general";

const MemberRestrict = ({restrictTo = [], external = false, showModalEdit}) => (
  <Menu>
    <Edit type="button" onClick={() => showModalEdit(true)}>
      <Abbr title="Edit">
        <FontAwesomeIcon icon={faEdit} />
      </Abbr>
    </Edit>
    <Label bold>Restrict Checksheet visibility</Label>
    <Wrapper>
      {restrictTo?.length > 0 ? (
        <>
          <Text quiet>
            <strong>Restricted to {restrictTo[0].publicId ? "users" : "roles"}:</strong>{" "}
            {restrictTo
              .map(({label, firstName, lastName}) =>
                firstName ? `${firstName} ${lastName}` : label
              )
              .join(", ")}
          </Text>
          {external && (
            <ExternalText quiet>
              <strong>Available via email only</strong>
            </ExternalText>
          )}
        </>
      ) : (
        <Text quiet>No restriction set.</Text>
      )}
    </Wrapper>
  </Menu>
);

MemberRestrict.propTypes = {
  restrictTo: PropTypes.arrayOf(PropTypes.any),
  external: PropTypes.bool,
  showModalEdit: PropTypes.func.isRequired
};

// Style Overrides
const Menu = styled.div`
  position: relative;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  width: 100%;
  padding: ${pad}px;
  margin-bottom: ${pad}px;

  ${Label} {
    ${voice.quiet};
    margin-bottom: ${pad / 2}px;
  }

  ${Inline} {
    flex-wrap: wrap;
    margin-top: ${pad / 2}px;
    gap: ${pad / 2}px;
  }
`;

const Wrapper = styled.div`
  margin-top: ${pad}px;
`;

const Edit = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${pad / 2}px ${pad}px;
  width: min-content;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const ExternalText = styled(Text)`
  margin-top: ${pad}px;
`;

export default MemberRestrict;
