import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Style
import {heroTheme} from "../../style/components/variables";

const ChartPath = ({
  chartId,
  line,
  onclick,
  mousemove,
  mouseover,
  mouseleave,
  strokeWidth = 1.5,
  color = heroTheme.black
}) => (
  <Path
    d={line}
    fill="transparent"
    color={color}
    clipPath={`url(#clip-${chartId})`}
    onClick={onclick}
    onMouseEnter={mouseover}
    onMouseLeave={mouseleave}
    onMouseMove={mousemove}
    pointerEvents={!!onclick || !!mouseover || !!mouseleave || !mousemove ? 1 : 0}
    strokeWidth={strokeWidth}
  />
);

ChartPath.propTypes = {
  chartId: PropTypes.string.isRequired,
  line: PropTypes.any,
  onclick: PropTypes.func,
  mousemove: PropTypes.func,
  mouseover: PropTypes.func,
  mouseleave: PropTypes.func,
  strokeWidth: PropTypes.number,
  color: PropTypes.string
};

// Style Overrides
const Path = styled.path`
  fill: transparent;
  stroke: ${({color}) => color || "black"};
  stroke-width: ${({strokeWidth}) => strokeWidth}px;
  stroke-opacity: 1;
  stroke-linecap: round;
  pointer-events: ${({pointerEvents}) => (pointerEvents === true ? "auto" : "none")};
`;

export default ChartPath;
