import React, {useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useApi from "../../hooks/useApi";
import usePrevious from "../../hooks/usePrevious";

// Contexts
import {AuthContext} from "../../contexts/auth";

// Components
import SearchSelect from "../../components/SearchSelect";
import Modal from "../../components/Modal";
import RenderChecksheetBuilder from "./RenderChecksheetBuilder";
import {InputError, InputRadioGroup, InputText} from "../../components/form/FormInputs";

// Utils
import {FIELD, newFromBuilder, validBuilder} from "../../utils/builder";

// Style
import {pad} from "../../style/components/variables";
import {
  Button,
  Error,
  FormField,
  FormGroup,
  HeadingCenter,
  Inline,
  Form
} from "../../style/components/general";

const defaultValues = {
  label: "",
  mode: "User",
  members: []
};

const schema = yup.object().shape({
  label: yup.string().nullable().required("Please provide a label."),
  members: yup
    .array()
    .nullable()
    .test({
      test: val => Array.isArray(val) && val.length > 0,
      message: "Please provide restriction members."
    })
});

const recurseCopy = (ids, currentById, byId, allIds, level = 0) => {
  let hasFields = false;
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const item = currentById[id];
    if (item) {
      if (item.element === FIELD) {
        byId[id] = {...item};
        if (level === 0) allIds.push(id);
        hasFields = true;
      } else if (item.children?.length && item.toggle) {
        const result = recurseCopy(item.children, currentById, byId, allIds, level + 1);
        if (result) {
          hasFields = true;
          byId[id] = {...item, children: [...item.children]};
          if (level === 0) allIds.push(id);
        }
      }
    }
  }
  return hasFields;
};

const deepCopyBuilder = builder => {
  const newById = {};
  const newAllIds = [];
  recurseCopy(builder.allIds, builder.byId, newById, newAllIds);
  return {byId: newById, allIds: newAllIds};
};

const ModalChecksheetRestriction = ({
  visible,
  setVisible,
  goBack,
  target,
  facilityId,
  builder,
  update
}) => {
  const {roles} = useContext(AuthContext);

  const {api: apiUser} = useApi("facility-users");

  const expandBuilder = subset => {
    if (!builder?.byId || !subset?.byId) return builder;

    const {byId, allIds} = builder;

    const newById = Object.fromEntries(
      Object.entries(byId).map(([key, val]) => [
        key,
        {
          ...val,
          toggle: key in subset.byId
        }
      ])
    );

    return {byId: newById, allIds};
  };

  const initialMemberType = useMemo(
    () => (target?.members?.[0]?.label ? "Role" : "User"),
    [target]
  );

  const [memberResults, setMemberResults] = useState([]);
  const [builderError, setBuilderError] = useState();
  const [copiedBuilder, setCopiedBuilder] = useState(
    target ? expandBuilder(target.builder) : deepCopyBuilder(builder)
  );

  const builderSubset = useMemo(() => {
    const filteredBuilder = {allIds: [], byId: {}};
    newFromBuilder(copiedBuilder.allIds, copiedBuilder, filteredBuilder);
    return filteredBuilder;
  }, [copiedBuilder]);

  const form = useForm({
    defaultValues: target
      ? {
          label: target.label,
          members: target.members.map(member =>
            initialMemberType === "User"
              ? {publicId: member.publicId, email: member.email}
              : {id: member.id, label: member.label}
          ),
          mode: initialMemberType,
          id: target.id
        }
      : defaultValues,
    resolver: yupResolver(schema)
  });

  const {
    setValue,
    watch,
    handleSubmit,
    trigger,
    formState: {errors, submitCount}
  } = form;

  const watchMode = watch("mode");
  const watchMembers = watch("members");

  const prevMode = usePrevious(watchMode);

  useEffect(() => {
    if (submitCount > 0) {
      const valid = validBuilder(builderSubset.allIds, builderSubset);

      const errorMessage =
        "Invalid selection, please make sure all Components and Groups have at least one field or there is one checksheet level field selected.";
      setBuilderError(!valid ? errorMessage : undefined);
    }
  }, [submitCount, builderSubset]);

  const handleSave = ({label, members, id}) => {
    const valid = validBuilder(builderSubset.allIds, builderSubset);

    const errorMessage =
      "Invalid selection, please make sure all Components and Groups have at least one field or there is one checksheet level field selected.";
    if (!valid) setBuilderError(errorMessage);
    else {
      setBuilderError(undefined);

      update({
        id,
        label,
        members,
        builder: builderSubset
      });

      goBack();
    }
  };

  useEffect(() => {
    if (prevMode && prevMode !== watchMode) setValue("members", []);
  }, [prevMode, watchMode, setValue]);

  useEffect(() => {
    if (submitCount > 0) trigger("members");
  }, [watchMembers, trigger, submitCount]);

  const handleSearchUser = query => {
    const filter = {
      Omit: watchMembers.map(({publicId}) => publicId),
      showHidden: true
    };

    if (query) filter.Search = query;

    const params = {
      facilityId,
      noTest: true,
      limit: query ? 5 : 20,
      filter: JSON.stringify(filter)
    };

    apiUser.callGet("", params).then(({status, data}) => {
      if (status === 200 && data) setMemberResults(data);
    });
  };

  const handleSearchRole = query => {
    if (query)
      setMemberResults(
        roles?.filter(
          role =>
            !watchMembers.some(({label}) => label === role.label) &&
            role.label.toLowerCase().includes(query?.toLowerCase())
        ) || []
      );
    else
      setMemberResults(
        roles?.filter(role => !watchMembers.some(({label}) => label === role.label)) || []
      );
  };

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      hasBackButton={!!goBack}
      goBack={goBack}
      fixedHeight
      allowScroll={false}>
      <HeadingCenter>Create Checksheet Restriction</HeadingCenter>

      <Inline fullWidth fullHeight>
        <ColLeft>
          <FormProvider {...form}>
            <Form onSubmit={handleSubmit(handleSave)} noValidate>
              <FormGroup>
                <FormField>
                  <InputText name="label" label="Label" required />
                </FormField>
                <FormField>
                  <InputRadioGroup name="mode" options={["User", "Role"]} />
                </FormField>
                <FormField>
                  {watchMode === "User" ? (
                    <SearchSelect
                      label="Members"
                      placeholder="Search users..."
                      results={memberResults}
                      setResults={setMemberResults}
                      search={handleSearchUser}
                      add={r =>
                        setValue("members", [
                          ...watchMembers,
                          {email: r.email, publicId: r.publicId}
                        ])
                      }
                      showAll
                    />
                  ) : (
                    <SearchSelect
                      label="Members"
                      placeholder="Search roles..."
                      results={memberResults}
                      setResults={setMemberResults}
                      search={handleSearchRole}
                      add={r => setValue("members", [...watchMembers, {label: r.label, id: r.id}])}
                      showAll
                    />
                  )}
                  <InputError errors={errors} name="members" />
                </FormField>
                <MembersWrapper>
                  {watchMembers?.map(({email, label, id, publicId}) => (
                    <Button
                      type="button"
                      quiet
                      key={`member-${id || publicId}`}
                      onClick={() =>
                        setValue(
                          "members",
                          watchMembers.filter(curr => {
                            if (email && curr.email !== email) return true;
                            if (label && curr.label !== label) return true;
                            return false;
                          })
                        )
                      }>
                      {email || label}&nbsp;
                      <FontAwesomeIcon icon={faClose} />
                    </Button>
                  ))}
                </MembersWrapper>
              </FormGroup>

              <Submit type="submit">Save</Submit>
            </Form>
          </FormProvider>
        </ColLeft>
        <ColRight>
          {builderError && <Error>{builderError}</Error>}
          <DragDropContext onDragStart={() => {}} onDragEnd={() => {}}>
            <Droppable droppableId="checksheet.builder" isDropDisabled>
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <RenderChecksheetBuilder
                    builder={copiedBuilder}
                    setBuilder={setCopiedBuilder}
                    toggleOnly>
                    {provided.placeholder}
                  </RenderChecksheetBuilder>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ColRight>
      </Inline>
    </Modal>
  );
};

ModalChecksheetRestriction.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  update: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  facilityId: PropTypes.number.isRequired,
  target: PropTypes.objectOf(PropTypes.any)
};

// Style Overrides
const Col = styled.div`
  width: 100%;
  align-self: start;
`;

const ColLeft = styled(Col)`
  width: calc(40% - 5px);
`;

const ColRight = styled(Col)`
  width: calc(60% - 5px);
  overflow-y: scroll;
  height: calc(100% - 80px);
  padding-bottom: 20px;
`;

const Submit = styled(Button)`
  margin-bottom: ${pad}px;
`;

const MembersWrapper = styled(Inline)`
  flex-direction: column;
  align-items: start;
`;

export default ModalChecksheetRestriction;
