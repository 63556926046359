import React, {useEffect, useContext, useState, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {hexToRgba} from "../../utils/helpers.js";

// Style
import {z} from "../../style/components/mixins.js";
import {bp} from "../../style/components/breakpoints.js";
import {voice} from "../../style/components/typography.js";
import {pad, border, radius, transition} from "../../style/components/variables.js";
import {
  Label,
  Text,
  ButtonFull,
  Input,
  Loader,
  NotLoaded,
  scrollbar
} from "../../style/components/general.js";

const ComponentTemplates = ({
  templateList,
  setTemplateList,
  setTemplate,
  setShowModalTemplate,
  refresh = false,
  setRefresh,
  maxHeight = "70vh",
  hasPrimaryAddress = false
}) => {
  const isMounted = useMountedState();

  const {roleCanAccessPage, roleCanAccessResource} = useContext(AuthContext);

  const [currentResults, setCurrentResults] = useState([]);

  const {api: apiBase, loading} = useApi("base-components");

  const form = useForm({defaultValues: {search: ""}});
  const {watch, register, setValue} = form;
  const query = watch("search");

  const templateListFiltered = useMemo(() => {
    if (hasPrimaryAddress) return templateList || [];
    if (templateList)
      return templateList.filter(template => {
        const {fields} = template;
        const conditions = fields
          .filter(({condition}) => !!condition?.list)
          .map(({condition}) => condition);
        const formulas = fields.filter(({formula}) => !!formula).map(({formula}) => formula);
        for (let j = 0; j < conditions.length; j++) {
          const {list} = conditions[j];
          if (list?.some(({depends}) => ["rainfall", "cumulative"].includes(depends))) return false;
        }

        for (let j = 0; j < formulas.length; j++) {
          const formula = formulas[j];
          if (formula?.some(({value}) => ["rainfall", "cumulative"].includes(value))) return false;
        }
        return true;
      });
    return [];
  }, [hasPrimaryAddress, templateList]);

  useEffect(() => {
    if (isMounted()) {
      apiBase.callGet("", {query: ""}).then(response => {
        if (response.data) {
          if (query && query !== "") setCurrentResults(response.data);
          else setCurrentResults([]);
          setTemplateList(response.data);
        }
      });
    }
  }, [apiBase, isMounted, query, setTemplateList]);

  useEffect(() => {
    if (refresh) {
      setValue("search", "");
      setRefresh(false);
    }
  }, [refresh, setRefresh, setValue]);

  useEffect(() => {
    if (query === "") setCurrentResults([]);
    if (templateListFiltered && templateListFiltered.length > 0) {
      const filtered = templateListFiltered.filter(template =>
        template.label.toLowerCase().includes(query.toLowerCase())
      );
      setCurrentResults(filtered);
    }
  }, [query, templateListFiltered]);

  const templateTable = () => {
    if (loading)
      return (
        <TemplateNotLoaded>
          <TemplateLoader />
        </TemplateNotLoaded>
      );

    if (currentResults.length > 0)
      return currentResults.map(component => (
        <ComponentTemplate
          type="button"
          key={component.id}
          onClick={() => {
            setTemplate(component);
            setShowModalTemplate(true);
          }}>
          {component.label}
        </ComponentTemplate>
      ));

    return query && query !== "" && <Text>No templates found</Text>;
  };

  return (
    <Menu>
      <Label bold quiet>
        Apply Component Template
      </Label>
      {roleCanAccessPage("component_templates") && roleCanAccessResource("component", "create") && (
        <SettingsLink to="/components">
          <FontAwesomeIcon icon={faCog} />
        </SettingsLink>
      )}
      <Search type="text" placeholder="Search" autoComplete="off" {...register("search")} />
      <ListWrapper>
        <TemplateTable maxHeight={maxHeight}>{templateTable()}</TemplateTable>
      </ListWrapper>
    </Menu>
  );
};

ComponentTemplates.propTypes = {
  templateList: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTemplateList: PropTypes.func.isRequired,
  setTemplate: PropTypes.func.isRequired,
  setShowModalTemplate: PropTypes.func.isRequired,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  maxHeight: PropTypes.string,
  hasPrimaryAddress: PropTypes.bool
};

// Style Overrides
const Menu = styled.div`
  position: relative;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  width: 100%;
  padding: ${pad}px;
  margin-bottom: ${pad * 2}px;
  max-height: 39vh;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    z-index: ${z("above")};
    position: absolute;
    left: 0;
    width: 100%;
    height: ${pad}px;
    border-radius: ${radius};
  }

  &:after {
    top: 0;
    background: ${({theme}) =>
      `linear-gradient(to bottom, ${`${hexToRgba(theme.tertiary)} 0%`}, ${
        theme.name === "light" ? "rgba(255, 255, 255, 0) 100%" : "rgba(0, 0, 0, 0) 100%"
      })`};
  }

  &:before {
    bottom: 0;
    background: ${({theme}) =>
      `linear-gradient(to top, ${`${hexToRgba(theme.tertiary)} 0%`}, ${
        theme.name === "light" ? "rgba(255, 255, 255, 0) 100%" : "rgba(0, 0, 0, 0) 100%"
      })`};
  }
`;

const ListWrapper = styled.div`
  position: relative;
`;

const TemplateTable = styled.div`
  position: relative;
  max-height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollbar};
`;

const CreateButton = styled(ButtonFull)`
  ${voice.bold};
  text-transform: uppercase;
  margin-right: ${pad}px;
  margin-bottom: ${pad}px;

  &:last-child {
    margin-right: 0;
  }
`;

const ComponentTemplate = styled(CreateButton)`
  background: transparent;
  color: ${({theme}) => theme.component};
  border: ${border} solid ${({theme}) => theme.component};
  margin-top: ${pad}px;
  transition: ${transition};
  ${voice.normal};

  ${bp(5)} {
    ${voice.largeBold};
  }

  svg {
    fill: ${({theme}) => theme.component};
  }

  &:hover {
    color: ${({theme}) => theme.tertiary};
    background: ${({theme}) => theme.component};

    svg {
      fill: ${({theme}) => theme.tertiary};
    }
  }
`;

const Search = styled(Input)`
  margin-top: ${pad}px;
  margin-bottom: ${pad}px;
`;

const SettingsLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${pad}px;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const TemplateLoader = styled(Loader)`
  top: -17px;
  &:after {
    border-top: ${radius} solid rgba(0, 0, 0, 0.5);
    border-right: ${radius} solid rgba(0, 0, 0, 0.5);
    border-bottom: ${radius} solid rgba(0, 0, 0, 0.5);
    border-left: ${radius} solid ${({theme}) => theme.primary};
  }
`;

const TemplateNotLoaded = styled(NotLoaded)`
  display: block;
  height: 30px;
  min-height: 30px;
`;

export default ComponentTemplates;
