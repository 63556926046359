import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faBoxArchive,
  faCalendarDay,
  faKeyboard,
  faNoteSticky,
  faPaperclip,
  faSearch,
  faStickyNote
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import usePrevious from "../../hooks/usePrevious.js";

// Utils
import {prettyDateWithDayInUserTimezone} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import BaseTable from "../../components/BaseTable.js";
import ModalNotes from "../checksheets/ModalNotes.js";
import ModalAttachments from "../checksheets/ModalAttachments.js";
import ModalRecordView from "../checksheets/ModalRecordView.js";
import ToggleSwitch from "../../components/ToggleSwitch.js";
import ArchiveRecord from "./ArchiveRecord.js";

// Style
import {pad} from "../../style/components/variables.js";
import {bp} from "../../style/components/breakpoints.js";
import {voice} from "../../style/components/typography.js";
import {
  Button,
  Heading,
  Abbr,
  Inline,
  Text,
  HeadingMedium,
  TableFooter,
  TableWrapper,
  Small,
  SearchWrapper,
  Search,
  Error,
  Pill,
  SearchIcon
} from "../../style/components/general.js";

const ModalViewAllRecords = ({visible, setVisible, target, reload}) => {
  const {id: checksheetId, name, frequency, builder, restrictTo} = target || {};

  const isMounted = useMountedState();

  const {roleCanAccessResource, atLeast, roles} = useContext(AuthContext);
  const {facility} = useContext(FacilityNavContext);

  const {settings} = useContext(SettingsContext);

  const isActive = useMemo(() => facility && !facility.isDeleted, [facility]);

  const [searchType, setSearchType] = useState("text");
  const [tableData, setTableData] = useState(null);
  const [query, setQuery] = useState(null);
  const [orderBy, setOrderBy] = useState("desc");
  const [groupBy, setGroupBy] = useState("createdAt");
  const [selected, setSelected] = useState(null);
  // Pagination
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  // Modals
  const [showModal, setShowModal] = useState(false);
  const [viewRecordVisible, setViewRecordVisible] = useState(false);
  const [modalNotesVisible, setModalNotesVisible] = useState(false);
  const [modalAttachmentsVisible, setModalAttachmentsVisible] = useState(false);
  const [modalAcknowledgeVisible, setModalAcknowledgeVisible] = useState(false);
  const [modalExternalVisible, setModalExternalVisible] = useState(false);

  const showDeleted = useRef(false);
  const scrollPos = useRef(null);
  const scrollContainer = useRef(null);

  const {api, loading} = useApi("checksheet-records");

  const limit = useRef(12);

  const prevOrderBy = usePrevious(orderBy);
  const prevGroupBy = usePrevious(groupBy);

  const getNotesColumnContent = useCallback(
    record => {
      if (record.overdue && !record.archived && atLeast("admin"))
        return (
          <Inline>
            {!!record.noteCount && (
              <NoteIcon
                onClick={() => {
                  setSelected(record);
                  setModalNotesVisible(true);
                }}>
                <FontAwesomeIcon icon={faStickyNote} />
                <p>{record.noteCount}</p>
              </NoteIcon>
            )}
          </Inline>
        );

      if (record.noteCount || record.fileCount)
        return (
          !!record.noteCount && (
            <NoteIcon
              onClick={() => {
                setSelected(record);
                setModalNotesVisible(true);
              }}>
              <FontAwesomeIcon icon={faStickyNote} />
              <p>
                {record.noteCount +
                  (atLeast("admin") && record.archivedNotes ? record.archivedNotes.length : 0)}
              </p>
            </NoteIcon>
          )
        );

      return null;
    },
    [atLeast]
  );

  const getAttachmentsColumnContent = useCallback(
    record =>
      !!record.fileCount && (
        <Abbr title={`${record.fileCount} record attachment(s)`}>
          <AttachmentIcon
            onClick={() => {
              setSelected(record);
              setModalAttachmentsVisible(true);
            }}>
            <FontAwesomeIcon icon={faPaperclip} />
            <p>{record.fileCount}</p>
          </AttachmentIcon>
        </Abbr>
      ),
    []
  );

  const formatTableData = useCallback(
    records =>
      records?.map(record => ({
        submission: (
          <Inline>
            {roleCanAccessResource("facility_checksheet_record", "view") && (
              <>
                <Button
                  type="button"
                  onClick={() => {
                    setViewRecordVisible(true);
                    setSelected(record);
                  }}
                  data-testid="tableRecord.preview">
                  View
                </Button>
                {roleCanAccessResource("facility_checksheet_record", "archive") &&
                  record.overdue &&
                  !record.archived &&
                  isActive && (
                    <Edit
                      onClick={() => {
                        setSelected(record);
                        setModalAcknowledgeVisible(true);
                      }}>
                      <Abbr title="Archive">
                        <FontAwesomeIcon icon={faArchive} />
                      </Abbr>
                    </Edit>
                  )}
              </>
            )}
          </Inline>
        ),
        noteCount: getNotesColumnContent(record),
        fileCount: getAttachmentsColumnContent(record),
        status: (
          <Status status={record.status} archived={record.archived} quiet>
            {record.archived && (
              <Abbr title="Archived">
                <FontAwesomeIcon icon={faBoxArchive} />
                &nbsp;
              </Abbr>
            )}
            {record.status}
            {record.draft ? ` - ${record.percentComplete}%` : ""}
          </Status>
        ),
        dateDue: record.overdue ? (
          <WarningMsg>
            {prettyDateWithDayInUserTimezone(record.dateDue, settings.timezone, "ddd, MMM D YYYY")}
          </WarningMsg>
        ) : (
          prettyDateWithDayInUserTimezone(record.dateDue, settings.timezone, "ddd, MMM D YYYY")
        ),
        completedAt: !record.overdue && (
          <Completed late={dayjs(record.completedAt).isAfter(dayjs(record.dateDue))}>
            {record.completedBy &&
              record.completedBy.firstName &&
              `${record.completedBy.firstName} ${record.completedBy.lastName} on `}
            {prettyDateWithDayInUserTimezone(
              record.completedAt,
              settings.timezone,
              "ddd, MMM D YYYY"
            )}
          </Completed>
        )
      })),
    [roleCanAccessResource, isActive, getNotesColumnContent, getAttachmentsColumnContent, settings]
  );

  const getTableData = useCallback(
    (p, l) => {
      setSelected(null);

      const filter = {showDeleted: showDeleted.current};

      if (query) filter.Search = query;

      const payload = {
        page: p || 1,
        orderBy: orderBy,
        groupBy,
        limit: l ?? limit.current,
        filter: JSON.stringify(filter)
      };

      if (checksheetId) payload.facilityChecksheetId = checksheetId;

      api.callGet(null, payload).then(({status, data}) => {
        if (status === 200 && data) {
          const formatted = data.records ? formatTableData(data.records) : [];

          // Page Limit
          const {page, pages, total: dataTotal} = data;

          setCurrentPage(page);
          setPageTotal(pages);
          setTableData(prev => (p === 1 ? formatted : [...prev, ...formatted]));
          setTotal(dataTotal);
        }
      });
    },
    [api, formatTableData, checksheetId, query, orderBy, groupBy]
  );

  useEffect(() => {
    if (
      isMounted() &&
      prevOrderBy &&
      prevGroupBy &&
      (prevOrderBy !== orderBy || prevGroupBy !== groupBy)
    )
      getTableData(1);
  }, [isMounted, getTableData, prevOrderBy, prevGroupBy, orderBy, groupBy]);

  useEffect(() => {
    if (query || query === "") getTableData(1);
  }, [getTableData, query]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && !tableData) {
      // estimation for number of cards to overflow
      const approxRows = Math.ceil((window.innerHeight * 0.9 - 200) / 50);
      limit.current = approxRows;
      getTableData(1, approxRows);
    }
  }, [isMounted, tableData, getTableData]);

  // Load more on Scroll
  const handleScroll = useCallback(() => {
    const maxScrollTop =
      scrollContainer.current.scrollHeight - scrollContainer.current.clientHeight;
    if (
      scrollContainer.current.scrollTop + 1 >= maxScrollTop &&
      currentPage < pageTotal &&
      !loading
    )
      getTableData(currentPage + 1);
  }, [currentPage, getTableData, loading, pageTotal]);

  useEffect(() => {
    const modal = scrollContainer.current;
    modal.addEventListener("scroll", handleScroll);
    return () => modal.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useLayoutEffect(() => {
    if (
      viewRecordVisible ||
      modalNotesVisible ||
      modalAttachmentsVisible ||
      modalAcknowledgeVisible ||
      modalExternalVisible
    ) {
      scrollPos.current = document.getElementById("modal").scrollTop;
      setShowModal(true);
    } else {
      scrollContainer.current.scrollTop = scrollPos.current;
      setShowModal(false);
    }
  }, [
    viewRecordVisible,
    modalNotesVisible,
    modalAcknowledgeVisible,
    modalAttachmentsVisible,
    modalExternalVisible
  ]);

  const handleSearch = e => {
    setCurrentPage(1);
    setPageTotal(1);
    setQuery(e.target.value);
  };

  if (showModal && viewRecordVisible && selected)
    return (
      <ModalRecordView
        visible={viewRecordVisible}
        setVisible={setViewRecordVisible}
        hasBackButton
        goBack={() => setViewRecordVisible(false)}
        record={selected}
        checksheet={target}
        updateData={reload}
      />
    );

  if (showModal && modalNotesVisible && selected)
    return (
      <ModalNotes
        visible={modalNotesVisible}
        setVisible={setVisible}
        hasBackButton
        goBack={() => setModalNotesVisible(false)}
        pendingNotes={selected.postedNotes}
        resolvedNotes={selected.resolvedNotes}
        noteFileCount={selected.noteFileCount}
        noteCt={selected.noteCount}
        fileCt={selected.fileCount}
        files={selected.files}
        archivedNotes={atLeast("admin") ? selected.archivedNotes : null}
        updateData={reload}
        checksheetName={name}
        dateDue={selected.dateDue}
        builder={builder}
      />
    );

  if (showModal && modalAttachmentsVisible && selected)
    return (
      <ModalAttachments
        visible={modalAttachmentsVisible}
        setVisible={setVisible}
        hasBackButton
        goBack={() => setModalAttachmentsVisible(false)}
        builder={builder}
        files={selected.files}
        pendingNotes={selected.postedNotes}
        resolvedNotes={selected.resolvedNotes}
      />
    );

  if (showModal && modalAcknowledgeVisible && selected)
    return (
      <ArchiveRecord
        visible={modalAcknowledgeVisible}
        setVisible={setVisible}
        hasBackButton
        goBack={() => setModalAcknowledgeVisible(false)}
        updateData={reload}
        record={selected}
        checksheet={target}
      />
    );

  if (showModal && modalExternalVisible)
    return (
      <Modal
        visible={modalExternalVisible}
        setVisible={setVisible}
        hasBackButton
        goBack={() => setModalExternalVisible(false)}>
        <HeadingMedium>External Checksheet</HeadingMedium>
        <Text>
          This is an external checksheet available to&nbsp;
          {roles
            ? restrictTo
                ?.filter(({id}) => roles.filter(({id: roleId}) => id === roleId)[0]?.label)
                .map(({id}) => roles.filter(({id: roleId}) => id === roleId)[0].label)
                .join(", ")
            : "roles"}
          . It is only available for completion via a link provided by email.
        </Text>
      </Modal>
    );

  return (
    <Modal visible={visible} setVisible={setVisible} scrollRef={scrollContainer}>
      <TableWrapper>
        <TableMenuRow>
          <Inline>
            <ChecksheetName>
              <Abbr title={name}>{name}</Abbr>
            </ChecksheetName>
            {frequency?.name && (
              <Frequency>
                <Abbr title={frequency.name.toUpperCase()}>{frequency.name.toUpperCase()}</Abbr>
              </Frequency>
            )}
            <SearchWrapper>
              {searchType === "text" && (
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
              )}
              <Search
                name="search"
                type={searchType}
                placeholder="Search..."
                onChange={handleSearch}
              />
              <Spaced>
                <ToggleSwitch
                  iconLeft={faKeyboard}
                  iconRight={faCalendarDay}
                  textLeft="Text"
                  textRight="Date"
                  onChange={() => setSearchType(prev => (prev === "text" ? "date" : "text"))}
                  options={["text", "date"]}
                  defaultSelected="text"
                />
              </Spaced>
            </SearchWrapper>
          </Inline>
        </TableMenuRow>
        <BaseTable
          headings={{
            facilityChecksheetId: {header: "", disabled: true},
            submission: {header: "Record", disabled: true},
            noteCount: {
              header: (
                <Abbr title="Notes">
                  <NoteColumnHeader icon={faNoteSticky} />
                </Abbr>
              ),
              disabled: false
            },
            fileCount: {
              header: (
                <Abbr title="Attachments">
                  <AttachmentColumnHeader icon={faPaperclip} />
                </Abbr>
              ),
              disabled: false
            },
            status: {header: "Status", disabled: false},
            dateDue: {header: frequency ? "Due" : "", disabled: false},
            completedAt: {header: "Date Completed", disabled: false}
          }}
          data={tableData}
          loading={loading}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
        />
        <TableFooter>
          <Inline>
            <Text>
              <span>{total} total</span>
            </Text>
            {target?.external && (
              <External quiet>
                External checksheet available to&nbsp;
                <Button type="button" onClick={() => setModalExternalVisible(true)} inText quiet>
                  roles
                </Button>
                .
              </External>
            )}
          </Inline>
        </TableFooter>
      </TableWrapper>
    </Modal>
  );
};

ModalViewAllRecords.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  target: PropTypes.objectOf(PropTypes.any).isRequired,
  reload: PropTypes.func.isRequired
};

// Style Overrides

const TableMenuRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pad}px;
`;

const NoteIcon = styled(Inline)`
  flex-wrap: nowrap;
  color: ${({theme}) => theme.primary};
  width: min-content;
  cursor: pointer;
  margin-bottom: 2px;
  font-size: 14px;
  gap: 2px;

  svg {
    pointer-events: none;
    fill: ${({theme}) => theme.primary};
    font-size: 14px;
  }

  p {
    margin-top: 1px;
    margin-left: ${pad / 2}px;
  }
`;

const ChecksheetName = styled(Heading)`
  text-overflow: ellipsis;
  overflow-x: hidden;
  display: block;
  white-space: nowrap;
  max-width: 65%;

  ${bp(2)} {
    max-width: inherit;
  }
`;

const Frequency = styled(Small)`
  width: fit-content;
  min-width: min-content;
  padding: ${pad / 4}px ${pad / 2}px;
  border-radius: 10px;
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.primary};
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  ${voice.quiet};
`;

const Spaced = styled.div`
  margin-left: ${pad}px;
`;

const NoteColumnHeader = styled(FontAwesomeIcon)`
  fill: ${({theme}) => theme.tertiary};
`;

const AttachmentColumnHeader = styled(FontAwesomeIcon)`
  fill: ${({theme}) => theme.tertiary};
`;

const WarningMsg = styled(Error)`
  ${voice.normal};
  color: ${({theme}) => theme.error};
`;

const Completed = styled(Error)`
  ${voice.normal};
  color: ${({late, theme}) => (late ? theme.error : "inherit")};
`;

const AttachmentIcon = styled(Inline)`
  flex-wrap: nowrap;
  color: ${({theme}) => theme.primary};
  width: min-content;
  cursor: pointer;
  margin-bottom: 2px;
  font-size: 14px;

  svg {
    pointer-events: none;
    fill: ${({theme}) => theme.primary};
    font-size: 14px;
  }

  p {
    margin-top: 1px;
    margin-left: ${pad / 2}px;
  }
`;

const TransitionButton = styled(Button)`
  transition: opacity 100ms;
  opacity: 1;
  visibility: visible;

  ${({locked}) =>
    locked
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
        `
      : ""}

  ${({loading}) =>
    loading
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : ""}
`;

const Edit = styled(TransitionButton)`
  display: none;

  ${bp(1)} {
    display: block;
  }
`;

const Status = styled(Pill)`
  width: 118px;
  justify-content: center;

  background: ${({status, theme}) => {
    if (status === "Incomplete") return theme.warning;
    if (status === "Overdue") return theme.error;
    if (status === "Late") return theme.alert;
    return theme.success;
  }};

  ${({archived}) =>
    archived &&
    css`
      opacity: 0.7;
    `}

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const External = styled(Text)`
  display: inherit;
`;

export default ModalViewAllRecords;
